<!-- <span class="status-cell-btn lh-sm ml-rem" 
[ngStyle]="{
    'color': params?.value === defaultStatusName ? 'text-secondary' : params?.data?.statusColorCode || params?.data?.priorityColorCode || params?.data?.ecommerceOrderStatusColorCode || colorAdded,
    'border-color': params?.value === defaultStatusName ? 'text-secondary' : params?.data?.statusColorCode || params?.data?.priorityColorCode || params?.data?.ecommerceOrderStatusColorCode || colorAdded}">
    <span class="f-10">
        {{ params?.value ||'' }}
    </span>
</span> -->


<div class="d-flex align-items-center gap-2 ">
    <span class="status-cell-btn lh-sm ml-rem position-relative" 
    [ngStyle]="{
        'color': params?.value === defaultStatusName ? 'text-secondary' : params?.data?.statusColorCode || params?.data?.priorityColorCode || params?.data?.ecommerceOrderStatusColorCode  || colorAdded,
        'border-color': params?.value === defaultStatusName ? 'text-secondary' : params?.data?.statusColorCode || params?.data?.priorityColorCode || params?.data?.ecommerceOrderStatusColorCode || colorAdded}">
        <span class="f-10 ">
            {{ params?.value ||'' }}
        </span>
        <span class="position-absolute transfer_icon" *ngIf="params.value === 'Failed'" [ngClass]="(params?.data?.emailStatusColorCode || params?.data?.smsStatusColorCode || params?.data?.whatsappStatusColorCode)? d-flex : d-none">
            <app-svg-icon [name]="'transfer_icon'" class="cursorPointer"></app-svg-icon>
        </span>
    </span>
    <!-- ViewIcon icon start --> 
    <span class="view_icon_renderer" *ngIf="params?.viewIcon" [ngbTooltip]="getTooltipContent(params?.colDef?.field)" placement="top" container="body" (click)="onViewClick(params)">
        <app-svg-icon [name]="'view'" [width]="'24'" [height]="'18'"></app-svg-icon>
    </span>
    <!-- ViewIcon icon end --> 
</div>

