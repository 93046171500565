<div class="container-fluid d-flex flex-column h-100 px-0 gap-2">
    <app-load-dropdown-data [formName]="formName" (fillDropdownData)="fillDropdownData()"></app-load-dropdown-data>
    <div class="row">
        <div class="col-12">
            <div class="row mt-2 d-flex justify-content-between">
                <div class="col-xl-6 col-lg-6 col-md-6 pe-0">
                    <app-search-input></app-search-input>
                </div>
                <div *ngIf="catelogueForm == catelogue?.InventoryCatelogue" class="col-xl-5 col-lg-5 col-md-5 d-flex justify-content-end gap-2 align-items-center">
                    <div class="position-relative">
                        <span class="f-10 font-semibold  position-absolute filter-count left_1-2">
                            <span class="d-flex justify-content-center">{{filterCountValue}}</span>
                        </span>
                        <app-button class="" (buttonOutputEvent)="openAdvanceFilterPopUp()" showFilterIcon="true"
                        [buttonClass]="'white_button common-filter-size gap-0'" [buttonType]="'button'" showIconCount="true">
                        </app-button>
                    </div>
                    <div class="d-flex align-items-center justify-content-end">
                        <app-button class="" showRefreshIcon="true" [buttonClass]="'white_button common-refresh-size gap-0'"
                                    [buttonType]="'button'" (buttonOutputEvent)="resetFilter()">
                        </app-button>
                    </div>
                    <app-button class="" [buttonLabel]="'Sync Jewellry Catalogue'" [buttonClass]="'dark_pink_button text-nowrap'"
                        [buttonId]="'sync_Catalogue'" [buttonType]="'submit'"
                        (buttonOutputEvent)="syncJewellryCatalogueButton()">
                    </app-button>
                    <span *ngIf="selectedCardCount" class="f-10 font-semibold  position-absolute filter-count right_14-9">
                        <span class="d-flex justify-content-center">{{selectedCardCount}}</span>
                    </span>
                    <app-button class="" [buttonLabel]="'New Sale Order'" [buttonClass]="'dark_pink_button text-nowrap'"
                        [buttonId]="'new_saleorder'" [buttonType]="'submit'"
                        (buttonOutputEvent)="newSaleOrder()"
                        [ngbTooltip]="sendDataService?.noPermissionTooltip"
                        [disableTooltip]="sendDataService?.permissionVM?.salesOrderCanView"
                        [disabled]="!(sendDataService?.permissionVM?.salesOrderCanView)">
                    </app-button>
                    <span *ngIf="selectedCardCount" class="f-10 font-semibold  position-absolute filter-count right_5">
                        <span class="d-flex justify-content-center">{{selectedCardCount}}</span>
                    </span>
                    <app-button class=""
                        [buttonLabel]="'Catalogue Quotation'" [buttonClass]="'dark_pink_button text-nowrap'"
                        [buttonId]="'Catalogue_Quotation'" [buttonType]="'submit'"
                        (buttonOutputEvent)="CatalogueQuotation()"
                        [ngbTooltip]="sendDataService?.noPermissionTooltip"
                        [disableTooltip]="sendDataService?.permissionVM?.catalogueQuotationCanView"
                        [disabled]="!(sendDataService?.permissionVM?.catalogueQuotationCanView)"
                        >
                    </app-button>
                    <app-button class="" [buttonLabel]="'Add'" [buttonClass]="'blue_button'" [buttonType]="'button'"
                        [showPlusIconwhite]="true" (buttonOutputEvent)="navigateToDetails(0)">
                    </app-button>
                    <!-- [ngbTooltip]="sendDataService?.noPermissionTooltip"
                    [disableTooltip]="(this.formModel?.invoiceModel?.id ? sendDataService?.permissionVM?.jewelryCatalogueCanUpdate : sendDataService?.permissionVM?.jewelryCatalogueCanAdd)"
                    [disabled]="!(this.formModel?.invoiceModel?.id ? sendDataService?.permissionVM?.jewelryCatalogueCanUpdate : sendDataService?.permissionVM?.jewelryCatalogueCanAdd)" -->
                </div>
            </div>
        </div>
    </div>
    <div class="row product_images m-0 flex-fill h-0 scroll-container px-1">
        <ng-container  *ngIf="!isCardLoading && showNoRecord" >
            <div class="no-record-item d-flex justify-content-center align-items-center">
                <p class="font-family-text-color">No Record found</p>
            </div>
        </ng-container> 
        <div class="product_box col-xl-2 col-lg-2 col-2 px-2 pb-3" *ngFor="let item of isCardLoading ? shimmerCard : jewelryCatalogueList ">
            <ng-container *ngIf="isCardLoading">
                <app-shimmering-effect
                [CardView]="'JewelleryCard'" >
               </app-shimmering-effect> 
                </ng-container> 
            <div *ngIf="!isCardLoading" class="card m-0" [ngClass]="{'selected_card': selectedCards?.includes(item)}">
                <div class="d-flex justify-content-end " *ngIf="item?.label; let i=index">
                   <div class="text-gray-color out_stock f-14 font-semibold">{{item?.label}}</div>
                </div>
                <div class="product_img cursorPointer">
                    <!-- [ngClass]="{'pe-none':isCheckDefault(item?.images[i]?.imageUrl)}" -->
                    <img class="img-fluid" (click)="openImgPopUp(item)" [src]="isBase64Image(item?.images[0]?.imageUrl)" alt="jewelry image" />
                </div>
                <div class="card-body px-2 py-1">
                        <div class="f-14 font-semibold">
                            <div class="f-16 font-semibold tags text-truncate">
                                {{item?.designNo || 'NA'}}
                            </div>
                        </div>
                    <div class=" d-flex flex-row justify-content-between ">
                        <div class="col-10">
                            <div class="d-flex justify-content-between">
                                <div class="text-gray-color title f-12 w_180px font-semibold cursorPointer text-truncate"
                                    (click)="navigateToDetails(item?.id)"> {{item?.title ?? 'NA'}}</div>
                            </div>
                            <div class="text-primary font-semibold f-16 lh-1"> {{item?.weight || 'NA'}} <span *ngIf="item?.weight">gm</span>
                            </div>
                        </div>
                        <div class="col" (click)="item?.item?.length === 0 ? null : onCardClickForSelected(item)"
                            [ngClass]="{'opacity-30':item?.item?.length === 0 ,'cursorPointer':item?.item?.length !== 0}"
                            [ngbTooltip]="item?.item?.length === 0 ? 'BOM not added in this Item.' : 'Add item for Sale Order'">
                            <div *ngIf="selectedCards?.includes(item)">
                                <app-svg-icon name="addCartwithoutbackground"></app-svg-icon>
                            </div>
                            <div *ngIf="!selectedCards?.includes(item)">
                                <app-svg-icon name="addCardWithbackground"></app-svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row py-1 mx-0 px-0">
        <div class="col-12 d-flex justify-content-between align-items-center pagination_div px-2 border">
            <div class="text-nowrap f-11 text-gray-color"> Showing <span class="font-semibold">{{totalRecords === 0 ? '0' : getStartRange()}}</span>
                to <span class="font-semibold">{{getEndRange()}}</span> of <span class="font-semibold">{{totalRecords}}</span> entries</div>
            <div class="d-flex">
                <div class="page-size-control px-2 d-flex align-items-center">
                    <select [(ngModel)]="getAllJewelryCatalogueRequestModel.pageSize" (ngModelChange)="onPageSizeChange()" class="page-size-dropdown f-11 cursorPointer pe-2">
                        <option *ngFor="let option of paginationPageSizes?.limitOptions" [ngValue]="option?.value">
                            Show {{option?.key}} Items
                        </option>
                    </select>
                    
                </div>
                <ngb-pagination 
                    #ngbPagination 
                    [collectionSize]="getAllJewelryCatalogueRequestModel?.getAll ? 1 : totalRecords" 
                    [pageSize]="getAllJewelryCatalogueRequestModel?.getAll ? 1 : getAllJewelryCatalogueRequestModel?.pageSize" 
                    [page]="getAllJewelryCatalogueRequestModel?.getAll ? 1 : getAllJewelryCatalogueRequestModel?.page" 
                    [maxSize]="5" 
                    (pageChange)="onPageChanged($event)"
                    [boundaryLinks]="true"
                    class="d-flex justify-content-end grid-pagination">
                    <ng-template ngbPaginationPrevious>
                        <img src="./assets/svg/left-arrow.svg" alt="" />
                    </ng-template>
                    <ng-template ngbPaginationNext>
                        <img src="./assets/svg/right-arrow.svg" alt="" />
                    </ng-template>
                </ngb-pagination>
            </div>
        </div>
    </div> 
</div>