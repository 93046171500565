import { Component, Input } from '@angular/core';
import { RegisterUserRequestModel, RegisteredUserVM } from '../business-registration.model';

@Component({
  selector: 'app-bank-details-registration',
  templateUrl: './bank-details-registration.component.html',
  styleUrls: ['./bank-details-registration.component.scss']
})
export class BankDetailsRegistrationComponent {
  @Input("user") usermodel : RegisteredUserVM =  new RegisteredUserVM();

  onKeydown(event: KeyboardEvent, targetElementId: string): void {
    if (event.key === 'Enter') {
      const nextElement = document.getElementById(targetElementId);
      if (nextElement) {
        nextElement.focus();
      }
    }
  }
  
}
