
<div class="row py-2">
    <div class="col-12 gap-2 d-flex align-items-center">
    
        <div *ngIf="metalSectionModel?.isBarcodeInput" class="d-flex align-items-center">
            <form action="">
                <div class="d-flex align-items-center gap-2">
                    <label class="f-14 font-semibold text-gray-color text-nowrap mb-0"> Barcode
                    </label>
                    <div>
                        <app-input 
           
                        [inputName]="'barcodeNo'" 
                        [inputType]="'text'" 
                        [inputId]="'barcodeNo'"
                        [inputValue]="metalSectionModel.barcodeNo"
                        (inputValueEmit)="metalSectionModel.barcodeNo = $event"
                        [isRequired]="false" 
                        (keydown.enter)="getProductDataByBarcodeNo()"
                        >
                        </app-input> 
                       
                    </div>
                    <div class="input-group-append "(click)="clearBarcode()">
                        <span class="input-group-text px-2 cursorPointer  ">
                            <img src="./assets/images/svg-icon/barcode_icon.svg" alt="">
                        </span>
                    </div>
                    <div class="d-flex align-items-center cursorPointer" *ngIf="formName==storage.FORMNAME_TRANSACTION_SALESINVOICE">
                        <app-svg-icon name="fromDept"></app-svg-icon>
                    </div>
                </div>
            </form>
        </div>
        <div class="w-241px" *ngIf="metalSectionModel?.isDesignNo">
            <div class="d-flex align-items-center gap-2">
                <label for="designno" class="f-14 font-semibold text-gray-color text-nowrap mb-0 w-75px">Design No
                </label>
                <div class="w-100" style="z-index: 1;">
                    <app-custom-select2 [whichDropdown]="storage.DESIGN_NO_DROPDOWN"
                        (valueChanged)="assignDesignNo($event)"></app-custom-select2>
                </div>
                <app-svg-icon name="reseticon" class="cursorPointer" (click)="resetdesignNoDropdown()"></app-svg-icon>                        
            </div>
        </div>
       
        <div class="col d-flex align-items-center gap-2 justify-content-end">
            <div class="d-flex gap-3">
                <div class="d-flex align-items-center" *ngIf="metalSectionModel.isInternal_Fix">
                    <app-checkbox  (check)="metalFixChecked($event)" [status]="formModel?.invoiceModel?.metalFix" [disabled]="false"></app-checkbox>
                    <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="formModel.invoiceModel.metalFix = !formModel.invoiceModel.metalFix; metalFixChecked(formModel.invoiceModel.metalFix)">Metal Unfix</span>
                </div>
                <div class="d-flex align-items-center" *ngIf="metalSectionModel.isInternal_Fix">
                    <app-checkbox (check)="internalFixChecked($event)" [status]="formModel?.invoiceModel?.internalFix" [disabled]="false"></app-checkbox>
                    <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="formModel.invoiceModel.internalFix = !formModel.invoiceModel.internalFix; internalFixChecked(formModel.invoiceModel.internalFix)">UnFix</span>
                </div>
                <div class="d-flex align-items-center" *ngIf="metalSectionModel.isAML">
                    <app-checkbox (check)="AMLChecked($event)" [status]="formModel?.invoiceModel?.isAML" [disabled]="false"></app-checkbox>
                    <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="formModel.invoiceModel.isAML = !formModel.invoiceModel.isAML; AMLChecked(formModel.invoiceModel.isAML)">AML</span>
                </div>
                <div class="d-flex align-items-center" *ngIf="metalSectionModel.isKYC">
                    <app-checkbox (check)="KYCChecked($event)" [status]="formModel?.invoiceModel?.isKYC" [disabled]="false"></app-checkbox>
                    <span class="text-primary f-12 font-semibold ps-2 cursorPointer" (click)="formModel.invoiceModel.isKYC = !formModel.invoiceModel.isKYC; KYCChecked(formModel.invoiceModel.isKYC)">KYC</span>
                </div>
            </div>
            <form action="">
                <div class="d-flex align-items-center">
                    <div class="">
                      <label class="f-14 font-semibold text-gray-color text-nowrap mb-0 pe-2"> Goz
                    </label>
                    </div>
                    <div class="col">
                      <app-input
                        [applyDecimalOrPercent]="true"
                        [decimalRestrictedDigit]="3"
                        [isBlureApplicable]="true"
                        [inputName]="'gozUsdRate'"
                        [inputType]="'text'"
                        [inputId]="'gozUsdRate'"
                        [inputValue]="metalSectionModel?.gozUsdRate"
                        (inputValueEmit)="metalSectionModel.gozUsdRate = $event">
                      </app-input>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<button class="dashed-button"  [disabled]="!formModel?.invoiceModel?.accountLedgerId && metalSectionModel?.isSelectedAccountLedgerId" 
[ngClass]="{'opacity-50 pointer_none': !formModel?.invoiceModel?.accountLedgerId && metalSectionModel?.isSelectedAccountLedgerId, 'cursorPointer': formModel?.invoiceModel?.accountLedgerId || !metalSectionModel?.isSelectedAccountLedgerId }"    (click)="formModel.voucherType === voucherTypeName.Appraisal ? openMetalSectionPopupAppraisal() :openMetalSectionPopup(undefined)">
    <i class="fa fa-box"></i> Add Item (Shift + Q)
</button>
          
