export class Administration {
}
export class CardMainModel{
    id:number;
    cardTitle:string;
    cardTableData:CardTableData[]=[];
    isAdd:false;
  }
  
  export class CardTableData{
    id:number;
    name?:string|null;
    description?:string|null;
    purityId?: number;
    purityPer?:string|null;
    ischecked: boolean;
    ID?: string|number|null;
    isEdit: false;
   
  }

  // export enum AdministrationCardTitle {
  //   Category = "Category",
  //   Purity = "Purity",
  //   Warehouse = "Warehouse",
  //   Carat = "Carat",
  //   Brand = "Brand",
  //   Salesperson = "Salesperson",
  //   Remark = "Remark",
  //   Unit = "Unit",
  // }
  
  // purity model 
  export class InsertPurityRequestModel{
    name             : string;
    active?          : boolean;
    isEdit?          : boolean = false;
  }
  
  export class UpdatePurityRequestModel extends InsertPurityRequestModel{
    id               : number;
  }

  export class GetAllPurityResponseModel extends InsertPurityRequestModel{
    id               : number;
  }
// carat model
  export class InsertCaratRequestModel{
    name?            : string;
    purityId?        : string;
    purityPer?       : string;
    active?          : boolean;
    description      : string;
    isEdit?          : boolean = false;
  }

  export class UpdateCaratRequestModel extends InsertCaratRequestModel{
    id               : number;
  }

  export class GetAllCaratResponseModel extends InsertCaratRequestModel{
    id               : number;
  }
  
  // Unit Model
  export class InsertUnitRequestModel{
    noOfDecimalPlaces?: number;
    description?      : string;
    formalName?       : string;
    name?             : string;
    active?           : boolean;
    isEdit?           : boolean = false;
  }

  export class UpdateUnitRequestModel extends InsertUnitRequestModel{
    id                : number;
  }

  export class GetAllUnitResponseModel extends InsertUnitRequestModel{
    id                : number;
  }
 
  // Remark Model
  export class InsertRemarkRequestModel{
    name            : string;
    active          : boolean;
    isEdit?         : boolean = false;
  }

  export class UpdateRemarkRequestModel extends InsertRemarkRequestModel{
    id               : number;
  }
  export class GetAllRemarkResponseModel extends InsertRemarkRequestModel{
    id               : number;
  }
  
   // Category Model
   export class InsertCategoryRequestModel{
    name            : string;
    active?         : boolean;
    isEdit?         : boolean = false;
  }

  export class UpdateCategoryRequestModel extends InsertCategoryRequestModel{
    id               : number;
  }

  export class GetAllCategoryResponseModel extends InsertCategoryRequestModel{
    id               : number;
  }
////////////Task Type Model////////////////////
///insert model
export class InsertTaskTypeRequestModel{
  name              ?: string;
  active            : boolean = true;
  isEdit            ?: boolean = false;
}
///update model
export class UpdateTaskTypeRequestModel extends InsertTaskTypeRequestModel{
  id: 0;
  name: string;
  active: boolean = true;
}
//get all task type
export class GetAllTaskTypeResponseModel extends InsertTaskTypeRequestModel{
  id                ?:number;
  name            : string;
  active         : boolean;
}

////////////////Break Type/////////////////////
export class InsertBreakTypeRequestModel{
  name              ?: string;
  active            : boolean = true;
  isEdit            ?: boolean = false;
}
// ///update model
export class UpdateBreakTypeRequestModel extends InsertBreakTypeRequestModel{
  id: 0;
  name: string;
  active: boolean = true;
}
//get all task type
export class GetAllBreakTypeResponseModel extends InsertBreakTypeRequestModel{
  id               ?:number;
  name             : string;
  active           : boolean;
}

////////////////Campaign Group/////////////////////
export class InsertCampaignGroupRequestModel{
  name              ?: string;
  active            : boolean = true;
  isEdit            ?: boolean = false;
}
// ///update model
export class UpdateCampaignGroupRequestModel extends InsertCampaignGroupRequestModel{
  id: 0;
  name: string;
  active: boolean = true;
}
//get all CamaignGroup
export class GetAllCampaignGroupResponseModel extends InsertCampaignGroupRequestModel{
  id               ?:number;
  name             : string;
  active           : boolean;
}
//-------------------------------

////////////Loan Product Type////////////////////
///insert model
export class InsertLoanProductTypeRequestModel{
  name              ?: string;
  active            ?: boolean;
  isEdit            ?: boolean = false;
}
///update model
export class UpdateLoanProductTypeRequestModel extends InsertLoanProductTypeRequestModel{
  id: 0;
  name: string;
  active: boolean = true;
}
///Get All Loan Product Type
export class GetAllLoanProductTypeResponseModel extends InsertLoanProductTypeRequestModel{
  id                ?:number;
  name            : string;
  active?         : boolean; 
}
//-------------------------------

////////////Loan Product Type////////////////////
///insert model
export class InsertLoanReasonRequestModel{
  name              ?: string;
  active            ?: boolean;
  isEdit            ?: boolean = false;
}
///update model
export class UpdateLoanReasonTypeRequestModel extends InsertLoanReasonRequestModel{
  id: 0;
  name: string;
  active: boolean = true;
}
///Get All Loan Product Type
export class GetAllLoanReasonResponseModel extends InsertLoanReasonRequestModel{
  id                ?:number;
  name            : string;
  active?         : boolean; 
}
//-------------------------------



////////////Expences Category Model////////////////////
///insert model
export class InsertExpensesCategoryRequestModel{  
  name              ?: string;
  active            ?: boolean;
  isEdit            ?: boolean = false;
}
///update model
export class UpdateExpensesCategoryRequestModel extends InsertExpensesCategoryRequestModel{
  id: 0;
  name: string;
  active: boolean = true;
}
//get all ExpensesCategory
export class GetAllExpensesCategoryResponseModel extends InsertExpensesCategoryRequestModel{
  id                ?:number;
  name            : string;
  active?         : boolean; 
}

  // Qaulity Model
  export class InsertQaulityRequestModel{
  name            : string;
  active?         : boolean;
  isEdit?         : boolean = false;
  }

  export class UpdateQaulityRequestModel extends InsertQaulityRequestModel{
    id               : number;
  }

  export class GetAllQaulityResponseModel extends InsertQaulityRequestModel{
    id              : number;
  }

  // location model start
    export class InsertLocationRequestModel {
      name              ?: string;
      active            ?: boolean;
      isEdit            ?: boolean = false;
    }
  
    export class UpdateLocationRequestModel extends InsertLocationRequestModel{
      id                ?:number;
    }

    export class GetAllLocationResponseModel extends InsertLocationRequestModel{
      id                ?:number;
    }
    // location model end
  

    // Collection model start
    export class InsertCollectionRequestModel {
      name              ?: string;
      active            ?: boolean;
      description       ?: string;
      isEdit            ?: boolean = false;
    }
  
    export class UpdateCollectionRequestModel extends InsertCollectionRequestModel{
      id                ?:number;
    }

    export class GetAllCollectionResponseModel extends InsertCollectionRequestModel{
      id                ?:number;
    }
    
    // Collection model end
  

    // Color model start
    export class InsertColorRequestModel {
      name              ?: string;
      active            ?: boolean;
      description       ?: string;
      isEdit            ?: boolean = false;
    }
  
    export class UpdateColorRequestModel extends InsertColorRequestModel{
      id                ?:number;
    }

    export class GetAllColorResponseModel extends InsertColorRequestModel{
      id                ?:number;
    }

    // Color model end
  

    // Cut model start
    export class InsertCutRequestModel {
      name              ?: string;
      active            ?: boolean;
      isEdit            ?: boolean = false;
    }
  
    export class UpdateCutRequestModel extends InsertCutRequestModel{
      id                ?:number;
    }

    export class GetAllCutResponseModel extends InsertColorRequestModel{
      id                ?:number;
    }
  // Cut model end


  // Shape model start
    export class InsertShapeRequestModel {
      name              ?: string;
      active            ?: boolean;
      isEdit            ?: boolean = false;
    }

    export class UpdateShapeRequestModel extends InsertShapeRequestModel{
      id                ?:number;
    }
    
    export class GetAllShapeResponseModel extends InsertShapeRequestModel{
      id                ?:number;
    }
  // Shape model end


   // Size model start
    export class InsertSizeRequestModel {
      name              ?: string;
      active            ?: boolean;
      isEdit            ?: boolean = false;
    }

    export class UpdateSizeRequestModel extends InsertSizeRequestModel{
      id                ?:number;
    }
    
    export class GetAllSizeResponseModel extends InsertSizeRequestModel{
      id                ?:number;
    }
  // Size model end


   // Size model start
    export class InsertDocumentTypeRequestModel {
      name              ?: string;
      active            ?: boolean;
      isExpired         ?: boolean;
      isEdit            ?: boolean = false;
    }

    export class UpdateDocumentTypeRequestModel extends InsertDocumentTypeRequestModel{
      id                ?:number;
    }
    
    export class GetAllDocumentTypeResponseModel extends InsertDocumentTypeRequestModel{
      id                ?:number;
    }
  // Size model end

  // Metal Model
  export class InsertMetalRequestModel{
    name            : string;
    hsnCode?        : string;
    active?         : boolean;
    systemName?     : string;
    isEdit?         : boolean = false;
    }

  export class UpdateMetalRequestModel extends InsertMetalRequestModel{
    id               : number;
  }

  export class GetAllMetalResponseModel extends InsertMetalRequestModel{
    id               : number;
  }
  
  // Seivesize Model
  export class InsertSeivesizeRequestModel{
    name            : string;
    active?         : boolean;
    isEdit?         : boolean = false;
  }

  export class UpdateSeivesizeRequestModel extends InsertSeivesizeRequestModel{
    id               : number;
  }

  export class GetAllSeivesizeResponseModel extends InsertSeivesizeRequestModel{
    id               : number;
  }
  
  // SettingType Model
    export class InsertSettingTypeRequestModel {
    name            : string;
    active?         : boolean;
    value?          : number;
    settingTypeId?  : number;
    isEdit?         : boolean = false;
  }
 
  export class UpdateSettingTypeRequestModel extends InsertSettingTypeRequestModel{
    id               : number;
  }
  

  // DiamondSize Model
  export class InsertDiamondSizeRequestModel{
    name            : string;
    active?         : boolean;
    isEdit?         : boolean = false;
  }

  export class UpdateDiamondSizeRequestModel extends InsertDiamondSizeRequestModel{
    id               : number;
  }

  export class  GetAllDiamondSizeResponseModel extends InsertDiamondSizeRequestModel{
    id               : number;
  }
 
   // ExchangeRate Model
   export class InsertExchangeRateRequestModel{
    name            : string;
    active?         : boolean;
    currencyId?     : number;
    rate?           : number;
    description?    : string;
    isEdit?         : boolean = false;
  }

  export class UpdateExchangeRateRequestModel extends InsertExchangeRateRequestModel{
    id               : number;
  }

  
  export class GetAllExchangeRateResponseModel extends InsertExchangeRateRequestModel{
    id               : number;
  }
  // Branch Model
  export class InsertBranchRequestModel{
    name            : string;
    active?         : boolean;
    addressId?      : number;
    mobileNumber1?  : number;
    mobileNumber2?  : number;
    email?          : string;
    companyId?      : number;
    isEdit?         : boolean = false;
  }

  export class UpdateBranchRequestModel extends InsertBranchRequestModel{
    id               : number;
  }

  // Bucket Model
  export class InsertBucketRequestModel{
    name             : string;
    active?          : boolean;
    barcodeNo?       : number;
    minimumQuantity? : number;
    maximumQuantity? : number;
    minimumWeight?   : number;
    maximumWeight?   : number;
    isEdit?          : boolean = false;
  }
 
  export class UpdateBucketRequestModel extends InsertBucketRequestModel{
    id               : number;
  }


  // Currency Model
  export class InsertCurrencyRequestModel{
    name             : string;
    active?          : boolean;
    noOfDecimalPlaces? : number;
    description?     : string;
    symbol?          : string;
    isDefault?       : boolean;
    isEdit?          : boolean = false;
  }

  export class UpdateCurrencyRequestModel extends InsertCurrencyRequestModel{
    id               : number;
  }

  export class GetAllCurrencyResponseModel extends InsertCurrencyRequestModel{
    id               : number;
  }
  
  // Setting Model
  export class InsertSettingRequestModel{
    name             : string;
    active?          : boolean;
    value?           : number;
    settingTypeId?   : number;
    isEdit?          : boolean = false;
  }
 
  export class UpdateSettingRequestModel extends InsertSettingRequestModel{
    id               : number;
  }

  export class GetAllSettingResponseModel extends InsertSettingRequestModel{
    id               : number;
  }

  // TaxSetting Model
  export class InsertTaxSettingRequestModel{
    name             : string;
    active?          : boolean;
    applicableOn     : number;
    comment?         : string;
    rate?            : number;
    calculationMode  : number;
    isEdit?          : boolean = false;
  }

  export class UpdateTaxSettingRequestModel extends InsertTaxSettingRequestModel{
    id               : number;
  }

   // UnitConversion Model
   export class InsertUnitConversionRequestModel{
    name             : string;
    active?          : boolean;
    unitId?          : number;
    conversionRate?  : number;
    quantities?      : number;
    isEdit?          : boolean = false;
  }

  export class UpdateUnitConversionRequestModel extends InsertUnitConversionRequestModel{
    id               : number;
  }
  export class GetAllUnitConversionResponseModel extends InsertUnitConversionRequestModel{
    id                : number;
  }
 
    // Rate Model
    export class InsertRateRequestModel{
      MetalId?          : number;
      caratId?          : number;
      currentRate?      : number;
      metalExchangeRate?: number;
      active?           : boolean = true;
      isEdit?           : boolean = false;
    }
    export class UpdateRateRequestModel extends InsertRateRequestModel{
      id               : number;
    }

    export class GetAllRateResponseModel extends InsertRateRequestModel{
      id               : number;
    }


      
   // Counter model start
   export class InsertCounterRequestModel {
      name              ?: string;
      active            ?: boolean;
      isEdit            ?: boolean = false;
   }

    export class UpdateCounterRequestModel extends InsertCounterRequestModel{
      id                ?:number;
    }
    
    export class GetAllCounterResponseModel extends InsertCounterRequestModel{
      id                ?:number;
    }
  // Counter model end


    // Metal Model
    export class InsertupdateBarcodeToolboxRequestModel{
      id              : number;
      name?           : string;
      metalId?        : number;
      metalName?      : string;
      systemName?     : string;
      active?         : boolean;
      isEdit?         : boolean = false;
    }

  
    export class GetAllBarcodeToolboxResponseModel extends InsertupdateBarcodeToolboxRequestModel{
      id               : number;
    }
    

     // Collection model start
     export class InsertPacketTypeRequestModel {
      name              ?: string;
      active            ?: boolean;
      weight            ?: number=0;
      isEdit            ?: boolean = false;
    }
  
    export class UpdatePacketTypeRequestModel extends InsertPacketTypeRequestModel{
      id                ?:number;
    }

    export class GetAllPacketTypeResponseModel extends InsertPacketTypeRequestModel{
      id                ?:number;
    }
    
    // Collection model end


          
   // Counter model start
    export class InsertClarityRequestModel {
      name              ?: string;
      active            ?: boolean;
      isEdit            ?: boolean = false;
  }

    export class UpdateClarityRequestModel extends InsertClarityRequestModel{
      id                ?:number;
    }
    
    export class GetAllClarityResponseModel extends InsertClarityRequestModel{
      id                ?:number;
    }
  // Counter model end
