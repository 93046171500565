<div class="d-flex gap-3 commonInputContainer align-items-center w-100" >
    <label *ngIf="inputLabel" for="exampleFormControlInput1" class="text-nowrap mb-0 w-50">{{inputLabel}} </label>
    <input
    appFocus="{{ enableFocus }}"  
    autocomplete="off"
    [type]="inputType" 
    [id]="inputId" 
    [name]="inputName" 
    [placeholder]="inputPlaceholder"
    [maxlength]="inputMaxlength"
    [(ngModel)]="inputValue" 
    [class]="inputClass +' form-control'"
    (ngModelChange)="inputValueEmit.emit(inputValue)"
    [required]="isRequired"
    [disabled]="disabled"
    [tabIndex]="readOnly?'-1':'0'"
    [readOnly]="readOnly" 
    [pattern]="isPassPattern ? passPattern : (isEmailPattern ? emailPattern : (isTimePattern ? timePattern : null))"
    [max]="max"
    [min]="min"
    (keydown)="isEmailPattern?convertToLowerCase($event):null"
    (focus)="focusInput($event)"
    (blur)="inputOnBlurEmit.emit(inputValue)"
    [maxlength]="maxLength"
    [minlength]="minLength"
    [appMaxLength]="max"
    [email]="inputType == 'email' ? true :false" 
    [ngClass]="{'readonly-field': readOnly, 'border-red': input.invalid && (input.dirty || input.touched)}"
    *ngIf="(decimalPlaces == 0 && !isOnlyNumbers && !isOnlyAlphabetsAndNumbers && !isOnlyAlphabets && !applyDecimalOrPercent && !applyOnlyOneNumber)"
    #input="ngModel"
    noEmoji
    >   
    <input
        appFocus="{{ enableFocus }}"  
        autocomplete="off"
        [type]="inputType" 
        [tabIndex]="readOnly?'-1':'0'"
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly"
        [max]="max"
        [min]="min"
        [pattern]="isPassPattern?passPattern :null"
        [email]="inputType == 'email' ? true :false" 
        onlyNumbers *ngIf="isOnlyNumbers"
        [ngClass]="{'readonly-field': readOnly}"
        noEmoji
        >
    <input
        appFocus="{{ enableFocus }}"  
        autocomplete="off"
        [type]="inputType" 
        [id]="inputId" 
        [tabIndex]="readOnly?'-1':'0'"
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly"
        [max]="max"
        [min]="min"
        [pattern]="isPassPattern?passPattern :null"
        [email]="inputType == 'email' ? true :false" 
        onlyAlphabetsAndNumbers *ngIf="isOnlyAlphabetsAndNumbers"
        [allowDecimal]="isAllowDecimal" 
        [ngClass]="{'readonly-field': readOnly}"
        noEmoji
        >
    <input
        appFocus="{{ enableFocus }}"  
        autocomplete="off"
        [type]="inputType" 
        [tabIndex]="readOnly?'-1':'0'"
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly"
        [max]="max"
        [min]="min"
        [pattern]="isPassPattern?passPattern :null"
        [email]="inputType == 'email' ? true :false" 
        onlyAlphabets *ngIf="isOnlyAlphabets"
        [ngClass]="{'readonly-field': readOnly}"
        noEmoji
        >
    <input
        appFocus="{{ enableFocus }}"  
        autocomplete="off"
        [type]="inputType" 
        [tabIndex]="readOnly?'-1':'0'"
        [id]="inputId" 
        noMultipleDecimals 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [max]="max"
        [min]="min"
        [pattern]="isPassPattern?passPattern :null"
        [readOnly]="readOnly"
        [email]="inputType == 'email' ? true :false" 
        appNumberWithDecimal *ngIf="decimalPlaces > 0"
        [decimalPlaces]="decimalPlaces" 
        [applyDecimal]="readOnly"
        [ngClass]="{'readonly-field': readOnly}"
        noEmoji
        >
    <input
        appFocus="{{ enableFocus }}"  
        autocomplete="off"
        [type]="inputType" 
        [tabIndex]="readOnly?'-1':'0'"
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly" 
        [pattern]="isPassPattern?passPattern :null"
        [max]="max"
        [min]="min"
        [email]="inputType == 'email' ? true :false" 
        [ngClass]="{'readonly-field': readOnly}"
        AppTwoDigitDecimaNumber
        [isPercentApplicable]="isPercentApplicable"
        [isBlureApplicable]="isBlureApplicable"
        [decimalRestrictedDigit]="decimalRestrictedDigit"
        noMultipleDecimals
        *ngIf="applyDecimalOrPercent"
        noEmoji
        >    
    <input
        appFocus="{{ enableFocus }}"  
        autocomplete="off"
        [type]="inputType" 
        [tabIndex]="readOnly?'-1':'0'"
        [id]="inputId" 
        [name]="inputName" 
        [placeholder]="inputPlaceholder"
        [maxlength]="inputMaxlength"
        [(ngModel)]="inputValue" 
        [class]="inputClass +' form-control'"
        (ngModelChange)="inputValueEmit.emit(inputValue)"
        [required]="isRequired"
        [disabled]="disabled"
        [readOnly]="readOnly" 
        [pattern]="isPassPattern?passPattern :null"
        [max]="max"
        [min]="min"
        [email]="inputType == 'email' ? true :false" 
        [ngClass]="{'readonly-field': readOnly}"
        AppZeroToOneNumber
        [isBlureApplicable]="isBlureApplicable"
        *ngIf="applyOnlyOneNumber"
        noEmoji
        > 
        <!-- <button id="{{'hidden'+inputName}}" class="position-absolute d-flex h-100 align-items-center end-0 opacity-0"></button> -->
        <span *ngIf="isEyeIconShow" class="position-absolute d-flex h-100 align-items-center end-0 font-11 font-roboto-regular pe-4 pointer">
            <svg *ngIf="!show" class="cursorPointer pink-hover-icon"  (click)="showHidePass()" xmlns="http://www.w3.org/2000/svg" width="18" height="12.792" viewBox="0 0 19.937 12.792">
                <g id="Group_3982" data-name="Group 3982" transform="translate(0)">
                  <path id="Path_2245" data-name="Path 2245" d="M4622.305-703.779a10.527,10.527,0,0,1-6.641-2.628,20.514,20.514,0,0,1-2.929-2.893,1.075,1.075,0,0,1-.048-1.649,20.188,20.188,0,0,1,4.3-3.89,9.078,9.078,0,0,1,10.7.033,20.807,20.807,0,0,1,4.153,3.747,1.133,1.133,0,0,1,.032,1.745c-2.177,2.54-4.607,4.671-7.99,5.41a4.259,4.259,0,0,1-.62.12C4622.94-703.764,4622.622-703.779,4622.305-703.779Zm-.113-11.473c-3.385.193-5.874,2.256-8.119,4.75-.346.385-.035.623.192.9a14.126,14.126,0,0,0,3.863,3.264,7.442,7.442,0,0,0,5.558,1.123,11.829,11.829,0,0,0,5.517-3.2c2.152-2.061,1.767-1.585.1-3.352a6.783,6.783,0,0,0-.528-.522A10.55,10.55,0,0,0,4622.192-715.252Z" transform="translate(-4612.325 716.566)" fill="#a3abb8"/>
                  <path id="Path_2246" data-name="Path 2246" d="M4648.493-701.155a3.763,3.763,0,0,1-3.96,3.886,3.872,3.872,0,0,1-3.908-3.879,4.05,4.05,0,0,1,3.959-4.009A3.9,3.9,0,0,1,4648.493-701.155Zm-3.985,2.6a2.532,2.532,0,0,0,2.693-2.511,2.655,2.655,0,0,0-2.593-2.778,2.856,2.856,0,0,0-2.692,2.624A2.661,2.661,0,0,0,4644.508-698.56Z" transform="translate(-4634.609 707.582)" fill="#a3abb8"/>
                </g>
            </svg>
            <svg *ngIf="show" class="cursorPointer " (click)="showHidePass()" id="Group_3981" data-name="Group 3981" xmlns="http://www.w3.org/2000/svg" width="18" height="12.792" viewBox="0 0 20.008 15.059">
              <path id="Path_2242" data-name="Path 2242" d="M4622.135-848.26a8.268,8.268,0,0,1,3.628.644,1.094,1.094,0,0,0,1.46-.359,16.329,16.329,0,0,1,1.27-1.277c.284-.274.614-.387.935-.079a.63.63,0,0,1-.016.945c-.158.189-.338.36-.513.535q-6.328,6.342-12.658,12.682a7.067,7.067,0,0,1-.537.511.605.605,0,0,1-.935-.041.592.592,0,0,1,.059-.859c.388-.411.772-.83,1.2-1.2s.421-.582-.074-.925a13.651,13.651,0,0,1-3.53-3.619,1.044,1.044,0,0,1-.036-1.344C4614.765-846.044,4617.846-848.211,4622.135-848.26Zm.025,1.27c-3.273-.014-5.762,1.544-7.894,3.891a1.365,1.365,0,0,0,.019,2.214,14.693,14.693,0,0,0,2.347,2.121c.359.24.788.8,1.263.185.438-.565,1.455-.815.855-1.955a3.384,3.384,0,0,1,.736-4.078,3.681,3.681,0,0,1,4.285-.742,1.22,1.22,0,0,0,1.547-.728c.095-.7-.6-.522-.961-.652A6.077,6.077,0,0,0,4622.161-846.99Zm-2.5,5c.04.368-.115.914.347,1.086.35.13.562-.349.8-.583.735-.71,1.447-1.444,2.17-2.167.386-.386.376-.606-.213-.773A2.507,2.507,0,0,0,4619.657-841.992Z" transform="translate(-4612.156 849.516)" fill="#a3abb8"/>
              <path id="Path_2243" data-name="Path 2243" d="M4645.009-822.628a1.663,1.663,0,0,1,1.946-.853c3.86.51,7.264-1.523,9.436-4.518.277-.381.114-.635-.114-.936a11.676,11.676,0,0,0-2.043-2.134c-.263-.208-.552-.4-.17-.739.268-.24.469-.684.949-.3a12.348,12.348,0,0,1,2.91,3.326.8.8,0,0,1-.057.9c-2.616,4.1-7.285,6.715-12.388,5.47A2.844,2.844,0,0,1,4645.009-822.628Z" transform="translate(-4638.026 835.928)" fill="#a3abb8"/>
              <path id="Path_2244" data-name="Path 2244" d="M4660.053-818.712a3.433,3.433,0,0,1-.759,3.462,3.521,3.521,0,0,1-3.2,1.244c-.486-.05-.392-.289-.151-.544a2.387,2.387,0,0,1,1.189-.8,2.2,2.2,0,0,0,1.729-1.786C4659-817.846,4659.6-818.113,4660.053-818.712Z" transform="translate(-4646.48 825.26)" fill="#a3abb8"/>
            </svg>
           
        </span>




</div>


