import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { DataService } from 'src/app/shared/services/data.service';
import { BarcodeTrackingRequestModel, GetAllDepartmentReportRequestModel, GetRequestModel, GetallBarcodestockRequestModel, GetallDayReportRequestModel, GetallDraftsRequestModel, GetallstockDetailRequestModel, GetallstockRequestModel, MetalFixInsertUpdateRequestModel, PrintDiamondCertificate, PrintSaleInvoice, CheckPermissionFor, RfidRequest } from './report.model';
import { API_URL_GET_ALL_GRID_GET_DIAMOND_CURRENT_STOCK, API_URL_JOBWORK_ORDER_LIST, API_URL_SALEORDER_PROCESS_LIST } from 'src/app/shared/url-helper/url-helper';
import { NotificationServiceTypes, StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Router } from '@angular/router';
import { AddDataToRefine } from './old-jewellery/old-jewellery.model';
import { TransactionStockJournalTabs } from '../Transaction/transaction.model';
import { VoucherType, VoucherTypeName } from 'src/app/shared/models/common.model';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PrintPreviewComponent } from 'src/app/shared/common-components/common-popup/print-preview/print-preview.component';
import { ManufacturerService } from '../manufacturer/manufacturer.service';
import { IpcRenderService } from 'src/app/shared/services/ipc-render.service';
import { TransactionService } from '../Transaction/transaction.service';
import { environment } from 'src/environments/environment';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { HttpParams } from '@angular/common/http';
import { ModalPopupSize } from '../../shared/models/common.model';
import { StockJournalType } from '../Transaction/stock-journal/stock-journal.model';
import { CrmService } from '../administration/crm/crm.service';


@Injectable({
  providedIn: 'root'
})
export class ReportService {
  storageName = StorageName;
  constructor(public sendDataService: SendDataService, public transactionService: TransactionService, private ipcService: IpcRenderService, public service: DataService, public storageService: StorageService, public manufacturerService: ManufacturerService, private router: Router, public toastrService: ToastrService, public notificationService: NotificationService, private crmService:CrmService) {

  }
  public SaleInvoiceNavigate = new BehaviorSubject<any>(undefined);
  public stockHistorynavigate = new BehaviorSubject<any>(undefined);

  // ******Report Invoice start********
  //Invoices
  GetInvoiceReportData(data): Observable<ResponseModel> {
    let url = "api/app/invoice/getallinvoice";
    const json =JSON.stringify(data);
    return this.service.post(url, data, true, null, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateallledgerposting(data): Observable<ResponseModel> {
    let url = "api/app/invoice/updateallledgerposting";
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  createcustomtransaction(data): Observable<ResponseModel> {
    let url = "api/app/planetpayment/createcustomtransaction";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }

  printbarcodeaslabelorcard(data: any): Observable<ResponseModel> {
    let url = "api/app/stockreport/printbarcodeaslabelorcard";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // Drafts
  GetDraftsReportData(getallDraftsRequestModel: GetallDraftsRequestModel): Observable<ResponseModel> {
    let url = "api/app/invoicedraft/getallinvoicedraft";
    return this.service.post(url, getallDraftsRequestModel, true, null, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  // ******Repart Invoice end********

  // ********Stock Report start*******

  getallstock(getallstockRequestModel: GetallstockRequestModel): Observable<ResponseModel> {
    let url = "api/app/stockreport/getGoldSilverCurrentStock";
    return this.service.post(url, getallstockRequestModel, true, false, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getDiamondCurrentStock(getallstockRequestModel: GetallstockRequestModel): Observable<ResponseModel> {
    let url = API_URL_GET_ALL_GRID_GET_DIAMOND_CURRENT_STOCK;
    return this.service.post(url, getallstockRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllStockHistoryOfDiamond(getallstockRequestModel: GetallstockRequestModel): Observable<ResponseModel> {
    let url = "api/app/stockreport/getAllStockHistoryOfDiamond";
    return this.service.post(url, getallstockRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  GetAllDiamondAndStones(data: any, URL): Observable<ResponseModel> {
    let url = URL;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  GetAllDiamondAndGemStones(data: any): Observable<ResponseModel> {
    let url = "api/app/stockreport/getonlydiamondreport";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateRate(currentGoldRate: any): Observable<ResponseModel> {
    let url = `api/app/stockreport/updatetodaysrate?currentGoldRate=${currentGoldRate}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  GetAllGoldAndSilver(data: any, URL): Observable<ResponseModel> {
    let url = URL;
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllImmitationAndWatches(data: any): Observable<ResponseModel> {
    let url = "api/app/stockreport/getimitationwatchesreport";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getimitationbybarcodevouchertypeidvouchernometalid(data): Observable<ResponseModel> {
    let url = "api/app/stockreport/getimitationbybarcodevouchertypeidvouchernometalid";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getallstockhistory(data: any, path): Observable<ResponseModel> {
    let url = `api/app/stockreport/${path}`;
    const json = JSON.stringify(data);
    return this.service.post(url, data, true, false, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getallstockDetail(getallstockDetailRequestModel: GetallstockDetailRequestModel): Observable<ResponseModel> {
    let url = "api/app/stockreport/getallstockdetails";
    return this.service.post(url, getallstockDetailRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getallstockDetailReport(data): Observable<ResponseModel> {
    let url = "api/app/goldstockanalysis/getgoldstockanalysisreportbyproduct";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getallRFIDAvailable(getallrfdcstockRequestModel: GetRequestModel): Observable<ResponseModel> {
    let url = "api/app/stockreport/getallavailablestockrfidbarcode";
    return this.service.post(url, getallrfdcstockRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getallRFIDScanned(getallrfidScannedRequestModel: GetRequestModel): Observable<ResponseModel> {
    let url = "api/app/stockreport/getallscannedrfidbarcode";
    return this.service.post(url, getallrfidScannedRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  deleteRFIDStock(data: any): Observable<ResponseModel> {
    let url = "api/app/stockreport/deletetrackingrfidbarcode";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  //metal fixing start 
  insertUpdateMetalfix(metalFixInsertUpdateRequestModel: MetalFixInsertUpdateRequestModel): Observable<ResponseModel> {
    let url = "api/app/metalfixing/insertupdatemetalfixing";
    const json = JSON.stringify(metalFixInsertUpdateRequestModel);
    
    return this.service.post(url, metalFixInsertUpdateRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };



  getMetalFixingByInvoiceId(invoiceId: number, voucherTypeId: number) {
    const url = `api/app/metalfixing/getmetalfixingbyinvoiceid?invoiceId=${invoiceId}&voucherTypeId=${voucherTypeId}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getsaleinvoicebyid(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/saleinvoice/getsaleinvoicebyid";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  getscrapinvoicebyid(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/scrapinvoice/getscrapinvoicebyid";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  getsaleiorderbyid(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/saleorder/getsaleorderbyid";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };
  getjobworkinvoicebyid(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/Jobworkinvoice/getJobworkinvoicebyid";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };
  getconsignmentoutbyid(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/ConsignmentOut/getPrintConsignmentOutById";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };
  getcontravoucherbyid(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/contravoucher/getcontravoucherbyid";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  getInsertScannedRfids(data: RfidRequest): Observable<ResponseModel> {
    let url = "api/app/stockreport/insertscannedrfids";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  //metal fixing end

  // Ageing report
  getStockAgeingReport(data): Observable<ResponseModel> {
    let url = ``;
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }

  getBalanceAgeingReport(data): Observable<ResponseModel> {
    let url = `api/ageingreport/getallageingreport`;
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }
  // Ageing report end

  //Whatsapp API Start
  getCampaignWhatsapp(data): Observable<ResponseModel> {
    let url = `api/app/whatsappcampaign/getallwhatsappcampaign`;
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }
  //Whatsapp API End
  printInvoices(voucherTypeId, id, rdlcResourceName?) {
    var httpParams: any;
    if (rdlcResourceName != undefined)
      httpParams = new HttpParams().set('rdlcResourceName', rdlcResourceName);
    this.transactionService.getInvoiceById(voucherTypeId, id, httpParams, true).subscribe({
      next: (res) => {
        this.handleResponcePrint(res);
      }, error: (err) => {
      }
    })
  }

  handleResponceRFIDPrint(res, isBarcodePrint = false, isBase64 = false) {
    if (this.ipcService.isRunningInElectron) {
      var printerSetting = this.storageService.retrieve(StorageName.PRINTER_SETTING);
      
      if (this.ipcService.isRunningInElectron) {
        var printerName = isBarcodePrint == true ? printerSetting.barcodePrinterName : printerSetting.invoicePrinterName;
        var isSilentPrint = isBarcodePrint == true ? printerSetting.isBarcodePrint : printerSetting.isDirectPrint;
        this.ipcService.send('print-label', { isSilentPrint: isSilentPrint, printerName: printerName, response: res });
        this.ipcService.on('printFinished', (event, data) => {
          this.toastrService.success("Print Finished");
        });
      }
    }
  }

  isBase64Image(imageUrl: string | null | undefined): string | undefined {
    // Check if the string starts with a data URI prefix for an image
    if (!imageUrl)
      return '';
    // /^data:(image\/[a-zA-Z]+|video\/mp4);base64,/

    if (/^data:([a-zA-Z]+\/[a-zA-Z0-9-.+]+);base64,/.test(imageUrl) || imageUrl?.startsWith(environment?.baseUrl) || imageUrl == null || imageUrl?.trim() === "") {
      return imageUrl;
  } else {
      return environment.baseUrl + imageUrl;
  }
  }

  processResponse(
    result: any,
    invoiceData: any,
    notificationConfigurationModel: any[],
    isPrint: boolean,
    notificationServiceTypeId: NotificationServiceTypes = undefined,
    rdlcResourceName: string = undefined,
    fromInvoice: boolean = false
  ): any {
    // Helper function to retrieve notification permissions
    const getNotificationPermission = (type: string): boolean => {
      return notificationConfigurationModel
        ?.find((item: any) => item.id === type)?.extra1?.toLowerCase() === 'true';
    };

    // Helper function to retrieve notification permissions
    const getNotificationHasAttachmentPermission = (type: string): boolean => {
      return notificationConfigurationModel
        ?.find((item: any) => item.id === type)?.extra2?.toLowerCase() === 'true';
    };

    // Helper function to check if a notification type is valid
    const isNotificationValid = (contact: string | undefined, permission: boolean): boolean => {
      return !!contact && permission;
    };

    // Retrieve account ledger details
    const accountLedgerJSON = this.storageService
      .retrieve(StorageName.ACCOUNTLEDGER_DROPDOWN)
      ?.find(a => a.id === invoiceData?.accountLedgerId?.toString())?.extra2;

    const accountLedgerDetails = accountLedgerJSON
      ? JSON.parse(accountLedgerJSON)[0]
      : undefined;

    let accountLedgerContactNo = accountLedgerDetails?.contactNo?.replace('-', '')

    // Determine notification permissions
    const sendWhatsApp = getNotificationPermission(NotificationServiceTypes.WhatsApp.toString());
    const sendSMS = getNotificationPermission(NotificationServiceTypes.SMS.toString());
    const sendEmail = getNotificationPermission(NotificationServiceTypes.Email.toString());

    // Determine notification has attachment permissions
    const whatsAppHasAttachment = getNotificationHasAttachmentPermission(NotificationServiceTypes.WhatsApp.toString());
    const smsHasAttachment = getNotificationHasAttachmentPermission(NotificationServiceTypes.SMS.toString());
    const emailHasAttachment = getNotificationHasAttachmentPermission(NotificationServiceTypes.Email.toString());


    // Validate notifications
    let validForWhatsApp = isNotificationValid(accountLedgerContactNo, sendWhatsApp);
    let validForSMS = isNotificationValid(accountLedgerContactNo, sendSMS);
    let validForEmail = isNotificationValid(accountLedgerDetails?.email, sendEmail);

    if(notificationServiceTypeId)
    {   
      switch (notificationServiceTypeId) {
        case NotificationServiceTypes.WhatsApp:
          validForWhatsApp = (!validForWhatsApp) ? validForWhatsApp : true;
          validForEmail = false;
          validForSMS = false;
          break;
  
        case NotificationServiceTypes.Email:
          validForWhatsApp = false;
          validForEmail = (!validForEmail) ? validForEmail : true;
          validForSMS = false;
          break;
  
        case NotificationServiceTypes.SMS:
          validForWhatsApp = false;
          validForEmail = false;
          validForSMS= (!validForSMS) ? validForSMS : true;
          break;
      }
    }
    
    if (invoiceData.sendNotification == false && fromInvoice == true) {
      validForWhatsApp = false;
      validForEmail = false;
      validForSMS = false;
    }
    // Proceed if any notification is valid or printing is enabled
    if (validForWhatsApp || validForSMS || validForEmail || isPrint) {
      let httpParams = (isPrint) ? new HttpParams().set('rdlcResourceName', rdlcResourceName) : undefined;
      this.transactionService
        .getInvoiceById(invoiceData?.voucherTypeId, result.response, httpParams, true, false)
        .subscribe(res => {
          const invoicePrintPath = res.response.invoicePrintPath;
          res.response.notificationTemplateName = invoiceData?.notificationTemplateName;

          // Handle WhatsApp message
          if (validForWhatsApp) {
            res.response.invoicePrintPath =  (whatsAppHasAttachment) ? res?.response?.invoicePrintPath : ''
            const notificationRequestModel = {
              invoiceData: res.response,
              notificationServiceTypeId: NotificationServiceTypes.WhatsApp,
              invoicePrintPath: res.response.invoicePrintPath, 
              contactNo: accountLedgerContactNo //Not used for generateNotification api
            };
            this.callSendNotification(notificationRequestModel);
          }

          // Handle SMS message
          if (validForSMS) {
            res.response.invoicePrintPath =  (smsHasAttachment) ? res?.response?.invoicePrintPath : ''
            const notificationRequestModel = {
              invoiceData: res.response, 
              notificationServiceTypeId: NotificationServiceTypes.SMS,
              invoicePrintPath: res?.response?.invoicePrintPath, //Not used for generateNotification api
              contactNo: accountLedgerContactNo //Not used for generateNotification api
            };
            this.callSendNotification(notificationRequestModel);
          }

          // Handle Email message
          if (validForEmail) {
            res.response.invoicePrintPath =  (emailHasAttachment) ? res?.response?.invoicePrintPath : ''
            const notificationRequestModel = {
              invoiceData: res.response , 
              notificationServiceTypeId: NotificationServiceTypes.Email,
              invoicePrintPath: res?.response?.invoicePrintPath, //Not used for generateNotification api
              contactNo: accountLedgerContactNo //Not used for generateNotification api
            };
            this.callSendNotification(notificationRequestModel);
          }

          // Handle print operation
          if (isPrint) {
            res.response = invoicePrintPath;
            this.handleResponcePrint(res);
          }
          return res;
          //
        });
    }
  }
  
  handleResponcePrint(res, isBarcodePrint = false, isBase64 = false) {
    if (res.isSuccess == false)
      this.notificationService.errorPopup(res.errors[0]);
    else {
      if (res?.response == null) {
        this.notificationService.errorPopup("Please set print (GO TO SETTINGS => SET SOFTWARE => RDLC DESIGN)");
        return;
      }
      if (res?.response?.includes('.pdf')) {
        if (this.ipcService.isRunningInElectron) {
          var url = this.isBase64Image(this.isBase64Image(res?.response))
          this.ipcService.send('print-pdf', url);
        }
        else this.openPrintPreviewPopUp(res?.response);
      }
      else {
        // const decodedHtml = this.decodeHtml(res?.response);
        // 
        var printerSetting = this.storageService.retrieve(StorageName.PRINTER_SETTING);
        
        if (this.ipcService.isRunningInElectron) {
          var printerName = isBarcodePrint == true ? printerSetting.barcodePrinterName : printerSetting.invoicePrinterName;
          var isSilentPrint = isBarcodePrint == true ? printerSetting.isBarcodePrint : printerSetting.isDirectPrint;
          this.ipcService.send('getPrint', { isSilentPrint: isSilentPrint, printerName: printerName, response: res?.response });
          this.ipcService.on('printFinished', (event, data) => {
            this.toastrService.success("Print Finished");
          });
        } else {
          if (!isBarcodePrint)
            this.openPrintPreviewPopUp("", res?.response);
          else
            this.toastrService.success("Print Finished");

          //this.openPrintDialog(res?.response,isBase64);
        }
      }
    }
  }

  public handleError(error: any,gridApi?) {
    if(error?.error?.message != "Input validation failed!")
      // this.errorpopup(error?.error?.message);
    // this.toaster.error(error?.error?.errors[0]);
    return throwError(error);
  }

  removeHtmlTagsWithRegex(htmlString: string): string {
    return htmlString.replace(/<\/?[^>]+>/gi, '').replace(/&nbsp;/g, '');
  }

  callSendNotification(notificationRequestModel):any {
    this.generateNotification(notificationRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        if (res.isSuccess) {
          if (notificationRequestModel.notificationServiceTypeId === NotificationServiceTypes.WhatsApp) {
            const requestAcountLedgerList = [];
            requestAcountLedgerList.push({ contactInfo: notificationRequestModel?.contactNo });
            var url = this.isBase64Image(notificationRequestModel?.invoicePrintPath)
            this.crmService.startBackgroundTask(
              requestAcountLedgerList,
              this.removeHtmlTagsWithRegex(res?.response),
              url,
              undefined
            );
          }

          return res;
        }
      });
  }

  openPrintDialog(htmlContent, isBase64 = false) {
    
    if (htmlContent == undefined || htmlContent == null || htmlContent == "") {
      this.notificationService.errorPopup("Please set print <br>(GO TO SETTINGS => SET SOFTWARE => RDLC DESIGN)");
    } else {
      // Create a hidden iframe
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);

      // Set iframe content
      const iframeDocument = iframe.contentWindow?.document;
      if (iframeDocument) {
        iframeDocument.open();
        iframeDocument.write(htmlContent);
        iframeDocument.close();
        // Wait for the iframe to load its content before triggering print
        const waitForLoad = setInterval(() => {
          if (iframe.contentWindow?.document.readyState === 'complete') {
            clearInterval(waitForLoad);
            iframe.contentWindow?.print();
            document.body.removeChild(iframe); // Clean up by removing the iframe
          }
        }, 100);
      } else {
        console.error('Failed to open print window');
      }
    }
  }

  decodeHtml(html: string): string {
    // Create a new textarea element
    const textarea = document.createElement('textarea');
    // Set its HTML content to the encoded HTML string
    textarea.innerHTML = html;
    // The textContent property of the textarea will contain the decoded HTML
    return textarea.textContent || '';
  }

  openPrintPreviewPopUp(resourceFileUrl: string = "", html: string = "") {
    var data = { printHtml: html, printUrl: resourceFileUrl }
    this.notificationService.openModalPopup(PrintPreviewComponent, data, undefined, ModalPopupSize.XL, "print-popup").then(
      (result) => {
        if (result) {

        }
      }
    )
  }


  getPrintDiamondCertificate(data: PrintDiamondCertificate): Observable<ResponseModel> {
    let url = "api/app/stockreport/printdiamondcertificate";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  getPrintJobworkOrderById(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/JobWorkOrder/getJobworkOrderById";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  getPrintReceiptVoucherById(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/ReceiptVoucher/getReceiptVoucherById";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  getPrintSaleFixingById(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/SaleFixing/getSaleFixingById";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };
  getPrintPaymentVoucherById(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/PaymentVoucher/getPaymentVoucherById";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };
  getPrintJournalVoucherById(data: PrintSaleInvoice): Observable<ResponseModel> {
    let url = "api/app/JournalVoucher/getJournalVoucherById";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };

  updaterfidtag(data: any): Observable<ResponseModel> {
    let url = "api/app/stockreport/updaterfidtag";
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  getPurchaseInvoiceById(id: number) {
    let url = 'api/app/Purchaseinvoice/getPurchaseinvoicebyid';
    const data = id
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      if (response.isSuccess == true) {
        this.storageService.getInvoice.next(response);

        this.storageService.setSelect2Dropdown.next(response.response?.invoiceModel?.accountLedgerId?.toString())
      }
      return response;
    })
    );
  }


  // ********Stock Report end*******


  // ********Day Report start*******

  // getallDayBookReport(getallDayReportRequestModel: GetallDayReportRequestModel): Observable<ResponseModel> {
  //   let url = "api/app/DayBook/getAllDayBook";
  //   return this.service.post(url, getallDayReportRequestModel, true).pipe<ResponseModel>(
  //     tap((response: any) => {
  //       return response;
  //     })
  //   );
  // }

  getallDayBookReport(getallDayReportRequestModel: GetallDayReportRequestModel): Observable<ResponseModel> {
    let url = "api/app/daybook/getalldaybook";
    return this.service.post(url, getallDayReportRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // ********Day Report end*******

  // ********Day Report start*******

  getallSaleOrderList(data: any): Observable<ResponseModel> {
    let url = API_URL_SALEORDER_PROCESS_LIST;
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }


  // ********Day Report end*******

  // ********Account Ledger Report start*******
  getAccountLedgerReportAmount(data: any): Observable<ResponseModel> {
    let url = "api/app/accountledgerReport/getAccountLedgerReport";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAccountLedgerReportMetal(data: any): Observable<ResponseModel> {
    let url = "api/app/accountledgerReport/getAccountLedgerReportMetal";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // ********Account Ledger Report end*******


  // ******** Account Ledger Detail Info Start *******
  getAccountLedgerDetailInfo(data: any): Observable<ResponseModel> {
    let url = "api/app/accountledgerreport/getaccountledgerdetailbyledgerid";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // ******** Account Ledger Detail Info end *******

  // Barcode Stock apis Start

  deleteBarcodeTracking(): Observable<ResponseModel> {
    let url = `api/app/barcodesetting/deletebarcodetracking`
    return this.service.delete(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  deleteBarcodeStock(data: any): Observable<ResponseModel> {
    let url = `api/app/stockreport/deletebarcode`
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getallBarcodeStock(getallBarcodestockRequestModel: GetallBarcodestockRequestModel): Observable<ResponseModel> {
    let url = "api/app/stockreport/getallbarcodestock";
    const json = JSON.stringify(getallBarcodestockRequestModel);
    return this.service.post(url, getallBarcodestockRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  printBarcodeStock(barcodeTrackingRequestModel: BarcodeTrackingRequestModel): Observable<ResponseModel> {
    let url = "api/app/stockreport/printbarcode";
    return this.service.post(url, barcodeTrackingRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getJobworkOrders(data: any): Observable<ResponseModel> {
    let url = API_URL_JOBWORK_ORDER_LIST;
    return this.service.post(url, data, true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  getVoucherTypeText(value: string): string | undefined {
    const enumKeys = Object.keys(VoucherType).filter(k => typeof VoucherType[k as any] === 'string');
    const enumValues = enumKeys.map(k => VoucherType[k as any]);

    const index = this.getVoucherTypeName(enumValues[parseInt(value) - 1]);
    return index;
  }

  getVoucherTypeName(value: any): string | undefined {
    if (value == 'SaleInvoice')
      value = 'SalesInvoice';
    const enumKeys = Object.keys(VoucherTypeName).filter(k => k === value);
    const enumValues = enumKeys.map(k => VoucherTypeName[k as any]);

    const index = enumValues[0];
    return index;
  }

  getAllKycReport(data: any): Observable<ResponseModel> {
    let url = "api/app/kyc/getkycreport";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // getByIdkyc data
  getByIdKycReport(id: any): Observable<ResponseModel> {
    let url = "api/app/kyc/getattacheddcoumentbyledgerid";
    return this.service.post(url, id, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


  downloadDocuments(id) {
    let url = `api/app/accountledger/downloadattacheddocumentzipbyaccountledgerid?accountLedgerId=${id}`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  printDocument(data) {
    let url = "api/app/kyc/ledgerkycreportprint";
    return this.service.post(url,data,true ).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //#region  navigate to invoices ;
  //#endregion  navigate to invoices ;
  navigateToInvoice(voucherTypeId: number, id: number, stockJournalTypeId?: number, againstVoucherTypeId = undefined, againstVoucherNo = undefined, isItemAdded?: boolean, againstInvoiceNo?: number) {
    var voucherTypeName = this.getVoucherTypeText(voucherTypeId.toString());
    if (againstVoucherTypeId != null && againstVoucherTypeId != 0 && againstVoucherTypeId != undefined) {
      voucherTypeName = this.getVoucherTypeText(againstVoucherTypeId);
      id = againstVoucherNo;
    }

    switch (voucherTypeName) {
      case StorageName.FORMNAME_TRANSACTION_PURCHASEFIXING:
        this.storageService.store("PurchaseFixingId", id);
        this.router.navigate(['Transaction/purchase-fixing']);
        break;
      case StorageName.FORMNAME_TRANSACTION_SALEFIXING:
        this.storageService.store("SaleFixingId", id);
        this.router.navigate(['Transaction/sale-fixing']);
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEORDER:
        this.storageService.store("PurchaseOrderId", id);
        this.router.navigate(['Transaction/purchase-order']);
        break;
      case StorageName.FORMNAME_TRANSACTION_CREDITNOTE:
        this.storageService.store("creditnoteId", id);
        this.router.navigate(['Transaction/credit-note']);
        break;
      case StorageName.FORMNAME_TRANSACTION_DEBITNOTE:
        this.storageService.store("debitnoteId", id);
        this.router.navigate(['Transaction/debit-note']);
        break;
      case StorageName.FORMNAME_TRANSACTION_PAYMENTVOUCHER:
        this.storageService.store("PaymentVoucherId", id);
        this.router.navigate(['Transaction/payment-voucher']);
        break;
      case StorageName.FORMNAME_TRANSACTION_RECEIPTVOUCHER:
        this.storageService.store("ReceiptVoucherId", id);
        this.router.navigate(['Transaction/receipt-voucher']);
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIALIN:
        this.storageService.store("MaterialInId", id);
        this.router.navigate(['Transaction/material-in']);
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIALOUT:
        this.storageService.store("MaterialOutId", id);
        this.router.navigate(['Transaction/material-out']);
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
        this.storageService.store("PurchaseInvoiceId", id);
        this.router.navigate(['Transaction/purchase-invoice']);
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEQUOTATION:
        this.storageService.store("PurchaseQuotationId", id);
        this.router.navigate(['Transaction/purchase-quotation']);
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESORDER:
        this.storageService.store("SaleOrderId", id);
        this.router.navigate(['Transaction/sale-order']);
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
        this.storageService.store("SaleInvoiceId", id);
        this.router.navigate(['Transaction/saleinvoice']);
        break;
      case "Stock Journal":
        this.storageService.store("StockJournalId", id);
        this.storageService.store("StockJournalTypeId", stockJournalTypeId);
        if (isItemAdded) {
          this.storageService.store("againstInvoiceNo", againstInvoiceNo);
          this.router.navigate(['/Transaction/stock-journal-2']);
        } else {
          if (stockJournalTypeId == StockJournalType.MovementStock) this.router.navigate(['/Transaction/stock-journal']);
          if (stockJournalTypeId == StockJournalType.Transfer) this.router.navigate(['/Inventory/stock-transfer']);
        }
        break;
      case StorageName.FORMNAME_TRANSACTION_JOURNALVOUCHER:
        this.storageService.store("JournalVoucherId", id);
        this.router.navigate(['/Transaction/journal-voucher']);
        break;
      case StorageName.FORMNAME_TRANSACTION_CONTRAVOUCHER:
        this.storageService.store("ContraVoucherId", id);
        this.router.navigate(['/Transaction/contra-voucher']);
        break;
      case StorageName.FORMNAME_TRANSACTION_OLDJEWELRY_SCRAPINVOICE:
        this.storageService.store("ScrapInvoiceId", id);
        this.router.navigate(['Transaction/old-jewelry-scrap-invoice']);
        break;

      case StorageName.FORMNAME_TRANSACTION_JOBWORK_ORDER:
        this.storageService.store(StorageName.STORED_JOBWORK_ORDERID, id);
        this.router.navigate(['Transaction/jobwork-order']);
        break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE:
        this.storageService.store(StorageName.STORED_JOBWORK_INVOICEID, id);
        this.router.navigate(['Transaction/jobwork-invoice']);
        break;
      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNALMOVEMENT:
        this.storageService.store(StorageName.STORED_STOCKJOURNAL_MOVEMENT, id);
        this.storageService.store("transactionstockJournalMovement", TransactionStockJournalTabs.movement);
        this.router.navigate(['Transaction/stock-journal']);
        break;
      case StorageName.FORMNAME_ORDERS_REPAIRORDER:
        this.storageService.store("RepairOrderId", id);
        this.router.navigate(['Orders/repair-order']);
        break;
      case StorageName.FORMNAME_TRANSACTION_REPAIRINVOICE:
        this.storageService.store(StorageName.STORED_TRANSACTION_REPAIRINVOICEID, id);
        this.router.navigate(['Transaction/repair-invoice']);
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_ISSUE:
        this.storageService.store(StorageName.STORED_MATERIAL_ISSUEID, id);
        this.router.navigate(['Transaction/material-issue']);
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_RECEIVE:
        this.storageService.store(StorageName.STORED_MATERIAL_RECEIVEID, id);
        this.router.navigate(['Transaction/material-receive']);
        break;
      case StorageName.FORMNAME_INVENTORY_PHYSICALSTOCK:
        this.storageService.store("PhysicalStockId", id);
        this.router.navigate(['Inventory/physical-stock']);
        break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTOUT:
        this.storageService.store("ConsignmentOutId", id);
        this.router.navigate(['Inventory/consignment-out']);
        break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTIN:
        this.storageService.store("ConsignmentInId", id);
        this.router.navigate(['Inventory/consignment-in']);
        break;
      case StorageName.FORMNAME_TRANSACTION_SALES_QUOTATION:
        this.storageService.store(StorageName.STORED_TRANSACTION_SALESQUOTATION_ID, id);
        this.router.navigate(['Transaction/estimate']);
        break;
      case StorageName.FORMNAME_TRANSACTION_DELIVERY_NOTE:
        this.storageService.store(StorageName.STORED_TRANSACTION_DELIVERYNOTE_ID, id);
        this.router.navigate(['Transaction/deliverynote']);
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESRETURN:
        this.storageService.store("SaleReturnInvoiceId", id);
        this.router.navigate(['Transaction/sale-return']);
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASERETURN:
        this.storageService.store("PurchaseReturnInvoiceId", id);
        this.router.navigate(['Transaction/purchase-return']);
        break;
      case StorageName.FORMNAME_TRANSACTION_EXPENSES:
        this.storageService.store("ExpenseInvoiceId", id);
        this.router.navigate(['Transaction/expenses']);
        break;
      case StorageName.FORMNAME_TRANSACTION_INVESTMENT_LAYAWAYS_FUND:
        this.storageService.store("InvestmentFundInvoiceId", id);
        this.router.navigate(['Transaction/investment-fund']);
        break;
      case StorageName.FORMNAME_PRODUCT_ADD_APPRAISAL:
        this.storageService.store("AppraisalId", id);
        this.router.navigate(['Product/add-appraisal']);
        break;
        case StorageName.FORMNAME_ORDERS_CATALOGUE_QUOTATION:
          this.storageService.store("CatalogueQuotationId", id);
          this.router.navigate(['Orders/catalogue-quotation']);
          break;
      case StorageName.FORMNAME_TRANSACTION_SALEFIXINGDIRECTINVOICE:
        this.storageService.store("SaleFixingDirectInvoiceId", id);
        this.router.navigate(['Transaction/sale-fixing-direct']);
        break;
        case StorageName.FORMNAME_TRANSACTION_PURCHASEFIXINGDIRECTINVOICE:
          this.storageService.store("PurchaseFixingDirectInvoiceId", id);
          this.router.navigate(['Transaction/purchase-fixing-direct']);
          break;
    }
  }

  isDisableButtonPermissionWise(voucherTypeName, checkPermissionFor) {
    switch (voucherTypeName) {
      case StorageName.FORMNAME_TRANSACTION_PURCHASEFIXING:

        break;
      case StorageName.FORMNAME_TRANSACTION_SALEFIXING:

        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEORDER:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.purchaseOrderCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.purchaseOrderCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_CREDITNOTE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.creditNoteCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.creditNoteCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_DEBITNOTE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.debitNoteCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.debitNoteCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_PAYMENTVOUCHER:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.paymentVoucherCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.paymentVoucherCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_RECEIPTVOUCHER:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.receiptVoucherCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.receiptVoucherCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIALIN:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.materialInCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.materialInCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIALOUT:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.materialOutCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.materialOutCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEINVOICE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.purchaseInvoiceCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.purchaseInvoiceCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASEQUOTATION:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.purchaseQuotationCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.purchaseQuotationCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESORDER:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.salesOrderCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.salesOrderCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESINVOICE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.salesInvoiceCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.salesInvoiceCanView;
        break;
      case "Stock Journal":
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.stockJournalOption1CanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.stockJournalOption1CanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_JOURNALVOUCHER:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.journalVoucherCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.journalVoucherCanView;
        break;
        break;
      case StorageName.FORMNAME_TRANSACTION_CONTRAVOUCHER:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.contraVoucherCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.contraVoucherCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_OLDJEWELRY_SCRAPINVOICE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.oldJewelryScrapInvoiceCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.oldJewelryScrapInvoiceCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORK_ORDER:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.jobworkOrderCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.jobworkOrderCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_JOBWORKINVOICE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.jobworkInvoiceCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.jobworkInvoiceCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_STOCKJOURNALMOVEMENT:

        break;
      case StorageName.FORMNAME_ORDERS_REPAIRORDER:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.repairOrderCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.repairOrderCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_REPAIRINVOICE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.repairInvoiceCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.repairInvoiceCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_ISSUE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.materialIssueCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.materialIssueCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_MATERIAL_RECEIVE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.materialReceiveCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.materialReceiveCanView;
        break;
      case StorageName.FORMNAME_INVENTORY_PHYSICALSTOCK:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.physicalStockCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.physicalStockCanView;
        break;
      case StorageName.FORMNAME_INVENTORY_CREATEMEMO:
        break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTOUT:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.consignmentOutCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.consignmentOutCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_CONSIGNMENTIN:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.consignmentInCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.consignmentInCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_SALES_QUOTATION:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.salesQuotationCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.salesQuotationCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_DELIVERY_NOTE:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.deliveryNoteCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.deliveryNoteCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_SALESRETURN:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.salesReturnCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.salesReturnCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_PURCHASERETURN:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.purchaseReturnCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.purchaseReturnCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_EXPENSES:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.expensesCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.expensesCanView;
        break;
      case StorageName.FORMNAME_TRANSACTION_INVESTMENT_LAYAWAYS_FUND:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.investmentFundCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.investmentFundCanView;
        break;
      case StorageName.FORMNAME_PRODUCT_ADD_APPRAISAL:
        if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.appraisalCanDelete;
        if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.appraisalCanView;
        break;
        case StorageName.FORMNAME_ORDERS_CATALOGUE_QUOTATION:
          if (checkPermissionFor == CheckPermissionFor.CanDelete) return this.sendDataService?.permissionVM?.catalogueQuotationCanDelete ;
          if (checkPermissionFor == CheckPermissionFor.CanView) return this.sendDataService?.permissionVM?.catalogueQuotationCanView ;
          break;
  

        
    }
  }


  //Report old-jewellery-scrap start

  getalloldjewellery(data): Observable<ResponseModel> {
    let url = "api/app/oldjewelleryscrap/getoldjewelleryscrap";
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getalloldjewelleryStocked(data): Observable<ResponseModel> {
    let url = "api/app/oldjewelleryscrap/getoldjewelleryscrapstocked";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  stockout(data: any): Observable<ResponseModel> {
    let url = "api/app/oldjewelleryscrap/stockout";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  getOldJewelleryScrapRefine(data): Observable<ResponseModel> {
    let url = "api/app/oldjewelleryscrap/getoldjewelleryscraprefine";
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllOldJewelryScrapReceived(data): Observable<ResponseModel> {
    let url = "api/app/oldjewelleryscrap/getAllOldJewelryScrapReceived";
    const json = JSON.stringify(data);
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  getAllManufacturingDepartmentReport(data: GetAllDepartmentReportRequestModel): Observable<ResponseModel> {
    let url = "api/app/manufacturingDepartment/GetAllManufacturingDepartmentReport";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updatescrapinvoiceitemstatus(data: AddDataToRefine): Observable<ResponseModel> {
    let url = "api/app/scrapinvoice/updatescrapinvoiceitemstatus";
    const json = JSON.stringify(data);
    return this.service.put(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  //Report old-jewellery-scrap end

  generateNotification(data: any): Observable<ResponseModel> {
    let url = "api/app/notification/generateNotification";
    return this.service.post(url, data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  };


  getFixingPositionReport(data): Observable<ResponseModel> {
    let url = `api/app/fixingposition/getfixingpositionreport`;
    const json = JSON.stringify(data);
    return this.service.post(url, data, true, false, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }

  checkinvoicereferences(data): Observable<ResponseModel> {
    let url = `api/app/invoice/checkinvoicereferences`;
    return this.service.post(url, data, true, false, false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    )
  }
}