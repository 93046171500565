import { ChangeDetectorRef, Component, Input, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DepositInto, IconHover, PaymentMethod, StorageName } from 'src/app/shared/enum/common-enum';
import { CommonService } from 'src/app/shared/services/common.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { InputDatepickerRendererComponent } from '../../ag-grid/input-datepicker-renderer/input-datepicker-renderer.component';
import { DiamondCategory, MetalType } from 'src/app/shared/models/common.model';

@Component({
  selector: 'app-payment-type-popups',
  templateUrl: './payment-type-popups.component.html',
  styleUrls: ['./payment-type-popups.component.scss']
})
export class PaymentTypePopupsComponent  extends CommonService{
  currentHoverEffect = IconHover.Pink;
  @ViewChild("paymentTypeForm") paymentTypeForm!: NgForm;
  @ViewChild('chequeDateComp') inputDatepickerRendererComponent!: InputDatepickerRendererComponent;
  @Input() data: any;
  apiRequestModel: any = {};
  isadded: any;
  isedited: any;
  depositIntotransferFormDropdown: any;
  FilterproductDropdown: any;
  filteredDiamondCategories : any;
  isCategoryDisabled: boolean = true;
  selectedName : any;
  metalType : MetalType
  constructor(private cdRef: ChangeDetectorRef ,
    public activeModal: NgbActiveModal,  public renderer      : Renderer2,public storageService : StorageService) {
    super(renderer);
   }

   ngOnInit(): void {
   this.initialCall()
  }

  initialCall() {
    this.apiRequestModel = this.data?.apiRequestModel || {};
    this.isedited = !!this.data?.apiRequestModel;
    this.isadded = !this.isedited;
    this.filterdropdown(this.data?.paymentMethodId);
    
    const metalDropdown = this.data?.getAllDropdownModel?.metalDropdown?.filter(x => x?.id != null);
    this.apiRequestModel.metalId ||= metalDropdown[0]?.id;

    this.OnMetalChanges(this.apiRequestModel?.metalId);
    this.cdRef.detectChanges();
}
  selectedChequeDate(event) {
    this.apiRequestModel.chequeDate = event;
  }
  isDisabled(){
    return  this.apiRequestModel.metalId !== MetalType.Diamond.toString()
  }
  OnMetalChanges(event) {
    this.selectedName = this.data?.getAllDropdownModel?.metalDropdown?.find((item: any) => item?.id === event)?.name;
    const diamondcategoryDropdown = this.data?.getAllDropdownModel?.diamondCategoryDropdown?.filter(x => x?.id !== '0');
    this.filteredDiamondCategories = diamondcategoryDropdown.filter((category: any) => category.id !== DiamondCategory.Jewellery.toString());
    this.apiRequestModel.diamondCategoryId ||= this.filteredDiamondCategories[0]?.id;

    if (this.selectedName !== 'Diamond & Stones') {
        this.apiRequestModel.diamondCategoryId = null;
    }
  
    this.FilterproductDropdown = this.data?.getAllDropdownModel?.productDropdown?.filter(x => x?.extra1 === event);
    this.apiRequestModel.productDetailId ||= this.FilterproductDropdown[0]?.id;
} 


  Clear() {
    Object.assign(this.apiRequestModel, {
        depositeIntoId: 0,
        transferFromId: 0,
        productDetailId: 0,
        metalId:0,
        diamondCategoryId:null,
        transactionNo: undefined,
        amount: null,
        purityPer: null,
        purityWt: null,
        itemCode: undefined,
        rate: null,
        quantity: null,
        grossWt: null,
    });

    this.initialCall();
    this.inputDatepickerRendererComponent?.getupdateCurrentDate();
}

filterdropdown(paymentMethodId){
  switch (Number(paymentMethodId)) {
    case PaymentMethod.Card:
      this.depositIntotransferFormDropdown =this.storageService.retrieve(StorageName.CREDITCARD_DROPDOWN);
      if(!this.apiRequestModel?.depositeIntoId)
      this.apiRequestModel.depositeIntoId =this.depositIntotransferFormDropdown[0]?.id;
      if(!this.apiRequestModel?.transferFromId)
        this.apiRequestModel.transferFromId =this.depositIntotransferFormDropdown[0]?.id;
      if(this.isadded)
        this.apiRequestModel.amount =this.data?.amountBalance;
      break;
    case PaymentMethod.Cash:
      this.depositIntotransferFormDropdown =  this.storageService.retrieve(StorageName.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Cash_in_Hand) ?? [];
      if(!this.apiRequestModel?.depositeIntoId)
      this.apiRequestModel.depositeIntoId =this.depositIntotransferFormDropdown[0]?.id;
      if(!this.apiRequestModel?.transferFromId)
        this.apiRequestModel.transferFromId =this.depositIntotransferFormDropdown[0]?.id;
      if(this.isadded)
      this.apiRequestModel.amount =this.data?.amountBalance;
      break;
    case PaymentMethod.Bank:
    case PaymentMethod.Cheque:
    case PaymentMethod.UPI:
      this.depositIntotransferFormDropdown =this.storageService.retrieve(StorageName.DEPOSIT_INTO_DROPDOWN)?.filter(a => a.extra2 == DepositInto.Bank_Account || a.extra2 == DepositInto.Bank_OD_AC) ?? [];
      if(!this.apiRequestModel?.depositeIntoId)
      this.apiRequestModel.depositeIntoId =this.depositIntotransferFormDropdown[0]?.id;
      if(!this.apiRequestModel?.transferFromId)
        this.apiRequestModel.transferFromId =this.depositIntotransferFormDropdown[0]?.id;
      if(this.isadded)
        this.apiRequestModel.amount =this.data?.amountBalance;
      break;
  }
}


  Save() {
    const newId = this.isadded ? this.generateUniqueId() : this.apiRequestModel.id; 
    this.activeModal.close({ response: [{ ...this.apiRequestModel, added: this.isadded, edited:this.isedited, paymentMethodId: this.data?.paymentMethodId ,id:newId}], isSuccess: true });
  }

  calculatepurityWt(){
    if(this.apiRequestModel?.grossWt)
    this.apiRequestModel.purityWt = (Number(this.apiRequestModel?.grossWt) * Number(this.apiRequestModel?.purityPer || 1));
  }

  calculateAmount(){
    if(this.apiRequestModel.rate && this.apiRequestModel.purityWt)
    this.apiRequestModel.amount =  Number(this.apiRequestModel.rate) * Number(this.apiRequestModel.purityWt);

  }

  onCellValueChangedDetect(field) {
    var fieldArrayWeight =['grossWt','purityPer','purityWt'];
    var fieldArrayAmount =['amount','rate'];
    fieldArrayWeight?.forEach((key) => {
      if(field != key)
        this.apiRequestModel[key] = this.fixWeightDecimal(this.apiRequestModel[key] );
    });
    fieldArrayAmount?.forEach((key) => {
      if(field != key)
        this.apiRequestModel[key] = this.fixAmountDecimal(this.apiRequestModel[key] );
    });
  }

  generateUniqueId(): number {
    return Date.now() + Math.floor(Math.random() * 1000); 
  }

}
