import { Component, ElementRef, EventEmitter, Input, Optional, Output, SimpleChanges, SkipSelf } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      deps: [[Optional, NgForm]],
      useFactory: (ngForm: NgForm) => ngForm,
    },
  ],
})
export class SelectComponent {
  @Input() id:string;
  @Input() name:string;
  @Input() selectClass:string='';
  @Input() isDateTimePipe= false;

  @Input() isRequired:boolean;
  @Input() isDisabled:boolean;
  @Input() options: any[] = [];
  @Input() selectedValue: string = ''
  @Input() isValueOptionShow: boolean = true;
  @Input() optionDisables: any[] = []; 
  @Input () optionDisable:any;
  @Output() valueChanged = new EventEmitter<string>();
  @Input() defaultOption:boolean = false;
  @Input() isOptionDisabled:boolean;
  @Input() accountLedgerId: number | null;
  @Input() isEstimate:boolean = false;
  @Input() enableFocus: boolean = false;

  constructor(private elementRef: ElementRef){

  }
  
  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    if (this.enableFocus) {
      this.focusElement();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] && this.enableFocus) {
      this.focusElement();
    }
  }

  focusElement(): void {
    const element = this.elementRef.nativeElement.querySelector('select'); 
    if (element) {
      element.focus();
    }
  }

  setDropdownText(value) {
    this.id = value;
  }

  clearSelectedValue() {
    this.selectedValue = null; 
}

// Handle selection change
onChange(event: Event): void {
  const select = event?.target as HTMLSelectElement;
  const selectedOption = select.options[select?.selectedIndex];

  if (selectedOption?.disabled) {   // Prevent disabled options from being selected
    select.selectedIndex = Array?.from(select?.options)?.findIndex((option) => !option?.disabled);
  }
}

onMouseDown(event: MouseEvent): void {  // Handle mouse interaction
  const target = event?.target as HTMLOptionElement;
  if (target?.tagName === 'OPTION' && target?.disabled) {
    event?.preventDefault(); // Prevent the dropdown from opening
    event?.stopPropagation();
  }
}
 // Accept an array of IDs to disable

isOptionDisableds(optionId: string): boolean {
    return this.optionDisables.includes(optionId);
}

truncateText(text: string, maxLength: number): string {
  return text?.length > maxLength ? text?.slice(0, maxLength) + '...' : text;
}

}