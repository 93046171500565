<div class="d-flex align-items-center justify-content-end px-2">
  <div class="d-flex align-items-center justify-content-start gap-2 pt-2">
    <app-svg-icon [ngbTooltip]="'Print'" [name]="'print'" (click)="printPdf()" [width]="'26'" [height]="'26'" [ngClass]="currentHoverEffect" class="cursorPointer"></app-svg-icon>
    <app-svg-icon [ngbTooltip]="'Send SMS'" [name]="'textmsg'" (click)="sendNotification(1)" [width]="'26'" [height]="'26'" [ngClass]="currentHoverEffect" class="cursorPointer"></app-svg-icon>
    <app-svg-icon [ngbTooltip]="'Send Mail'" [name]="'email'" (click)="sendNotification(2)" [width]="'26'" [height]="'26'" [ngClass]="currentHoverEffect" class="cursorPointer"></app-svg-icon>
    <app-svg-icon [ngbTooltip]="'Share To WhatsApp'" [name]="'whatsapp'" (click)="sendNotification(3)" [width]="'26'" [height]="'26'" [ngClass]="currentHoverEffect" class="cursorPointer"></app-svg-icon>
    <app-svg-icon [ngbTooltip]="'Copy Link'" [name]="'copy'" (click)="copyText()" [width]="'26'" [height]="'26'" [ngClass]="currentHoverEffect" class="cursorPointer"></app-svg-icon>
    <app-svg-icon [ngbTooltip]="'Close'" [name]="'cross'" (click)="activeModal.dismiss()" [ngClass]="currentHoverEffect" class="cross-button cursorPointer"></app-svg-icon>
  </div>
</div>
<style>
 
</style>
<div class="modal-body ">
  <iframe #pdfIframe
      *ngIf="src"
      [src]="src"
      style="width: 100%; height: 100vh; border: none;"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
></iframe>
<iframe #pdfIframe
*ngIf="data?.printHtml"
style="width: 100%; height: 100vh; border: none;"
webkitallowfullscreen
mozallowfullscreen
allowfullscreen
></iframe>
</div>