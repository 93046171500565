<div class="card shine"
[ngClass]="{'invoice-draft-card': CardView === 'InvoiceAndDraftCard','EventCardBody':CardView === 'EventCardView'}"
[ngStyle]="{'width.%': width,'height.%': height, 'background': background}" 
>

    <!-- Sale-Order-Process region -->
    <ng-container *ngIf="CardView === 'SaleOrderCard'" >
        <div class="liness image-box"></div>
        <div class="d-flex align-items-center justify-content-between p-1">
            <div class="lines labels"></div>
            <div class="lines labels"></div>
        </div>
        <div class="p-1">
          <div class="lines Name-label "></div>
        </div>
        <div></div>
        <div class="d-flex align-items-center justify-content-end p-1 ">
             <div class="lines "></div>
        </div>
        <div class="p-1">
            <div class="icon "></div>
        </div>
    </ng-container>
<!-- Sale-Order-Process region end -->

<!-- Jewellery Card region  -->
    <ng-container *ngIf="CardView === 'JewelleryCard'" >
        <div class="liness image-box"></div>
        <div class="p-1">
        <div class="lines Name-label "></div>
        <div class="lines" ></div>
        </div>
    </ng-container>
<!-- Jewellery Card region end -->

    <!-- Manufacturing Card region -->
    <ng-container *ngIf="CardView === 'ManufacturingCard'" >
        <div class="liness image-box"></div>
        <div class="d-flex align-items-center justify-content-between p-1">
            <div class="lines labels"></div>
            <div class="lines labels"></div>
        </div>
        <div class="p-1">
          <div class="lines Name-label "></div>
          <div class="lines Name-label "></div>
          <div class="lines Name-label "></div>
        </div>
        <div></div>
        <div class="p-1">
            <div class="icon "></div>
        </div>
    </ng-container>
    <!-- Manufacturing Card region end-->

    <!-- InvoiceAndDraftCard Card region -->
    <ng-container *ngIf="CardView === 'InvoiceAndDraftCard'" >
        <div class="lines ms-3 table-line"></div>
        <div class="ps-3 pt-0 pb-0 d-flex">
            <div class="border-gray1-right div-card-one">
                <div class="lines labels-row-one"></div>
                <div class="lines labels-row-mid"></div>
                <div class="lines labels-row-one"></div>
            </div>
            <div class="border-gray1-right ps-3 div-card-one">
                <div class="lines labels-row-one"></div>
                <div class="lines labels-row-icon"></div>
                <div class="lines labels-row-icon-two"></div>
            </div>
            <div class="border-gray1-right ps-3 div-card-two">
                <div class="lines labels-row"></div>
                <div class="lines labels-row"></div>
                <div class="lines labels-row"></div>
            </div>
            <div class="border-gray1-right pe-3 div-card-two div-align-right">
                <div class="lines labels-row"></div>
                <div class="lines labels-row"></div>
            </div>
            <div class="border-gray1-right pe-3 div-card-three div-align-right">
                <div class="lines labels-row"></div>
                <div class="lines labels-row"></div>
            </div>
           <div class="d-flex align-items-center justify-content-center">
            <div class="lines ms-3 label-box"></div>
            <div class="lines ms-2 label-box"></div>
            <div class="lines ms-2 label-box"></div>
            <div class="lines ms-2 label-box"></div>
            <div class="lines ms-2 label-box"></div>
            <div class="lines ms-2 label-box"></div>
           </div>
        </div>
       <div class="d-flex justify-content-end me-3 pe-3 align-items-center">
        <div class="lines labels-row-end"></div>
       </div>
    </ng-container>
    <!-- InvoiceAndDraftCard Card region end -->

    <!-- Dashboard Card region  -->
    <ng-container *ngIf="CardView === 'Dashboard'" >
        <div *ngFor="let shimmer of CardCount" >
        <div class="p-1">
            <div class="dashboard-row"></div>
        </div>
        </div>
    </ng-container>
<!-- Dashboard Card region end -->

<!-- EventCardView Card region  -->
    <ng-container *ngIf="CardView === 'EventCardView'" >
        <div *ngFor="let shimmer of CardCount" >
        <div class="ps-3 d-flex gap-2">
            <div class="eventcard-column"></div>
            <div class="d-flex flex-column w-100 gap-0 mt-1" >
            <div class="lines mt-2 eventcard-row"></div>
            <div class="lines mt-2 eventcard-row"></div>
            <div class="lines mt-2 eventcard-row"></div>
            </div>
            <div class="lines  eventcard-last-row"></div>
        </div>
        </div>
    </ng-container>
<!-- EventCardView Card region end -->
</div>
