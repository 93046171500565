import { Component,ElementRef,EventEmitter,forwardRef, Input, Output, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IconHover } from '../../enum/common-enum';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-custom-month-picker',
  templateUrl: './custom-month-picker.component.html',
  styleUrls: ['./custom-month-picker.component.scss'],
})
export class CustomMonthPickerComponent {
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  years:string[] = [];

  isMonthGridVisible = false;
  selectedMonthToShow;
  currentHoverEffect = IconHover.Pink;
  selectedYearIndex: number | null = null;
  @Input() selectedYear: string|number = new Date().getFullYear();
  @Input() selectedMonth: any ;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @Output() onApply = new EventEmitter();

  constructor(public activeModal: NgbActiveModal){
    this.years = this.generateYears();
  }

  generateYears(): string[] {
    const startYear = 1900;
    const currentYear = new Date().getFullYear();
    const endYear = currentYear + 50;

    const years: string[] = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year?.toString());
    }
    return years;
  }


  apply() {
    this.onApply.emit({year:this.selectedYear , month :this.selectedMonth });
  }

  getSelectedMonth(){
    if(!this.selectedMonth) return;
    const selectdMonth =  parseInt(this.selectedMonth);
   return  this.months[selectdMonth-1] || "";
  }

  clear() {
    this.selectedMonth = null;
    this.selectedMonthToShow = null;
  }


  scrollToYear(yearDiv: ElementRef|any, yearIndex: number): void {
    // Scroll the clicked year div into view
    // yearDiv?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    // Toggle the visibility of the month's grid
    // this.toggleMonthGrid(yearIndex);
  }

  getMonthStartAndEnd(year: number, month: number): { startDate: string; endDate: string } {
    const datePipe = new DatePipe('en-US');
    const startDate = new Date(year, month - 1, 1);     // Get the start date (1st day of the month)
    const endDate = new Date(year, month, 0);      // Get the end date (last day of the month)
  
    const formatDate = (date: Date) => datePipe.transform(date, 'yyyy-MM-dd');   // Format the dates using DatePipe
   
    return {
      startDate: formatDate(startDate) || '',
      endDate: formatDate(endDate) || '',
    };
  }

  selectYear(year , ){
    this.selectedYear = year;
  }

  scrollToTop(): void {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.scrollTo({
        top: 0,
        behavior: 'smooth' // Enables smooth scrolling
      });
    }
  }
}
