import { Component, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { DataService } from './shared/services/data.service';
import { StorageName } from './shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from './shared/services/layout.service';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
// import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  ipAddress: any;
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(private layoutService: LayoutService, @Inject(DOCUMENT) private document: Document,
    private loader: LoadingBarService, public storageService: StorageService, public dataService: DataService,
    private translate: TranslateService, private cdr: ChangeDetectorRef
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit(): void {
    const url = this.storageService.retrieve(StorageName.IP_ADDRESS);
    if (url) environment.baseUrl = url.trim();

    // for change url icon dyanamic for index.html
    this.setFavicon(this.layoutService?.displayDefaultCompanyIcon);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  setFavicon(url: string | undefined): void {
    const favicon = this.document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (favicon) {
      favicon.href = url;
    }
  }
}

export const COLORS = {
  RED: '#FF0000',
}