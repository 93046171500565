<div class="month-picker">
  <div class="mb-3 d-flex justify-content-between">
      <div class="f-14 font-semibold">{{getSelectedMonth()}} {{selectedYear}}</div>
      <!-- <div><app-svg-icon [name]="'cross'"  class="cursorPointer" [ngClass]="currentHoverEffect" (click)="activeModal?.dismiss()"></app-svg-icon> </div> -->
  </div>
  <div class="d-flex flex-column align-items-center month_year_options_container" #scrollContainer>
    <div class="w-100 month_year_options" *ngFor="let year of years; let i = index"  #yearDiv>
      <p class="mb-0 lh-1 year p-1 px-2 cursorPointer" (click)="selectYear(year)"> {{ year }} </p>
      <div class="month-grid my-1" *ngIf="selectedYear == year">
        <button
          *ngFor="let month of months; let j = index"
          [ngClass]="{ 'selected': selectedMonth == (j+1)?.toString()?.padStart(2, '0')}"
          (click)="selectedMonth = (j+1)?.toString()?.padStart(2, '0')">
          {{ month }}
        </button>
      </div>
    </div>
  </div>
  
  
  <div class="modal-footer pt-2 gap-2 d-flex align-items-center justify-content-center">
      <app-button class="m-0" [disabled]="!selectedMonth" [buttonLabel]="'Apply'" (buttonOutputEvent)="apply();"
      [buttonClass]="'blueText_white_bg_button'" [buttonType]="'submit'">
      </app-button>
      <app-button class="my-0" (buttonOutputEvent)="clear();" [buttonLabel]="'Clear'"
          [buttonClass]="'common_button d-flex align-items-center justify-content-center pink_button text-nowrap f-14 font-semibold '"
          [buttonType]="'button'">
      </app-button>
  </div>
</div>