import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from '../../services/common.service';
import { GetAllTaskTypeResponseModel, } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';
import { StorageService } from '../../services/storage.service';
import { StorageName } from '../../enum/common-enum';

@Component({
  selector: 'app-task-type',
  templateUrl: './task-type.component.html',
  styleUrls: ['./task-type.component.scss']
})
export class TaskTypeComponent extends CommonService implements OnInit{

  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllTaskType          : any[]=[];
  copyGetAllTaskType        : any[]=[]
  editTaskType               = new GetAllTaskTypeResponseModel();
  isAdd                       : boolean = false;
  getAllDropdownModel : any = {};
  storageName = StorageName;

constructor(
  public renderer:Renderer2,
  public administrationService : AdministrationService,
  public toaster: ToastrService,
  public sendDataService : SendDataService,
  public storageService:StorageService
) 
{
  super(renderer)
}

ngOnInit(): void {
  this.getAllTaskTypeList();
  this.getAllDropdownModel.taskTypeUsedInDropdown = this.storageService.retrieve(this.storageName.TASKTYPE_USEDIN_DROPDOWN);
}

add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return; if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
  this.isAdd = true;
  const obj = {id:0, name:'',usedIn:null,active:true}
  this.getAllTaskType?.push(obj);
  this.copyGetAllTaskType?.push(obj);
  setTimeout(()=>{
    const element = document.getElementById(`tasktypename${obj.id}`);
    element?.focus();
  },0)
}


cancel(){
  if(this.isAdd)
  {
    this.isAdd = false;
    this.getAllTaskType = this.getAllTaskType.filter((x)=>x.id != 0);
    this.copyGetAllTaskType = this.copyGetAllTaskType.filter((x)=>x.id != 0);
  }
  else if(this.editTaskType?.id && this.editTaskType?.isEdit){
    const index = this.copyGetAllTaskType.findIndex((x)=>x.id == this.editTaskType?.id);
    const singleData =JSON.parse(JSON.stringify(this.copyGetAllTaskType))[index];
    this.getAllTaskType[index] = singleData;
    this.editTaskType = new GetAllTaskTypeResponseModel();
  }  
}


edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
  item.isEdit = true;
  const controlObj = formData?.form?.controls;
  if(controlObj){
    const id = Object.keys(controlObj)[0];
    const element = document.getElementById(id);
    element?.focus();
  }
   this.editTaskType = {...item};
}

checkIsEdit(arr:any[]):boolean{
  return arr?.some((x)=>x.isEdit)
}

  // *********** Task Type API START *********
  // **********get all Task Type *********
  getAllTaskTypeList() {
    this.administrationService.getAllTaskType().subscribe({
      next:(res)=>{
        if(res.isSuccess){
            this.getAllTaskType = res.response??[];
            this.editTaskType = new GetAllTaskTypeResponseModel();
            this.copyGetAllTaskType = JSON.parse(JSON.stringify(this.getAllTaskType.filter((x)=>x.active == true)))??[];
          }else{           
          this.getAllTaskType = [];
          this.copyGetAllTaskType = [];
        }  
      },
      error:(err)=>{
        this.getAllTaskType = []; 
        this.copyGetAllTaskType = [];
      }
    })
  }

 // *************insert Task Type **************
  InserTaskTypeList(item) {
    const {id , ...rest} = item;
    this.administrationService.insertTaskType(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            this.isAdd = false;
            this.getAllTaskTypeList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }

  // **********update Task Type *********
    UpdateTaskType(item) {
    const {isEdit , ...rest} = item;
    this.administrationService.updateTaskType(rest)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe((res) => {
          if(res.isSuccess){
            
            this.isAdd = false;
            this.getAllTaskTypeList();
            this.toaster.success(res.message);
          }else{
            this.toaster.error(res.errors[0]);
          }  
      });
    }  

    // **********delete Task Type *********
    DeleteTaskType(id) {
      if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
      this.administrationService.deleteTaskType(id)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.getAllTaskTypeList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
      });
    }
  
  // *********** TaskType API END *********
}
