import { Injectable } from '@angular/core';
import { KeyCreatorParams, ValueFormatterParams } from 'ag-grid-community';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DataService } from './data.service';
import { Location } from '@angular/common';
import { cjAppMainUrl, cjDarkLogoPath, cjHeaderLogoPath, cjLightLogoPath, cjLoginLogoPath, jsBusinessRegistrationLogoPath, jsHeaderLogoPath, jsLightLogoPath, jsLoginLogoPath } from '../utils/common-utils';
import * as LZString from 'lz-string';
@Injectable({
    providedIn: 'root'
  })
export class StorageService {
    private storage: any;
    public invoiceNoCalled = new BehaviorSubject<any>(undefined);
    public onAddProductsInItemList = new BehaviorSubject<any>(undefined);
    public onAddProductsInPaymentList = new BehaviorSubject<any>(undefined);
    public onItemListRowClicked = new BehaviorSubject<any>(undefined);
    public onInvoiceCalculation = new BehaviorSubject<any>(undefined);
    public callInvoiceCalculation = new BehaviorSubject<any>(undefined);
    public sortAndFilter = new BehaviorSubject<any>(undefined);
    public hideColumnsPermanent = new BehaviorSubject<any>(undefined);
    public resetInvoice = new BehaviorSubject<any>(undefined);
    public onPaymentListChanged = new BehaviorSubject<any>(undefined);
    public onDeleteRowClicked =  new Subject<any>();
    public onPrintIconClicked =  new Subject<any>();
    public onEditRowClicked = new BehaviorSubject<any>(undefined);
    public onResetRowClicked = new BehaviorSubject<any>(undefined);
    public getInvoice = new BehaviorSubject<any>(undefined);
    public onTaxChange = new BehaviorSubject<any>(undefined);
    public onTabKeyPressed = new BehaviorSubject<any>(undefined);
    public lastCellTriggered = new Subject<any>();
    public onNavTabKeyPressed = new Subject<any>();
    public onclearDesignNo = new Subject<any>();
    public onAddButtonKeyPressed = new Subject<any>();
    public onCellClicked = new Subject<any>();
    public onFilter = new Subject<any>();
    public filterData = new Subject<any>();
    public onFilterData = new Subject<any>();
    public setSelect2Dropdown = new Subject<any>();
    public cellRendererIconClicked = new Subject<any>();
    public cellRendererIconBarcodepopup= new Subject<any>();
    public AddOnLoansClick = new Subject<any>();
    public PayInterestClick = new Subject<any>();
    public cellRendererDiamondPrintClicked = new Subject<any>();
    public cellRendererIconCardPrintClicked = new Subject<any>();
    // public cellRendererIconCardPaymentClicked = new Subject<any>();
    public cellRendererIconLabelPrintClicked = new Subject<any>();
    public imgCellClicked = new BehaviorSubject<any>(undefined);
    public barcodeCellClicked = new BehaviorSubject<any>(undefined);
    public stockJournalRecordFromTransfer = new Subject<any>();
    public RFIDSCanned = new BehaviorSubject<any>(undefined);
    public service:DataService;
    public outOfMetalSection = new BehaviorSubject<any>(undefined);
    public onReceiveButtonclick = new Subject<any>();  //for transactio manufacturing job order list tab
    public onJobWorkOrderButtonclick = new Subject<any>();  //for transactio manufacturing job order list tab
    public releaseUnreleaseButtonclick = new Subject<any>();
    public stockJournalcreateupdate= new Subject<any>();
    public selectedMetalIdForFilter = new BehaviorSubject<any>(undefined);
    public totalRecordpagonation = new BehaviorSubject<any>(undefined);
    public barcodeScanValueSource = new Subject<any>();
    barcodeScanValueChange$ = this.barcodeScanValueSource.asObservable();
    public customDropdownValue = new BehaviorSubject<any>(undefined);
    public sendWhatsappImg = new BehaviorSubject<any>(undefined);
    public sendEmailImg = new BehaviorSubject<any>(undefined);
    public resetSearchFilter = new Subject<void>;
    resetInvoiceEvent = new Subject<void>()
    //#region  dynamic set svg path variable
    logoLogin  :string;
    logoMain  :string;
    logoHeader :string;
    logoBusinessRegistrationPage
    //#endregion

    mainUrl;
    providedCompanyName

    constructor() {
        this.setStorage();
        //this.storage = sessionStorage; // localStorage;
        const { host, origin } = window.location;
        this.mainUrl = host;
        if (host == cjAppMainUrl) {
            document.documentElement.style.setProperty('--providedCompanyName', "Canadian Jewellers Technology");
            document.documentElement.style.setProperty('--login-bg-image-url', "url('assets/images/login/cj-background-login-img.png')");
            this.providedCompanyName = "Canadian Jewellers Technology";
            this.logoLogin = cjLoginLogoPath;
            this.logoMain = cjDarkLogoPath;
            this.logoHeader = cjHeaderLogoPath;
            this.logoBusinessRegistrationPage = jsBusinessRegistrationLogoPath;
        } else {
            // document.documentElement.style.setProperty('--providedCompanyName', "Jewelsteps");
            document.documentElement.style.setProperty('--login-bg-image-url', "url('assets/images/login/js-background-login-img.png')");
            this.providedCompanyName = "Jewelsteps";
            this.logoLogin = jsLoginLogoPath;
            this.logoMain = jsLightLogoPath;
            this.logoHeader = jsHeaderLogoPath;
            this.logoBusinessRegistrationPage = jsBusinessRegistrationLogoPath
        }
    }

    public retrieve(key: string): any {
        const compressed = localStorage.getItem(key);
        if (compressed)
            return JSON.parse(LZString.decompressFromUTF16(compressed) || '{}');

        return undefined;
    }

    public store(key: string, value: any) {
        try {
            const compressed = LZString.compressToUTF16(JSON.stringify(value));
            this.storage.setItem(key, compressed);
        }
        catch (ex) {
            console.error('Error storing data in localStorage', { key, value, error: ex });
        }
    }

    public clear() {
        localStorage.clear();
        sessionStorage.clear();
    }

    public removeItem(key: string) {
        this.storage.removeItem(key);
    }

    public setRememberMe(value: any) {
        this.store('rememberMe', value);
        this.setStorage();
    }
    
    private setStorage() {
        const rememberMe = this.retrieve('rememberMe');
        // this.storage = (rememberMe && rememberMe == "true") ? localStorage : sessionStorage;
        this.storage = localStorage;
    }

    keyCreator(params: KeyCreatorParams): string {
        return params.value.code;
    }
    valueFormatter(params: ValueFormatterParams): string {
        return params.value.name;
    }
}
