import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';
import { StorageService } from '../../services/storage.service';
import { ResponseModel } from '../../models/response-model.model';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemListSectionService {
  constructor(public service:DataService,public storageService:StorageService) { }

  GenerateBarcodeForSaleOrderItem(){
    let url = `api/app/saleorder/generatebarcodeforsaleorderitem`;
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

}
