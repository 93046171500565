import { Component, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { AgGridI, PaginationModel, defaultPageLimit } from 'src/app/shared/common-components/ag-grid/ag-grid.model';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { ReportService } from '../../../report.service';
import { Subscription, catchError, throwError } from 'rxjs';
import { AgGridComponent } from 'src/app/shared/common-components/ag-grid/ag-grid.component';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ExportAs, IconHover, StorageName } from 'src/app/shared/enum/common-enum';
import { AgGridAccountLedgerReportAmountTable } from './accountledger-report-amount.model';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { AgGridButtonCellrendererService } from 'src/app/shared/common-components/ag-grid/ag-grid-button-cellrenderer/ag-grid-button-cellrenderer.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { exportRequest } from '../../../report.model';
import { ExcelConverterServiceService } from 'src/app/shared/services/excel-converter-service.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AccountledgerReportAmountAdvancedFilterComponent } from './advanced-filter-popup/advanced-filter-popup.component';
import { CommonFilterService } from 'src/app/shared/services/common-filter.service';
import { ToastrService } from 'ngx-toastr';
import { FileReaderService } from '../../../../../shared/services/file.reader.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAccountLedgerDetailInfo } from '../accountledger-detail-info/accountledger-detail-info.model';


@Component({
  selector: 'app-accountledger-report-amount',
  templateUrl: './accountledger-report-amount.component.html',
  styleUrls: ['./accountledger-report-amount.component.scss']
})
export class AccountLedgerReportAmountComponent extends CommonService implements OnInit {
  agGridDataModel         : AgGridI;
  agGridDayReportRowData :any[]=[];
  totalRecords        : number;
  gridApi:any
  storageName = StorageName;
  formName = StorageName.FORMNAME_REPORT_ACCOUNTLEDGERREPORT_BALANCEAMOUNT;
  mainFormName =StorageName.FORMNAME_REPORT_ACCOUNTLEDGERREPOR;
  paginationStorageName = StorageName.PAGINATION_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID;
  apiRequestStorageName = this.storageName.APIREQUEST_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID;
  apiRequestModel : any = {};
  getAllDropdownModel  : any = {};
  pagination = new PaginationModel();
  @ViewChildren(AgGridComponent) grids: QueryList<AgGridComponent>;
  @Output() rowClickEventForNavigation: EventEmitter<any> = new EventEmitter<any>();
  @Input() data : any;
  public viewAllButtonSubscription: Subscription; 
  onFilterdataSubscription: Subscription;
  exportAs = ExportAs
  currentHoverEffect = IconHover.Pink;
  agGridRefineTable: any;
  @Output() changeValue = new EventEmitter<any>();
  @Output() emitFilterData = new EventEmitter();
  filterCountValue:any ;
  paramsData : any;
  constructor(
    public renderer : Renderer2,
    public activeModal    : NgbActiveModal,
    public storageService:StorageService,
    private sendDataService: SendDataService,
    public fileReaderService : FileReaderService,
    private reportService :ReportService,
    public excelConverterServiceService : ExcelConverterServiceService ,
    private agGridButtonCellrendererService :AgGridButtonCellrendererService,
    public notificationService : NotificationService,
    public toaster: ToastrService,
    public commonFilterService:CommonFilterService,
  ){
    super(renderer);
    this.viewAllButtonSubscription = this.agGridButtonCellrendererService.viewHistoryClicked$.subscribe((params: any) => {
      const data = params.data;
      this.navgiateToAccountLedgerDetailInfo(data);
    });

    this.onFilterdataSubscription = this.storageService.onFilterData.subscribe((params: any) => {
      
      this.apiRequestModel.filters = params.filters
    });
  };

  ngOnInit(): void {
    this.initialData();
  }
  initialData() {
    if (this.paramsData?.ledgerId) {
      this.agGridDataModel = AgGridAccountLedgerDetailInfo;
    } else {
      this.agGridDataModel = AgGridAccountLedgerReportAmountTable;
    }
    this.agGridDataModel.isServerSidepagination = true;
    this.agGridDataModel.showPagination = true;
    this.agGridDataModel.showFilter = true;
    this.sendDataService.displayPageName(this.formName);
    this.setPaginationRequestModel();
  }
  close(){
    this.activeModal.close()
  }


  onGridReady(event){
    this.gridApi  = event; 
    this.setDefaultFilter();
  }

  setDefaultFilter() {
      this.apiRequestModel.filters = '';
      if(this.data?.elementsToHide){
        this.filterCountValue = 1;
      }else this.filterCountValue = 2;
      this.commonFilterService.setDefaultFilter(this.apiRequestStorageName, this.apiRequestModel, this.agGridDataModel.filterStorageName);
      this.emitFilterData.emit()
      this.getReport();
  }

  setPaginationRequestModel() {
    var storedPagination : any;
    this.apiRequestModel.page = storedPagination != undefined ? storedPagination.page : 1;
    this.apiRequestModel.pageSize = storedPagination != undefined ? storedPagination.pageSize : 25;
  }

  attachedAppliedRequstData(gridApi){
    const requestModel = {
      fromDate:this.apiRequestModel?.fromDate ,
      toDate:this.apiRequestModel?.toDate
    };

    gridApi.api.appliedRequestModelData = requestModel;
  }

  getReport() {
    this.showLoading(this.gridApi);
    this.apiRequestModel = this.storageService.retrieve(this.apiRequestStorageName);
    this.reportService.getAccountLedgerReportAmount(this.apiRequestModel).subscribe({
      next: (res) => {
        this.commonFilterService.processServersideApiResponse(res,this.grids.first,this.gridApi,this.pagination,this.apiRequestModel.getAll);
      },
      error: (err) => {
          this.hideLoading(this.gridApi);          
          this.gridApi?.api?.setRowData([]);
      }
    })
    this.changeValue.emit(this.filterCountValue);
  }

  refreshGridApi(){
    this.gridApi.api.refreshHeader();
  }


  onPagination(pagination: PaginationModel) {
    this.storeChangedPagination(pagination);
    this.getReport();
  }

  storeChangedPagination(paginationModel: PaginationModel) {
    this.pagination = this.commonFilterService.storeChangedPagination(paginationModel, this.pagination,this.paginationStorageName,this.apiRequestStorageName);
  }

  calculateTotal(field: string): number {
    let total = 0;
  
    if (this.agGridDayReportRowData && this.agGridDayReportRowData.length > 0) {
      total = this.agGridDayReportRowData.reduce((sum, item) => {
        return sum + (item[field] || 0);
      }, 0);
    }
    return total;
  }

  totalInwardWeight: number = 0;
  totalOutwardWeight: number = 0;
  totalcreditWeight: number = 0;
  totalinvoiceWeight: number = 0;
  totaldebitWeight: number = 0;
  
  updateTotalWeights() {
    this.totalInwardWeight = this.calculateTotal('inwardWt');
    this.totalOutwardWeight = this.calculateTotal('outwardWt');
    this.totalcreditWeight = this.calculateTotal('credit');
    this.totalinvoiceWeight = this.calculateTotal('debit');
    this.totaldebitWeight = this.calculateTotal('invoiceNo');
  }
  fillDropdownData(){ 
    this.getAllDropdownModel.branchDropdown = this.storageService.retrieve(StorageName.BRANCH_DROPDOWN);
    this.getAllDropdownModel.voucherTypeDropdown= this.storageService.retrieve(StorageName.VOUCHER_TYPE_DROPDOWN);
    this.getAllDropdownModel.allAccountLedgerDropdown =this.storageService.retrieve(StorageName.ALL_ACCOUNTLEDGER_DROPDOWN);
    this.getAllDropdownModel.ledgerTypeDropdown =this.storageService.retrieve(StorageName.LEDGERTYPE_DROPDOWN);
    this.getAllDropdownModel.accountGroupDropdown= this.storageService.retrieve(StorageName.ACCOUNTGROUP_DROPDOWN);
    this.getAllDropdownModel.typeOfVoucherDropdown= this.storageService.retrieve(StorageName.FILTER_VOUCHER_TYPE_TYPEOFVOUCHER);
    this.getAllDropdownModel.againstDropdown = this.storageService.retrieve(StorageName.AGAINST_DROPDOWN);

  }
  

  handleError(error: any) {
    console.error('An error occurred:', error);
    return throwError('Something went wrong');
  }

  onPageChanged(value) {
    this.apiRequestModel.page = value;
    this.getReport();
  }
  
  getEndRange(): number {
    const endRange = this.apiRequestModel.page * this.apiRequestModel.pageSize;
    return endRange > this.totalRecords ? this.totalRecords : endRange;
  }

  getStartRange(): number {
    return (this.apiRequestModel.page - 1) * this.apiRequestModel.pageSize + 1;
  }

  navgiateToAccountLedgerDetailInfo(params){
    const defaultFilterData =
    {
      ledgerId: params.ledgerId,
      filters: this.apiRequestModel.filters
    }
    this.rowClickEventForNavigation.emit(defaultFilterData);
  }

  openAdvanceFilterPopUp() {
    const data = {getAllDropdownModel: this.getAllDropdownModel,agGridDataModel: this.agGridDataModel,apiRequestModel: this.apiRequestModel,modalFilterData: this.data,};
    this.notificationService.openModalPopup(AccountledgerReportAmountAdvancedFilterComponent, data, 'Advance Filter')
      .then((resultData) => {
        if (resultData) {
          const filteredRules = resultData.filters?.rules?.filter(
            (item) =>
              !((item.field === 'onlyBalance' && item.data === 'false') || (this.data?.elementsToHide && item.field === 'branchId'))
          );
          resultData.filters.rules = filteredRules;
          this.apiRequestModel.filters = JSON.stringify(resultData.filters);
          this.storageService.store(this.apiRequestStorageName, this.apiRequestModel);
          this.filterCountValue = this.filterCount({ filters: { rules: filteredRules } });
            this.emitFilterData.emit();
          this.getReport();
        }
      });
  }
  

  resetFilter() {
    this.setDefaultFilter();
  }

  callApiForExportData(){
    const exportAs = this.apiRequestModel.exportAs;
    this.reportService.getAccountLedgerReportAmount(this.apiRequestModel).subscribe(
      {
        next: (res) => {
        this.fileReaderService.exportFile(res, exportAs);
        },error(error) {
        console.error('Error in downloading Excel/Pdf file:', error);
        },
       }
    );
  }

  ngOnDestroy(): void {
    this.viewAllButtonSubscription.unsubscribe();
    this.onFilterdataSubscription?.unsubscribe();
    this.agGridDataModel.rows=[];
    this.gridApi?.api?.setRowData([]);
  }

}
