import { Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs';
import { GetAllUnitResponseModel } from 'src/app/components/administration/administration.model';
import { SendDataService } from '../../services/send-data.service';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.scss']
})
export class UnitComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;
  getAllUnitData              : any[]=[];

  copyGetAllUnitData     : any[]=[];
  editUnitData           = new GetAllUnitResponseModel();
  isAdd                       : boolean = false;
 
  constructor(
    public renderer:Renderer2,
    public administrationService : AdministrationService,
    public toaster: ToastrService,
    public sendDataService : SendDataService,
  ) 
  {
    super(renderer)
  }

ngOnInit(): void {
  this.getAllUnitList();
  }


  add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
    this.isAdd = true;
    const obj = {id:0,noOfDecimalPlaces:0,description:'',formalName:'',name:'', active:true}
    this.getAllUnitData?.push(obj);
    this.copyGetAllUnitData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`unitName${obj.id}`);
      element?.focus();
    },0)
  }


  cancel(){
    if(this.isAdd)
    {
      this.isAdd = false;
      this.getAllUnitData = this.getAllUnitData.filter((x)=>x.id != 0);
      this.copyGetAllUnitData = this.copyGetAllUnitData.filter((x)=>x.id != 0)
    }
    else if(this.editUnitData?.id && this.editUnitData?.isEdit){
      const index = this.copyGetAllUnitData.findIndex((x)=>x.id == this.editUnitData?.id);
      const singleData =JSON.parse(JSON.stringify(this.copyGetAllUnitData))[index];
      this.getAllUnitData[index] = singleData;
      this.editUnitData = new GetAllUnitResponseModel();
    }  
  }


  edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
    item.isEdit = true;
    const controlObj = formData?.form?.controls;
    if(controlObj){
      const id = Object.keys(controlObj)[0];
      const element = document.getElementById(id);
      element?.focus();
    }
     this.editUnitData = {...item};
  }

  checkIsEdit(arr:any[]):boolean{
    return arr?.some((x)=>x.isEdit)
  }
  
    // *********** UNIT API START *********
    // **********get all unit ********* 
    getAllUnitList() {
      this.administrationService.getAllUnitData = [];
      this.administrationService.getAllUnit().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.getAllUnitData = res.response??[];
            this.administrationService.getAllUnitData = JSON.parse(JSON.stringify(this.getAllUnitData.filter((x)=>x.active==true)))??[];
            this.editUnitData = new GetAllUnitResponseModel();
            this.copyGetAllUnitData = JSON.parse(JSON.stringify(this.getAllUnitData))??[];
          }else{           
            this.getAllUnitData = [];
            this.copyGetAllUnitData = [];
          }  
        },
        error:(err)=>{
          this.getAllUnitData = []; 
          this.administrationService.getAllMetalData = [];
          this.copyGetAllUnitData = [];
        }
      })
    }
    
   // *************insert unit **************
    InserUnitList(item) {
      const {id , ...rest} = item;
      this.administrationService.insertUnit(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllUnitList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      }
  
    // **********update unit *********
      UpdateUnitList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateUnit(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllUnitList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      }  
  
      // **********delete unit *********
      DeleteUnitList(id) {
        if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteUnit(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllUnitList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
    
    // *********** UNIT API END *********
}
