import { Injectable } from '@angular/core';
import { DataService } from '../../shared/services/data.service';
import { ResponseModel } from '../../shared/models/response-model.model';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public TransactionTypedata: number;
  constructor(public service:DataService) { }

  getProductTaxByProductDetailId(id:string){
    let url = `api/app/product/getProductTaxByProductDetailId?Id=${id}`;
    return this.service.get(url,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

}
