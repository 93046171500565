import { MakingChargeType, StorageName } from 'src/app/shared/enum/common-enum';
import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { EditDeleteIconService } from './edit-delete-icon.service'
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CheckboxRendererService } from '../checkbox-renderer/checkbox-renderer.service';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { BlockUnblockStatus } from 'src/app/components/administration/crm/crm.model';
import { IconHover } from '../../../enum/common-enum';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ToastrService } from 'ngx-toastr';

export interface Icons{
  cameraIcon:boolean,
  resetIcon:boolean,
  clockIcon:boolean,
  saveIcon:boolean,
  editIcon:boolean,
  focusOnColumn:string,
  tableId?:string,
  isEditRowOnEdit :boolean,
  isFirstDeleteIcon:boolean,
  isDeleteIcon:boolean,
  billIcon:boolean,
  downloadIcon:boolean,
  downloadDocumentFileIcon:boolean,
  ShowDetailsIcon:boolean,
  printIcon:boolean,
  isCheckbox:boolean,
  isConfirmationPopOpen
 }

@Component({
  selector: 'app-edit-delete-icon',
  templateUrl: './edit-delete-icon.component.html',
  styleUrls: ['./edit-delete-icon.component.scss']
})
export class EditDeleteIconComponent implements ICellRendererAngularComp {
  // public params: ICellRendererParams & Icons;
  currentHoverEffect = IconHover.Pink;
  public params: any;
  @Input() apiEndpoint: string;
  storageName = StorageName;
  getParamasData:any;
  isConfirmationPopOpen = true;
  isDeleteIcon = true;
  previousData
  currentUrl
  canDeleteOrUpdate:boolean=true;

  //checkbox rendere
  
  checked: boolean=false;
  labelName:string ='';
  showLabel: boolean = true; 
  isReadOnly:boolean;
  userId:number;
  isCursor: boolean;
  isCommonCheckbox: boolean = false;
  isCommonReverseTax:boolean =false;
  status = BlockUnblockStatus;
  constructor(private checkboxRendererService:CheckboxRendererService,private editDeleteIconService: EditDeleteIconService,public route: Router,   
    public storageService : StorageService,
    public notificationService: NotificationService,
    private modalService : NgbModal,public activeModal:NgbActiveModal,
    public sendDataService: SendDataService, 
    public toaster: ToastrService,
  ){
  }

  agInit(params: ICellRendererParams & Icons): void {
    this.params = params;
    this.canDeleteOrUpdate = params.data?.canDeleteOrUpdate
    this.currentUrl = this.route.url;
    this.isConfirmationPopOpen = params?.isConfirmationPopOpen??true;
    this.isDeleteIcon = params?.isDeleteIcon??true;
    this.previousData = JSON.parse(JSON.stringify(this.params.node.data));
  
// checkbox renderer

    if (this.params?.isCommonCheckbox == true || this.params?.isCommonReverseTax == true) {
      this.checked = this.params?.value;
  }
    else{
          this.checked = this.params?.data?.active??false;
          this.labelName = this.params.data.name; 
          if(this.labelName ==undefined && this.params.data.out_LedgerName==undefined){
            this.labelName=this.params.data.in_LedgerName
          }
          if(this.params.data.in_LedgerName ==undefined && this.labelName==undefined){
            this.labelName=this.params.data.out_LedgerName
          }
          this.showLabel = this.params.showLabel !== undefined ? this.params.showLabel : true;
          this.userId = this.params.data.id; 
    }
   
    this.isReadOnly = this.params.isReadOnly !== undefined ? this.params.isReadOnly : false; 
    this.isCursor = this.params?.isCursor !== undefined ? this.params?.isCursor : true;
  }

  handleEditIconClick(params): void {
    params?.api?.deselectAll();
    params?.node?.setSelected(true);
    var allRows = params?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    allRows.forEach(row => {
      row.added = true;
    });
    params.node.data.added = false;
    // Emit an event to the main Angular application with the entire params object
  const customEvent = new CustomEvent('editIconClick', {
    detail: { data: params },
  });
  this.params.api.dispatchEvent(customEvent);
    
  }
  checkAllValues(params: any): boolean {
    return params?.data?.colorId && params?.data?.clarityId && params?.data?.sizeId && params?.data?.shapeId;
  }
  handleRapnetIconClick(params): void {
    if(this.checkAllValues(params)){ 
    params.api.deselectAll();
    params.node.setSelected(true);
  const customEvent = new CustomEvent('rapnetIconClick', {
    detail: { data: params },
  });
  this.params.api.dispatchEvent(customEvent);
}else{
  this.toaster?.error(  'Please fill in all required fields: color, clarity,shape and size.')
}
  }


  refresh(): boolean {
    // checkbox renderer

    this.checked = this.params?.data?.checkedBox;
    this.labelName = this.params.data.name;
    this.showLabel = this.params.showLabel !== undefined ? this.params.showLabel : true;
    this.isCursor = this.params.isCursor !== undefined ? this.params.isCursor : true;
    return true;
  }
  checkboxStates: { userId: number; active: boolean }[] = [];

  // editRowData(params) {
  //   if(this.params?.isEditRowOnEdit){
  //     this.params.saveIcon = true;
  //     this.params.editIcon = true;
  //     this.params.api.startEditingCell({
  //       colKey:this.params.focusOnColumn,
  //       rowIndex:this.params.rowIndex
  //     });
  //   }else{
  //     this.editrowdata(this.params)
  //   }
  // }
  
  saveRowData(params){
    
    this.params.saveIcon = false;
    this.params.editIcon = false;
    this.editDeleteIconService.triggerSaveClick(params.data);
    this.params.api.stopEditing();
   
  }

  editrowdata(params){
    // 
    //  this.editDeleteIconService.triggerEditClick(params.data);

     const customEvent = new CustomEvent('editIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
    //  this.storageService.onEditRowClicked.next(params);
  }

  onDeleteClick(params) {
    if (this.params?.DeleteMappedData) {
      this.openMappedConfirmationPopup(params);
    } else {
      this.openDeleteConfirmationPopup(params);
    }
  }


  onDelete(params){
    if(params?.isDisabled === true) return;
     const custoDeleteEvent = new CustomEvent('deleteButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(custoDeleteEvent);
  }
  
  openMappedConfirmationPopup(params) {
    params.data.deleteLedgers = false;
    this.notificationService.confirmationPopup('Deleting', `Do you want to delete ledgers which are mapped with this group?`, 'delete-confirm', true, 'Yes', `No`, false).subscribe((result) => {
      if (result) {
        params.data.deleteLedgers = result.isConfirmed;
        this.openDeleteConfirmationPopup(params)
      }
    });
  }

  openDeleteConfirmationPopup(params) {
    this.notificationService.confirmationPopup('Deleting', `Are you sure you want to delete from this list?`, 'delete-confirm', false, 'Delete', 'Cancel', true).subscribe((result) => {
      if (result.isConfirmed) {
        this.deleterowdata(params);
      } else if (result.isDismissed) {
      }
    });
  }
  
  deleterowdata(params)
  {
    params.api.deselectAll();
    params.node.setSelected(true);
     const selectedNode = params.api.getSelectedRows();
   //  modal?.dismiss('Cross click');
     this.params.api.applyTransaction({ remove: selectedNode});
     params.api.deselectAll();
     const custoDeleteEvent = new CustomEvent('deleteButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(custoDeleteEvent);
   // this.storageService.onDeleteRowClicked.next(params);
  }

  onResetClick(params) {
    for (var key in params.data) {
      switch (key) {
        case 'quantity':
          params.data[key] = '1';
          break;
        case 'puritySale':
        case 'purityPurchase':
        case 'purityPer':
        case 'requestedPurityPer':
          params.data[key] = "1.0";
          break;
        case 'makingTypeId':
          params.data[key] = MakingChargeType.PG;
          break;
        case 'id':
          params.data[key] = params.data[key];
          break;
        case 'metalName':
          params.data[key] = params.data[key];
          break;
        case 'stockValue':
        case 'rate':
          params.data[key] = '0.00';
          break;
        case 'weight':
          params.data[key] = '0.000';
          break;
        default:
          params.data[key] = undefined
          break
      }
    }
    params.api.refreshCells();
  }
  
  onPrintClick(params){
    
    // Emit an event to the main Angular application with the entire params object
    const customEvent = new CustomEvent('printButtonClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
      
  }
  onbillClick(params){
       // Emit an event to the main Angular application with the entire params object
    const customEvent = new CustomEvent('billIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

  onClockClick(params){
       // Emit an event to the main Angular application with the entire params object
    const customEvent = new CustomEvent('ClockIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

  ondownloadClick(params: any) {
    const fileUrl = params?.data?.documentUrl || params?.data?.documentPath;
    if (fileUrl) {
        const customEvent = new CustomEvent('downloadIconClick', {
            detail: { data: params }
        });
        this.params.api.dispatchEvent(customEvent);
    } 
  }


  onClickKycDetailsIcon(params){
    
       // Emit an event to the main Angular application with the entire params object
    const customEvent = new CustomEvent('kycDetailsIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

  onClickShowDetailsIcon(params){
    
       // Emit an event to the main Angular application with the entire params object
    const customEvent = new CustomEvent('ShowDetailsIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

  onClickdownloadIcon(params){
    
    const customEvent = new CustomEvent('downloadIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  }

// checkbox renderer

onCheckboxChange(event: any) {
  event.target.checked = !event?.target?.checked;
  this.checkboxRendererService.TriggerCheckBox(this.params);
}

//#region  rfid comp start
onClickBarcodeIcon(params){
  const customEvent = new CustomEvent('barcodeIconClick', {
    detail: { data: params },
  });
  this.params.api.dispatchEvent(customEvent);
 // this.storageService.cellRendererIconClicked.next(this.params)
}

onClickLabelPrint(params){
  const customEvent = new CustomEvent('labelPrintClick', {
    detail: { data: params },
  });
  this.params.api.dispatchEvent(customEvent);

//  this.storageService.cellRendererIconLabelPrintClicked.next(this.params)
}

onClickCardPrint(params){
  const customEvent = new CustomEvent('cardPrintClick', {
    detail: { data: params },
  });
  this.params.api.dispatchEvent(customEvent);

 // this.storageService.cellRendererIconCardPrintClicked.next(this.params)
}
onClickCardPayment(params){
  const customEvent = new CustomEvent('cardPaymentIconClick', {
    detail: { data: params },
  });
  this.params.api.dispatchEvent(customEvent);
}
onInfoIconClick(params){
const customEvent = new CustomEvent('InfoIconClick', {
 detail: { data: params },
});
this.params.api.dispatchEvent(customEvent);
// this.storageService.cellRendererIconBarcodepopup.next(this.params)

}

openExpandebleData(params){
  const customEvent = new CustomEvent('expandableIconClick', {
    detail: { data: params },
  });
  this.params.api.dispatchEvent(customEvent);
}
//#endregion rfid comp end
onBlockUnblockStatusClick(params) {
  const customEvent = new CustomEvent('blockUnblockIconClick', {
    detail: { data: params },
  });
  this.params.api.dispatchEvent(customEvent);
}

}