import { AgColDefsI, AgGridI } from "./../../../../../shared/common-components/ag-grid/ag-grid.model";
import { TextRendererComponent} from "./../../../../../shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { AgGridCustomInputComponent} from "./../../../../../shared//common-components/ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";
import { AgGridCustomDropdownComponent} from "./../../../../../shared//common-components/ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { PlusIconComponent } from "src/app/shared/common-components/ag-grid/plus-icon/plus-icon.component";
import { StorageName } from "src/app/shared/enum/common-enum";
import { StorageService } from "src/app/shared/services/storage.service";
import { EditDeleteIconComponent } from "src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";

  const SrNo: AgColDefsI = {
    headerName: "Sr. No.",
    field: "srNo",
    valueGetter: 'node.rowIndex + 1',
    minWidth: 70,
    maxWidth: 70,
    headerClass: "custom-column-group-cell px-0 border-left-0",
    cellClass: "custom-column-group-cell border-left-0",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  };

  const AcLedger: AgColDefsI = {
    headerName: "A/c Ledger",
    field: "accountLedgerId",
    minWidth: 200,
    sortable: false,
    editable: true,
    isRequired: true,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.ACCOUNTLEDGER_DROPDOWN)
      },
    },
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    cellClass: "custom-column-group-cell px-0 border-left-0",
    headerClass: "custom-column-group-cell px-0 border-left-0"
  
  };

  const Amount: AgColDefsI = {
    headerName: "Amount",
    field: "amount",
    minWidth: 100,
    maxWidth: 100,
    headerClass: "custom-column-group-cell px-2 border-left-0",
    cellClass: "custom-column-group-cell border-left-0",
    editable: true,
    sortable: false,
    isRequired: true,
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    headerComponent: PlusIconComponent, 
    headerComponentParams: {enableMenu:false },
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    },
    cellRendererParams: {
      editIcon: true,
      // tableId: StorageName.FORMNAME_ADDITIONAL_AMOUNT,
      isFirstDeleteIcon: true,
    },
  };

  const ActionColumn: AgColDefsI = {
    headerName: "",
    field: "",
    editable: false,
    sortable:false,
    cellRenderer: EditDeleteIconComponent,
    headerClass: "custom-column-group-cell border-left-0",
    cellClass: "custom-column-group-cell d-flex align-items-center justify-content-center border-left-0",
    minWidth: 50,
    maxWidth: 50,
  };

  export const ColDefsAdditionalAmount: AgColDefsI[] = [
    SrNo,
    AcLedger,
    Amount,
    ActionColumn
  ];

  export const agGridDataModelAdditionalAmount: AgGridI = {
    colDefs: ColDefsAdditionalAmount,
    rowSelection: "multiple",
    rowHeight:28,
    // tableId : StorageName.FORMNAME_ADDITIONAL_AMOUNT
  };