import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { StorageService } from '../../services/storage.service';
import { CommonService } from '../../services/common.service';
import { Subscription, catchError } from 'rxjs';
import { TaxSectionModel, agGridDataModelTax } from "./tax-section.model"
import { ResponseModel } from '../../models/response-model.model';
import { CountryCode, StorageName } from '../../enum/common-enum';
@Component({
  selector: 'app-tax-section',
  templateUrl: './tax-section.component.html',
  styleUrls: ['./tax-section.component.scss']
})
export class TaxSectionComponent extends  CommonService {
  @Output() onItemListChange: EventEmitter<any> = new EventEmitter();
  @Input() formName:string;
  @Input() formModel:any;
  agGridDataModelTax : any
  applicableOnInvoice : any;
  @Input() gridDynamicHeightClass : string = 'h-250px';
  gridApi:any;
  onAddProductsInItemListSubscription: Subscription;
  resetInvoiceSubscription: Subscription;
  getInvoiceSubscription: Subscription;
  @Input() taxSectionModel =new TaxSectionModel();

  constructor(
    private agGridService         : AgGridService,
    public  renderer              : Renderer2,
    public  storageService        : StorageService,
    ){
      super(renderer);
      this.onAddProductsInItemListSubscription = this.storageService.onInvoiceCalculation.subscribe((result) => {
        if(result != undefined)
          if(result?.invoiceTaxDetail != undefined)
            this.onInvoiceCalculationResponse(result);
       });
       this.resetInvoiceSubscription = this.storageService.resetInvoice.subscribe((result) => {
        if(result != undefined)
        this.resetInvoiceResponse(result);
       });
       this.getInvoiceSubscription = this.storageService.getInvoice.subscribe((result) => {
        if(result != undefined)
          if(result?.response?.invoiceTaxDetail != undefined)
            this.getInvoiceResponse(result?.response);
       });
  }
  
  ngOnInit() {
    this.agGridDataModelTax = agGridDataModelTax;
    this.setStorageNames();
  }

  setStorageNames()
  {
    this.agGridDataModelTax.storageName =this.taxSectionModel.storageName;
  }

  getInvoiceResponse(result)
  {
    result?.invoiceTaxDetail?.forEach((x)=> x?.taxAmount != 0 ? x.active = true : x.active  = false );
    this.formModel.invoiceTaxDetail = result?.invoiceTaxDetail;
    this.onInvoiceCalculationResponse(result);
  }

  loadStatewiseTaxList(accountLedgerId)
  {
    this.formModel.invoiceModel.accountLedgerId = accountLedgerId;
    const countryCode      = this.storageService.retrieve('layoutDetails')?.countryCode??'';
    if(countryCode==CountryCode.IN){
      this.formModel.invoiceModel.accountLedgerId =accountLedgerId;
      const allLedgers= this.storageService.retrieve(StorageName.ACCOUNTLEDGER_DROPDOWN) 
      var data = allLedgers?.filter(a=>a.id.toString()==accountLedgerId);
      if(data.length > 0){
          var applicableOnInvoice = this.applicableOnInvoice?.filter(a=>a.extra2==data[0].extra1)
          // send event to child
          // this.voucherWiseData.applicableOnProducts=this.applicableOnProducts?.filter(a=>a.extra2==data[0].extra1)
          this.addTaxColumns(applicableOnInvoice);
      }else{
        var applicableOnInvoice = this.applicableOnInvoice;
         // send event to child
          // this.voucherWiseData.applicableOnProducts=this.applicableOnProducts;
          this.addTaxColumns(applicableOnInvoice);
      }
    }
  }

  
  onGridReady(gridApi) {
    this.gridApi = gridApi;
  }

  onInvoiceCalculationResponse(result)
  {
    // this.gridApi.api?.getModel()?.rowsToDisplay?.map(a => a?.data)
    this.gridApi.api.setRowData(this.formModel.invoiceTaxDetail);
  }

  // loadVoucherTypeWiseData() {
  //   this.agGridService.getDataFromVoucherType(this.formModel.voucherType).pipe(catchError((err) => this.handleError(err))).subscribe((res: ResponseModel) => {
  //       if (res.isSuccess === true) {
  //         this.formModel.invoiceTaxDetail = res.response.applicableOnInvoice.map((x) => {return { taxId: x.id, taxName : x.name};}); 
  //         this.agGridService.addRows(this.gridApiTax,res.response.applicableOnInvoice);
  //     setTimeout(() => {
       
  //     }, 2000);
  //       } 
  //     });
  // }



  addTaxColumns(applicableOnInvoice)
  {
    this.agGridService.addRows(this.gridApi,applicableOnInvoice);
  }

  rowClicked(event){
    this.gridApi.api.deselectAll();
    event.node.setSelected(true);
    this.storageService.onItemListRowClicked.next(event);
  }

  resetInvoiceResponse(formName){
    if(formName != undefined)
    {
      this.gridApi?.api?.setRowData([]);
      this.formModel = {};
      this.formModel.invoiceModel = {};
    }
  }

  handleError(arg0: any): any {
    throw new Error('Method not implemented.');
  }

  //#region

  onCheckBoxClick(params) {
    this.onItemListChange.emit(this.formModel); 
  }

  onHeaderCheckboxClick(params){
    params?.detail?.data?.api.forEachNode((node)=>{
      node?.setDataValue('taxAmount' , !node?.data?.active ? '0' : node?.data?.taxAmount)
    })
  }

  //#endregion

  ngOnDestroy(){
    this.onAddProductsInItemListSubscription?.unsubscribe();
    this.resetInvoiceSubscription?.unsubscribe();
    this.getInvoiceSubscription?.unsubscribe();

  }
}
