<div class="container-fluid d-flex ps-5 pe-0 flex-column pt-3 h-100">
  <form class="theme-form" ngNativeValidate #UserForm="ngForm">
    <div class="row" style="gap: 4rem;">
      <div class="col-5 pe-0">
        <div class="row">
          <div class="col-12 pe-0 pb-3">
            <div class="row">
                <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                  <span class="f-14 font-semibold text-light text-nowrap mb-0">Acc. No.</span>
                </div>
                <div class="col pe-0">
                  <app-input
                    onlyNumbers
                    [inputName]="'bankAccountNumber'"
                    [inputType]="'text'"
                    [inputId]="'bankAccountNumber'"
                    [inputValue]="usermodel.bankAccountNumber"
                    (inputValueEmit)="usermodel.bankAccountNumber = $event"
                    [isRequired]="true"
                    (keydown.enter)="onKeydown($event, 'bankName')"
                    [enableFocus]="true">
                  </app-input>
                </div>
            </div>
          </div>
          <!-- end bankAccountNumber name -->
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Bank Name
                </span>
              </div>
              <div class="col pe-0">
                <app-input
                  onlyAlphabets 
                  [inputName]="'bankName'"
                  [inputType]="'text'"
                  [inputId]="'bankName'"
                  [inputValue]="usermodel.bankName"
                  (inputValueEmit)="usermodel.bankName = $event"
                  (keydown.enter)="onKeydown($event, 'accountHolderNameId')"
                  [isRequired]="true">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end l name -->
          <div class="col-12 pe-0 pb-3 d-none">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">IFSC Code</span>
              </div>
              <div class="col pe-0">
                <app-input
                  [inputName]="'iFSCCode'"
                  [inputType]="'text'"
                  [inputId]="'iFSCCode'"
                  [inputValue]="usermodel.ifscCode"
                  (inputValueEmit)="usermodel.ifscCode = $event"
                  [isRequired]="true">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end iFSCCode -->
        </div>
        <!-- end row -->
      </div>
      <!-- end first col-5 -->
      <div class="col-5 pe-0">
        <div class="row">
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">Acc. Holder Name
                </span>
              </div>
              <div class="col pe-0">
                <app-input
                  onlyAlphabets 
                  [inputName]="'accountHolderName'"
                  [inputType]="'text'"
                  [inputId]="'accountHolderNameId'"
                  [inputValue]="usermodel.accountHolderName"
                  (inputValueEmit)="usermodel.accountHolderName = $event"
                  (keydown.enter)="onKeydown($event, 'branchNameId')"
                  [isRequired]="true">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end accountHolderName name -->
          <div class="col-12 pe-0 pb-3">
            <div class="row">
              <div class="w-135 col-xl-3 col-lg-5 col-md-5">
                <span class="f-14 font-semibold text-light text-nowrap mb-0">
                  Bank Branch
                  <!-- <span class="text-color-danger">*</span> -->
                </span>
              </div>
              <div class="col pe-0">
                <app-input
                  [isOnlyAlphabetsAndNumbers]="true"
                  [inputName]="'branchName'"
                  [inputType]="'text'"
                  [inputId]="'branchNameId'"
                  [inputValue]="usermodel.branchName"
                  (inputValueEmit)="usermodel.branchName = $event"
                  [isRequired]="false">
                </app-input>
              </div>
            </div>
          </div>
          <!-- end branchName name -->
        </div>
      </div>
    </div>
  </form>
  </div>
  
