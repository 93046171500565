import { Component, ElementRef, Input, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { IconHover, ImgUploadAttachKey, StorageName } from '../../enum/common-enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../services/notification.service';
import { CommonService } from '../../services/common.service';
import { CompressImageService } from '../../services/compress-image.service';
import { AgGridI } from '../ag-grid/ag-grid.model';
import { AgGridDataModelMetalAppraisal, AppraisalTabEnum, DiamondStoneCategory } from './metal-section-child-appraisal.model'
import { DiamondCategory, MetalType, ModalPopupSize } from '../../models/common.model';
import { TransactionService } from 'src/app/components/Transaction/transaction.service';
import { catchError } from 'rxjs';
import { ResponseModel } from '../../models/response-model.model';
import { StorageService } from '../../services/storage.service';
import { OpenImagePopupComponent } from '../common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { AgGridService } from '../ag-grid/ag-grid.service';
import { AppMetalAppraisalComponent } from './app-metal-appraisal/app-metal-appraisal.component';
@Component({
  selector: 'app-metal-section-child-appraisal',
  templateUrl: './metal-section-child-appraisal.component.html',
  styleUrls: ['./metal-section-child-appraisal.component.scss']
})
export class MetalSectionChildAppraisalComponent extends CommonService {
  currentHoverEffect = IconHover.Pink;
  tabItems: any[] = [];
  tabId: any;
  gridApi: any;
  @Input() data: any = {};
  newdata: any;
  appraisalTabEnum: typeof AppraisalTabEnum = AppraisalTabEnum;
  agGridDataModel: AgGridI;
  imageResourceList = [];
  selectedImage: any;
  @ViewChild('jewelryWrapper', { static: false }) jewelryWrapper!: ElementRef;
  @ViewChild('summaryWrapper', { static: false }) summaryWrapper!: ElementRef;


  @ViewChildren('jewelryAppraisal, diamondAppraisal, summaryAppraisal') appraisals!: QueryList<AppMetalAppraisalComponent>;
  constructor(public activeModal: NgbActiveModal, 
    private agGridService: AgGridService, 
    public transactionService: TransactionService, 
    public notificationService: NotificationService, 
    public storageService: StorageService, 
    public renderer: Renderer2, 
    public compressImageService: CompressImageService,
    public elementRef: ElementRef) {
    super(renderer);
  }

  ngOnInit(): void {
    var storageUDFs = new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN);
    setTimeout(() => {
      this.setUdfColumns(storageUDFs);
    }, 2000)
    this.agGridDataModel = AgGridDataModelMetalAppraisal;
   
    if(this.data?.metalSectionModel.barcodeNo)
    this.getProductDataByBarcodeNo(this.data?.metalSectionModel.barcodeNo);
  }

  onTabChange(nextId) {
    this.tabId = nextId.nextId;
    this.gridApi?.api?.deselectAll();
    switch(this.tabId)
    {
      case AppraisalTabEnum.Jewellery:this.appraisals.get(0).onTabChange(this.tabId,this.appraisals.get(0).formModel); break;
      case AppraisalTabEnum.DiamondAndGemstone:this.appraisals.get(1).onTabChange(this.tabId,this.appraisals.get(1).formModel); break;
      case AppraisalTabEnum.Summary: break;
    }
  }

  syncSummaryHeight(): void {
    const jewelryHeight = this.jewelryWrapper?.nativeElement?.offsetHeight;
    this.summaryWrapper.nativeElement.style.height = `${jewelryHeight}px`;
  }

  ngAfterViewInit() {
    this.onTabChange({ nextId : AppraisalTabEnum.Jewellery });
    setTimeout(() => {
      this.syncSummaryHeight();
      this.fillPreviousRow();
    }, 1000);
  }

  onGridReady(event) {
    this.gridApi = event;
    this.addupdateddata();
  }

  onAddRowToMetalGrid(newData: any) {
    if(newData.rowIndex == undefined || newData.rowIndex == 0)
    {
      newData.rowIndex = this.gridApi?.api?.getModel()?.rowsToDisplay.length + 1;
      this.agGridService.addUpdateData(this.gridApi, [newData]);
    }
    else
    {
      var rowData = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data).filter(item => item.rowIndex == newData.rowIndex);
      rowData = newData;
      //this.gridApi.api.refreshCells();
    }
    this.reOrderGridData();
    switch(this.tabId)
    {
      case AppraisalTabEnum.Jewellery : this.appraisals.get(0).formModel = {}; break;
      case AppraisalTabEnum.DiamondAndGemstone :  this.appraisals.get(1).formModel = {}; break;
    }
  }

  fillPreviousRow() {
    var result = this.data?.metalSectionModel?.previousRow;
    var data = result?.detail?.data?.data;
    if (result != undefined) {
        this.onItemListRowClickedResponse(data?.detailedJson, false, data?.parentMetalId, data);
    }
  }
  
  addupdateddata() {
    const data =this.data?.metalSectionModel?.previousRow?.detail?.data?.data
    this.data.formModel.comment=  data?.comment; 
    this.data.formModel.groupName= data?.groupName;
    if (data?.detailedJson) {
      const items = JSON?.parse(data?.detailedJson);
      
      if (items?.diamondItem) {
        this.newdata = items?.diamondItem;
        this.gridApi?.api?.setRowData(this.newdata);
        this.onEditIconClick(this.newdata);
      }
      if (items?.invoiceItemImageResources) {
        this.imageResourceList = items.invoiceItemImageResources;
        this.imgOnLoad(this.imageResourceList);
      }
    }
  }

  tabChange(tabid) {
    this.tabId = tabid;
  }

  onEditIconClick(event) {
    let data = event?.detail?.data?.data || event;
    let items = Array.isArray(data) ? data : [data]; // Normalize to an array
    if (Array?.isArray(data)) 
    items = items?.filter(item => item?.diamondCategoryId === DiamondStoneCategory.GemStones)?.concat(items?.filter(item => item?.diamondCategoryId === DiamondStoneCategory.Diamonds))?.concat(items?.filter(item => item?.diamondCategoryId === DiamondStoneCategory.Jewellery));    
    items?.forEach(item => {
      switch (Number(item?.diamondCategoryId)) {
        case DiamondStoneCategory.Jewellery:
          this.appraisals.get(0).formModel = item;
          this.tabId = AppraisalTabEnum.Jewellery;
          break;
        case DiamondStoneCategory.Diamonds:
        case DiamondStoneCategory.GemStones:
          this.appraisals.get(1).formModel = item;
          this.tabId = AppraisalTabEnum.DiamondAndGemstone;
          break;
        case AppraisalTabEnum.Summary:
          this.tabId = AppraisalTabEnum.Summary;
          break;
      }
    });
  }
  

  Cancel() {
    this.activeModal?.close();
  }

  addProductsInItemList() {
    var allMetals : any = {};
    var metalGridRows = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    allMetals.isMelting = this.data?.formModel?.invoiceModel?.isMelting;
    allMetals.parentMetalId = MetalType.Diamond;
    allMetals.diamondItem = metalGridRows;
    allMetals.itemList = this.data?.formModel?.itemList;
    allMetals.invoiceTaxDetail = this.data?.formModel?.invoiceTaxDetail;
    allMetals.VoucherTypeId = this.data?.formModel?.invoiceModel?.voucherTypeId;
    allMetals.invoiceItemImageResources = this.imageResourceList;
    allMetals.comment = this.data?.formModel?.comment;
    allMetals.groupName = this.data?.formModel?.groupName;
    allMetals.isGroup = allMetals?.groupName === "" ? false : true;
    this.transactionService.getInvoiceItemWithDescription(allMetals)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        this.data.formModel.itemTaxDetails = res.response.invoiceTaxDetail;
        this.data.formModel.invoiceModel.groupName = "";
        this.storageService.onAddProductsInItemList.next(res);
        this.activeModal?.close();
      });
  }

  reOrderGridData() {
    var metalGridRows = this.gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
    const reorderedData = [
      ...metalGridRows?.filter(row => row?.diamondCategoryId === DiamondStoneCategory.Jewellery),
      ...metalGridRows?.filter(row => row?.diamondCategoryId !== DiamondStoneCategory.Jewellery)
    ];
    this.gridApi?.api?.setRowData(reorderedData);
    this.gridApi?.api?.refreshCells({ force: true });
  }



  // ************images section start**********
  excludeDeletedResourcesLength() {
    return this.imageResourceList?.filter((x) => !x?.deleted)?.length ? true : false;
  }

  resourceFile(item: any) {
    this.processFile(item);
  }

  processFile(file: any) {
    
    this.compressImageService.compress(file).subscribe(
      compressedFile => {
        this.readThis(compressedFile).then((base64String) => {
          if (base64String) {
            
            const appraisalResourceObj = {
              imageUrl: base64String,
              deleted: false,
              added: true
            };
            this.imageResourceList.push(appraisalResourceObj);
            this.selectedImage = this.imageResourceList[0].imageUrl;

            this.imgOnLoad(this.imageResourceList);
          } else {
            console.error("Failed to get base64 string.");
          }
        })
          .catch((error) => {
            console.error("Error processing file:", error);
          });
      },
      error => {
        console.error('Error compressing file:', error);
      }
    );
  }

  imgOnLoad(files) {
    const list = this.imageResourceList?.filter((item) => item?.deleted != true);
    list?.length ? this.selectedImage = list[list?.length - 1]?.imageUrl : this.selectedImage = null;
  }

  onRemove(file, index) {
    if (file.id) {
      file.deleted = true;
      file.imageUrl = null;
    } else this.imageResourceList?.splice(index, 1);
    this.imgOnLoad(this.imageResourceList);
  }

  previewImg(file) {
    this.selectedImage = file.imageUrl;
  }

  readThis(event: any): Promise<string | null> {
    return new Promise((resolve) => {
      const file: File = event;
      const myReader: FileReader = new FileReader()
      myReader.onloadend = (e) => {
        const base64String = myReader.result as string;
        resolve(base64String);
      };
      myReader.onerror = (error) => {
        console.error("File reading failed:", error);
        resolve(null);
      };
      if (file) {
        myReader.readAsDataURL(file);
      } else {
        console.error("No file selected.");
        resolve(null);
      }
    });
  }

  onSelect(event, from) {
    const files = from === 1 ? event.target.files : event.addedFiles;
    if (files.length > 1) {
      this.resourceMultipleFile(files)
    } else {
      this.resourceFile(files[0])
    }
  }

  openAddImagePopup() {
    this.notificationService.openAddImagePopup(this.imageResourceList, true, ImgUploadAttachKey.ImageUrl, undefined, true).then((res) => {
      if (res === false) return;
      this.imageResourceList = [...res];
      this.imgOnLoad(this.imageResourceList);
    })
  }

  openImgPopUp() {
    if (!this.imageResourceList.length) return;
    var data = {
      jewelryCatalogue: [... this.imageResourceList],
    }
    this.notificationService.openModalPopup(OpenImagePopupComponent, data, '', ModalPopupSize.SM, 'image_popup_fullscreen', true, true).then(
      (result) => {
        if (result) {

        }
      }
    )
  }

  resourceMultipleFile(items: any) {
    for (const file of items)
      this.processFile(file)
  }
  // ************images section end**********

  // *********udf section start*******
  setUdfColumns(storageUDFs?: any) {
    if (this.data?.metalSectionModel != undefined) {
      const metalIdWiseUdfDiamond = storageUDFs?.filter((element) => element?.extra2 == MetalType.Diamond);
      this.agGridService.updateColumnDefinations(this.agGridDataModel, this.gridApi, MetalType.Diamond, metalIdWiseUdfDiamond);
    }
  }


  // *********udf section end*******

  // *******by barcode no get data ******

  getProductDataByBarcodeNo(barcodeNo, formName?) {
  if(barcodeNo !=''){
    setTimeout(() => {
      if (formName == undefined)
        formName = this.data.formModel.voucherType;
      if (barcodeNo) {
        this.transactionService.getPrductByBarcodeNo(barcodeNo, formName,this.data?.metalSectionModel.voucherWiseData.applicableOnProductsByCustomer,this.data.formModel?.invoiceModel?.metalFix,this.data.formModel?.invoiceModel?.internalFix).subscribe({
          next: (res) => {
            
            if (res?.response?.detailedJson != '{"goldItem":[],"silverItem":[],"diamondItem":[],"stoneGemsItem":[],"imitationItem":[]}') {
              this.onItemListRowClickedResponse(res?.response?.detailedJson, true);
            }
          }, error: (err) => { }
        })
      }
     
    }, 1000);
  }}


  onItemListRowClickedResponse(event, IsBarcodeScan = false, parentMetalId = undefined, result?: any) {
    var records: any;
    if (event != undefined)
      records = JSON.parse(event);
    if(IsBarcodeScan)
      {
        if (this.data?.metalSectionModel.voucherWiseData != undefined) {
          this.agGridService.cameraButtonDisabled = true;
          records.diamondItem?.forEach(item => {
          });
        }
      }
    this.data.metalSectionModel.comment = result?.comment;
    this.data.metalSectionModel.groupName = result?.groupName;
    records?.diamondItem.forEach(element => {
      Object.keys(element).forEach(key => {
        if (element[key] === null) {
          delete element[key];
        }
        delete element['diamondItems'];
      });
    });
    this.agGridService.addNewRowWithData(this.gridApi, records?.diamondItem[0]);
    this.reOrderGridData();
    // this.gridApi.api.setRowData(records?.diamondItem);
  }


}

