import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss']
})
export class StatusCellRendererComponent implements ICellRendererAngularComp{

  params:any;
  colorAdded:string;
  defaultStatusName: string = 'NA';
  viewIcon: boolean = false;
  agInit(params: any): void {
    this.params = params;

    // if(this.params?.value ==0){
    //   this.params.value = ''
    // }
    if (!this.params?.value || this.params?.value.trim() === '') {
      this.params.value = this.defaultStatusName; // Assign default status name
    }
    
    if (this.params.data.priorityId) {
      switch (this.params.data.priorityId) {
        case 10:
          this.colorAdded = '#008000';
          break;
        case 20:
          this.colorAdded = '#4F39CD';
          break;
        case 30:
          this.colorAdded = '#F92B35';
          break;
      }
    }
    if (this.params.data.statusId) {
      switch (this.params.data.statusId) {
        case 1:
          this.colorAdded ='#421CB7';
          break;
        case 2:
          this.colorAdded ='#2eb5e2';
          break;
          case 3:
            this.colorAdded ='#F92B35';
            break;
      }
    }

    if (this.params?.value === this.defaultStatusName) {
      this.colorAdded = '#7C89A4'; // Gray color for 'NA'
    }
    if(this.params?.colorAdded) this.colorAdded = this.params?.colorAdded;
  }
    
  // #region start of notification log 
  getTooltipContent(field: string): string {
    switch (field) {
        case 'smsStatusName':
            return this.params?.data?.smsMessageBody || 'No SMS message available';
        case 'emailStatusName':
            return this.params?.data?.emailMessageBody || 'No Email message available';
        case 'whatsappStatusName':
            return this.params?.data?.whatsappMessageBody || 'No Whatsapp message available';
        default:
            return 'No message available';
    }
  }
  onViewClick(params: any) {
    const customEvent = new CustomEvent('viewIconClick', {
      detail: { data: params },
    });
    this.params.api.dispatchEvent(customEvent);
  } 
  // #region end of notification log 

  refresh(params: any): boolean {
    return true;
  }
}
