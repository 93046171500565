import { AgColDefsI, AgGridI } from "src/app/shared/common-components/ag-grid/ag-grid.model";
import { TextRendererComponent } from "src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { StorageName } from "src/app/shared/enum/common-enum";
import { AmountColWidth, WeightColWidth } from "../../../shared/enum/common-enum";
import { NumericCellRendererComponent } from "../../../shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
import { RadioRendererComponent } from "../../../shared/common-components/ag-grid/radio-renderer/radio-renderer.component";


const InvoiceNo: AgColDefsI = {
  headerName: "Invoice No.",
  resizable: false,
  sortable: false,
  headerClass:"px-0 custom-column-group-cell",
  field:'invoiceNo',
  minWidth:150,
  flex: 1,
  cellClass:'mx-0 px-2 custom-column-group-cell',
  editable:false,
  cellRenderer:TextRendererComponent,
};
const ItemColumn: AgColDefsI = {
  headerName: "Item",
  resizable: false,
  sortable: false,
  headerClass:"px-0 custom-column-group-cell",
  field:'productName',
  minWidth:150,
  flex: 1,
  cellClass:'mx-0 px-2 custom-column-group-cell',
  editable:false,
  cellRenderer:TextRendererComponent,
};
const GrossWtColumn: AgColDefsI = {
    headerName: "Gross Wt.",
    field: "grossWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
};

const TotalAmtColumn: AgColDefsI = {
  headerName: "Total Amt.",
  field: "totalAmount",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0 ag_header_end",
  cellClass: "custom-column-group-cell px-0 text-end ",
  editable: false,
  sortable: false,
  resizable:true,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams:{isAmt:true},
}

const PaidAmtColumn: AgColDefsI = {
  headerName: "Paid Amt.",
  field: "paidAmount",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  headerClass: "custom-column-group-cell px-0 ag_header_end",
  cellClass: "custom-column-group-cell px-0 text-end ",
  editable: false,
  sortable: false,
  resizable:true,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams:{isAmt:true},
}
const RadioField: AgColDefsI = {
  headerName: "",
  resizable: false,
  sortable: false,
  lockPosition: "left",
  field:'active',
  minWidth:40,
  maxWidth:40,
  cellRenderer: RadioRendererComponent,
  headerClass: "custom-column-group-cell",
  cellClass: "custom-column-group-cell p-0",
  cellRendererParams: { showLabel: false }, 
  editable:false,
};

export const ColDefsAgainstOfList: AgColDefsI[] = [
  RadioField, 
  InvoiceNo,
  ItemColumn,
  GrossWtColumn,
  TotalAmtColumn,
  PaidAmtColumn
];

export const AgGridAgainstOfList: AgGridI = {
  colDefs: ColDefsAgainstOfList,
  rowSelection: "single",
  storageName: StorageName.TRANSACTION_INVOICES_AGAINSTOFPOPUP_GRID,
};

export enum AgainstOf {
  SaleOrder = 1,
  DeliveryNote = 2,
  SaleQuotation = 3,
  SalesInvoice = 4,
  PurchaseInvoice = 5,
  RepairOrder = 6,
  PurchaseQuotation = 7,
  PurchaseOrder = 8,
}

// sale order dropdown popup end