<div>
  <div class="modal-header d-flex align-items-center justify-content-center position-relative">
    <div><span class="font-semibold text-primary"> {{ data?.paymetType === 'OldJewellery' ? 'Scrap' : data?.paymetType === 'MetalExchange' ? 'M. Exch.' : data?.paymetType }} Payment</span></div>
    <app-svg-icon [name]="'cross_button_border'" class="cross-button-border"></app-svg-icon>
    <app-svg-icon [name]="'cross'" [ngClass]="currentHoverEffect" class="cross-button"(click)="activeModal.dismiss()"></app-svg-icon>
  </div>
  <form class="theme-form" ngNativeValidate #paymentTypeForm="ngForm">
    <div class="modal-body mx-2 mb-2">
      <div class="row">
        <div *ngIf="data?.paymetType !='OldJewellery' && data?.paymetType !='MetalExchange' && data?.depositeIntoColumn== true" class="col-12 d-flex align-items-center">
          <label class="w-90px f-14 font-semibold text-gray-color mb-0" for="depositeIntoId">Deposit Into</label>
          <div class="col">
            <app-select 
              [options]="depositIntotransferFormDropdown"
              [selectedValue]="apiRequestModel?.depositeIntoId"
              (valueChanged)="apiRequestModel.depositeIntoId = $event;"
              [id]="'depositeIntoId'"
              [name]="'depositeIntoId'" 
              [isRequired]="false"
              >
            </app-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngIf="data?.paymetType !='OldJewellery' && data?.paymetType !='MetalExchange' && data?.transferFromColumn== true" class="col-12 d-flex align-items-center">
          <label class="w-90px f-14 font-semibold text-gray-color mb-0" for="transferFromId">Transfer From</label>
          <div class="col">
            <app-select 
              [options]="depositIntotransferFormDropdown"
              [selectedValue]="apiRequestModel?.transferFromId"
              (valueChanged)="apiRequestModel.transferFromId = $event;"
              [id]="'transferFromId'"
              [name]="'transferFromId'" 
              [isRequired]="false"
              >
            </app-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div *ngIf="data?.paymetType === 'OldJewellery' || data?.paymetType === 'MetalExchange'" class="col-6 pe-2 d-flex align-items-center mb-2">
          <label class="w-90px f-14 font-semibold text-gray-color mb-0" for="metalId">Metal</label>
          <div class="col">
            <app-select 
              [options]="data?.getAllDropdownModel?.metalDropdown"
              [selectedValue]="apiRequestModel?.metalId"
              (valueChanged)="apiRequestModel.metalId = $event;OnMetalChanges($event)" 
              [id]="'metalId'"
              [name]="'metalId'" 
              [isRequired]="false"
              >
            </app-select>
          </div>
        </div>
        <div *ngIf="(data?.paymetType === 'OldJewellery' || data?.paymetType === 'MetalExchange') && selectedName === 'Diamond & Stones'" class="col-6 ps-0 d-flex align-items-center mb-2">
          <label class="w-90px f-14 font-semibold text-gray-color mb-0" for="diamondCategoryId">Category</label>
          <div class="col">
            <app-select 
              [options]="filteredDiamondCategories"
              [selectedValue]="apiRequestModel?.diamondCategoryId"
              (valueChanged)="apiRequestModel.diamondCategoryId = $event;" 
              [id]="'diamondCategoryId'"
              [name]="'diamondCategoryId'" 
              [isRequired]="false"
              [isDisabled]="isDisabled()"
              >
            </app-select>
          </div>
        </div>
        
      </div>
      <div class="row">
        <div *ngIf="data?.paymetType =='OldJewellery' ||data?.paymetType =='MetalExchange'" class="col-12 pe-2 d-flex align-items-center mb-2">
          <label class="w-90px f-14 font-semibold text-gray-color mb-0" for="productDetailId">Product</label>
          <div class="col">
            <app-select 
              [options]="FilterproductDropdown"
              [selectedValue]="apiRequestModel?.productDetailId"
              (valueChanged)="apiRequestModel.productDetailId = $event;" 
              [id]="'productDetailId'"
              [name]="'productDetailId'" 
              [isRequired]="false"
              >
            </app-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex" [ngClass]="{'mt-2':data?.paymetType =='Card' || data?.paymetType =='Cheque' || data?.paymetType =='Bank' || data?.paymetType =='UPI'}">
          <div *ngIf="data?.paymetType =='Card' || data?.paymetType =='Cheque' || data?.paymetType =='Bank' || data?.paymetType =='UPI'"  class="col-6 d-flex align-items-center pe-2 d-flex">
            <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
              Trans No.
            </div>
            <div class="col">
              <app-input 
                [inputName]="'transactionNo'" 
                [inputType]="'text'" 
                [inputId]="'transactionNo'"
                [inputValue]="apiRequestModel?.transactionNo" 
                (inputValueEmit)="apiRequestModel.transactionNo = $event"
                [isRequired]="false"
                (keydown.enter)="onKeydown($event, 'amount')"
                >
              </app-input>
            </div>
          </div>
          <div *ngIf="data?.paymetType =='Cheque'" class="col-6 d-flex align-items-center justify-content-end">
            <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
              Cheque Dt.
            </div>
            <div class="col-7">
              <app-input-datepicker-renderer #chequeDateComp
                [inputDatepickerName]="'chequeDate'"
                [inputDate]="apiRequestModel?.chequeDate" 
                [id]="'chequeDate'"
                (dateChanged)="selectedChequeDate($event)"
                >
              </app-input-datepicker-renderer>
            </div>
          </div>
          <div *ngIf="data?.paymetType =='Card' || data?.paymetType =='Bank' || data?.paymetType =='UPI'" class="col-6 d-flex d-flex align-items-center">
            <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
              Amount
            </div>
            <div class="col">
              <app-input 
                [decimalRestrictedDigit]="2"
                [applyDecimalOrPercent]="true"
                [inputName]="'amount'" 
                [inputType]="'text'" 
                [inputId]="'amount'"
                [inputValue]="apiRequestModel?.amount" 
                (inputValueEmit)="apiRequestModel.amount = $event"
                [isRequired]="false"
                >
              </app-input>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="data?.paymetType =='OldJewellery' ||data?.paymetType =='MetalExchange'" class="col-12 d-flex align-items-center mb-2">
        <div *ngIf="data?.paymetType =='OldJewellery' ||data?.paymetType =='MetalExchange'" class="col-6 pe-2 d-flex d-flex align-items-center">
          <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
            Weight
          </div>
          <div class="col">
            <app-input 
              [inputName]="'grossWt'" 
              [inputType]="'text'" 
              [inputId]="'grossWt'"
              [decimalRestrictedDigit]="3"
              [applyDecimalOrPercent]="true" 
              [inputValue]="apiRequestModel?.grossWt" 
              (inputValueEmit)="apiRequestModel.grossWt = $event;calculatepurityWt();onCellValueChangedDetect('grossWt')"
              (keydown.enter)="onKeydown($event, 'purityPer')"
              >
            </app-input>
          </div>
        </div>
        <div class="col-6 d-flex align-items-center">
          <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
            Purity / Carat
          </div>
          <div class="col">
            <app-input 
              [decimalRestrictedDigit]="3"
              [applyDecimalOrPercent]="true" 
              [inputName]="'purityPer'" 
              [inputType]="'text'" 
              [inputId]="'purityPer'"
              [inputValue]="apiRequestModel?.purityPer" 
              (inputValueEmit)="apiRequestModel.purityPer = $event;calculatepurityWt();onCellValueChangedDetect('purityPer')"
              (keydown.enter)="onKeydown($event, 'purityWt')"
              >
            </app-input>
          </div>
        </div>
      </div>
      <div *ngIf="data?.paymetType =='OldJewellery' ||data?.paymetType =='MetalExchange'" class="col-12 d-flex d-flex align-items-center">
        <div class="col-6 d-flex d-flex pe-2 align-items-center">
          <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
            Purity Wt.
          </div>
          <div class="col">
            <app-input 
              [inputName]="'purityWt'" 
              [inputType]="'text'" 
              [inputId]="'purityWt'"
              [readOnly]="true"
              [decimalRestrictedDigit]="3"
              [applyDecimalOrPercent]="true" 
              [inputValue]="apiRequestModel?.purityWt" 
              (keydown.enter)="onKeydown($event, 'quantity')"
              (inputValueEmit)="apiRequestModel.purityWt = $event;calculateAmount();onCellValueChangedDetect('purityWt')"
              >
            </app-input>
          </div>
        </div>
        <div class="col-6 d-flex d-flex align-items-center">
          <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
            Quantity
          </div>
          <div class="col">
            <app-input 
              onlyNumbers
              [inputName]="'quantity'" 
              [inputType]="'text'" 
              [inputId]="'quantity'"
              [inputValue]="apiRequestModel?.quantity" 
              (inputValueEmit)="apiRequestModel.quantity = $event"
              [isRequired]="false"
              (keydown.enter)="onKeydown($event, 'rate')"
              >
            </app-input>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex d-flex align-items-center mt-2">
        <div *ngIf="data?.paymetType =='OldJewellery' ||data?.paymetType =='MetalExchange'" class="col-6 pe-2 d-flex">
          <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
            Rate
          </div>
          <div class="col">
            <app-input 
              [applyDecimalOrPercent]="true"
              [decimalRestrictedDigit]="2"
              [inputName]="'rate'" 
              [inputType]="'text'" 
              [inputId]="'rate'" 
              [inputValue]="apiRequestModel?.rate"
              (inputValueEmit)="apiRequestModel.rate = $event;calculateAmount();onCellValueChangedDetect('rate')" 
              [isRequired]="false"
              (keydown.enter)="onKeydown($event, 'amount')"
              >
            </app-input>
          </div>
        </div>
        <div *ngIf="data?.paymetType !='Card' && data?.paymetType !='Bank' && data?.paymetType !='UPI'" class="col-6 d-flex d-flex align-items-center"  [ngClass]="{'pe-2': data?.paymetType !== 'OldJewellery' && data?.paymetType !== 'MetalExchange'}">
          <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
            Amount
          </div>
          <div class="col">
            <app-input 
              [decimalRestrictedDigit]="2"
              [applyDecimalOrPercent]="true"
              [inputName]="'amount'" 
              [inputType]="'text'" 
              [inputId]="'amount'"
              [readOnly]="data?.paymetType =='MetalExchange' ?true:false"
              [inputValue]="apiRequestModel?.amount" 
              (inputValueEmit)="apiRequestModel.amount = $event;onCellValueChangedDetect('amount')"
              [isRequired]="false"
              (keydown.enter)="onKeydown($event, 'itemCode')"
              >
            </app-input>
          </div>
        </div>
      </div>
      <div *ngIf="data?.paymetType =='OldJewellery' ||data?.paymetType =='MetalExchange'" class="col-6 pe-2 mt-2 d-flex align-items-center">
        <div class="w-90px f-14 font-semibold text-gray-color text-nowrap mb-0">
          Item Code
        </div>
        <div class="col">
          <app-input 
            [inputName]="'itemCode'" 
            [inputType]="'text'" 
            [inputId]="'itemCode'"
            [inputValue]="apiRequestModel?.itemCode" 
            (inputValueEmit)="apiRequestModel.itemCode = $event"
            [isRequired]="false"
            >
          </app-input>
        </div>
      </div>
    
    </div>
    <div class="modal-footer pt-3 pb-2 mx-2">
      <app-button class="my-0 me-3" (buttonOutputEvent)="Clear()" [buttonLabel]="'Clear'" [buttonClass]="'light_button'"
        [buttonType]="'button'">
      </app-button>
      <app-button class="m-0" [buttonLabel]="'Save'" (buttonOutputEvent)="Save()" [buttonId]="'saveButton'"
        [buttonClass]="'blue_button'" [buttonType]="'button'">
      </app-button>
    </div>
  </form>
</div>