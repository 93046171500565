import { AgColDefsI, AgGridI, Filters } from "../../../shared/common-components/ag-grid/ag-grid.model";
import { AgGridButtonCellrendererComponent } from "../../../shared/common-components/ag-grid/ag-grid-button-cellrenderer/ag-grid-button-cellrenderer.component";
import { getCellStyleForColor, TextRendererComponent } from "../../../shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { AmountColWidth, BarcodeColWidth, ColumnCellType, MetalColWidth, NameColWidth, ProductColWidth, QuantityColWidth, StorageName, UDFColWidth, VoucherColWidth, WeightColWidth } from "../../../shared/enum/common-enum";
import { CustomHeaderParentComponent } from "../../../shared/common-components/ag-grid/custom-header-parent/custom-header-parent.component";
import { CheckboxRendererComponent } from "../../../shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component";
import { JwellaryImageComponent } from "../../../shared/common-components/ag-grid/jwellary-image/jwellary-image.component";
import { RfidIconComponent } from "../../../shared/common-components/ag-grid/rfid-icon/rfid-icon.component";
import { API_URL_GET_ALL_AVAILABLESTOCKRFIDBARCODE, API_URL_GET_ALL_SCANNEDRFIDBARCODE, API_URL_GET_ALL_STOCK, API_URL_GET_ALL_STOCKDETAILS, API_URL_GET_ALL_STOCKHISTORY, API_URL_GET_ALL_STOCKHISTORY_GOLDSILVER, API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT, API_URL_GETALLBARCODESTOCK, API_URL_GET_ALL_STOCKDETAILS_REPORT } from "../../../shared/url-helper/url-helper";
import { NumericCellRendererComponent } from "src/app/shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
import { DateCellRendererComponent } from "src/app/shared/common-components/ag-grid/date-cell-renderer/date-cell-renderer.component";
import { AgGridCustomInputComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";
import { StorageService } from "src/app/shared/services/storage.service";
import { AgGridInnerHTMLDisplayComponent } from "src/app/shared/common-components/ag-grid/ag-grid-inner-htmldisplay/ag-grid-inner-htmldisplay.component";
import { AgGridCustomDropdownComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { TrackOrderCellrenderComponent } from "src/app/shared/common-components/ag-grid/track-order-cellrender/track-order-cellrender.component";
import { EditDeleteIconComponent } from "src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";

// *****************Stock Report start*********************

const ActionColumn: AgColDefsI = {
    headerName: "",
    field: "action",
    maxWidth: 120,
    minWidth:120,
    sortable: true,
    editable: false,
    resizable: false,
    headerComponentParams: { enableSorting: false},
    cellRenderer: AgGridButtonCellrendererComponent, 
    cellRendererParams: { viewHistory: true ,},
    cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
    headerClass: "custom-column-group-cell px-0",
    footerClass: "custom-footer-class",
    suppressPaste: true,
  };
  
  const ProductColumn: AgColDefsI = {
    headerName: "Product Name",
    field: "productName",
    minWidth: ProductColWidth.MinWidth,
    maxWidth:ProductColWidth.MaxWidth,
    resizable: true,
    sortable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0"
  };
  const Product: AgColDefsI = {
    headerName: "Product Name ",
    field: "productName",
    minWidth: ProductColWidth.MinWidth,
    maxWidth:ProductColWidth.MaxWidth,
    resizable: true,
    sortable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0 ",
    
  };
  
  const MetalColumn: AgColDefsI = {
    headerName: "Metal",
    field: "metalName",
    minWidth: MetalColWidth.MinWidth,
    maxWidth: MetalColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: TextRendererComponent,
  };
  const QuantityColumn: AgColDefsI = {
    headerName: "Qty",
    field: "quantity",
    minWidth: QuantityColWidth.MinWidth,
    maxWidth: QuantityColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true },
  };

  const HallmarkRate: AgColDefsI = {
    headerName: "Hallmark Rate",
    field: "hallmarkRate",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell cursorPointer",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
  };

  export const NetAmtWithTaxColumn: AgColDefsI = {
    headerName: "Net Amt With Tax",
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
    headerClass:
      "px-0 custom-column-group-cell border-0  ag_header_end ",
    field: "netAmountWithTax",
    sortable: true,
    editable : false,
    cellClass: " custom-column-group-cell text-end",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    headerComponentParams: { enableSorting: false},
  };
  const TaxAmtColumn: AgColDefsI = {
    headerName: "Tax Amt",
    field: "taxAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 ag_header_end ",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  }

  const NetAmt: AgColDefsI = {
    headerName: "Net Amt", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell text-end px-0",
    cellStyle: {'text-align': 'right'},
    field: 'netAmount',
    flex:2,
    editable:false,
    lockPosition:true,
    suppressMovable: true,
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
};
const DiscountPer: AgColDefsI = {
  headerName: "Discount Per",
  field: "discountPer",
  minWidth: AmountColWidth.MinWidth,
  maxWidth:AmountColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 ",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}
const DiscountAmount: AgColDefsI = {
  headerName: "Discount Amount",
  field: "discountAmount",
  minWidth: AmountColWidth.MinWidth,
  maxWidth:AmountColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 ",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  isRequired:true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};
export const MetalValueColumn: AgColDefsI = {
  headerName: "Metal Value",
  field: "metalValue",
  minWidth: MetalColWidth.MinWidth,
  maxWidth: MetalColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 ",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
};

const PurchaseAmountColumn: AgColDefsI = {
  headerName: "Purchase Amount",
  field: "purchaseAmount",
  minWidth: AmountColWidth.MinWidth,
  maxWidth:AmountColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell tex-end px-0",
  headerClass: "custom-column-group-cell px-0 ag_header_end",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
    inputMaxlength:8
  },
}

  const HallmarkAmount: AgColDefsI = {
    headerName: "Hallmark Amount",
    field: "hallmarkAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
  };
  
  const WeightColumn: AgColDefsI = {
    headerName: "Gross Weight",
    field: "weight",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell ag_header_end  px-0",
    cellClass: "custom-column-group-cell cursorPointer",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true ,isCursor: true },

  };
  const DiamondWeightColumn: AgColDefsI = {
    headerName: "Diamond Wt",
    field: "weight",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
   
  }
  const Locationcolumn1: AgColDefsI = {
    
    headerName: "Location",
    field: 'locationName',
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell px-2",
    minWidth: 170,
    sortable: true,
    editable: false,
    cellRenderer:TextRendererComponent,
}
  const PureWeight: AgColDefsI = {
    headerName: "Pure/D.Wt.",
    field: "netWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
  };
  const StockValue: AgColDefsI = {
    headerName: "Stock Value",
    field: "stockValue",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isAmt:true}
  };
  
  // export const GroupNameColumn: AgColDefsI = {
  //   headerName: "Group Name",
  //   headerClass:
  //     "px-0 custom-column-group-cell  ",
  //   field: "groupName",
  //   sortable: true,
  //   editable: false,
  //   resizable: true,
  //   cellRenderer: TextRendererComponent,
  //   cellClass: "custom-column-group-cell f-12 ",
  //   minWidth: NameColWidth.MinWidth,
  //   maxWidth: NameColWidth.MaxWidth,
  // };

  // export const GroupNamePopupColumn: AgColDefsI = {
  //   headerName: "Group Name",
  //   headerClass:
  //     "px-0 custom-column-group-cell justify_content_end ",
  //   field: "groupName",
  //   sortable: false,
  //   editable: false,
  //   cellRenderer: TextRendererComponent,
  //   cellClass: "custom-column-group-cell",
  //   minWidth: NameColWidth.MinWidth,
  //   maxWidth: NameColWidth.MaxWidth,
  //   resizable: true,
  // };

  const categoryNameColumn: AgColDefsI =
  { headerName: "Category", 
    headerClass: "px-0 custom-column-group-cell border-0 ",        
    field: 'categoryName',
    sortable:true,
    cellClass:'px-2 custom-column-group-cell',
    editable:false,
    cellRenderer:TextRendererComponent,
    resizable: true,
    minWidth: 70, 
    flex: 2,
  };
  const BranchName: AgColDefsI = { 
    headerName: "Branch Name", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-0 custom-column-group-cell border-0 ",
    field: 'branchName',
    cellClass:'px-0 custom-column-group-cell ',
    suppressMovable: false,
    resizable: true, 
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:false},
    editable:false, 
    minWidth: 180, flex:2,
  }

  const StockReportColumnDefForCurrentStockTable :AgColDefsI[] = [
  ActionColumn,
  ProductColumn,
  Locationcolumn1,
  MetalColumn,
  QuantityColumn,
  WeightColumn,
  PureWeight,
  StockValue,
  categoryNameColumn,
  BranchName
  ]
  
  export const AgGridCurrentStockTable: AgGridI = {
    colDefs: StockReportColumnDefForCurrentStockTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    columnSettings:true,
    singleClickEdit:true,
    storageName : StorageName.REPORT_STOCKREPORT_CURRENTSTOCK_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_CURRENTSTOCK_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_CURRENTSTOCK_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_CURRENTSTOCK_GRID,
    rows:[],
    rowHeight:28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_STOCK,
    isServerSidepagination : true,
    showPagination: true
  };
  
  const filteredColumns = StockReportColumnDefForCurrentStockTable.filter(column => {
    return column.field !== 'action' && column.field !== 'groupName';
  });

  const columnsWithExtra = [...filteredColumns, 
                              // GroupNamePopupColumn
                            ];
  export const AgGridCurrentStockpopup: AgGridI = {
    colDefs: columnsWithExtra,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    columnSettings:true,
    singleClickEdit:true,
    storageName : StorageName.REPORT_STOCKREPORT_CURRENTSTOCK_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_CURRENTSTOCK_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_CURRENTSTOCK_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_CURRENTSTOCK_GRID,
    rows:[],
    headerHeight : 70,
    rowHeight:28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_STOCK,
    isServerSidepagination : true,
  };
  
  const SaleAmount: AgColDefsI = {
    headerName: "Sale Amount",
    field: "saleAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    headerComponentParams: {isRequired: false, enableSorting: false},
    cellClass: "custom-column-group-cell text-end px-0",
    headerClass: "custom-column-group-cell px-0 ag_header_end",
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
      inputMaxlength:8
    },
  }
  const ImageColumn: AgColDefsI = {
    headerName: "",
    headerClass:"px-0 custom-column-group-cell border-0 ",
    field: "imageUrls",
    cellRenderer: JwellaryImageComponent,
    cellClass: "px-0 custom-column-group-cell",
    minWidth: 36,
    maxWidth: 36,
    editable:false,
    lockPosition:'left',
    lockPinned:true,
    pinned : 'left',
    suppressMovable: false,
    cellRendererParams:{fromImageUrl:true,isAddImage:true},
    headerComponentParams: { enableSorting: false},
    resizable: false,
  };
  const stockImageColumn: AgColDefsI = {
    headerName: "",
    headerClass:"px-0 custom-column-group-cell border-0 ",
    field: "imageUrls",
    cellRenderer: JwellaryImageComponent,
    cellClass: "px-0 custom-column-group-cell",
    minWidth: 36,
    maxWidth: 36,
    editable:false,
    lockPosition:'left',
    lockPinned:true,
    pinned : 'left',
    suppressMovable: false,
    cellRendererParams:{fromDetailJson: true},
    headerComponentParams: { enableSorting: false},
    resizable: false,
  };
  
  const ActionHistoryColumn: AgColDefsI = {
    headerName: "",
    field: "action",
    maxWidth: 70,
    minWidth: 70,
    sortable: true,
    editable: false,
    resizable: false,
    headerComponentParams: { enableSorting: false},
    cellRenderer: AgGridButtonCellrendererComponent, 
    cellRendererParams: { view: true },
    cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
    headerClass: "custom-column-group-cell px-0",
  };
  const DateColumn: AgColDefsI = {
    headerName: "Date",
    field: "date",
    minWidth: 70,
    headerClass: "custom-column-group-cell px-0  ag_header_end",
    cellClass: "custom-column-group-cell text-end",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer:DateCellRendererComponent,
    // headerComponentParams:{
    //   filterValueColumnName: "date",
    //   filterIdColumnName: "date",    
    //   isInputFilter: true,
    // },
    
  };
  const BarcodeNo: AgColDefsI = {
    headerName: "Barcode No",
    field: "barcodeNo",
    minWidth: BarcodeColWidth.MinWidth,
    maxWidth: BarcodeColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  };
  const UniqueBarcode: AgColDefsI = {
    headerName: "Unique Barcode",
    field: "uniqueBarcode",
    minWidth: BarcodeColWidth.MinWidth,
    maxWidth: BarcodeColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  };

  const categoryName: AgColDefsI =
  { headerName: "Category", 
    //headerClass: "px-0 custom-column-group-cell border-0 text-gray-color f-14 font-semibold customHeaderClass",        
    field: 'categoryName',
    sortable:true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable:false,
    cellRenderer:TextRendererComponent,
    resizable: true,
    minWidth: 70, flex: 2,
  };
  const Rate: AgColDefsI = {
    headerName: "Rate",
    field: "rate",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  }
  const ProductNameColumn: AgColDefsI = {
    headerName: "Product Name",
    field: "productName",
    minWidth: ProductColWidth.MinWidth,
    maxWidth:ProductColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  };
  const Locationcolumn: AgColDefsI = {
    
      headerName: "Location",
      field: 'locationName',
      headerClass: "custom-column-group-cell px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: 170,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
  };
  const Metal: AgColDefsI = {
    headerName: "Metal",
    field: "metalName",
    minWidth: MetalColWidth.MinWidth,
    maxWidth: MetalColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  };
  const VoucherTypeColumn: AgColDefsI = {
    headerName: "Voucher Type",
    field: "voucherTypeName",
    minWidth: VoucherColWidth.MinWidth,
    maxWidth: VoucherColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  };
  const VoucherNoColumn: AgColDefsI = {
    headerName: "Voucher No.",
    field: "voucherNo",
    minWidth: VoucherColWidth.MinWidth,
    maxWidth: VoucherColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0 text-primary text-underline",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams: { isReadOnly: false ,isCursor: true },
  }
  const InvoiceNoColumn: AgColDefsI = {
    headerName: "Invoice No.",
    field: "invoiceNo",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell px-0 text-primary text-underline",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams: { isReadOnly: false ,isCursor: true },
  };
  const BranchColumn: AgColDefsI = {
    headerName: "Branch",
    field: "branchName",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  };
  const CustomerNameColumn: AgColDefsI = {
    headerName: "Customer Name",
    field: "customerName",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "customerId",
      filterIdColumnName: "customerName",
      filterParams: StorageName.ACCOUNTLEDGER_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
  };

  const InwardQtyColumn: AgColDefsI = {
    headerName: "Inward Wt.",
    field: "inwardWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
    headerComponentParams:{
      filterValueColumnName: "inwardWt",
      filterIdColumnName: "inwardWt",
      isInputFilter: true,
    }
  };
  const OutwardQtyColumn: AgColDefsI = {
    headerName: "Outward Wt.",
    field: "outwardWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
    headerComponentParams:{
      filterValueColumnName: "outwardWt",
      filterIdColumnName: "outwardWt",
      isInputFilter: true,
    }
  };

  const GrossWtColumn: AgColDefsI = {
    headerName: "Gross Wt",
    field: "grossWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
    headerComponentParams:{
      filterValueColumnName: "grossWt",
      filterIdColumnName: "grossWt",
      isInputFilter: true,
      isAllowNumber:true
    }
  };

  const GrossWeightColumn: AgColDefsI = {
    headerName: "Gross Wt",
    field: "grossWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: getCellStyleForColor(ColumnCellType.OpeningWtColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
  };

  const PureWtColumn: AgColDefsI = {
    headerName: "Pure Wt.",
    field: "pureWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: getCellStyleForColor(ColumnCellType.OpeningWtColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
  };
  const QTYColumn: AgColDefsI = {
    headerName: "Qty.",
    field: "qty",
    minWidth: QuantityColWidth.MinWidth,
    maxWidth: QuantityColWidth.MaxWidth,
  resizable: true,
    cellStyle: getCellStyleForColor(ColumnCellType.OpeningAmountColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
  };

  const RFIDColumn: AgColDefsI = {
    headerName: "RFID",
    field: "rfid",
    minWidth: 70,
    resizable: true,
    sortable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 ",
 
  };
  const AgainstVoucherColumn: AgColDefsI = {
    headerName: "Against Voucher No",
    field: "againstVoucherNo",
    minWidth: VoucherColWidth.MinWidth,
    maxWidth: VoucherColWidth.MaxWidth,
    sortable: true,
    resizable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 ",
 
  };
  const TypeOfVoucherColumn: AgColDefsI = {
    headerName: "Type Of Voucher",
    field: "typeOfVoucher",
    minWidth: VoucherColWidth.MinWidth,
    maxWidth: VoucherColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
    // headerComponentParams:{
    //   filterValueColumnName: "typeOfVoucher",
    //   filterIdColumnName: "typeOfVoucher",
    //   isInputFilter: true,
    // }
  };
  export const RequestedWtColumn: AgColDefsI = {
    headerName: "Requested Wt.",
    headerComponentParams: { isRequired: false , enableSorting: false},
    field: "requestedWt",
    sortable: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    editable: false,
    resizable: true,
    // isRequired: true,
    cellClass: "custom-column-group-cell px-0 text-center ",
    headerClass: "custom-column-group-cell px-0 ",
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:3,
      isOnlyNumbers:true,
      inputMaxlength:8
    }
  };
  
  export const RequestedPurityColumn: AgColDefsI = {
    headerName: "Requested Purity",
    headerComponentParams: { isRequired: false , enableSorting: false},
    field: "requestedPurityPer",
    sortable: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    editable: false,
    resizable: true,
    // isRequired: true,
    cellClass: "custom-column-group-cell px-0 text-center",
    headerClass: "custom-column-group-cell px-0 ",
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:3,
      isOnlyNumbers:true,
      inputMaxlength:8
    }
  };
  export const RequestedPurityWtColumn: AgColDefsI = {
    headerName: "Requested Purity Wt",
    headerComponentParams: { isRequired: false , enableSorting: false},
    field: "requestedPurityWt",
    sortable: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    editable: false,
    // isRequired: true,
    cellClass: "custom-column-group-cell px-0 text-center",
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:3,
      isOnlyNumbers:true,
      inputMaxlength:8
    }
  };
  export const StoneAmountColumn: AgColDefsI = {
    headerName: "Stone Amount",
    field: "stoneAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell ag_header_end px-1 ",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
  };
  export const DiamondAmountColumn: AgColDefsI = {
    headerName: "Diamond Amount",
    field: "diamondAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    resizable: true,
    cellRenderer: TextRendererComponent,
  };
  export const PurityColumn: AgColDefsI = {
    headerName: "Purity",
    headerComponentParams: { isRequired: false , enableSorting: false},
    field: "purityPer",
    sortable: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    editable: false,
    resizable: true,
    // isRequired: true,
    cellClass: "custom-column-group-cell px-0 text-center",
    headerClass: "custom-column-group-cell px-0 ",
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:3,
      isOnlyNumbers:true,
      inputMaxlength:8
    }
  };
  const lessWtColumn: AgColDefsI = {
    headerName: "Less Wt.",
    field: "lessWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: TextRendererComponent,
  };
  const PurityWtColumn: AgColDefsI = {
    headerName: "Purity Wt",
    field: "puritywt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell text-end ', 
    headerClass: "custom-column-group-cell px-0 ag_header_end ",
 
  };
  const WastagePer: AgColDefsI = {
    headerName: "Wastage Per.",
    headerComponentParams: { isRequired: false  ,enableSorting: false },
    field: "wastagePer",
    sortable: true,
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    editable: false,
    resizable: true,
    // isRequired: true,
    cellClass: "custom-column-group-cell px-0 text-center",
    headerClass: "custom-column-group-cell px-0",
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
      inputMaxlength:5
    }
  }
  const WastageWt: AgColDefsI = {
    headerName: "Wastage Wt.",
    headerComponentParams: { isRequired: false  ,enableSorting: false},
    field: "wastageWt",
    sortable: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    editable: false,
    // isRequired: true,
    cellClass: "custom-column-group-cell px-0 text-center",
    headerClass: "custom-column-group-cell px-0",
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:3,
      isOnlyNumbers:true,
      inputMaxlength:8
    }
  }
  export const NetWtColumn: AgColDefsI = {
    headerName: "Net Wt",
      field: "netWt",
      minWidth: WeightColWidth.MinWidth,
      maxWidth:WeightColWidth.MaxWidth,
      cellStyle: {'text-align': 'right'},
      headerClass: "custom-column-group-cell ag_header_end  px-0",
      cellClass: "custom-column-group-cell text-end",
      editable: false,
      sortable: true,
      resizable: true,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams : {isWeight:true},
  };
  export const AmountColumn: AgColDefsI = {
    headerName: "Amount",
      field: "amount",
      minWidth: AmountColWidth.MinWidth,
      maxWidth:AmountColWidth.MaxWidth,
      headerClass: "custom-column-group-cell ag_header_end px-0",
      cellClass: "custom-column-group-cell text-end",
      editable: false,
      sortable: true,
      resizable: true,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams : {isAmt:true},
  };
  
  export const AlloyWtColumn: AgColDefsI = {
    headerName: "Alloy Wt.",
    headerComponentParams: { isRequired: false , enableSorting: false},
    field: "alloyWt",
    sortable: true,
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    editable: false,
    resizable: true,
    // isRequired: true,
    cellStyle: {'text-align': 'right'},
    cellClass: "custom-column-group-cell px-0 text-end",
    headerClass: "custom-column-group-cell ag_header_end px-0 ",
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:3,
      isOnlyNumbers:true,
      inputMaxlength:8
    }
  };
  const FinalWt: AgColDefsI = {
    headerName: "Final Wt",
    field: "finalWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end ",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
  }
  const StandardWt: AgColDefsI = {
    headerName: "Standard Wt",
    field: "standardWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0 ag_header_end ",
    cellClass: "custom-column-group-cell px-0 text-end",
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
  }
  const ActualPurityColumn: AgColDefsI = {
    headerName: "ActualPurity",
    headerComponentParams: { isRequired: false, enablePlusIconLight: true , enableSorting: false},
    field: "actualPurity",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellEditor:AgGridCustomInputComponent,
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0 ",
  }
  const HUIDNoColumn: AgColDefsI = {
    headerName: "HUID No.",
    field: "huidno",
    minWidth: 70,
    sortable: true,
    editable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellClass:'px-0 custom-column-group-cell', 
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
 
  };
  const PacketLessWt: AgColDefsI = {
    headerName: "Packet Less Wt",
    field: "packetLessWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
  }
  const PacketWt: AgColDefsI = {
    headerName: "Packet Wt",
    field: "packetWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
    headerComponentParams: {isRequired: false, enableSorting: false},
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0 ",
  }
  const NotionalMeltingProfitAndLossColumn: AgColDefsI = {
    headerName: "National Melting Profit And Loss",
    field: "notionalMeltingProfitAndLoss",
    minWidth: 70,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell",
    editable: false,
    resizable: true,
    sortable: true,
    cellRenderer: TextRendererComponent,
    headerComponentParams: { enableSorting: false},
    cellEditor:AgGridCustomInputComponent,
  }
  const MakingRate: AgColDefsI = {
    headerName: "Making Rate",
    field: "makingRate",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    headerClass: "custom-column-group-cell ag_header_end px-0 ",
    cellClass: "custom-column-group-cell text-end",
    cellStyle: {'text-align': 'right'},
    editable: false,
    sortable: true,
    resizable: true,
    cellRenderer: TextRendererComponent,
    headerComponentParams: { enableSorting: false},
    cellEditor:AgGridCustomInputComponent,
  }
  const MakingAmt: AgColDefsI = {
    headerName: "Amt", 
    headerComponentParams: {enableMenu:false},
    sortable:true,
    headerClass: "px-1 custom-column-group-cell ag_header_end border-0 f-14 theme-light-voilet-color font-semibold ",
    field: 'makingAmount',
    cellClass:'px-1 custom-column-group-cell text-end theme-color-dark-gray', 
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    flex:2,
    editable:false,
    suppressMovable: false,
    resizable: true,
    cellRenderer: TextRendererComponent,
};
const MakingDiscount: AgColDefsI = {
  headerName: "Making Discount", 
  headerComponentParams: {enableMenu:false},
  sortable:true,
  headerClass: "px-1 custom-column-group-cell ag_header_end border-0 f-14 theme-light-voilet-color font-semibold ",
  field: 'makingDiscount',
  cellClass:'px-1 custom-column-group-cell text-end theme-color-dark-gray', 
  minWidth: AmountColWidth.MinWidth,
  maxWidth:AmountColWidth.MaxWidth,
  flex:2,
  editable:false,
  suppressMovable: false,
  resizable: true,
  cellRenderer: TextRendererComponent,
};

export const UDF1: AgColDefsI = {
  headerName: "UDF1",
  field: "udF1",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  sortable: true,
  editable: false,
  resizable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF1_DROPDOWN);
    },
  },
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF1" && a.extra1 == "1")?.length > 0,
  hide :true,
  isVisibleOnColumnSetting : true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF1" && a.extra1 == "1")?.length > 0,

  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
};
export const UDF2: AgColDefsI = {
  headerName: "UDF2",
  field: "udF2",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  sortable: true,
  editable: false,
  resizable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF2_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF2" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF2" && a.extra1 == "1")?.length > 0,

};
export const UDF3: AgColDefsI = {
  headerName: "UDF3",
  field: "udF3",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  sortable: true,
  editable: false,
  resizable: true,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF3_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF3" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF3" && a.extra1 == "1")?.length > 0,

};
export const UDF4: AgColDefsI = {
  headerName: "UDF4",
  field: "udF4",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF4_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF4" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF4" && a.extra1 == "1")?.length > 0,

};
export const UDF5: AgColDefsI = {
  headerName: "UDF5",
  field: "udF5",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF5_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF6: AgColDefsI = {
  headerName: "UDF6",
  field: "udF6",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF7: AgColDefsI = {
  headerName: "UDF7",
  field: "udF7",
  minWidth: 70,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF7" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF7" && a.extra1 == "1")?.length > 0,

};
export const UDF8: AgColDefsI = {
  headerName: "UDF8",
  field: "udF8",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF8" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF8" && a.extra1 == "1")?.length > 0,
};
export const UDF9: AgColDefsI = {
  headerName: "UDF9",
  field: "udF9",
  minWidth: 70,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,

  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF9" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF9" && a.extra1 == "1")?.length > 0,
};
export const UDF10: AgColDefsI = {
  headerName: "UDF10",
  field: "udF10",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:3,
    isOnlyNumbers:true,
    inputMaxlength:10
  },
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF10" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF10" && a.extra1 == "1")?.length > 0,

};

export const UDF11: AgColDefsI = {
  headerName: "UDF11",
  field: "udF11",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF11_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF12: AgColDefsI = {
  headerName: "UDF12",
  field: "udF12",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF12_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF13: AgColDefsI = {
  headerName: "UDF13",
  field: "udF13",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF13_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF14: AgColDefsI = {
  headerName: "UDF14",
  field: "udF14",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF14_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF15: AgColDefsI = {
  headerName: "UDF15",
  field: "udF15",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF15_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};
export const UDF16: AgColDefsI = {
  headerName: "UDF16",
  field: "udF16",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF17: AgColDefsI = {
  headerName: "UDF17",
  field: "udF17",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF18: AgColDefsI = {
  headerName: "UDF18",
  field: "udF18",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF19: AgColDefsI = {
  headerName: "UDF19",
  field: "udF19",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF20: AgColDefsI = {
  headerName: "UDF20",
  field: "udF20",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  cellClass: "custom-column-group-cell",
  headerComponentParams: { enableSorting: false},
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomInputComponent,  
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length > 0,

};
export const UDF21: AgColDefsI = {
  headerName: "UDF21",
  field: "udF21",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF21_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};
export const UDF22: AgColDefsI = {
  headerName: "UDF22",
  field: "udF22",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF22_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF23: AgColDefsI = {
  headerName: "UDF23",
  field: "udF23",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF23_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF24: AgColDefsI = {
  headerName: "UDF24",
  field: "udF24",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF24_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF25: AgColDefsI = {
  headerName: "UDF25",
  field: "udF25",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF25_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF26: AgColDefsI = {
  headerName: "UDF26",
  field: "udF26",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF26_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF27: AgColDefsI = {
  headerName: "UDF27",
  field: "udF27",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF27_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF28: AgColDefsI = {
  headerName: "UDF28",
  field: "udF28",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF28_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF29: AgColDefsI = {
  headerName: "UDF29",
  field: "udF29",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF29_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass ",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};

export const UDF30: AgColDefsI = {
  headerName: "UDF30",
  field: "udF30",
  minWidth: UDFColWidth.MinWidth,
  maxWidth: UDFColWidth.MaxWidth,
  resizable: true,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  cellEditor: AgGridCustomDropdownComponent,
  headerComponentParams: { enableSorting: false},
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UDF30_DROPDOWN);
    },
  },
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 customHeaderClass",
  // hide :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,
  hide :true,
  // isVisibleOnColumnSetting :  new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length > 0,

};
  const StockReportColumnDefForStockhistoryGoldTable :AgColDefsI[] = [
    ActionHistoryColumn,
    DateColumn,
    BarcodeNo,
    RFIDColumn,
    Locationcolumn,
    // GroupNameColumn,
    // MetalColumn,
    AgainstVoucherColumn,
    TypeOfVoucherColumn,
    VoucherTypeColumn,
    VoucherNoColumn,
    InvoiceNoColumn,
    BranchColumn,
    QTYColumn,
    GrossWeightColumn,
    PureWtColumn,
    RequestedWtColumn,
    RequestedPurityColumn,
    StoneAmountColumn,
    DiamondAmountColumn,
    PurityColumn,
    lessWtColumn,
    PurityWtColumn,
    WastagePer,
    WastageWt,
    NetWtColumn,
    AlloyWtColumn,
    FinalWt,
    StandardWt,
    ActualPurityColumn,
    NotionalMeltingProfitAndLossColumn,
    MakingRate,
    MakingAmt,
    MakingDiscount,
    AmountColumn,
    UDF1,
    UDF2,
    UDF3,
    UDF4,
    UDF5,
    UDF6,
    UDF7,
    UDF8,
    UDF9,
    UDF10,
    UDF11,
    UDF12,
    UDF13,
    UDF14,
    UDF15,
    UDF16,
    UDF17,
    UDF18,
    UDF19,
    UDF20,
    UDF21,
    UDF22,
    UDF23,
    UDF24,
    UDF25,
    UDF26,
    UDF27,
    UDF28,
    UDF29,
    UDF30,
    HUIDNoColumn,
    PacketWt,
    PacketLessWt,
    Rate,
    // QuantityColumn,
    HallmarkRate,
    HallmarkAmount,
    NetAmtWithTaxColumn,
    TaxAmtColumn,
    NetAmt,
    DiscountPer,
    DiscountAmount,
    MetalValueColumn,
    PurchaseAmountColumn,
    SaleAmount,
    UniqueBarcode,
    categoryName,
    ]
    export const ColDefsStockAvailabilityGoldTable: AgColDefsI[] = [
      ...StockReportColumnDefForStockhistoryGoldTable,
      ImageColumn
     ];
   
     export const ColDefsStockHistoryGoldTable: AgColDefsI[] = [
       ...StockReportColumnDefForStockhistoryGoldTable,
       stockImageColumn
      ];
  export const AgGridStockhistoryGoldTable: AgGridI = {
    colDefs: ColDefsStockHistoryGoldTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    rowHeight: 28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_STOCKHISTORY,
    isServerSidepagination : true,
    showPagination: true
  };
  
  export const AgGridStockAvailabilityGoldTable: AgGridI = {
    colDefs: ColDefsStockAvailabilityGoldTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    rowHeight: 28,
    headerHeight : 28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_STOCKHISTORY_GOLDSILVER,
    isServerSidepagination : true,
    showPagination: true
  };
  const StockhistoryGoldPopup = StockReportColumnDefForStockhistoryGoldTable.filter(column => {
    return column.field !== 'groupName' && column.field !== 'imageUrls' && column.field !== 'action';
  });

  const columnsWithExtraColumnStockhistoryGold = [...StockhistoryGoldPopup,
                                                  // GroupNamePopupColumn
                                                  ];

  export const AgGridStockhistoryGoldSilverPopupTable: AgGridI = {
    colDefs: columnsWithExtraColumnStockhistoryGold,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_GOLD_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    headerHeight : 70,
    rowHeight: 28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_STOCKHISTORY_GOLDSILVER,
    isServerSidepagination : true,
    showPagination: true
  };

  const StockReportColumnDefForStockhistorySilverTable :AgColDefsI[] = [
    ActionHistoryColumn,
    DateColumn,
    BarcodeNo,
    RFIDColumn,
    // GroupNameColumn,
    // MetalColumn,
    AgainstVoucherColumn,
    TypeOfVoucherColumn,
    VoucherTypeColumn,
    VoucherNoColumn,
    Locationcolumn,
    InvoiceNoColumn,
    BranchColumn,
    QTYColumn,
    GrossWeightColumn,
    PureWtColumn,
    RequestedWtColumn,
    RequestedPurityColumn,
    StoneAmountColumn,
    DiamondAmountColumn,
    PurityColumn,
    lessWtColumn,
    PurityWtColumn,
    WastagePer,
    WastageWt,
    NetWtColumn,
    AlloyWtColumn,
    FinalWt,
    StandardWt,
    ActualPurityColumn,
    NotionalMeltingProfitAndLossColumn,
    MakingRate,
    MakingAmt,
    MakingDiscount,
    AmountColumn,
    UDF1,
    UDF2,
    UDF3,
    UDF4,
    UDF5,
    UDF6,
    UDF7,
    UDF8,
    UDF9,
    UDF10,
    UDF11,
    UDF12,
    UDF13,
    UDF14,
    UDF15,
    UDF16,
    UDF17,
    UDF18,
    UDF19,
    UDF20,
    UDF21,
    UDF22,
    UDF23,
    UDF24,
    UDF25,
    UDF26,
    UDF27,
    UDF28,
    UDF29,
    UDF30,
    HUIDNoColumn,
    PacketWt,
    PacketLessWt,
    Rate,
    // QuantityColumn,
    HallmarkRate,
    HallmarkAmount,
    NetAmtWithTaxColumn,
    TaxAmtColumn,
    NetAmt,
    DiscountPer,
    DiscountAmount,
    MetalValueColumn,
    PurchaseAmountColumn,
    SaleAmount,
    UniqueBarcode,
    categoryName,
    ]

    export const ColDefsStockAvailabilitySilverTable: AgColDefsI[] = [
      ...StockReportColumnDefForStockhistorySilverTable,
      ImageColumn
     ];
   
     export const ColDefsStockHistorySilverTable: AgColDefsI[] = [
       ...StockReportColumnDefForStockhistorySilverTable,
       stockImageColumn
      ];
  
  export const AgGridStockhistorySilverTable: AgGridI = {
    colDefs: ColDefsStockHistorySilverTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    rowHeight: 28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_STOCKHISTORY_GOLDSILVER,
    isServerSidepagination : true,
    showPagination: true
  };



  
  export const AgGridStockAvailabilitySilverTable: AgGridI = {
    colDefs: ColDefsStockAvailabilitySilverTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_SILVER_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    rowHeight: 28,
    headerHeight : 28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_STOCKHISTORY_GOLDSILVER,
    isServerSidepagination : true,
    showPagination: true
  };

  const ItemCodeColumn: AgColDefsI = {
    headerName: "Item Code",
    field: "itemCode",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "itemCode",
      filterIdColumnName: "itemCode",
      isInputFilter: true,
    },
    cellRenderer: TextRendererComponent
  };
  const BarcodeNoColumn: AgColDefsI = {
    headerName: "Barcode No",
    field: "barcodeNo",
    minWidth: BarcodeColWidth.MinWidth,
    maxWidth: BarcodeColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isInfo:true},
    headerComponentParams: { 
      enableSorting: false,
      filterValueColumnName: "barcodeno",
      filterIdColumnName: "barcodeno",
      isInputFilter: true,
    },
  };
  
  const IconColumn: AgColDefsI = 
  {
    headerName: "",
    field: '',
    headerClass: "custom-column-group-cell px-0",
    cellClass:'px-2 custom-column-group-cell d-flex justify-content-center align-items-center gap-2', 
    minWidth: 50,
    maxWidth: 50,
    sortable: false,
    editable: false,
    pinned:'right',
    lockPinned: true,
    lockPosition: "right",
    cellRenderer: EditDeleteIconComponent,
    cellRendererParams :{isInfo:true,isDeleteIcon:false},

};
  const BarcodeNoPopupColumn: AgColDefsI = {
    headerName: "Barcode No",
    field: "barcodeNo",
    minWidth: BarcodeColWidth.MinWidth,
    maxWidth: BarcodeColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
  };

  const DiamondCategoryColumn: AgColDefsI = {
    headerName: "Diamond Category",
    field: "diamondCategory",
    minWidth: 70,
  resizable: true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "diamondCategory",
      filterIdColumnName: "diamondCategory",
      filterParams: StorageName.DIAMOND_CATEGORY_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
  };

const CalculationTypeColumn: AgColDefsI = {
    headerName: "Calculation Type",
    field: "calculationType",
    minWidth: 60,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "calculationTypeId",
      filterIdColumnName: "calculationType",
      filterParams: StorageName.CALCULATION_TYPE_DROPDOWN,
      //floatingMultiselectDropDown: true,
    },
  };
  const GoldCaratColumn: AgColDefsI = {
    headerName: "Gold Carat",
    field: "goldCarat",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "goldCarat",
      filterIdColumnName: "goldCarat",
      isInputFilter: true,
      isAllowNumber:true
    },
  };
  const GoldWtColumn: AgColDefsI = {
    headerName: "Gold Wt",
    field: "goldWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
  resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "goldWt",
      filterIdColumnName: "goldWt",
      isInputFilter: true,
      isAllowNumber:true
    },
  };
  const GoldAmountColumn: AgColDefsI = {
    headerName: "Gold Amount",
    field: "goldAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "goldAmount",
      filterIdColumnName: "goldAmount",
      isInputFilter: true,
      isAllowNumber:true
    },
  };
  const StoneWtColumn: AgColDefsI = {
    headerName: "Stone Wt",
    field: "stoneWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "goldAmount",
      filterIdColumnName: "goldAmount",
      isInputFilter: true,
      isAllowNumber:true,
    },
    
  };
  const DiamondWtColumn: AgColDefsI = {
    headerName: "Diamond Wt",
    field: "diamondWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "diamondWt",
      filterIdColumnName: "diamondWt",
      isInputFilter: true,
      isAllowNumber:true,
    },
  };
  
  const DiamondCaratPopupColumn: AgColDefsI = {
    headerName: "D.Carat",
    field: "carat",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
  resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: NumericCellRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "carat",
      filterIdColumnName: "carat",
      isInputFilter: true,
      isAllowNumber:true
    },
    cellRendererParams : {isWeight:true},
  };

  const DiamondCaratColumn: AgColDefsI = {
    headerName: "D.Carat",
    field: "diA_Carat",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: NumericCellRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "diA_Carat",
      filterIdColumnName: "diA_Carat",
      isInputFilter: true,
      isAllowNumber:true
    },
    cellRendererParams : {isWeight:true},
  }; 
  const Quantity: AgColDefsI = {
    headerName: "Qty",
    field: "quantity",
    minWidth: QuantityColWidth.MinWidth,
    maxWidth: QuantityColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "quantity",
      filterIdColumnName: "quantity",    
      isInputFilter: true,
    },
  }; 
  const RateColumn: AgColDefsI = {
    headerName: "Rate",
    field: "rate",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    cellStyle: {'text-align': 'right'},
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "rate",
      filterIdColumnName: "rate",    
      isInputFilter: true,
    },
  };
  
  const CertificateNumberColumn: AgColDefsI = {
    headerName: "Certificate Number",
    field: "certificateNumber",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "certificateNumber",
      filterIdColumnName: "certificateNumber",    
      isInputFilter: true,
    },
  }; 

  const StyleColumn: AgColDefsI = {
    headerName: "Style",
    field: "style",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "style",
      filterIdColumnName: "style",
      filterParams: StorageName.STYLE_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent
  }; 

  const CutColumn: AgColDefsI = {
    headerName: "Cut",
    field: "cut",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "cut",
      filterIdColumnName: "cut",
      filterParams: StorageName.CUT_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent
  };  
  const UDF1Column: AgColDefsI = {
    headerName: "UDF1" , // new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF1")[0].name,
    field: "udF1",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF1",
      filterIdColumnName: "udF1",
      filterParams: StorageName.UDF1_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF1" && a.extra1 == "1").length == 0,
  }; 
  const UDF2Column: AgColDefsI = {
    headerName : "UDF2",//new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF2")[0]?.name || "UDF2",
    field: "udF2",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF2",
      filterIdColumnName: "udF2",
      filterParams: StorageName.UDF2_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
   hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF2" && a.extra1 == "1").length == 0,
  }; 
  const UDF3Column: AgColDefsI = {
    headerName: "UDF3",// new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF3")[0]?.name || "UDF3",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF3",
      filterIdColumnName: "udF3",
      filterParams: StorageName.UDF3_DROPDOWN,
     // floatingMultiselectDropDown: true,
    },
   hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF3" && a.extra1 == "1").length == 0,
  }; 
  const UDF4Column: AgColDefsI = {
    headerName: "UDF4",//new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF4")[0]?.name || "UDF4",
    field: "udF4",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF4",
      filterIdColumnName: "udF4",
      filterParams: StorageName.UDF4_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
   hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF4" && a.extra1 == "1").length <= 0,
  }; 
  const UDF5Column: AgColDefsI = {
    headerName: "UDF5",//new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5")[0]?.name || "UDF5",
    field: "udF5",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF5",
      filterIdColumnName: "udF5",
      filterParams: StorageName.UDF5_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
   hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF5" && a.extra1 == "1")?.length <= 0,
  }; 
  const UDF6Column: AgColDefsI = {
    headerName: "UDF6", //new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6")[0]?.name || "UDF6",
    field: "udF6",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF6",
      filterIdColumnName: "udF6",
      isInputFilter: true,
      isAllowNumber:true
    },
   hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF6" && a.extra1 == "1")?.length <= 0,
  }; 
  const UDF7Column: AgColDefsI = {
    headerName:   "UDF7",//new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF7")[0]?.name || "UDF7",
    field: "udF7",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF7",
      filterIdColumnName: "udF7",
      isInputFilter: true,
      isAllowNumber:true
    },
   hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF7" && a.extra1 == "1")?.length <= 0,
  }; 
  const UDF8Column: AgColDefsI = {
    headerName: "UDF8",// new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF8")[0]?.name || "UDF8",
    field: "udF8",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF8",
      filterIdColumnName: "udF8",
      isInputFilter: true,
      isAllowNumber:true
    },
   hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF8" && a.extra1 == "1")?.length <= 0,
  }; 
  const UDF9Column: AgColDefsI = {
    headerName: "UDF9",//new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF9")[0]?.name || "UDF9",
    field: "udF9",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF9",
      filterIdColumnName: "udF9",
      isInputFilter: true,
      isAllowNumber:true
    },
   hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF9" && a.extra1 == "1")?.length <= 0,
  }; 
  const UDF10Column: AgColDefsI = {
    headerName: "UDF10",//new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF10")[0]?.name || "UDF10",
    field: "udF10",
    minWidth: UDFColWidth.MinWidth,
    maxWidth: UDFColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "udF10",
      filterIdColumnName: "udF10",
      isInputFilter: true,
      isAllowNumber:true
    },
    hide : new StorageService().retrieve(StorageName.UDF_PROPERTY_DROPDOWN)?.filter(a=>a.id == "UDF10" && a.extra1 == "1")?.length <= 0,
  }; 
  const ColorColumn: AgColDefsI = {
    headerName: "Color",
    field: "color",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "color",
      filterIdColumnName: "color",
      filterParams: StorageName.COLOR_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent
  };  

  const SeiveSizeColumn: AgColDefsI = {
    headerName: "SeiveSize",
    field: "seiveSize",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "seiveSizeId",
      filterIdColumnName: "seiveSize",
      filterParams: StorageName.SEIVE_SIZE_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
  };  

  const SizeColumn: AgColDefsI = {
    headerName: "Size",
    field: "size",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "sizeId",
      filterIdColumnName: "size",
      filterParams: StorageName.SIZE_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
  };  

const ShapeColumn: AgColDefsI = {
    headerName: "Shape",
    field: "shape",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "shape",
      filterIdColumnName: "shape",
      filterParams: StorageName.SHAPE_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent
  };  

const ClarityColumn: AgColDefsI = {
    headerName: "Clarity",
    field: "clarity",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,    
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    headerComponentParams:{
      filterValueColumnName: "clarity",
      filterIdColumnName: "clarity",
      filterParams: StorageName.CLARITY_DROPDOWN,
      floatingMultiselectDropDown: true,
    },
    cellRenderer: TextRendererComponent
  }; 

  const SaleAmountColumn: AgColDefsI = {
    headerName: "Sale Amount",
    field: "saleAmount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    resizable: true,
    sortable: true,
    editable: false,
    cellRenderer: TextRendererComponent,
    headerComponentParams: {isRequired: false, enableSorting: false,ilterValueColumnName: "saleAmount",
    filterIdColumnName: "saleAmount",
    isInputFilter: true,
    isAllowNumber:true},
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
      inputMaxlength:8
    },
  }
  
  const TitleColumn: AgColDefsI = {
    headerName: "Title",
    field: "title",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 ",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    isRequired:true,
    cellRenderer: AgGridInnerHTMLDisplayComponent,
    headerComponentParams:{
      filterValueColumnName: "title",
      filterIdColumnName: "title",
      isInputFilter: true,
      isAllowNumber:true
    },
  }; 

  const CardlabelPrintColumn: AgColDefsI = 
  {
    headerName: "",
    field: 'action',
    headerClass: "custom-column-group-cell  px-0",
    cellClass:'px-2 custom-column-group-cell d-flex justify-content-center align-items-center gap-2', 
    minWidth: 70,
    maxWidth: 70,
    sortable: false,
    editable: false,
    lockPosition: "right",
    suppressMovable: false,
    lockPinned: true,
    pinned:'right',
    cellRenderer: EditDeleteIconComponent,
   cellRendererParams :{BCL:false ,isDeleteIcon: false, RFID:false ,CardLabel:true},

};
const CheckboxColumn: AgColDefsI = {
  headerName: "",
  field: "active",    
  lockPosition: "left",
  cellClass: "custom-column-group-cell", 
  cellRenderer:CheckboxRendererComponent  ,
  headerClass:"custom-column-group-cell justify-content-center px-0  border-0",
  minWidth: 50,
  maxWidth: 50,
  editable:false, 
};

const DocumentSVGColumn: AgColDefsI = 
  {
    headerName: "",
    field: 'documentsvg',
    minWidth: 70,
    maxWidth: 70,
   cellRenderer: TrackOrderCellrenderComponent,
   resizable: true,
   cellStyle: {'text-align': 'center'},
  headerClass: "custom-column-group-cell px-0",
   cellClass: "custom-column-group-cell",
   editable: false,
   sortable: false,
   cellRendererParams :{PrintSvg:true},


};
  const StockReportColumnDefForStockhistoryDiamondTable :AgColDefsI[] =[
    CheckboxColumn,
    ImageColumn,
    ActionHistoryColumn,
    DateColumn,
    BarcodeNoColumn,
    IconColumn,
    ItemCodeColumn,
    ProductNameColumn,  
    Locationcolumn,
    VoucherTypeColumn,
    // GroupNamePopupColumn,
    InvoiceNoColumn,
    BranchColumn,   
    StyleColumn,
    DiamondCategoryColumn,
    CalculationTypeColumn,
    TitleColumn,
    GrossWtColumn,
    DiamondWeightColumn,
    DiamondCaratColumn,
    Quantity,
    RateColumn,
    PurchaseAmountColumn,
    SaleAmountColumn,
    CertificateNumberColumn,
    CutColumn,
    ColorColumn,
    SeiveSizeColumn,
    SizeColumn,
    ShapeColumn,
    ClarityColumn,
    UDF1Column,
    UDF2Column,
    UDF3Column,
    UDF4Column,
    UDF5Column,
    UDF6Column,
    UDF7Column,
    UDF8Column,
    UDF9Column,
    UDF10Column,
    GoldCaratColumn,
    GoldWtColumn,
    GoldAmountColumn,
    StoneWtColumn,
    StoneAmountColumn,
    CustomerNameColumn,
    CardlabelPrintColumn,
    DocumentSVGColumn,
  ]

  export const AgGridStockhistoryDiamondTable: AgGridI = {
    colDefs: StockReportColumnDefForStockhistoryDiamondTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    headerHeight : 70,
    rowHeight: 28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT,
    isServerSidepagination : true,
    showPagination: true
  }
  
  // const StockReportColumnDefForStockhistoryDiamondPopupTable :AgColDefsI[] =[
  //   DateColumn,
  //   BarcodeNoColumn,
  //   ItemCodeColumn,
  //   ProductNameColumn,  
  //   VoucherTypeColumn,
  //   GroupNameColumn,
  //   InvoiceNoColumn,
  //   BranchColumn,   
  //   StyleColumn,
  //   DiamondCategoryColumn,
  //   CalculationTypeColumn,
  //   TitleColumn,
  //   GrossWtColumn,
  //   DiamondWeightColumn,
  //   DiamondCaratColumn,
  //   Quantity,
  //   RateColumn,
  //   PurchaseAmountColumn,
  //   SaleAmountColumn,
  //   CertificateNumberColumn,
  //   CutColumn,
  //   ColorColumn,
  //   SeiveSizeColumn,
  //   SizeColumn,
  //   ShapeColumn,
  //   ClarityColumn,
  //   UDF1Column,
  //   UDF2Column,
  //   UDF3Column,
  //   UDF4Column,
  //   UDF5Column,
  //   UDF6Column,
  //   UDF7Column,
  //   UDF8Column,
  //   UDF9Column,
  //   UDF10Column,
  //   GoldCaratColumn,
  //   GoldWtColumn,
  //   GoldAmountColumn,
  //   StoneWtColumn,
  //   StoneAmountColumn,
  //   CustomerNameColumn,
  // ]
  const StockhistoryDiamondPopup = StockReportColumnDefForStockhistoryDiamondTable.filter(column => {
    return column.field !== 'action' && column.field !== 'barcode' && column.field !== 'imageUrls' && column.field !== 'diA_Carat' && column.field !== 'documentsvg' ;
  });

  const columnsWithExtraStockhistoryDiamond = [...StockhistoryDiamondPopup ,DiamondCaratPopupColumn];
  export const AgGridStockhistoryDiamondPopupTable: AgGridI = {
    colDefs: columnsWithExtraStockhistoryDiamond,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_DIAMOND_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    headerHeight : 70,
    rowHeight: 28,
    isServerSidepagination : true,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT,
  }
  
 
  const StockReportColumnDefForStockhistoryStoneGemsTable :AgColDefsI[] =[
    ImageColumn,
    ActionHistoryColumn,
    DateColumn,
    BarcodeNoColumn,
    ItemCodeColumn,
    ProductNameColumn,  
    Locationcolumn,
    VoucherTypeColumn,
    // GroupNameColumn,
    InvoiceNoColumn,
    BranchColumn,   
    StyleColumn,
    DiamondCategoryColumn,
    CalculationTypeColumn,
    TitleColumn,
    GrossWtColumn,
    DiamondWeightColumn,
    DiamondCaratColumn,
    Quantity,
    RateColumn,
    PurchaseAmountColumn,
    SaleAmountColumn,
    CertificateNumberColumn,
    CutColumn,
    ColorColumn,
    SeiveSizeColumn,
    SizeColumn,
    ShapeColumn,
    ClarityColumn,
    UDF1Column,
    UDF2Column,
    UDF3Column,
    UDF4Column,
    UDF5Column,
    UDF6Column,
    UDF7Column,
    UDF8Column,
    UDF9Column,
    UDF10Column,
    GoldCaratColumn,
    GoldWtColumn,
    GoldAmountColumn,
    DiamondWtColumn,
    DiamondAmountColumn
  ]

  export const AgGridStockhistoryStoneGemsTable: AgGridI = {
    colDefs: StockReportColumnDefForStockhistoryStoneGemsTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_STONEGEMS_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    headerHeight : 70,
    rowHeight: 28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT,
    isServerSidepagination : true,
    showPagination: true
  }
  
 

  const ImmitationHuidNo: AgColDefsI = {
    headerName: "Huid No",
    field: "huidNo",
    minWidth: 120,
    sortable: true,
    editable: false,
    resizable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    headerComponentParams:{
      filterValueColumnName: "huidNo",
      filterIdColumnName: "huidNo",
      isRequired: false, 
      enableSorting: false,
      isInputFilter: true,
      isAllowNumber:true
    },
  }
  const ImmitationPacketWt: AgColDefsI = {
    headerName: "Packet Wt",
    field: "packetWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    headerComponentParams:{
      filterValueColumnName: "packetWt",
      filterIdColumnName: "packetWt",
      isRequired: false, 
      enableSorting: false,
      isInputFilter: true,
      isAllowNumber:true
    },
  }
  
  const ImmitationNetAmount: AgColDefsI = {
    headerName: "Net Amount",
    field: "amount",
    minWidth: AmountColWidth.MinWidth,
    maxWidth:AmountColWidth.MaxWidth,
    sortable: true,
    editable: false,
    resizable: false,
    cellRenderer: TextRendererComponent,
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellEditor:AgGridCustomInputComponent,
    cellEditorParams:{
      decimalPlaces:2,
      isOnlyNumbers:true,
    },
    headerComponentParams:{
      filterValueColumnName: "amount",
      filterIdColumnName: "amount",
      isRequired: false, 
      enableSorting: false,
      isInputFilter: true,
      isAllowNumber:true
    },
  }

  
const ImmitationDiscountPer: AgColDefsI = {
  headerName: "Discount Per",
  field: "discountPer",
  minWidth: AmountColWidth.MinWidth,
  maxWidth: AmountColWidth.MaxWidth,
  sortable: true,
  editable: false,
  resizable: false,
  cellRenderer: TextRendererComponent,
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 ",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  },
  headerComponentParams:{
    filterValueColumnName: "discountPer",
    filterIdColumnName: "discountPer",
    isRequired: false, 
    enableSorting: false,
    isInputFilter: true,
    isAllowNumber:true
  },
}

const ImmitationDiscountAmount: AgColDefsI = {
  headerName: "Discount Amount",
  field: "discountAmount",
  minWidth: AmountColWidth.MinWidth,
  maxWidth:AmountColWidth.MaxWidth,
  sortable: true,
  editable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams:{
    filterValueColumnName: "discountAmount",
    filterIdColumnName: "discountAmount",
    isRequired: false, 
    enableSorting: false,
    isInputFilter: true,
    isAllowNumber:true
  },
  cellClass: "custom-column-group-cell px-0 text-end",
  headerClass: "custom-column-group-cell px-0 ag_header_end",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
  }
}
const ImmitationNetAmountWithTax: AgColDefsI = {
  headerName: "Net Amount Tax",
  field: "amount",
  minWidth: AmountColWidth.MinWidth,
  maxWidth:AmountColWidth.MaxWidth,
  sortable: true,
  editable: false,
  resizable: false,
  cellRenderer: TextRendererComponent,
  headerComponentParams: {isRequired: false, enableSorting: false},
  cellClass: "custom-column-group-cell px-0",
  headerClass: "custom-column-group-cell px-0 ",
  cellEditor:AgGridCustomInputComponent,
  cellEditorParams:{
    decimalPlaces:2,
    isOnlyNumbers:true,
    headerComponentParams:{
      filterValueColumnName: "amount",
      filterIdColumnName: "amount",
      isRequired: false, 
      enableSorting: false,
      isInputFilter: true,
      isAllowNumber:true
    }
  }
}

const ImmitationPacketLessWt: AgColDefsI = {
  headerName: "Packet Less Wt",
  field: "packetLessWt",
  minWidth: WeightColWidth.MinWidth,
    maxWidth:WeightColWidth.MaxWidth,
  sortable: true,
  editable: false,
  resizable: false,
  cellRenderer: TextRendererComponent,
  cellClass: "custom-column-group-cell text-end px-0",
  headerClass: "custom-column-group-cell px-0 ag_header_end",
  headerComponentParams:{
    filterValueColumnName: "packetLessWt",
    filterIdColumnName: "packetLessWt",
    isRequired: false, 
    enableSorting: false,
    isInputFilter: true,
    isAllowNumber:true
  }
}


  const StockReportColumnDefForStockhistoryimitationorwatchesTable :AgColDefsI[] =[
    CheckboxColumn,
    BarcodeNoPopupColumn,
    Product,
    Locationcolumn,
    // GroupNameColumn,
    ImmitationHuidNo,
    ImmitationPacketWt,
    GrossWtColumn,
    Quantity,
    ImmitationNetAmount,
    PurchaseAmountColumn,
    SaleAmountColumn,
    ImmitationDiscountPer,
    ImmitationDiscountAmount,
    ImmitationNetAmountWithTax,
    ImmitationPacketLessWt,
    DateColumn,
    CustomerNameColumn,
    BranchName
  ]


  export const AgGridStockhistoryimitationorwatchesTable: AgGridI = {
    colDefs: StockReportColumnDefForStockhistoryimitationorwatchesTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    headerHeight : 70,
    rowHeight:28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT,
    isServerSidepagination : true,
    showPagination: true
  }

  export const AgGridStockhistoryimitationorwatchesPopupTable: AgGridI = {
    colDefs: StockReportColumnDefForStockhistoryimitationorwatchesTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKHISTORY_IMITATIONORWATCHES_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    headerHeight : 70,
    rowHeight:28,
    apiUrl :  API_URL_GET_ALL_DIAMOND_STONE_IMITATION_REPORT,
  }
  const viewHistoryColumn: AgColDefsI = {
    headerName: "",
    field: "action",
    maxWidth: 120,
    minWidth:120,
    sortable: true,
    editable: false,
    resizable: false,
    headerComponentParams: { enableSorting: false},
    cellRenderer: AgGridButtonCellrendererComponent, 
    cellRendererParams: { viewHistory: true ,},
    cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
    headerClass: "custom-column-group-cell px-0",
    footerClass: "custom-footer-class",
    suppressPaste: true,
  };

  const StockReportColumnDefForStockDetailsTable: AgColDefsI[] = [
    {
    headerName: "Product",
    field: "productName",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
    // headerComponentParams:{
    //   filterValueColumnName: "productId",
    //   filterIdColumnName: "productName",
    //   filterParams: StorageName.PRODUCT_DROPDOWN,
    //   floatingMultiselectDropDown: true,
    // },
  },
  {
    headerName: "Metal",
    field: "metalName",
    minWidth: MetalColWidth.MinWidth,
    maxWidth: MetalColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
    // headerComponentParams:{
    //   filterValueColumnName: "metalId",
    //   filterIdColumnName: "metalName",
    //   filterParams: StorageName.METAL_DROPDOWN,
    //   floatingMultiselectDropDown: true,
    // },
  },
  {
  headerName: "Location",
  field: 'locationName',
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell px-2",
  minWidth: 170,
  sortable: true,
  editable: false,
  cellRenderer:TextRendererComponent,
  // headerComponentParams:{
  //   filterValueColumnName: "locationId",
  //     filterIdColumnName: "locationName",
  //   filterParams: StorageName.LOCATION_DROPDOWN,
  //   floatingMultiselectDropDown: true,
  // },
},
  // {
  //   headerName: "Group Name",
  //   headerClass:"px-0 custom-column-group-cell f-14 text-gray-color font-semibold",
  //   field: "groupName",
  //   sortable: true,
  //   editable: false,
  //   cellRenderer: TextRendererComponent,
  //   cellClass: "custom-column-group-cell text-primary font-regular text-underline f-12 ",
  //   cellRendererParams :{isCursor:true},
  //   minWidth: 80,
  //   headerComponentParams:{
  //     enableSorting: false,
  //     filterValueColumnName: "groupName",
  //     filterIdColumnName: "groupName",
  //     isInputFilter: true,
  //   },
  // },
    {
      headerName: "Gross Wt.",
      field: "grossWt",
      marryChildren: true,
      sortable: true,
      headerComponentParams: { isRequired: false ,enableSorting: true},
      headerClass: "custom-column-group-cell justify-content-center px-0",
      children: [ 
        {
        headerName: "Opening",
        field: "openingGrossWtValue",
        suppressMovable: false,
        lockPosition:false,
        resizable: true,
        headerClass: "px-0  custom-column-group-cell  ag_header_end",
        cellClass: "px-2 custom-column-group-cell ",
        cellStyle: {'text-align': 'right'},
        flex: 1,
        minWidth: WeightColWidth.MinWidth,
        maxWidth:WeightColWidth.MaxWidth,
        cellRenderer: NumericCellRendererComponent,
        cellRendererParams : {isWeight:true},
        editable:false,
        sortable: true,
        headerComponentParams:{
          filterValueColumnName: "openingGrossWtValue",
          filterIdColumnName: "openingGrossWtValue",
          isInputFilter: true,
          isAllowNumber:true
        }
      },
        {
          headerName: "Wt. In",
          field: "debitGrossWt",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell ag_header_end ",
          cellClass: "px-2 custom-column-group-cell ",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: WeightColWidth.MinWidth,
          maxWidth:WeightColWidth.MaxWidth,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "debitGrossWt",
            filterIdColumnName: "debitGrossWt",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
        {
          headerName: "Wt. Out",
          field: "creditGrossWt",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell  ag_header_end",
          cellClass: "px-2 custom-column-group-cell",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: WeightColWidth.MinWidth,
          maxWidth:WeightColWidth.MaxWidth,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "creditGrossWt",
            filterIdColumnName: "creditGrossWt",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
        {
          headerName: "Closing",
          field: "closingGrossWtValue",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell   ag_header_end",
          cellClass: "px-2 custom-column-group-cell ",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: WeightColWidth.MinWidth,
          maxWidth: WeightColWidth.MaxWidth,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "closingGrossWtValue",
            filterIdColumnName: "closingGrossWtValue",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
      ],
    },
    {
      headerName: "Pure Wt.",
      field: "pureWt",
      marryChildren: true,
      sortable: true,
      // isRequired: true,
      headerComponentParams: { isRequired: false ,enableSorting: true},
      headerClass: "custom-column-group-cell justify-content-center px-0",
      children: [
        {
          headerName: "Opening",
          field: "openingPureWtValue",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell  ag_header_end",
          cellClass: "px-2 custom-column-group-cell ",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: WeightColWidth.MinWidth,
          maxWidth:WeightColWidth.MaxWidth,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "openingPureWtValue",
            filterIdColumnName: "openingPureWtValue",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
        {
          headerName: "Wt. In",
          field: "debitPureWt",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell ag_header_end ",
          cellClass: "px-2 custom-column-group-cell",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: WeightColWidth.MinWidth,
          maxWidth:WeightColWidth.MaxWidth,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "debitPureWt",
            filterIdColumnName: "debitPureWt",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
        {
          headerName: "Wt. Out",
          field: "creditPureWt",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell  ag_header_end",
          cellClass: "px-2 custom-column-group-cell f-12 font-regular",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: WeightColWidth.MinWidth,
          maxWidth:WeightColWidth.MaxWidth,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "creditPureWt",
            filterIdColumnName: "creditPureWt",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
        {
          headerName: "Closing",
          field: "closingPureWtValue",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell  ag_header_end",
          cellClass: "px-2 custom-column-group-cell ",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: WeightColWidth.MinWidth,
          maxWidth: WeightColWidth.MaxWidth,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "closingPureWtValue",
            filterIdColumnName: "closingPureWtValue",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
      ],
    },

    {
      headerName: "Pcs",
      field: "pcs",
      marryChildren: true,
      sortable: true,
      headerComponentParams: { isRequired: false ,enableSorting: true},
      headerClass: "custom-column-group-cell justify-content-center px-0",
      children: [
        {
          headerName: "Opening",
          field: "openingPcsValue",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell   ag_header_end",
          cellClass: "px-2 custom-column-group-cell ",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: 90,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "openingPcsValue",
            filterIdColumnName: "openingPcsValue",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
        {
          headerName: "Wt. In",
          field: "debitPcs",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell ag_header_end ",
          cellClass: "px-2 custom-column-group-cell ",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: WeightColWidth.MinWidth,
          maxWidth:WeightColWidth.MaxWidth,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "debitPcs",
            filterIdColumnName: "debitPcs",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
        {
          headerName: "Wt. Out",
          field: "creditPcs",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell   ag_header_end",
          cellClass: "px-2 custom-column-group-cell ",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: WeightColWidth.MinWidth,
          maxWidth:WeightColWidth.MaxWidth,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "creditPcs",
            filterIdColumnName: "creditPcs",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
        {
          headerName: "Closing",
          field: "closingPcsValue",
          suppressMovable: false,
          lockPosition:false,
          resizable: true,
          headerClass: "px-0  custom-column-group-cell   ag_header_end",
          cellClass: "px-2 custom-column-group-cell ",
          cellStyle: {'text-align': 'right'},
          flex: 1,
          minWidth: 90,
          cellRenderer: NumericCellRendererComponent,
          cellRendererParams : {isWeight:true},
          editable:false,
          sortable: true,
          headerComponentParams:{
            filterValueColumnName: "closingPcsValue",
            filterIdColumnName: "closingPcsValue",
            isInputFilter: true,
            isAllowNumber:true
          }
        },
      ],
    },  
    {
      headerName: "Date",
      field: "date",
      maxWidth: 80,
      minWidth: 80,
      headerClass: "custom-column-group-cell px-0 customHeaderClass ag_header_end",
      cellClass: "custom-column-group-cell text-end",
      editable: false,
      sortable: true,
      cellRenderer:DateCellRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "date",
        filterIdColumnName: "date",    
        isInputFilter: true,
      },
    },
    { 
      headerName: "Branch Name", 
      headerComponentParams: {enableMenu:false},
      sortable:true,
      headerClass: "px-0 custom-column-group-cell border-0",
      field: 'branchName',
      cellClass:'px-0 custom-column-group-cell ',
      suppressMovable: false,
      resizable: true, 
      cellRenderer: TextRendererComponent,
      cellRendererParams :{isCursor:false},
      editable:false, 
      minWidth: 180, flex:2,
    }
  
  ];
   
  const filterColumn = StockReportColumnDefForStockDetailsTable.filter(column => {
    return column.field !=="date";
  });
  const filterStockReportColumnDefForStockDetailsTable = [...filterColumn, viewHistoryColumn];

  export const AgGridStockDetailsTable: AgGridI = {
    colDefs: filterStockReportColumnDefForStockDetailsTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKDETAILS_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKDETAILS_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKDETAILS_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKDETAILS_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    rowHeight:28,
   // headerHeight : 60,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_STOCKDETAILS,
    isServerSidepagination : true,
  };

  const filteredStockReportColumn = StockReportColumnDefForStockDetailsTable.filter(column => {
    return column.field !== 'groupName' && column.field !== 'imageUrls' && column.field !== 'action' && column.field !=="metalName" && column.field !=="productName";
  });

  export const AgGridStockDetailsReportTable: AgGridI = {
    colDefs: filteredStockReportColumn,
    rowSelection: "multiple",
    storageName : StorageName.REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKANALYSISREPORTBYPRODUCT_GRID,
    columnSettings:true,
    rows:[],
    rowHeight:28,
    apiUrl :  API_URL_GET_ALL_STOCKDETAILS_REPORT,
    showPagination: false
  };

  const filteredStockDetailColumns = StockReportColumnDefForStockDetailsTable.filter(column => {
    return column.field !== 'groupName' && column.field !== 'imageUrls' && column.field !== 'action'   ;
  });

  const columnsWithExtraColumn = [...filteredStockDetailColumns,
                                  // GroupNamePopupColumn
                                  ];

  export const AgGridStockDetailsPopupGrid: AgGridI = {
    colDefs: columnsWithExtraColumn,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName : StorageName.REPORT_STOCKREPORT_STOCKDETAILS_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_STOCKDETAILS_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_STOCKDETAILS_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_STOCKDETAILS_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    headerHeight : 70,
    rowHeight:28,
    isFilter: true,  
    apiUrl :  API_URL_GET_ALL_STOCKDETAILS,
    isServerSidepagination : true,
  };
  
  
  const AvailableStockColumn: AgColDefsI = {
    headerName: "AvailableStock",
    field: "AvailableStock",
    resizable: false,
    sortable: true,
    marryChildren: true,
    headerGroupComponent: CustomHeaderParentComponent,
    headerClass: "px-2 d-block text-primary",
    children: [
    ]
    
  };
  
  const StockReportColumnDefForAvailableStockTable :AgColDefsI[] = [
    // AvailableStockColumn
    {
      headerName: "",
      field: 'isScanned',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2 d-flex justify-content-center",
      cellRendererParams: { showLabel: false, isReadOnly: true, isCommonCheckbox: true },
      cellRenderer: CheckboxRendererComponent,
      sortable: false,
      resizable: false,
      editable: false,
      suppressMoveIntoParents: false,
      minWidth: 40,
      maxWidth: 40,
      lockPosition: "left",
      suppressMovable: false,
      lockPinned: true,
      pinned: 'left',
    },
    {
      headerName: "Branch",
      field: 'branchName',
      // lockPinned: true,
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      editable:false,
      resizable: true,
      suppressMoveIntoParents: true,
      minWidth: NameColWidth.MinWidth,
      maxWidth: NameColWidth.MaxWidth,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "branchId",
        filterIdColumnName: "branchName",
        filterParams: StorageName.BRANCH_DROPDOWN,
        floatingMultiselectDropDown: true,
      }
    },
    {
      headerName: "Carat",
      field: 'carat',
      // lockPinned: true,
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      editable:false,
      resizable: true,
      suppressMoveIntoParents: true,
      minWidth: WeightColWidth.MinWidth,
      maxWidth: WeightColWidth.MaxWidth,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "carat",
        filterIdColumnName: "carat",
        filterParams: StorageName.CARAT_DROPDOWN,
        floatingMultiselectDropDown: true,
      }
    },
        {
      headerName: "Action",
      field: '',
      headerClass: "custom-column-group-cell ag_header_center  px-0",
      cellClass: "custom-column-group-cell text-center px-2",
      sortable: true,
      editable:false,
      resizable: false,
      suppressMoveIntoParents: true,
      minWidth: 70,
      maxWidth: 70,
      cellRenderer: RfidIconComponent,
     cellRendererParams :{Barcode:false , RFID:true},
     lockPosition:'left',

    },
    {
      headerName: "Metal",
      field: 'metalName',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: MetalColWidth.MinWidth,
      maxWidth: MetalColWidth.MaxWidth,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "metalId",
        filterIdColumnName: "metalName",
        filterParams: StorageName.METAL_DROPDOWN,
        floatingMultiselectDropDown: true,
      }
    },
    {
      headerName: "Product Code",
      field: 'productName',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: 150,
      flex: 1,
      cellRenderer: TextRendererComponent,
      // headerComponentParams:{
      //   filterValueColumnName: "productId",
      //   filterIdColumnName: "productName",
      //   filterParams: StorageName.PRODUCT_DROPDOWN,
      //   floatingMultiselectDropDown: true,
      // }
    },
    {
      headerName: "Location",
      field: 'locationName',
      headerClass: "custom-column-group-cell px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: 170,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
      headerComponentParams:{
      filterValueColumnName: "locationId",
      filterIdColumnName: "locationName",
        filterParams: StorageName.LOCATION_DROPDOWN,
        floatingMultiselectDropDown: true,
      
  }
},
    {
      headerName: "RFID Code",
      field: 'rfid',
      headerClass: "custom-column-group-cell  px-0 customHeaderClass",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: 150,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "rfid",
        filterIdColumnName: "rfid",
        isInputFilter: true,
      }
    },
    {
      headerName: "Barcode",
      field: 'barcodeNo',
      // lockPinned: true,
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      editable:false,
      resizable: true,
      suppressMoveIntoParents: true,
      minWidth: BarcodeColWidth.MinWidth,
      maxWidth: BarcodeColWidth.MaxWidth,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "barcodeno",
        filterIdColumnName: "barcodeno",
        isInputFilter: true,
      }
    },
    {
      headerName: "Qty",
      field: 'quantity',
      // lockPinned: true,
      headerClass: "custom-column-group-cell  px-0",
      cellStyle: {'text-align': 'right'},
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      editable:false,
      resizable: true,
      suppressMoveIntoParents: true,
      minWidth: QuantityColWidth.MinWidth,
      maxWidth: QuantityColWidth.MaxWidth,
      flex: 1,
      cellRenderer: NumericCellRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "quantity",
        filterIdColumnName: "quantity",
        isInputFilter: true,
        isAllowNumber:true
      }
    },
    {
      headerName: "Wt.",
      field: 'weight',
      // lockPinned: true,
      headerClass: "custom-column-group-cell  px-0",
      cellStyle: {'text-align': 'right'},
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      editable:false,
      resizable: true,
      suppressMoveIntoParents: true,
      minWidth: WeightColWidth.MinWidth,
      maxWidth:WeightColWidth.MaxWidth,
      flex: 1,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams : {isWeight:true},
      headerComponentParams:{
        filterValueColumnName: "weight",
        filterIdColumnName: "weight",
        isInputFilter: true,
        isAllowNumber:true
      }
    },
    {
      headerName: "Net Wt.",
      field: 'netWt',
      // lockPinned: true,
      headerClass: "custom-column-group-cell  px-0",
      cellStyle: {'text-align': 'right'},
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: WeightColWidth.MinWidth,
      maxWidth:WeightColWidth.MaxWidth,
      flex: 1,
      cellRenderer: NumericCellRendererComponent,
    cellRendererParams : {isWeight:true},
      headerComponentParams:{
        filterValueColumnName: "netwt",
        filterIdColumnName: "netwt",
        isInputFilter: true,
        isAllowNumber:true
      }
    },
    {
      headerName: "Final Wt.",
      field: 'finalWt',
      // lockPinned: true,
      headerClass: "custom-column-group-cell  px-0",
      cellStyle: {'text-align': 'right'},
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: WeightColWidth.MinWidth,
      maxWidth:WeightColWidth.MaxWidth,
      flex: 1,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams : {isWeight:true},
      headerComponentParams:{
        filterValueColumnName: "finalWt",
        filterIdColumnName: "finalWt",
        isInputFilter: true,
        isAllowNumber:true
      }
    },
    {
      headerName: "Voucher Type",
      field: 'voucherTypeName',
      // lockPinned: true,
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      editable:false,
      resizable: true,
      suppressMoveIntoParents: true,
      minWidth: VoucherColWidth.MinWidth,
      maxWidth: VoucherColWidth.MaxWidth,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "voucherTypeId",
        filterIdColumnName: "voucherTypeName",
        filterParams: StorageName.VOUCHER_TYPE_DROPDOWN,
        floatingMultiselectDropDown: true,
      }
    },
    {
      headerName: "invoice No",
      field: 'invoiceNo',
      // lockPinned: true,
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      editable:false,
      resizable: true,
      suppressMoveIntoParents: true,
      minWidth: 150,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "invoiceNo",
        filterIdColumnName: "invoiceNo",
        filterParams: StorageName.VOUCHER_TYPE_DROPDOWN,
        floatingMultiselectDropDown: true,
      }
    },
    { 
      headerName: "Branch Name", 
      headerComponentParams: {enableMenu:false},
      sortable:true,
      headerClass: "px-0 custom-column-group-cell border-0 ",
      field: 'branchName',
      cellClass:'px-0 custom-column-group-cell ',
      suppressMovable: false,
      resizable: true, 
      cellRenderer: TextRendererComponent,
      cellRendererParams :{isCursor:false},
      editable:false, 
      minWidth: 180, flex:2,
    }

  ]
  export const AgGridAvailableStockTable: AgGridI = {
    colDefs: StockReportColumnDefForAvailableStockTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    columnSettings:true,
    storageName:StorageName.REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_RFID_STOCK_AVAILABLESTOCK_ITEM_GRID,
    singleClickEdit:true,
    rows:[],
    headerHeight : 70,
    rowHeight:28,
    apiUrl :  API_URL_GET_ALL_AVAILABLESTOCKRFIDBARCODE,
    isFilter: true,  
    isServerSidepagination : true,
  };
  
  
  
  const ScannedStockColumn: AgColDefsI = {
    headerName: "scannedStock",
    field: "scannedStock",
    resizable: false,
    sortable: true,
    marryChildren: true,
    headerGroupComponent: CustomHeaderParentComponent,
    headerClass: "px-2 d-block text-primary",
    children: [
    ]
    
  };
  
  const StockReportColumnDefForScannedStockTable :AgColDefsI[] = [
  //  ScannedStockColumn
  {
    headerName: "",
    field: 'active',
    headerClass: "custom-column-group-cell  px-0",
    cellClass: "custom-column-group-cell px-2",
    cellRendererParams: { showLabel: false}, 
    cellRenderer:CheckboxRendererComponent  ,
    sortable: false,
    resizable: true,
    editable:false,
    suppressMoveIntoParents: false,
    minWidth: 40,
    maxWidth: 40,
    lockPosition: "left",
    suppressMovable: false,
    lockPinned: true,
    pinned:'left',
  },
  {
    headerName: "Branch",
    field: 'branchName',
    // lockPinned: true,
    headerClass: "custom-column-group-cell  px-0",
    cellClass: "custom-column-group-cell px-2",
    sortable: true,
    editable:false,
    resizable: true,
    suppressMoveIntoParents: true,
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    flex: 1,
    cellRenderer: TextRendererComponent,
    headerComponentParams:{
      filterValueColumnName: "branchId",
      filterIdColumnName: "branchName",
      filterParams: StorageName.BRANCH_DROPDOWN,
      floatingMultiselectDropDown: true,
    }
  },
  
    {
      headerName: "Product Code",
      field: 'productName',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: 150,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "productId",
        filterIdColumnName: "productId",
        filterParams: StorageName.PRODUCT_DROPDOWN,
        floatingMultiselectDropDown: true,
      },
    },
    {
      headerName: "Location",
      field: 'locationName',
      headerClass: "custom-column-group-cell px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: 170,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "locationId",
        filterIdColumnName: "locationName",
        filterParams: StorageName.LOCATION_DROPDOWN,
        floatingMultiselectDropDown: true,
      
  }
},
    {
      headerName: "RFID Code",
      field: 'rfid',
      headerClass: "custom-column-group-cell  px-0 customHeaderClass",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: 150,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "rfid",
        filterIdColumnName: "rfid",
        isInputFilter: true,
      }
    },
    {
      headerName: "Barcode",
      field: 'barcodeNo',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: BarcodeColWidth.MinWidth,
      maxWidth: BarcodeColWidth.MaxWidth,
      flex: 1,
      cellRenderer: TextRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "barcodeno",
        filterIdColumnName: "barcodeno",
        isInputFilter: true,
      }
    },
    {
      headerName: "Qty",
      field: 'quantity',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      cellStyle: {'text-align': 'right'},
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: QuantityColWidth.MinWidth,
      maxWidth: QuantityColWidth.MaxWidth,
      flex: 1,
      cellRenderer: NumericCellRendererComponent,
      headerComponentParams:{
        filterValueColumnName: "quantity",
        filterIdColumnName: "quantity",
        isInputFilter: true,
        isAllowNumber:true
      }
    },
    {
      headerName: "Wt.",
      field: 'weight',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      cellStyle: {'text-align': 'right'},
      sortable: true,
      editable:false,
      resizable: true,
      suppressMoveIntoParents: true,
      minWidth: WeightColWidth.MinWidth,
      maxWidth:WeightColWidth.MaxWidth,
      flex: 1,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams : {isWeight:true},
      headerComponentParams:{
        filterValueColumnName: "weight",
        filterIdColumnName: "weight",
        isInputFilter: true,
        isAllowNumber:true
      }
    },
    {
      headerName: "Net Wt.",
      field: 'netWt',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      cellStyle: {'text-align': 'right'},
      sortable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: WeightColWidth.MinWidth,
      maxWidth:WeightColWidth.MaxWidth,
      resizable: true,
      flex: 1,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams : {isWeight:true},
      headerComponentParams:{
        filterValueColumnName: "netwt",
        filterIdColumnName: "netwt",
        isInputFilter: true,
        isAllowNumber:true
      }
    },
    {
      headerName: "Final Wt.",
      field: 'finalWt',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      cellStyle: {'text-align': 'right'},
      sortable: true,
      editable:false,
      resizable: true,
      suppressMoveIntoParents: true,
      minWidth: WeightColWidth.MinWidth,
      maxWidth:WeightColWidth.MaxWidth,
      flex: 1,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams : {isWeight:true},
      headerComponentParams:{
        filterValueColumnName: "finalWt",
        filterIdColumnName: "finalWt",
        isInputFilter: true,
        isAllowNumber:true
      }
    },
    {
      headerName: "Voucher Type",
      field: 'voucherTypeName',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      sortable: true,
      resizable: true,
      editable:false,
      suppressMoveIntoParents: true,
      minWidth: VoucherColWidth.MinWidth,
      maxWidth: VoucherColWidth.MaxWidth,
      flex: 1,
      headerComponentParams:{
        filterValueColumnName: "voucherTypeId",
        filterIdColumnName: "voucherTypeName",
        filterParams: StorageName.VOUCHER_TYPE_DROPDOWN,   
        floatingMultiselectDropDown: true,
      }
    },
    // {
    //   headerName: "Action",
    //   field: '',
    //   lockPinned: true,
    //   headerClass: "custom-column-group-cell ag_header_center  px-0",
    //   cellClass: "custom-column-group-cell text-center px-2",
    //   sortable: true,
    //   editable:false,
    //   resizable: true,
    //   suppressMoveIntoParents: true,
    //   minWidth: 150,
    //   flex: 1,
    //  cellRenderer: RfidIconComponent,
  
    // },
  ]
  export const AgGridScannedStockTable: AgGridI = {
    colDefs: StockReportColumnDefForScannedStockTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    columnSettings:true,
    singleClickEdit:true,
    storageName:StorageName.REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID,
    initialRows: StorageName.REPORT_STOCKREPORT_RFID_STOCK_SCANNEDSTOCK_ITEM_GRID_INITIAL_ROW,
    rows:[],
    headerHeight : 70,
    rowHeight:28,
    apiUrl :  API_URL_GET_ALL_SCANNEDRFIDBARCODE,
    isServerSidepagination : false,
    isFilter: true ,  
  };
  
  
  const RFIDCode: AgColDefsI = {
    headerName: "RFIDCode",
    field: "rfid",
    minWidth: 70,
    resizable: true,
    headerClass: "custom-column-group-cell px-0 customHeaderClass",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  };
  
  const Barcode: AgColDefsI = {
    headerName: "Barcode",
    field: "barcode",
    minWidth: BarcodeColWidth.MinWidth,
    maxWidth: BarcodeColWidth.MaxWidth,
    resizable: true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
  };
  const StockReportColumnDefForUnknownStockTable :AgColDefsI[] = [
    RFIDCode,
    Barcode
  ]
  export const AgGridUnknownStockTable: AgGridI = {
    colDefs: StockReportColumnDefForUnknownStockTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    storageName:StorageName.REPORT_STOCKREPORT_RFID_STOCK_UNKNOWNSTOCK_ITEM_GRID,
    columnSettings:true,
    singleClickEdit:true,
    rows:[]
  };

  // For Barcode Stock Ag Grid Table
  const viewColumn: AgColDefsI = {

      headerName: "",
      field: "viewHistory",
      maxWidth: 70,
      minWidth: 70,
      sortable: true,
      editable: false,
      resizable: false,
      headerComponentParams: { enableSorting: false},
      cellRenderer: AgGridButtonCellrendererComponent, 
      cellRendererParams: { view: true },
      cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
      headerClass: "custom-column-group-cell px-0",
  };
  const checkColumn: AgColDefsI = 
    {
      headerName: "",
      headerComponentParams: { selectAll: true },
      field: 'isSelected',
      headerClass: "custom-column-group-cell  px-0",
      cellClass:'px-2 custom-column-group-cell d-flex justify-content-center', 
      minWidth: 20,
      sortable: false,
      editable: false,
      maxWidth: 40,
      // lockPosition: "left",
      suppressMovable: false,
      resizable: false,
      lockPinned: true,
      pinned:'left',
      // cellRendererParams: { showLabel: false,isReadOnly:false,isCommonCheckbox:true }, 
      cellRenderer:CheckboxRendererComponent,
      cellRendererParams: (params) => {
        const isBarcodeNo = params?.data?.barcodeNo ? true : false;
        return { showLabel: false,isReadOnly:!isBarcodeNo,isCommonCheckbox:true };
      },
    };
    
  const barcodeColumn: AgColDefsI = 
    {
      headerName: "Barcode",
      field: 'barcodeNo',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: BarcodeColWidth.MinWidth,
      maxWidth: BarcodeColWidth.MaxWidth,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
      // headerComponentParams: { 
      //   enableSorting: false,
      //   filterValueColumnName: "barcodeno",
      //   filterIdColumnName: "barcodeno",
      //   isInputFilter: true,
      // },
    };
    const itemCodeColumn: AgColDefsI = 
    {
      headerName: "Item Code",
      field: 'itemCode',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: 70,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
      // headerComponentParams: { 
      //   enableSorting: false,
      //   filterValueColumnName: "itemCode",
      //   filterIdColumnName: "itemCode",
      //   isInputFilter: true,
      // },
    };
  const branchColumn: AgColDefsI = 

    {
      headerName: "Branch",
      field: 'branchName',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: NameColWidth.MinWidth,
      maxWidth: NameColWidth.MaxWidth,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
      // headerComponentParams:{
      //   filterValueColumnName: "branchId",
      //   filterIdColumnName: "branchName",
      //   filterParams: StorageName.BRANCH_DROPDOWN,
      //   floatingMultiselectDropDown: true,
      // }
    };

  const caretColumn: AgColDefsI = 

    {
      headerName: "Carat",
      field: 'carat',
      headerClass: "custom-column-group-cell ag_header_end px-0",
      cellClass: "custom-column-group-cell text-end px-2",
      minWidth: WeightColWidth.MinWidth,
      maxWidth: WeightColWidth.MaxWidth, 
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
      // headerComponentParams:{
      //   filterValueColumnName: "carat",
      //   filterIdColumnName: "carat",
      //   filterParams: StorageName.CARAT_DROPDOWN,
      //   floatingMultiselectDropDown: true,
      // }
    };
  const actionColumn: AgColDefsI = 

      {
        headerName: "",
        field: 'action',
        headerClass: "custom-column-group-cell  px-0",
        cellClass:'px-2 custom-column-group-cell d-flex justify-content-center align-items-center gap-2', 
        minWidth: 100,
        maxWidth:100,
        resizable: false,
        sortable: false,
        editable: false,
        lockPosition: "right",
        suppressMovable: false,
        lockPinned: true,
        pinned:'right',
       cellRenderer: EditDeleteIconComponent,
       cellRendererParams :{BCL:true , RFID:false, isDeleteIcon:false},

    };
  const productColumn: AgColDefsI = 
     
    {
      headerName: "Product",
      field: 'productName',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: NameColWidth.MinWidth,
      maxWidth: NameColWidth.MaxWidth,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
      // headerComponentParams:{
      //   filterValueColumnName: "productId",
      //   filterIdColumnName: "productName",
      //   filterParams: StorageName.PRODUCT_DROPDOWN,
      //   floatingMultiselectDropDown: true,
      // }
    };
    const LocationColumn: AgColDefsI = 
     
    {
      headerName: "Location",
      field: 'locationName',
      headerClass: "custom-column-group-cell px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: 170,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
      // headerComponentParams:{
      //   filterValueColumnName: "locationId",
      // filterIdColumnName: "locationName",
      //   filterParams: StorageName.LOCATION_DROPDOWN,
      //   floatingMultiselectDropDown: true,
      // }
    };
  const rfidcodeColumn: AgColDefsI = 

    {
      headerName: "RFID",
      field: 'rfid',
      headerClass: "custom-column-group-cell  px-0 ",
      cellClass: "custom-column-group-cell px-2",
      minWidth: 70,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
      // headerComponentParams:{
      //   filterValueColumnName: "rfid",
      //   filterIdColumnName: "rfid",
      //   isInputFilter: true,
      // }
    };
  const metalColumn: AgColDefsI = 

    {
      headerName: "Metal",
      field: 'metalName',
      headerClass: "custom-column-group-cell  px-0",
      cellClass: "custom-column-group-cell px-2",
      minWidth: MetalColWidth.MinWidth,
      maxWidth: MetalColWidth.MaxWidth,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer:TextRendererComponent,
    //  headerComponentParams:{
    //   filterValueColumnName: "metalId",
    //   filterIdColumnName: "metalId",
    //   filterParams: StorageName.METAL_DROPDOWN,
    //   floatingMultiselectDropDown: true,
    // },
    };
  const wtColumn: AgColDefsI = 

    {
      headerName: "Wt",
      field: 'weight',
      headerClass: "custom-column-group-cell ag_header_end px-0",
      cellClass: "custom-column-group-cell text-end px-2",
      cellStyle: {'text-align': 'right'},
      minWidth: WeightColWidth.MinWidth,
      maxWidth:WeightColWidth.MaxWidth,
      resizable: true,
      sortable: true,
      editable: false,
      cellRenderer: NumericCellRendererComponent,
      cellRendererParams : {isWeight:true},
      // headerComponentParams:{
      //   filterValueColumnName: "weight",
      //   filterIdColumnName: "weight",
      //   isInputFilter: true, 
      // }
    };
  
const VoucherType: AgColDefsI =
{
  headerName: "Voucher Type",
  field: "voucherTypeName",
  minWidth: VoucherColWidth.MinWidth,
  resizable: true,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell",
  editable: false,
  sortable: true,
  cellRenderer: TextRendererComponent,
};

  const StockReportColumnDefForBarcodeStockTable :AgColDefsI[] = [
    checkColumn,
    ImageColumn,
    viewColumn,
    barcodeColumn,
    itemCodeColumn,
    rfidcodeColumn,
    metalColumn,
    productColumn,
    LocationColumn,
    // GroupNameColumn,
    IconColumn,
   // branchColumn,
    wtColumn,
    caretColumn,
    actionColumn, 
    BranchName,
    VoucherType,
    InvoiceNoColumn
    ]
  export const AgGridBarcodeStockTable: AgGridI = {
    colDefs: StockReportColumnDefForBarcodeStockTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    columnSettings:true,
    storageName:StorageName.REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_BARCODE_STOCK_GRID,
    singleClickEdit:true,
    headerHeight : 28,
    rowHeight:28,
    isFilter: true,  
    apiUrl : API_URL_GETALLBARCODESTOCK,
    isServerSidepagination : true,
    showPagination: true,
    selectAll:true,
  };

  const BarcodeSaleinvoiceTable:AgColDefsI[] = [
    barcodeColumn,
    rfidcodeColumn,
    metalColumn,
    productColumn,
    Locationcolumn,
    // GroupNamePopupColumn,
    // GroupNameColumn,
    branchColumn,
    wtColumn,
    caretColumn,
  ]

  export const AgGriBarcodePopupGrid: AgGridI = {
    colDefs: BarcodeSaleinvoiceTable,
    rowSelection: "multiple",
    addBlankRowOnEnter:true,
    columnSettings:true,
    storageName:StorageName.REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_POPUP_GRID,
    filterStorageName: StorageName.FILTER_REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID,
    sortStorageName: StorageName.SORT_REPORT_STOCKREPORT_BARCODE_STOCK_ITEM_GRID,
    paginationStorageName : StorageName.PAGINATION_REPORT_STOCKREPORT_BARCODE_STOCK_GRID,
    singleClickEdit:true,
    headerHeight : 28,
    rowHeight:28,
    isFilter: true,  
    apiUrl : API_URL_GETALLBARCODESTOCK,
    isServerSidepagination : true,
    showPagination: true
  };


  function getCellStyleForColumnB(params) {
    // params.value contains the current cell value
    // You can add any condition based on the cell value to determine the style
  
    if (params.value > 0) {
      // If the value is greater than 0, set background color to green
      return { backgroundColor: 'green' };
    } else if (params.value < 0) {
      // If the value is less than 0, set background color to red
      return { backgroundColor: 'red' };
    } else {
      // Default background color for other cases
      return null;
    }

  }