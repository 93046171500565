import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { catchError } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { NgForm } from '@angular/forms';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import { ToastrService } from 'ngx-toastr';
import { SendDataService } from '../../services/send-data.service';
import { GetAllUnitConversionResponseModel } from '../../../components/administration/administration.model';

@Component({
  selector: 'app-unit-conversion',
  templateUrl: './unit-conversion.component.html',
  styleUrls: ['./unit-conversion.component.scss']
})
export class UnitConversionComponent extends CommonService implements OnInit{
  @ViewChild('administrationFormData') administrationFormData! : NgForm ;

  getAllUnitData               : any[]=[];
  getAllUnitConversionData     : any[]=[];
  copyGetAllUnitConversionData : any[]=[];
  editUnitConversionData = new GetAllUnitConversionResponseModel();
  isAdd                  : boolean = false;
 
  constructor(
    public renderer:Renderer2,
    public administrationService : AdministrationService,
    public toaster: ToastrService,
    public sendDataService : SendDataService,
  ) 
  {
    super(renderer)
  }

  ngOnInit(): void {
    this.getAllUnitConversionList();
  }


  add(){
  if(!this.sendDataService?.permissionVM?.mastersCanAdd) return;
    this.isAdd = true;
    const obj = {id:0, name:'',active:true,unitId:null,conversionRate:null,quantities:null}
    this.getAllUnitConversionData?.push(obj);
    this.copyGetAllUnitConversionData?.push(obj);
    setTimeout(()=>{
      const element = document.getElementById(`unitConversionName${obj.id}`);
      element?.focus();
    },0)
  }


  cancel(){
    if(this.isAdd)
    {
      this.isAdd = false;
      this.getAllUnitConversionData = this.getAllUnitConversionData.filter((x)=>x.id != 0);
      this.copyGetAllUnitConversionData = this.copyGetAllUnitConversionData.filter((x)=>x.id != 0)
    }
    else if(this.editUnitConversionData?.id && this.editUnitConversionData?.isEdit){
      const index = this.copyGetAllUnitConversionData.findIndex((x)=>x.id == this.editUnitConversionData?.id);
      const singleData =JSON.parse(JSON.stringify(this.copyGetAllUnitConversionData))[index];
      this.getAllUnitConversionData[index] = singleData;
      this.editUnitConversionData = new GetAllUnitConversionResponseModel();
    }  
  }


  edit(item,formData){
  if(!this.sendDataService?.permissionVM?.mastersCanUpdate) return;
    item.isEdit = true;
    const controlObj = formData?.form?.controls;
    if(controlObj){
      const id = Object.keys(controlObj)[0];
      const element = document.getElementById(id);
      element?.focus();
    }
     this.editUnitConversionData = {...item};
  }

  checkIsEdit(arr:any[]):boolean{
    return arr?.some((x)=>x.isEdit)
  }
  
    // *********** UNIT Conversion API START *********
    // **********get all unit Conversion ********* 
    getAllUnitConversionList() {
      this.administrationService.getAllUnitConversion().subscribe({
        next:(res)=>{
          if(res.isSuccess){
            this.getAllUnitConversionData = res.response??[];
            this.editUnitConversionData = new GetAllUnitConversionResponseModel();
            this.copyGetAllUnitConversionData = JSON.parse(JSON.stringify(this.getAllUnitConversionData))??[];
          }else{           
            this.getAllUnitConversionData = [];
            this.copyGetAllUnitConversionData = [];
          }  
        },
        error:(err)=>{
          this.getAllUnitConversionData = []; 
          this.copyGetAllUnitConversionData = [];
        }
      })
    }
    
   // *************insert unit Conversion **************
   InserUnitConversionList(item) {
      const {id , ...rest} = item;
      this.administrationService.insertUnitConversion(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllUnitConversionList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      }
  
    // **********update unit Conversion *********
    UpdateUnitConversionList(item) {
      const {isEdit , ...rest} = item;
      this.administrationService.updateUnitConversion(rest)
          .pipe(catchError((err) => this.handleError(err)))
          .subscribe((res) => {
            if(res.isSuccess){
              this.isAdd = false;
              this.getAllUnitConversionList();
              this.toaster.success(res.message);
            }else{
              this.toaster.error(res.errors[0]);
            }  
        });
      }  
  
      // **********delete unit Conversion *********
      DeleteUnitConversionList(id) {
        if(!this.sendDataService?.permissionVM?.mastersCanDelete) return;
        this.administrationService.deleteUnitConversion(id)
            .pipe(catchError((err) => this.handleError(err)))
            .subscribe((res) => {
              if(res.isSuccess){
                this.getAllUnitConversionList();
                this.toaster.success(res.message);
              }else{
                this.toaster.error(res.errors[0]);
              }  
        });
      }
    
    // *********** UNIT Conversion API END *********

}

