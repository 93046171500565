import { Injectable } from "@angular/core";
import { ICellRendererParams } from "ag-grid-community";
import { BehaviorSubject, Observable, Subject, catchError, tap, throwError } from "rxjs";
import { DataService } from "../../services/data.service";
import { ResponseModel } from "../../models/response-model.model";
import { Filters, Operations, UDFField, Updategridcolumndefinition, defaultPageLimit } from "./ag-grid.model";
import { TextRendererComponent } from "./text-renderer/text-renderer.component";
import { StorageService } from "../../services/storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalPopupSize } from "../../models/common.model";
import { MakingChargeType, PaymentMethod, StorageName } from "../../enum/common-enum";
import { NotificationService } from "../../services/notification.service";
@Injectable({
  providedIn: "root",
})
export class AgGridService {
  public gridColumnApi = new BehaviorSubject(undefined);
  public gridApi = new BehaviorSubject(undefined);
  public preview = new BehaviorSubject(undefined);
  selectionChanged = new Subject<{ data: any }>();
  public nextRowAdded = new BehaviorSubject(undefined);

  public hiddenColumnsdisableSubject = new Subject<any>();
  hiddenColumns$ = this.hiddenColumnsdisableSubject.asObservable();

  public onNumericClickedSource = new Subject<any>();
  onNumberClicked$ = this.onNumericClickedSource.asObservable();

  public onRadioButtonSource = new Subject<any>();
  onRadioButtonChange$ = this.onRadioButtonSource.asObservable();

  public selectedDateSource = new Subject<any>();
  selectedDateChange$ = this.selectedDateSource.asObservable();
  cellRenderName:any;
  constructor(public notificationService: NotificationService,public service: DataService,public storageService: StorageService,private modalService: NgbModal,) {}
  public addRowSource = new Subject<any>();
  addRowSourceChange$ = this.addRowSource.asObservable();
  cameraButtonDisabled:boolean=false;
  public numericValue = new BehaviorSubject(undefined);



  getGridApi() {
    return this.gridApi.getValue();
  }

  setGridApi(gridApi) {
    this.gridApi.next(gridApi);
  }

  getGridColumnApi() {
    return this.gridColumnApi.getValue();
  }

  setGridColumnApi(gridColumnApi) {
    this.gridColumnApi.next(gridColumnApi);
  }
  
  triggerOnNumberClick(data: any) {
    this.onNumericClickedSource.next(data);
  }
  
  onChangeRadioSelected(data: any) {
    this.onRadioButtonSource.next(data);
  }

  onChangeSelectedDates(selectedDates, data: ICellRendererParams) {
    this.selectedDateSource.next({ selectedDates: selectedDates, data: data });
  }

  addRowInGrid(parmas:any){
    this.addRowSource.next(parmas)
  }

    //#region get enum keys by its value 
    getEnumKeyByValue(enumObj: any, value: string): string | undefined {
      return Object.keys(enumObj)?.find(key => enumObj[key] === value);
    }
    //#endregion get enum keys by its value 


  getDataFromVoucherType(voucherTypeName:string){
    let url = `api/app/taxsetting/gettaxdetailsandmetalsbyidvouchertypename?VoucherTypeName=${voucherTypeName}`;
    return this.service.get(url, true,undefined,false).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  generateObjectWithDefaultValues(columnDefinations)  {
    const defaultObject: any = {};
    // Iterate through column definitions
    if (columnDefinations) {
      columnDefinations?.forEach((columnDef) => {
        defaultObject[columnDef.field] = undefined;
        // var indexForMakingType = columnDef?.children?.findIndex(child=>child?.field == 'makingTypeId');
        // if(indexForMakingType != -1 || indexForMakingType != undefined){
        //   defaultObject[columnDef.children[indexForMakingType].field] = 2;
        // }
        defaultObject["added"] = true;
        defaultObject["edited"] = false;
        defaultObject["deleted"] = false;
        if (columnDef?.field == "quantity") {
          defaultObject[columnDef.field] = MakingChargeType.FIX;
        }
        if(columnDef.field == "making")
        {
          if(columnDef.children.field = "makingTypeId"){
            defaultObject[columnDef.children[0].field] = MakingChargeType.FIX;
          }
        }
        // if (columnDef.field == "making") {
        //     if(columnDef.children.field = "makingTypeId"){
        //       defaultObject[columnDef.children.field] = 2;
        //     }
        // } 
        if(columnDef?.field == "ratePer"){
          defaultObject[columnDef.field] = 100;
        }
      });
    }
    return defaultObject;
  }

  //#region add rows

  addDefaultRow(gridApi){
    if(gridApi != undefined){
        var existingRow = gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
        if(existingRow?.length == 0)
        {
          var defaultAddObject = this?.generateObjectWithDefaultValues(gridApi.columnApi.columnModel.columnDefs)
          gridApi.api.setRowData([]);
          gridApi.api.setRowData([defaultAddObject]);
        }
      }
  }

  appendNewRow(gridApi, data = undefined,setforcus = true){
    if(gridApi != undefined){
      if(data == undefined)
      data = {
        quantity : 1,
        added : true,
        edited : false,
        deleted : false,
        purityPer : '1.0',
        makingTypeId : MakingChargeType.FIX
      }
      if(gridApi.storageName == StorageName.MANUFACTURER_JOBWORK_QUEUE_PAYMENT_LIST_GRID)
          data.paymentMethodId = PaymentMethod.MetalExchange;
      var defaultAddObject = data;
      gridApi.api.applyTransaction({ add: [defaultAddObject] });
      // this.addRowInGrid(gridApi)

      let lastrow = gridApi.api.getDisplayedRowAtIndex(gridApi.api.getLastDisplayedRow());
      // scrolls to the first column, or first editable column. you can pass in the correct index
      var firstEditCol = gridApi.columnApi.getAllDisplayedColumns()[0];
      /////
      gridApi.api.ensureColumnVisible(firstEditCol );

      if(setforcus == true)
      {
        // sets focus into the first grid cell
        gridApi.api.setFocusedCell(lastrow.rowIndex, firstEditCol); 

        gridApi.api.startEditingCell({
          rowIndex: lastrow.rowIndex,
          colKey:firstEditCol.colId
        });
      }
      //  var checkLastRowNode = lastrow;
    }
  }

  focusCell(gridApi, index, col, data) {
    if (data) {
      var node = gridApi?.api?.getRowNode(index);
      if (node) {
        node.data[col] = data?.toString();
        gridApi?.api?.refreshCells();
      }
    }
  }

  getNodeByRowIndex(rowIndexToFind: number, gridApi): any {
    let foundNode = null;
  
    // Iterate through all nodes
    gridApi.api.forEachNode(node => {
      // Get the value of the 'rowIndex' column (replace 'rowIndex' with your actual column field)
      const rowIndexValue = node.data.rowIndex; // Replace 'rowIndex' with your actual column field
  
      // Check if the value matches the specified rowIndexToFind
      if (rowIndexValue === rowIndexToFind) {
        foundNode = node;
      }
    });
  
    return foundNode;
  }

  appendRow(gridApi,dataToAppend,columnToFocus = ""){
    if(gridApi != undefined){
      if (gridApi.api.getModel().getRowCount() == 0) {
        gridApi.api.applyTransaction({ add: [dataToAppend] });
      }
      else
      {
        var firstRowNode = gridApi.api.getRowNode(0);
        firstRowNode.data.purityPer = "1.0";
        if(columnToFocus == "")
        {
        firstRowNode.data.grossWt = dataToAppend.grossWt;
          firstRowNode.data.caratId = dataToAppend.goldCaratId;
        }
        else
        {
        firstRowNode.data.goldAmount = dataToAppend.goldAmount;
        }
        gridApi.api.refreshCells({ rowNodes: [firstRowNode], force: true });
      }

      let lastrow = gridApi.api.getDisplayedRowAtIndex(gridApi.api.getLastDisplayedRow());
      // scrolls to the first column, or first editable column. you can pass in the correct index
      var firstEditCol  : any = {};
      if(columnToFocus == "")
      {
      firstEditCol = gridApi.columnApi.getAllDisplayedColumns()[0];
      }
      else
      {
        var indexOfColumn = gridApi.columnApi.getAllDisplayedColumns().findIndex(a=>a.colId == columnToFocus);
        firstEditCol = gridApi.columnApi.getAllDisplayedColumns()[indexOfColumn + 1];
      }
      /////
      gridApi.api.ensureColumnVisible(firstEditCol );

      // sets focus into the first grid cell
      gridApi.api.setFocusedCell(lastrow.rowIndex, firstEditCol); 
      

      gridApi.api.startEditingCell({
        rowIndex: lastrow.rowIndex,
        colKey:firstEditCol.colId
      });
   
      //  var checkLastRowNode = lastrow;
    }
  }

  

  setFocusToLastRow(gridApi){
    if(gridApi != undefined){
      var existingRow = gridApi?.api?.getModel()?.rowsToDisplay?.map(a => a.data);
      if(existingRow?.length == 0)
      {
        var defaultAddObject = this.generateObjectWithDefaultValues(gridApi.columnApi.columnModel.columnDefs);
        defaultAddObject.added=true;
        gridApi.api.applyTransaction({ add: [defaultAddObject] });
      }
     
      let lastrow = gridApi.api.getDisplayedRowAtIndex(gridApi.api.getLastDisplayedRow());
      // scrolls to the first column, or first editable column. you can pass in the correct index
var firstEditCol = gridApi.columnApi.getAllDisplayedColumns()[0];
/////
gridApi.api.ensureColumnVisible(firstEditCol );

// sets focus into the first grid cell
gridApi.api.setFocusedCell(lastrow.rowIndex, firstEditCol); 

gridApi.api.startEditingCell({
  rowIndex: lastrow.rowIndex,
  colKey:firstEditCol.colId
});
    //  var checkLastRowNode = lastrow;
    }
  }

  addNewRowWithData(gridApi,data){
    if(gridApi != undefined){
      data.added  = true;
      data.edited = false;
      gridApi.api.applyTransaction({ add: [data] });
    //  gridApi.api.setFocusedCell(gridApi.rowIndex, firstEditCol);  
    }
  }

  addNewRowsWithData(gridApi,data, selected = false){
    if(gridApi != undefined){
      data?.forEach(element => {
        element.added  = true;
        element.edited = false;
        element.active = selected;
      });
    
      gridApi.api.applyTransaction({ add: data });
    //  gridApi.api.setFocusedCell(gridApi.rowIndex, firstEditCol);  
    }
  }

  addUpdateRowsWithData(gridApi, data, selected = false) {
    if (gridApi != undefined) {
      data.forEach(element => {
        element.added = data[0]?.added;
        element.edited = data[0]?.edited;
        element.active = selected;
        element.id =data[0]?.id;
        if (element?.edited) {
          gridApi?.api?.applyTransaction({ remove: [element] });
          gridApi?.api?.refreshCells();
        }else{
          gridApi?.api?.applyTransaction({ add: [element] });
        }
      });
    }
  }

  addUpdateData(gridApi, data, selected = false) {
    if (gridApi) {
      data.forEach(element => {
        element.added = data[0]?.added;
        element.edited = data[0]?.edited;
        element.active = selected;
        element.id = data[0]?.id;
        if (element?.edited) {
          gridApi.api.applyTransaction({ update: [element] });
        } else {
          gridApi.api.applyTransaction({ add: [element] });
        }
      });
    }
  }
  
  
  removeNewRowWithData(gridApi,data){
    if(gridApi != undefined){
      data.added  = true;
      data.edited = false;
      gridApi.api.applyTransaction({ remove: [data] });
    //  gridApi.api.setFocusedCell(gridApi.rowIndex, firstEditCol);  
    }
  }

  performFilterColumnwiseForMultiGrid(params,apiUrl,tableIndex,valueColumnName,filterData,textInput,sortStorageName,filterStorageName,paginationStorageName)
  {    
    // for decimal conversion
    let previousFilterList = this.storageService.retrieve(filterStorageName);
     // for decimal conversion
    var filterList = [];
    let index =  previousFilterList?.findIndex(a=>a.name == valueColumnName);
      if(previousFilterList!=undefined)
      {
          filterList = previousFilterList;
          
      }
      if(index != undefined && index != -1){
        filterList[index].type = undefined;
        filterList[index].textFilter = textInput;
        filterList[index].FilterData = filterData?.length == 0 ? undefined :  filterData;
      }
      else
      {
        if(filterData?.length == 0){
          filterData = undefined;
        }
        filterList.push({ type: undefined,  name:valueColumnName, textFilter:textInput , FilterData : filterData });
      }
   //   this.inputValue = textInput;
      this.storageService.store(filterStorageName,filterList);

      this.onSortAndFilter(params,apiUrl, sortStorageName,filterStorageName,paginationStorageName,tableIndex)

  }

  updategridcolumndefinition(updategridcolumndefinition:Updategridcolumndefinition){
    let url = 'api/app/gridcolumndefinition/insertupdategridcolumndefinition';
    var json = JSON.stringify(updategridcolumndefinition);
    
    return this.service.post(url,updategridcolumndefinition,false , null , false ).pipe<ResponseModel>(tap((response: any) => {
    return response;
      })
    );
  }


  addRow(gridApi,data){
    if(gridApi != undefined){
      gridApi.api.setRowData([data]);
    }
  }

  addRows(gridApi, rows){
    if(gridApi != undefined){
      gridApi.api.setRowData(rows);
    }
  }
  //#endregion
  async updateColumnNames(dataModel, gridApi) {
    if(gridApi != undefined)
    {
    const savedColumnState = gridApi.columnApi.getColumnState();
    var columnDefs = this.processSavedColumnState(savedColumnState,dataModel);
    if (gridApi != undefined) gridApi.api.setColumnDefs([...columnDefs]);
    }
  }

  //#region update ColumnDefinations
  async updateColumnDefinations(dataModel, gridApi , metalTabId?, UDFField?) {
    if(gridApi != undefined)
    {
    const savedColumnState = gridApi.columnApi.getColumnState();
    var columnDefs = this.processSavedColumnState(savedColumnState,dataModel,metalTabId,UDFField);

    // var newColumns: any[] = [];
    // var colDefs: any[] = [];
    // var columnStates: any[] = [];

    // voucherWiseData?.applicableOnProducts?.forEach((tax) =>
    //   newColumns.push({
    //     headerName: tax.name,
    //     field: tax.name,
    //     cellRenderer: TextRendererComponent,
    //     minWidth: 110,
    //     sortable: true,
    //     editable:false,
    //     cellClass: "custom-column-group-cell px-0 text-center",
    //     headerClass: "custom-column-group-cell px-0",
    //     cellEditor: AgGridCustomInputComponent,
    //     cellEditorParams: {
    //       decimalPlaces: 2,
    //       isOnlyNumbers: true,
    //     },
    //   })
    // );


    // voucherWiseData?.applicableOnProducts?.forEach((tax) => {
    //   columnStates.push({
    //     aggFunc: null,
    //     colId:
    //     tax.name,
    //     flex:
    //     1,
    //     hide:
    //     true,
    //     pinned:
    //     null,
    //     pivot:
    //     false,
    //     pivotIndex:
    //     null,
    //     rowGroup:
    //     false,
    //     rowGroupIndex:
    //     null,
    //     sort:
    //     null,
    //     sortIndex:
    //     null,
    //     width:
    //     200
    //   });
    // });

    // var newColumnObject: AgColDefsI = {
    //   headerName: "Tax",
    //   field: "tax",
    //   marryChildren: true,
    //   sortable: true,
    //   headerComponentParams: { isRequired: false },
    //   headerClass: "custom-column-group-cell justify-content-center px-0",
    //   children: [...newColumns],
    // };

    // var newArrayOfColDefs = [];
    // const indexOfObject = columnDefs.findIndex((object) => {
    //   return object.headerName === "Tax";
    // });

    // if (indexOfObject !== -1) 
    //   newArrayOfColDefs =  columnDefs.splice(indexOfObject, 1);

    //   columnDefs.push(newColumnObject);
 
     if (gridApi != undefined) gridApi.api.setColumnDefs([...columnDefs]);
  }
    
  }


  processSavedColumnState(savedState: any[],dataModel ,metalTabId?,UDFFieldColumns?): any[] {
    return savedState
    .map(savedColumn => this.findOriginalColumnDef(savedColumn, dataModel,savedState ,metalTabId,UDFFieldColumns))
    .filter(originalColumn => Object.keys(originalColumn).length > 0);
  }

  findOriginalColumnDef(savedColumn: any,dataModel, savedState , metalTabId,UDFFieldColumns): any {
    var originalColumnDef = dataModel.colDefs.find(colDef => colDef.field === savedColumn.colId) || undefined;

    if(savedColumn.colId == "makingTypeId")
    {
          originalColumnDef = dataModel.colDefs?.filter(a=>a.field == "making")[0];
          originalColumnDef.children.forEach(element => {
            var checkVisibility = savedState.filter(a=>a.colId ==element.field)[0];
            element.hide = checkVisibility.hide
          });
    }
  
    // if (originalColumnDef.children) {
    //   // If the original column has children, recursively process them
    //   originalColumnDef.children = originalColumnDef.children.map(child => this.findOriginalColumnDef(child,dataModel));
    // }
    if (originalColumnDef == undefined) {
      // If originalColumnDef is undefined, skip processing
      return {};
    }

// if(dataModel?.colDefs[0]?.storageName.indexOf("DIAMOND") != -1 || dataModel?.colDefs[0]?.storageName.indexOf("STONE") != -1)
  
    if (metalTabId) {
      const metalIdWiseUdf = UDFFieldColumns;
      const udfFieldStatus = '0';
      switch (savedColumn.colId) {
        case UDFField.UDF1:
          const udf1 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF1));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf1?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf1?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf1?.name;
          break;

        case UDFField.UDF2:
          const udf2 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF2));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf2?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf2?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf2?.name;
          break;

        case UDFField.UDF3:
          const udf3 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF3));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf3?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf3?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf3?.name;
          break;

        case UDFField.UDF4:
          const udf4 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF4));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf4?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf4?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf4?.name;
          break;

        case UDFField.UDF5:
          const udf5 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF5));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf5?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf5?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf5?.name;
          break;

        case UDFField.UDF6:
          const udf6 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF6));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf6?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf6?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf6?.name;
          break;

        case UDFField.UDF7:
          const udf7 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF7));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf7?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf7?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf7?.name;
          break;

        case UDFField.UDF8:
          const udf8 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF8));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf8?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf8?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf8?.name;
          break;

        case UDFField.UDF9:
          const udf9 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF9));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf9?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf9?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf9?.name;
          break;

        case UDFField.UDF10:
          const udf10 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF10));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf10?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf10?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf10?.name;
          break;

        case UDFField.UDF11:
          const udf11 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF11));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf11?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf11?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf11?.name;
          break;

        case UDFField.UDF12:
          const udf12 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF12));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf12?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf12?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf12?.name;
          break;

        case UDFField.UDF13:
          const udf13 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF13));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf13?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf13?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf13?.name;
          break;

        case UDFField.UDF14:
          const udf14 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF14));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf14?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf14?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf14?.name;
          break;

        case UDFField.UDF15:
          const udf15 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF15));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf15?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf15?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf15?.name;
          break;

        case UDFField.UDF16:
          const udf16 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF16));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf16?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf16?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf16?.name;
          break;

        case UDFField.UDF17:
          const udf17 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF17));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf17?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf17?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf17?.name;
          break;

        case UDFField.UDF18:
          const udf18 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF18));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf18?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf18?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf18?.name;
          break;

        case UDFField.UDF19:
          const udf19 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF19));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf19?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf19?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf19?.name;
          break;

        case UDFField.UDF20:
          const udf20 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF20));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf20?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf20?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf20?.name;
          break;

        case UDFField.UDF21:
          const udf21 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF21));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf21?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf21?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf21?.name;
          break;

        case UDFField.UDF22:
          const udf22 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF22));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf22?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf22?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf22?.name;
          break;

        case UDFField.UDF23:
          const udf23 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF23));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf23?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf23?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf23?.name;
          break;

        case UDFField.UDF24:
          const udf24 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF24));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf24?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf24?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf24?.name;
          break;

        case UDFField.UDF25:
          const udf25 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF25));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf25?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf25?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf25?.name;
          break;

        case UDFField.UDF26:
          const udf26 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF26));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf26?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf26?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf26?.name;
          break;

        case UDFField.UDF27:
          const udf27 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF27));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf27?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf27?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf27?.name;
          break;

        case UDFField.UDF28:
          const udf28 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF28));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf28?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf28?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf28?.name;
          break;

        case UDFField.UDF29:
          const udf29 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF29));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf29?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf29?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf29?.name;
          break;

        case UDFField.UDF30:
          const udf30 = metalIdWiseUdf?.find(a => a.id == this.getEnumKeyByValue(UDFField, UDFField.UDF30));
          savedColumn.hide = (savedColumn?.hide) ? savedColumn?.hide : udf30?.extra1 == udfFieldStatus ? true : false;
          originalColumnDef.hide = savedColumn.hide;
          originalColumnDef.isVisibleOnColumnSetting = !(udf30?.extra1 == udfFieldStatus ? true : false);
          originalColumnDef.headerName = udf30?.name;
          break;
      }
    }

    return {
      ...originalColumnDef,
      visible: savedColumn.visible,

aggFunc:
savedColumn.aggFunc,
flex:
savedColumn.flex,
hide:
savedColumn.hide,
pinned:
savedColumn.pinned,
pivot:
savedColumn.pivot,
pivotIndex:
savedColumn.pivotIndex,
rowGroup:
savedColumn.rowGroup,
rowGroupIndex:
savedColumn.rowGroupIndex,
sort:
savedColumn.sort,
sortIndex:
savedColumn.sortIndex,
width:
savedColumn.width,
      // Include other properties as needed
    };
  }

  handleError(err: any): any {
    throw new Error("Method not implemented.");
  }

  getIndexOfColState(columnStates,taxName) : any
  {
    columnStates.findIndex((object) => {
      return object.colId === taxName;
    });
  }
  //#endregion

  updateColumnValues(params:any,values:any=[],fieldName:string=''){
    const column = params?.columnApi?.getColumn(fieldName);
    if (column) {
      column.getColDef().cellEditorParams = {
        values: () => {
                   return values
                  }
      };
      column.getColDef().headerName = "Manthan"
      params.columnApi.setColumnState(params?.columnApi?.getColumnState());
    }
  }


  onSortAndFilter(params : any,apiUrl : string,storageNameSorting, storageNameFilter,storageNamePagination,afterDelete:boolean = false,tableIndex : number = 0) {
    apiUrl = apiUrl.replace(/\s/g, '');
    let filters = new Filters();
    var data = this.storageService.retrieve(storageNameFilter);
    if (data != undefined && data != null)
      data.forEach(element => {
        if (element.textFilter != null)
          filters = this.modifyFilter(filters, element.textFilter.toString(), element.name, "cn", false);
        if (element.FilterData != null) {
          const selectedIds = element.FilterData;
          var filterIds = selectedIds.join(',');
          filters = this.modifyFilter(filters, filterIds, element.name, "eq", false);
        }
      });

    const seletedIdMetalIdForFilter =  this.storageService.selectedMetalIdForFilter?.getValue();
    if(seletedIdMetalIdForFilter?.length){
      seletedIdMetalIdForFilter.forEach((filterItem)=>{
        filters.rules.push(filterItem)
      })
    }

    //  this.storageService.store(storageNameFilter, filters);

      var returnData = this.setPaginationAndSort(params,storageNameSorting, filters,storageNamePagination);
      returnData = {...returnData , ...params?.api?.appliedRequestModelData};
      this.storageService.filterData.next({params,returnData});
      if(storageNamePagination?.indexOf("TRANSFER")!= -1)
      {      
        this.storageService.sortAndFilter.next(returnData);
      }
      else
      {
        this.storageService.onFilterData.next(returnData)
        this.getDataSource(apiUrl,returnData).pipe(catchError((err) => this.handleError(err))).subscribe((res : any) => {
          if (res) {
            var allRecords = res?.response?.data.filter(a=>a?.isSumRow != true);
              params?.api?.setRowData(allRecords);
              this.storageService?.onFilter.next({data :  res.response.data , api : params?.api})
          } else {
            
          }
     });
    }
      
  }

  onSortAndFilterForMultiGrid(params : any,apiUrl : string,sortlist, data,pagination,afterDelete:boolean = false,tableIndex : number = 0) {
    let filters = new Filters();
    if (data != undefined && data != null)
      data.forEach(element => {
        if (element.textFilter != null)
          filters = this.modifyFilter(filters, element.textFilter.toString(), element.name, "cn", false);
        if (element.FilterData != null) {
          const selectedIds = element.FilterData;
          var filterIds = selectedIds.join(',');
          filters = this.modifyFilter(filters, filterIds, element.name, "eq", false);
        }
      });
      var returnData = this.setPaginationAndSortForMultiGrid(sortlist, filters,pagination);
      this.storageService.sortAndFilter.next(returnData);
  }

  setPaginationAndSortForMultiGrid(sortList, filters,storageNamePagination) : any
  {
    var reportModel : any = {};
    var storedPagination : any;
   reportModel.filters = JSON.stringify(filters);
   reportModel.sortList = sortList;
    storedPagination = this.storageService.retrieve(storageNamePagination);
    if(reportModel.filters == '{"rules":[],"groups":[]}')
    reportModel.filters = '';
    if(reportModel.sortList == undefined)
    reportModel.sortList = [];
    reportModel.page = storedPagination != undefined ? storedPagination.page : 1;
    reportModel.pageSize = storedPagination != undefined ? storedPagination.pageSize : 25;
    return reportModel
  }

   modifyFilter(filters, data, field, operation, fromPopup : boolean) {
    if (!data) {
      return filters;
    }
    var group, values;
    if (operation === null || operation === undefined || operation === "") {
      operation = Operations.eq;
    }
    else {
      if (field.toLowerCase().indexOf("date") > -1) {
        if (data !== null && data !== undefined && data !== "") {
          filters.rules.push({
            data: data,
            op: operation,
            field: field
          });
        }
      } else {
        values = data.split(",");
        if (values.length <= 1 && fromPopup == false)  {
          if (values[0] !== "") {
            data = values[0];
            if (data !== null && data !== undefined) {
              filters.rules.push({
                data: data,
                op: operation,
                field: field
              });
            }
          }
        } else {
          group = {
            groupOp: "OR",
            rules: []
          };
          filters.groups.push(group);
          for (var i = 0; i < values.length; i++) {
            var fund = values[i];
            if (fund !== null && fund !== undefined && fund !== "") {
              group.rules.push({
                data: fund,
                op: operation,
                field: field
              });
            }
          }
        }
      }
    }

    
    return filters;
  }

  
  
  setPaginationAndSort(params,storageNameSorting, filters,storageNamePagination) : any
  {
 
    var reportModel : any = {};
    var storedPagination : any;
    const ledgerId = this.storageService.retrieve(StorageName.STORED_SELECTED_REPORT_VIEW_ALL_LEDGERAMT_LEDGERID);
    if(ledgerId){
      reportModel.ledgerId = ledgerId;
    }
   reportModel.filters = JSON.stringify(filters);
   reportModel.sortList = this.storageService.retrieve(storageNameSorting);
    storedPagination = this.storageService.retrieve(storageNamePagination);
    if(storageNameSorting == StorageName.SORTING_REPORT_OLD_JEWELLERY_STOCK_GRID)
      {
       reportModel.statusId =storedPagination.statusId;
      }
    if(reportModel.filters == '{"rules":[],"groups":[]}')
    reportModel.filters = '';
  if(reportModel.sortList == undefined)
  reportModel.sortList = [];
    reportModel.page = storedPagination != undefined ? storedPagination.page : 1;
    reportModel.pageSize = storedPagination != undefined ?  storedPagination.pageSize : 25;
    // if(storageNamePagination=='PAGINATION_REPORT_DAYREPORT_GRID'){
    //   reportModel.pageSize = storedPagination !== undefined ? (storedPagination.pageSize === 1 ? 0 : storedPagination.pageSize) : 25;

    // }else{
    //   reportModel.pageSize = storedPagination !== undefined ? (storedPagination.pageSize == 1 ? 0 : storedPagination.pageSize) : 25; 
    // }
    const allColumns = params.columnApi.getAllColumns();
    const colDefs = allColumns.map(column => column.getColDef());
    colDefs.forEach(colDef => {
      const cellRendererName = colDef.cellRenderer;
      if(cellRendererName?.name =='CheckboxRendererComponent')
      {
      this.cellRenderName = cellRendererName?.name;
      }
    });
    if( this.cellRenderName == 'CheckboxRendererComponent' ||  reportModel.pageSize == 0){
      reportModel.getAll = true; 
    }else{
      reportModel.getAll = false;
    }
    return reportModel
  }

  performFilter(agGridDataModel: any) {    
    let rows = this.storageService.retrieve(agGridDataModel.initialRows);
    let sortList = this.storageService.retrieve(agGridDataModel.storageName);
    // agGridDataModel.columnApi.applyColumnState({
    //   state: sortList,
    //   defaultState: { sort: null },
    // });
    try {
      if (agGridDataModel.gridApi != undefined)
        agGridDataModel.gridApi.columnApi.applyColumnState({
          state: sortList,
          defaultState: { sort: null },
        });
      else
        agGridDataModel.columnApi.applyColumnState({
          state: sortList,
          defaultState: { sort: null },
        });;
    }
    catch (ex) {
      
    }

    var filterList = [];
    filterList = this.storageService.retrieve(agGridDataModel.filterStorageName);
    
    // new added code for selectall checkbox header when filter input
    if(agGridDataModel.api?.appliedChecboxSetListByField){
      const list = agGridDataModel.api?.appliedChecboxSetListByField?.gridRows;
      rows?.forEach((item) => {
        const correspondingItem = list?.find(x => x[agGridDataModel.api?.appliedChecboxSetListByField?.matchRowidFieldName] == item[agGridDataModel.api?.appliedChecboxSetListByField?.matchRowidFieldName]);
        if (correspondingItem) {
          item[agGridDataModel.api?.appliedChecboxSetListByField?.checkboxFieldName] = correspondingItem[agGridDataModel.api?.appliedChecboxSetListByField?.checkboxFieldName]; // Update the value
        }
      });
    }

    var newFilterRows: any[] = rows;
    filterList?.forEach(element => {
      if (element?.FilterData != undefined) {
        const value = element?.FilterData.map(a => a);
        
        if (value != undefined) {
          if (value.length == 0) {
            newFilterRows = rows;
          }
          else {            
            newFilterRows = newFilterRows?.filter(function (d) {
              var check: boolean = false;            
              value.forEach(val => {               
                if (d[element?.name]?.toString()?.toLowerCase() == (val?.toLocaleLowerCase()) || 
                    val?.toLocaleLowerCase() == "null/(empty)" ||
                    !val) {
                  
                      check = d[element.name]?.toString()?.toLowerCase() == (val?.toLocaleLowerCase()) || 
                          (val?.toLocaleLowerCase() == "null/(empty)" && (d[element?.name] == null || d[element?.name]?.toString()?.toLowerCase() == '')) ||
                          !val;
                }
              })
              return check;
            });
          }
        }
      }
      
      const txtInputValue = element?.textFilter?.toLowerCase();
      const type = element?.type;
      if (txtInputValue != undefined && txtInputValue != "" && txtInputValue != null) {
        newFilterRows = newFilterRows?.filter(function (d) {
          var checkInput: boolean = false;
          // checkInput =
          //   ((element.type == "textFilter"
          //     && d[element.name]?.toString()?.toLowerCase()?.includes(txtInputValue))
          //     || (element.type == "percentFilter"
          //       && ((d[element.name] * 100).toString()?.toLowerCase()?.includes(txtInputValue)))
          //     // || (element.type == "dateFilter"
          //     //   && ((new DatePipe('en-US').transform(d[element.name], 'MM/dd/yyyy')).toString().toLowerCase().startsWith(txtInputValue)))
          //   )
          //   var indexOfDate = element?.name?.toLowerCase()?.indexOf("date");
          //   if (element.type == "textFilter" && indexOfDate != -1) {

          //       var checkInput: boolean = false;
          //       // Format the date in the desired locale-specific format
          //       var date = d[element.name];
          //       const formattedDate = this.dateConvertorByRegion(date);
            
          //       // Check if the formatted date starts with txtInputValue
          //       checkInput = formattedDate.toLowerCase()?.includes(txtInputValue);
          //      // return checkInput;

          //   // return checkInput;
          //   }


          // Handle composite names (split by space)
          const compositeNames = element?.name?.split(" "); // Split into individual column names
          compositeNames?.forEach((namePart) => {
            // Check each part for matches
            checkInput =
              checkInput || // Preserve any previous match
              ((element.type === "textFilter" &&
                d[namePart]?.toString()?.toLowerCase()?.includes(txtInputValue)) ||
                (element.type === "percentFilter" &&
                  (d[namePart] * 100)
                    .toString()
                    ?.toLowerCase()
                    ?.includes(txtInputValue)));
          });

          // Special handling for 'date' fields
          compositeNames.forEach((namePart) => {
            const indexOfDate = namePart?.toLowerCase()?.indexOf("date");
            if (element.type === "textFilter" && indexOfDate !== -1) {
              const date = d[namePart];
              if (date) {
                // Format the date in the desired locale-specific format
                const formattedDate = this.dateConvertorByRegion(date);

                // Check if the formatted date contains the input value
                checkInput = checkInput || formattedDate.toLowerCase()?.includes(txtInputValue);
              }
            }
          });
          
         return checkInput;
         
        });
      }
    });

    var gridApi: any;
    if (agGridDataModel.gridApi != undefined)
      gridApi = agGridDataModel.gridApi.api;
    else
      gridApi = agGridDataModel.api;
    var storedPageSize = this.storageService.retrieve(agGridDataModel.paginationStore);
    gridApi.paginationSetPageSize(storedPageSize == undefined ? defaultPageLimit : storedPageSize);
    gridApi.setRowData(newFilterRows);
   
  
      // this.setPageSize.next({pageSize : data, rows : newFilterRows });
      return newFilterRows;
    }

    getDataSource(url : any,model : any):Observable<ResponseModel>{
      return this.service.post(url,model, true).pipe<ResponseModel>(
        tap((response: any) => {
          if(response.response != null)
          {
            return response.response.data;
          }
          else
          {
            response.response = {};
            response.response.data = [];
          }
        })
      );
    }

    // resetAllFilters(initialRowsStorageName, filterStorageName, storageNameSorting, gridApi = null) {
    //   this.storageService.removeItem(filterStorageName);
    //   let initialRows = this.sortService.performSort(initialRowsStorageName, storageNameSorting);
    //   if (gridApi != null && gridApi != undefined)
    //     gridApi.api.setRowData(initialRows);
     
    //   return initialRows;
    // }

      // Function to synchronize scrolling using Grid API
     synchronizeScroll(leftApi, rightApi) {
        var thisGridApi = leftApi.api;
        var otherGridApi = rightApi.api;

        var thisScrollPosition = thisGridApi.getHorizontalPixelRange();
        var otherScrollPosition = otherGridApi.getHorizontalPixelRange();

        // Synchronize horizontal scroll
        if (thisScrollPosition.left !== otherScrollPosition.left) {
            otherGridApi.setHorizontalScrollPosition(thisScrollPosition.left);
        }

        // Synchronize vertical scroll
        var thisVerticalScroll = thisGridApi.getVerticalPixelRange();
        var otherVerticalScroll = otherGridApi.getVerticalPixelRange();

        if (thisVerticalScroll.top !== otherVerticalScroll.top) {
            otherGridApi.setVerticalScrollPosition(thisVerticalScroll.top);
        }
    }


    addGridDropZone(leftApi,rightApi) {
  
      const dropZoneParams = rightApi.api.getRowDropZoneParams({
        onDragStop: (params) => {
          var deselectCheck = false;
          var moveCheck = true;
          var nodes = params.nodes;

          if (moveCheck) {
            leftApi.api.applyTransaction({
              remove: nodes.map(function (node) {
                return node.data;
              }),
            });
          
    // Remove selected rows from their current positions
    rightApi.api.applyTransaction({ remove: nodes.map(function (node) {
      return node.data;
    }) });

    // Insert selected rows at the top
    rightApi.api.applyTransaction({ add: nodes.map(function (node) {
      return node.data;
    }), addIndex: 0 });

    rightApi.api.forEachNode((node: any) => {
      if (node.data.active) {
        node.setSelected(true);
      }
    });

 
          } else if (deselectCheck) {
           // leftApi.api.setNodesSelected({ nodes, newValue: false });
          }
        },
      });
  
      leftApi.api.addRowDropZone(dropZoneParams);
    }

    
  getAllColumnDefination(dataModel): Promise<any> {
    // const gridName = "TRANSACTION_SALESINVOICE_GOLD_ITEM_GRID";//TEMP UPDATE COLUMN dataModel.storageName;
    const gridName = dataModel.storageName;//TEMP UPDATE COLUMN dataModel.storageName;
    return new Promise((resolve, reject) => {
      this.getAllcolumndefinition(gridName).pipe(catchError((err) => {this.handleError(err);
          reject(err);
          return throwError(err);
        }))
        .subscribe((res) => {
          if (res.isSuccess) {
            
            // this.dataModel.id=res.response.id;
            const newData = res.response.colDefs;
            const newcolumnDefsObject = JSON.parse(newData);
            this.storageService.store(gridName,newcolumnDefsObject);
            resolve(newcolumnDefsObject);
          }
        });
    });
  }

  insertUpdateAllColumnDefinations(gridName, colDefs): Promise<any> {
    var updategridcolumndefinition : any = {};
    updategridcolumndefinition.gridName = gridName;
    updategridcolumndefinition.colDefs = JSON.stringify(colDefs);
    return new Promise((resolve, reject) => {
      this.updategridcolumndefinition(updategridcolumndefinition).pipe(catchError((err) => {this.handleError(err);
          reject(err);
          return throwError(err);
        }))
        .subscribe((res) => {
          if (res.isSuccess) {
            
            if(res.response != null)
            {
            // this.dataModel.id=res.response.id;
            const newData = res.response.colDefs;
            const newcolumnDefsObject = JSON.parse(newData);
            resolve(newcolumnDefsObject);
            }
          }
        });
    });
  }

  openModal(componentType: any, params: any, dropdownStorageKey: string, dropdownField: string): void {
    const isFullscreen = dropdownStorageKey === 'PRODUCT_DROPDOWN';
    const data = {
      isHeightFull: isFullscreen ? true : undefined,
      hide_Header : isFullscreen ? true : false
    };
    this.notificationService.openModalPopup(componentType,data,'',ModalPopupSize.MD,'',isFullscreen).then(
      (result) => {
      if (result) {
        this.updateGridDropdownList(params, dropdownStorageKey, dropdownField);
      }})
    }

  updateGridDropdownList(params,storageName,fieldName){
      this.getDropdownByStorageName(storageName).subscribe((res=>{
        this.storageService.store(storageName,res?.response);
        const values = this.storageService.retrieve(storageName)
        const columnApi = params.columnApi;
        const column = columnApi.getColumn(fieldName);
        if (column) {
          const colDef = column.getColDef();
          if (colDef) {
              colDef.cellEditorParams = {
                values: () => {
                  return values
                 }
              };
              params.api.refreshCells({ columns: [fieldName] });
          } else {
              console.error(`Column definition not found for column with field name: ${fieldName}`);
          }
      } else {
          console.error(`Column with field name ${fieldName} not found`);
      }
      }))
  }
  
  getAllcolumndefinition(name){
    let url = `api/app/gridcolumndefinition/getbygridnamegridcolumndefinition?GridName=${name}`;
    return this.service.get(url,true ,undefined ,false).pipe<ResponseModel>(tap((response: any) => {
      return response;
      })
    );
  }

  getDropdownByStorageName(StorageName:string){
    let url = `api/app/dropdown/getdropdown?StorageName=${StorageName}`;
    return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }
  uploadInvoiceImages(data:any){
    let url ="api/app/invoice/uploadinvoiceimages";
    return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  updateColumns(params, fieldName, newOptions , removedColumnNames?:string[]) {
    const columnApi = params.columnApi;
    const gridApi = params.api;
    let colDefs = gridApi.getColumnDefs();
     // If there are columns to be removed, filter them out
  if (removedColumnNames && removedColumnNames?.length > 0) {
    colDefs = colDefs.filter(col => !removedColumnNames.includes(col.field));
  }
    const colDef = colDefs.find(col => col.field === fieldName);
    if (colDef) {
      if (colDef.cellEditorParams) {
        colDef.cellEditorParams.values = newOptions;
      } else {
        colDef.cellEditorParams = { values: newOptions };
      }
  
      gridApi?.setColumnDefs(colDefs);
      gridApi?.refreshCells({ columns: [fieldName], force: true });
    }
  }

  createColumnDefs(data: any[],editable = false, hideColumns = []): any[] {
    if (data.length === 0) {
      return [];
    }
    // Assume all objects in data have the same shape
    const keys = Object.keys(data[0]);

    return keys.map(key => ({
      headerName:  key.replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase
      .charAt(0).toUpperCase() + key.replace(/([a-z])([A-Z])/g, '$1 $2').slice(1),
      field: key,
      editable : editable,
      hide : hideColumns.indexOf(key) != -1,
      cellRenderer: TextRendererComponent,
      cellClass: "custom-column-group-cell px-0",
      headerClass: "custom-column-group-cell px-0",
    }));
  }
  getRapnetpriceList(data:any){
    let url ="api/app/rapnet/getrapnetpricelistasync";
    return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
    })
    );
  }

  
}