import { AgGridDataModelChargesTable, AgGridDataModelManufacturDesc, AgGridDataModelManufacturing, AgGridDataModelProductCharacteristics, AgGridDataModelProductList, AgGridDataModelTaxListTable, ProductDetailModel, ProductListModel, ProductModel, ProductOpening, ProductSpecification, TabView, TaxModel, exportRequest } from './product-opening.model';
import { Component, Input, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi } from 'ag-grid-community';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GridOptions } from 'ag-grid-community';
import { NgbActiveModal, NgbDropdown, NgbModal, NgbModalOptions, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { PaginationPageSizes } from 'src/app/shared/components/common-model/common-model';
import { PlusIconService } from 'src/app/shared/common-components/ag-grid/plus-icon/plus-icon.service';
import { DeleteComponent } from './popup/delete/delete.component';
import { MetalType, ModalPopupSize, commonModalOptions, commonModalOptionsLg} from '../../../shared/models/common.model'
import { AgGridI, PaginationModel, defaultPageLimit } from '../../../shared/common-components/ag-grid/ag-grid.model';
import { CommonService } from './../../../shared/services/common.service';
import { Subscription, catchError } from 'rxjs';
import { NgForm } from '@angular/forms';
import {ProductOpeningService} from './product-opening.service'
import {ProductService} from '../product.service'
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { ExportAs, StorageName } from 'src/app/shared/enum/common-enum';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CheckboxRendererService } from 'src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.service';
import { BranchDetailsComponent } from '../../administration/popups/branch-details/branch-details.component';
import { MasterModalTitle } from '../../administration/masters/masters.model';
import { BranchesTabComponent } from '../../administration/masters/tabs/branches-tab/branches-tab.component';
import { AddeditcategoryComponent } from './popup/addeditcategory/addeditcategory.component';
import { AdministrationService } from '../../administration/administration.service';
import { SaveComponent } from 'src/app/shared/common-components/pop-ups/save/save.component';
import { AgGridService } from 'src/app/shared/common-components/ag-grid/ag-grid.service';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { GetRequestModel } from '../../Report/report.model';
import { AgGridComponent } from 'src/app/shared/common-components/ag-grid/ag-grid.component';
import { CaratPopupComponent } from 'src/app/shared/common-components/common-popup/carat-popup/carat-popup.component';
import { MetalSectionModel } from 'src/app/shared/common-components/metal-section/metal-section.model';
import { ExcelConverterServiceService } from 'src/app/shared/services/excel-converter-service.service';
import { MastersService } from '../../administration/masters/masters.service';
import { CompressImageService } from 'src/app/shared/services/compress-image.service';
import { AddEditLocationComponent } from './popup/add-edit-location/add-edit-location.component';
import { User } from 'src/app/auth/auth/auth.model';
import { FieldValueComponent } from '../../administration/masters/tabs/user-define-fields/pop-up/field-value/field-value.component';
import { FileReaderService } from 'src/app/shared/services/file.reader.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { IconHover } from '../../../shared/enum/common-enum';
import { CommonFilterService } from '../../../shared/services/common-filter.service';
import { TaxComponent } from '../../administration/popups/tax/tax.component';
import { LoadDropdownDataService } from 'src/app/shared/common-components/load-dropdown-data/load-dropdown-data.service';

@Component({
  selector: 'app-product-opening',
  templateUrl: './product-opening.component.html', 
  styleUrls: ['./product-opening.component.scss'],
  providers: [NgbPaginationConfig]
})

export class ProductOpeningComponent  extends CommonService implements OnInit {
  private plusIconSubscription          : Subscription | undefined;
  agGridDataModelProductCharacteristics : AgGridI;
  agGridDataModelProductList            : AgGridI;
  agGridDataModelTaxListTable           : AgGridI;
  agGridDataModelManufacturing          : AgGridI;
  agGridDataModelManufacturDesc         : AgGridI;
  agGridDataModelChargesTable           : AgGridI;
  dropdownSettings                      : IDropdownSettings = {};
  taxGridApi                            : any;
  additionalCostGridApi                 : GridApi;
  additionalCostGridOptions             : GridOptions;
  billOfMaterialItemGridOptions         : GridOptions;
  productSpecificationGridOptions       : GridOptions;
  pageNumber = 1;
  totalRecords:number;
  currentHoverEffect = IconHover.Pink;
  storageName = StorageName
  taxList     :TaxModel[] = [];
  @Input() data : any={};
  taxListCopiedData :TaxModel[] = [];
  openTab: number=1;
  files: File[] = [];
  productSpecification :ProductSpecification[];
  ProductSpecificationModel = new ProductSpecification();
  gridApi                   : any;
  makeCopy:boolean=false;
  copyProduct:boolean=false;
  productNameObj:any;
  formModel :  any = {};
  metalSectionModel=new MetalSectionModel();
  editUser:any;
  exportAs = ExportAs;
  isRowClicked:boolean;
  paginationStorageName = StorageName.PAGINATION_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID;
  apiRequestStorageName = StorageName.APIREQUEST_PRODUCT_PRODUCT_OPENING_GRID;
  productName:string;
  @ViewChild(AgGridAngular) agGrid! : AgGridAngular;
  @ViewChild("ProductOpeningForm") ProductOpeningForm!: NgForm;
  @ViewChild(BranchesTabComponent)branchesTabComponent : BranchesTabComponent;
  @ViewChildren(AgGridComponent) grids: QueryList<AgGridComponent>
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ViewChild('ProductCharacteristics') ProductCharacteristics: AgGridComponent;
  @ViewChild('productLists') productLists: AgGridComponent;
  paginationPageSizes = new PaginationPageSizes();
  getAllDropdownModel  : any = {};
  productOpening      = new ProductOpening();
  productListModel    = new ProductListModel();
  productDetailModel  = new ProductDetailModel();
  productModel        = new ProductModel();
  taxModel            = new TaxModel();
  popupTitle          = MasterModalTitle;
  allProductDropdown             : any;
  receivedDataOnCheck            : any;
  ProductCharacteristicsCoulumns : any;
  productDetails                 : any;
  branchIds: any[] = []; 
  copySelectedBranches           : any;
  AdditionalCost                 : boolean=false;
  selectedImage                  : any;
  gridApiProductOpening          : any;
  gridApiProductCharacteristics  : any;
  gridApiProductTax  : any;
  productList                    : any[];
  isLoading :boolean=false;
  apiRequestModel : any = {};
  onFilterSubscription: Subscription;
  invoiceName: any;
  formName = StorageName.FORMNAME_PRODUCT_PRODUCTOPENING;
  user:User;
  pagination = new PaginationModel();
  getallrfidAvailbaleRequestModel = new GetRequestModel();
  userData: any;
  FilePath: any;
  fileInput ='';
  fileName ="";
  selectedFile: File | null = null;
 constructor(
    config                          : NgbPaginationConfig, 
    public activeModal              : NgbActiveModal,
    private modalService            : NgbModal, 
    private plusIconService         : PlusIconService,
    public notificationService      : NotificationService,
    private storageservice          : StorageService,
    public renderer                 : Renderer2,
    private productOpeningService   : ProductOpeningService,
    public toaster                  : ToastrService,
    public storageService           :StorageService,
    private checkboxRendererService : CheckboxRendererService,
    public productService           : ProductService,
    public administrationService : AdministrationService,
    private agGridService         : AgGridService,
    public sendDataService        : SendDataService,
    public excelConverterServiceService: ExcelConverterServiceService,
    public mastersService: MastersService,
    public compressImageService :CompressImageService,
    public fileReaderService : FileReaderService,
    public commonFilterService:CommonFilterService,
    public loadDropdownDataService : LoadDropdownDataService,) 
{
    config.size = 'sm';
    config.boundaryLinks = true;
    super(renderer);
    // this.getDataOnChecked();

    this.additionalCostGridOptions = {
      singleClickEdit : true,
    };
    this.billOfMaterialItemGridOptions={
      singleClickEdit : true,
    }
    this.productSpecificationGridOptions={
      singleClickEdit : true,
    }

  }


  handleTextClick(event){
    const currentId =event.data?.id;
    if (currentId !== this.productOpeningService.previouscellClickedId) {
      this.productOpeningService.previouscellClickedId = currentId;
      this.getCharacteristicsByProductId(event.data?.id);
      this.productDetails=event.data;
    } else {
      
    }
  }

  DropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: false
  };

  ngOnInit() {
    !this.data?.hide_Header ? this.sendDataService.displayPageName(this.formName):null;
    this.metalSectionModel.isInternal_Fix=false;
    this.metalSectionModel.isBarcodeInput=false;
    this.isLoading =true
    this.productSpecification=[];
    this.addemtyRow();
    this.agGridDataModelProductCharacteristics = AgGridDataModelProductCharacteristics;
    this.agGridDataModelTaxListTable = AgGridDataModelTaxListTable;
    this.agGridDataModelManufacturing     = AgGridDataModelManufacturing;
    this.agGridDataModelManufacturDesc     = AgGridDataModelManufacturDesc;
    this.agGridDataModelChargesTable     = AgGridDataModelChargesTable;
    this.agGridDataModelProductList = AgGridDataModelProductList;

    this.plusIconSubscription = this.plusIconService.plusIconClicked$.subscribe((params: string) => {
             this.openPopup(params)
    });
    // this.getAllTax();
    // this.getCharacteristicsByProductId(0);
    this.getSampleFileByPageName();
    this.onFilterSubscription = this.storageService.onFilter.subscribe((params: any) => {
      const res = { isSuccess: params?.data?.length ? true : false,  response: params };
      this.commonFilterService.processServersideApiResponse(res,this.productLists,this.gridApiProductOpening,this.pagination,this.pagination.getAll);
    });
  }

  onGridProdutListReady(event){
    this.gridApiProductOpening  = event; 
    this.getAllProductList()
  }
  onGridProductCharacteristicsReady(event){
    this.gridApiProductCharacteristics  = event; 
     this.getCharacteristicsByProductId(0); 
  }
  onGridProductTaxReady(event){
    this.gridApiProductTax  = event; 
     this.getAllTax(); 
  }

  onDeleteRowClickedResponse(event) {
    this.deleteProduct(event?.data?.id , event);
  }
  
  openPopup(params){
    switch (params?.displayName) {
      case 'Carat':  this.openCaratPopup(params); break;
      case 'Location': this.openLocationPopup(params);break;
    }
  }

  onCellValueChange(params) {
    
    if(params.data.metalId == MetalType.Diamond)
      {  this.calculationDiamond(params.data, params.node,params.column.colId);}
    else if(params.data.metalId == MetalType.Gold)
      {this.calculationGoldorSilver(params.data, params.node,params.column.colId);}
    else if (params.data.metalId == MetalType.Silver)
      {this.calculationGoldorSilver(params.data, params.node,params.column.colId);}
    else{
      
    }
  }

  calculationGoldorSilver(model, node, colId) {
    model.openingFinalWeight = model.openingWeight * model.openingPurity;
    this.gridApiProductCharacteristics?.api?.refreshCells();
  }

  calculationDiamond(model, node, colId) {
    switch (colId) {
      case "openingWeight":
        model.openingPurity = this.fixWeightDecimal(Number(model.openingWeight ?? 0) * 5, 3);
        break;

      case "openingPurity":
        model.openingWeight = this.fixWeightDecimal(Number(model.openingPurity ?? 0) / 5, 3);
        break;
    }

    model.openingFinalWeight = this.fixWeightDecimal(model.openingWeight, 3);
    this.gridApiProductCharacteristics?.api?.refreshCells();
  }

  addemtyRow() {
    const productSpecificationModel = new ProductSpecification();
    this.productSpecification = [...this.productSpecification, productSpecificationModel];
    this.productSpecificationGridOptions?.api?.setRowData(this.productSpecification);
  }

  gridOptionsProductCharacteristics: GridOptions = {
    // suppressClickEdit:true,
    singleClickEdit: true,
    getRowStyle: this.getRowStyle,
  };

  onfocus(event, id) {
    const elm = document.getElementById(id) as HTMLInputElement;
    elm.focus();
    elm.select();
  }

  gridOptions: GridOptions = {
    // suppressClickEdit: true,
    getRowStyle: this.getRowStyle,
    singleClickEdit:true
  };

  getRowStyle(params: any) {
    return {
      background: params.node.rowIndex % 2 === 0 ? '#EEF1F7' : '#FFFFFF',
    };
  }
  
  getAllTax() {
    this.showLoading(this.gridApiProductTax);
    this.mastersService.getAllTaxSetting().subscribe({
      next:(res)=>{
        if(res.isSuccess){
          // this.taxList = res.response??[];
          // this.taxList.map((a)=>a.active=false);
        const taxData = res.response??[];
        this.taxList =  taxData.map((tax)=>({
            // id : tax.id,
          taxId  : tax.id,
          active : false,
          name   : tax.name,
          taxValue : tax.rate,
          calculationMode:tax.calculationMode,
          added : false,
          edited : false,
          deleted : false,
          isAlreadyAdded : false
        }));
        this.taxListCopiedData = JSON.parse(JSON.stringify(this.taxList))
        }else{           
          this.hideLoading(this.gridApiProductTax); 
          this.taxList = [];
          this.taxListCopiedData = []
        }  
      },
      error:(err)=>{
        this.hideLoading(this.gridApiProductTax); 
        this.taxList = [];
        this.taxListCopiedData = []
      }
    })

  }

  // ******tax popup start*****
  openTaxPopup() {
      var data = {modalTitle: this.popupTitle.AddTaxSetting,getAllDropdownModel: this.getAllDropdownModel}
      this.notificationService.openModalPopup(TaxComponent, data,undefined,ModalPopupSize.MD, "", false, true)
      .then((result) => {
      if (result) {
        this.getAllTax();
      }
    })
  }

  // ******branch popup start*****
  openBranchPopup() {
    var data = {modalTitle : this.popupTitle.AddBranchDetail};
    this.notificationService.openModalPopup(BranchDetailsComponent, data,undefined,ModalPopupSize.LG,"",false,true)
      .then((result) => {
      if (result ) {
      this.mastersService.getAllBranch(data).subscribe({
      next:(res)=>{
      if(res?.isSuccess){
        this.agGridService?.getDropdownByStorageName(StorageName.BRANCH_DROPDOWN).subscribe({
          next:(res)=>{
            this.getAllDropdownModel.branchDropdown = res?.response??[];
          }
        })
      
      }
      },
      error:(err)=>{
      }
      })
    }
  });
  }

    // ******Save popup start*****

  openSavePopUp() {
    this.notificationService.confirmationPopup('Save Changes', 'Do you want to save changes?', 'save', false, 'Save', "Don't Save", true).subscribe((result) => {
      if (result.isDismissed) {
         
      } else if (result.isConfirmed) {
        this.saveData();  
      }
    });
  }
  // ******Save popup end*****

  addCategory(){
    this.notificationService.openModalPopup(AddeditcategoryComponent, undefined,'', ModalPopupSize.MD , '',false,true).then(
      (resultData) => {
      if (resultData) {
      this.administrationService.getAllCategory().subscribe((res)=>{
      let categoryDropdown  =  res.response;
      this.getAllDropdownModel.categoryDropdown = categoryDropdown.filter(x=>x.active==true);
      this.productOpening.productModel.categoryId = undefined;
      })
      }
      }
    );
  }

  saveData(){
    let rowsToDisplay = this.taxGridApi?.getModel()?.rowsToDisplay?.map(node => node?.data);
    rowsToDisplay = rowsToDisplay.map(data => ({
      ...data,
      calculationMode: data.calculationMode ? parseInt(data.calculationMode, 10) : 0,
   //   added: data.edited ? false : (data.active ? true : false)
        }));
    if(this.productOpening.productModel.id===0){
      // rowsToDisplay = rowsToDisplay.filter(data => data.active);
      for (const tax of rowsToDisplay) {
        tax.id =0;
      }
      //  rowsToDisplay = rowsToDisplay.filter(data => data.active);
      this.productOpening.productModel.taxIds = rowsToDisplay;
    }else{
      const oldValues1 = this.productOpening.productModel.taxIds;
      const oldValues = oldValues1.filter(taxId => taxId !== 0);
      // rowsToDisplay = rowsToDisplay.filter(data => data.active);
      rowsToDisplay = rowsToDisplay.map(data => {
        const originalCalculationMode = oldValues.find(item => item.taxId === data.taxId)?.calculationMode;
        const originalTaxValue = oldValues.find(item => item.taxId === data.taxId)?.taxValue;
        const oldActive = oldValues.find(item => item.taxId === data.taxId);
        
        const added = !oldActive && data.active; 
        const edited = (originalCalculationMode && originalCalculationMode !== data.calculationMode) || (originalTaxValue && originalTaxValue !== data.taxValue);
        return {
          ...data,
          edited: !!edited,
          deleted: !data.active,
          added: added,
        };
      });
     
      this.productOpening.productModel.taxIds = rowsToDisplay;
    }
    // this.productOpening.productModel.taxIds = rowsToDisplay;
      const copyprod = JSON.parse(JSON.stringify(this.productOpening))
      delete copyprod.resourceProperties;
      // copyprod.productDetailModel =  copyprod.productDetailModel.filter((x)=>x.active==true);
      for (let product of copyprod.productDetailModel) {
        if (product.active) {
            if (product.id === 0) {
                product.added = true;
                product.edited = false;
            } else {
                product.added = false;
                product.edited = true;
            }
            product.deleted = false;
        } else {
            product.deleted = true;
            product.added = false;
            product.edited = false;
        }
        product.none = false;
    }   
    if (this.productOpening?.productModel?.branchIds) {
      const branchIds = this.productOpening?.productModel?.branchIds;
      this.productOpening.productModel.branchIds = Array.isArray(branchIds) && branchIds?.length > 0 && typeof branchIds[0] === 'object' && 'id' in branchIds[0] ? branchIds.map(branch => branch?.id?.toString()) : branchIds;
    }else{
      this.productOpening.productModel.branchIds = [String(this.userData?.branchId)]
    }
      if(this.productOpening.productModel.id===0){
       
        this.addProduct(copyprod);
      }else{
        this.editProduct(copyprod);
      }
  //  }
  };
 
  addProduct(productOpening) {
    // this.billOfMaterialModel = null;
    // this.productOpening.billOfMaterialModel = null;
    if(this.copyProduct){
      productOpening.productDetailModel.map(x=>x.id=0);
    }
      this.productOpeningService.insertProduct(this.productOpening).subscribe({
        next:(res) => {
          if(res.isSuccess===true){
            this.productNameObj={name:productOpening?.productModel?.name};
            this.toaster.success(res.message);
            this.getAllProductList();
            this.getAllTax();
            this.clearDetails();
            this.ProductOpeningForm.reset();    
             } else {
            this.toaster.error(res.errors[0]);
          }
        },
        error:(err) =>{
          this.toaster.error(err?.error?.errors[0]); 
        }
      })
  }

  editProduct(productOpening){
    // this.billOfMaterialModel = null;
    // this.productOpening.billOfMaterialModel = null;
      this.productOpeningService.updateProduct(productOpening).subscribe({
        next:(res) => {
          if(res.isSuccess===true){
            this.toaster.success(res.message);
            this.getAllProductList();
            this.getAllTax();
            this.clearDetails();
            this.productOpeningService.previouscellClickedId =null;
          } else {
            this.toaster.error(res.errors[0]);
          }
        },
          error:(err) =>{
            this.toaster.error(err?.error?.errors[0]); 
          }
        })   
}

 deleteProduct(productId ,params?){
  this.productOpeningService.deleteProduct(productId).pipe(catchError((err)=>this.handleError(err)))
      .subscribe((res: ResponseModel) => {
        if(res.isSuccess===true){
          this.toaster.success(res.message);
          this.getAllProductList();
          this.getAllTax();
          this.clearDetails();
        } else {
          const transaction = {
            add: [params?.data], 
            addIndex: params?.rowIndex 
        };
        params?.api?.applyTransaction(transaction);
        this.toaster.error(res.errors[0]);
        }
      })
 }

  onPagination(pagination: PaginationModel) {
    this.isRowClicked = false;
    this.storeChangedPagination(pagination);
       this.getAllProductList();
  }
 
   storeChangedPagination(paginationModel: PaginationModel) {
    this.pagination = this.commonFilterService.storeChangedPagination(paginationModel, this.pagination,this.paginationStorageName,this.apiRequestStorageName);
  }
 
  getAllProductList(){
    this.showLoading(this.gridApiProductOpening);
    this.productListModel=this.storageService.retrieve(this.apiRequestStorageName) || {};
    this.productOpeningService.getAllProduct(this.productListModel).subscribe({
      next:(res)=>{
        if(res?.isSuccess){
          this.totalRecords = res.totalRecords;
          this.gridApiProductOpening?.api?.setRowData(res?.response?.data);
          this.commonFilterService.processServersideApiResponse(res,this.productLists,this.gridApiProductOpening,this.pagination,this.apiRequestModel.getAll);
          this.refreshGridApi();
        
        } else{
          this.hideLoading(this.gridApiProductOpening); 
          this.gridApiProductOpening?.api?.setRowData([]);
        }
      },error:(err)=>{
        this.hideLoading(this.gridApiProductOpening); 
        this.gridApiProductOpening?.api?.setRowData([]);
      }
    })
  }


  refreshGridApi(){
    this.gridApiProductOpening?.api.refreshHeader();
  }

getCharacteristicsByProductId(productId?:number){
  this.showLoading(this.gridApiProductCharacteristics);
  this.productOpeningService.getProductById(productId).pipe(catchError((err)=>this.handleError(err)))
  .subscribe((res)=>{
    if(res.isSuccess){
      this.hideLoading(this.gridApiProductCharacteristics); 
      this.productOpening= res?.response??[];
      if(!res?.response.productModel.showInStock && productId==0){
        this.productOpening.productModel.showInStock=true;
      }
      // this.editUser = this.productOpening.productModel;
      if(productId==0){
        this.productOpening?.productDetailModel?.map(a => {
          a.active = false;
          a.unitId = 1;
      });
      }
      
      this.gridApiProductCharacteristics?.api?.setRowData( this.productOpening?.productDetailModel)
      if(productId !=0){
        // this.productOpeningService.branchDetails.next(this.productOpening.productModel)
        this.makeCopy=true;
        this.getBranchId(this.productOpening?.productModel);
        if (this.productOpening?.productModel?.taxIds != undefined) {
          for (const item of this?.taxList) {
            item.active = false; 
            for (const tax of this.productOpening?.productModel?.taxIds) {
              // if (item?.id === tax?.id) {
                if (item?.taxId === tax?.taxId) {
                item.active = true;
                item.id = tax.id;
                item.taxValue = tax.taxValue;
                item.calculationMode =tax.calculationMode;
                item.isAlreadyAdded = true
                break;
              }
              else
              {
                item.active = false;
                item.id = tax.id;
                item.taxValue = 0;
                item.calculationMode = 0;
                item.isAlreadyAdded = false
              }
            }
          }
          this.taxListCopiedData = JSON.parse(JSON.stringify(this.taxList));
          this.taxGridApi.setRowData(this.taxList);
        }
      }else{
        this.hideLoading(this.gridApiProductCharacteristics); 
        // this.productOpening.billOfMaterialModel.billOfMaterialItemModel=[];
      }
    }
  })
}

makeCopyProduct(){
  let productName = this.productOpening?.productModel?.name;
  this.productOpening.productModel.name = 'Copy of '.concat(productName);
  this.makeCopy=false;
  this.productOpening.productModel.id=0;
  this.productDetailModel.id=0;
  this.copyProduct=true;
}

getBranchId(item){
if (JSON.stringify(item) !== "{}") {
  this.productOpening.productModel = item;
}
const branchIds = item?.branchIds ?? [];
this.productOpening.productModel.branchIds =item?.branchIds;
 this.branchIds = this.getAllDropdownModel?.branchDropdown.filter(branch => branchIds?.includes(Number(branch?.id))).map(branch => branch);
this.copySelectedBranches?.map((a) => (a.none = true));
}

getFilteredBranchIds(storageService, storageName) {
  this.userData = storageService.retrieve('user');
  const branchIds = [{ id: String(this.userData?.branchId), deleted: false, added: true, edited: false, none: false }];
  const branchDropdown = storageService.retrieve(storageName.BRANCH_DROPDOWN);
  const filteredBranchIds = branchDropdown?.filter(item =>branchIds.some(branchId => item.id === branchId.id));
  return filteredBranchIds;
}

getAllDropdownData(){
  this.getAllDropdownModel.branchDropdown = this.storageService.retrieve(this.storageName.BRANCH_DROPDOWN);
    this.branchIds = this.getFilteredBranchIds(this.storageService, this.storageName);
  this.getAllDropdownModel.categoryDropdown = this.storageService.retrieve(this.storageName.CATEGORY_DROPDOWN);
  this.getAllDropdownModel.collectionDropdown = this.storageService.retrieve(this.storageName.COLLECTION_DROPDOWN);
  this.getAllDropdownModel.applicableOnDropdown = this.storageService.retrieve(this.storageName.APPLICATIONON_DROPDOWN);
  this.getAllDropdownModel.calculationDropdown = this.storageService.retrieve(this.storageName.CALCULATION_DROPDOWN);
  this.getAllDropdownModel.stateDropdown = this.storageService.retrieve(this.storageName.STATE_DROPDOWN);  
  this.getAllDropdownModel.invoiceNo = this.storageService.retrieve(this.storageName.INVOICE_NO_DROPDOWN);  
}

clearDetails(){
  this.makeCopy=false;
  this.productModel = new ProductModel();
  this.productDetailModel = new ProductDetailModel();
  this.taxModel = new TaxModel();
  this.productDetails = null;
  this.getCharacteristicsByProductId(0);
  this.getAllTax();
  this.branchIds = this.getFilteredBranchIds(this.storageService, this.storageName);
  this.productOpeningService.previouscellClickedId=null;
}

tabChanged(value) {
  this.openTab = value;
  switch (this.openTab) {
      case 1: {
          break;
      }
      case 2: {
          break;
      }
      case 3: {
        break;
    }
  }
}

additionalCost(){
  this.AdditionalCost = !this.AdditionalCost
}

onSelect(event) {
  if(event.addedFiles.length>1){
    this.resourceMultipleFile(event.addedFiles)
    }else{
      this.resourceFile(event.addedFiles[0])
    }
}


resourceFile(item:any){
  this.processFile(item);
}

resourceMultipleFile(items:any){
  for(const file of items)
  this.processFile(file)
}

processFile(file: any) {
  
  this.compressImageService.compress(file).subscribe(
    compressedFile => {
  this.readThis(compressedFile)
    .then((base64String) => {
      if (base64String) {
        
        const billOfMaterialResourceObj ={
          base64Resource:base64String,
          fileName:file.name
        };
        
      } else {
        console.error("Failed to get base64 string.");
      }
    })
    .catch((error) => {
      console.error("Error processing file:", error);
    });
  },
  error => {
    console.error('Error compressing file:', error);
  }
);

}

imgOnLoad(files){
  this.selectedImage = files[0]?.base64Resource;
 }

previewImg(file){
this.selectedImage = file.base64Resource;
}

readThis(event: any): Promise<string | null> {
  return new Promise((resolve) => {
    const file: File = event;
    const myReader: FileReader = new FileReader()
    myReader.onloadend = (e) => {
      const base64String = myReader.result as string;
      resolve(base64String);
    };
    myReader.onerror = (error) => {
      console.error("File reading failed:", error);
      resolve(null);
    };
    if (file) {
      myReader.readAsDataURL(file);
    } else {
      console.error("No file selected.");
      resolve(null);
    }
  });
}

openCaratPopup(params){
  var data = {getAllDropdownModel: this.getAllDropdownModel}
  this.notificationService.openModalPopup(CaratPopupComponent,data,'', ModalPopupSize.MD , '',false,true).then(
    (resultData) => {
    if (resultData) {
      this.agGridService.updateGridDropdownList(params,this.storageName.CARAT_DROPDOWN,'caratId');
    }
    }
  );
}

openLocationPopup(params){

  var data = {getAllDropdownModel: this.getAllDropdownModel}
  this.notificationService.openModalPopup(AddEditLocationComponent,data, ModalPopupSize.SM , '').then(
    (resultData) => {
    if (resultData) {
      this.agGridService.updateGridDropdownList(params,this.storageName.LOCATION_DROPDOWN,'locationId');
    }
    }
  );
}


callApiForExport(){
  const exportAs = this.productListModel.exportAs;
  this.productOpeningService.getAllProduct(this.productListModel).subscribe(
    {
      next: (res) => {
      this.fileReaderService.exportFile(res, exportAs);
      },error(error) {
      console.error('Error in downloading Excel/Pdf file:', error);
      },
     }
  )
}

toggleExportDropdown() {
  if (this.dropdown.isOpen()) {
    this.dropdown.close();
  } else {
    this.dropdown.open();
  }
}

getSampleFileByPageName(){
  const requestData = {pageName:this.formName}
  this.mastersService.getsamplefilebypagename(requestData).subscribe({
     next:(res)=>{
       if(res?.isSuccess){
           this.FilePath = res?.response;
       }
     },error:(err)=>{
      
     }
   })
 }

 downloadSampleFile() {
  this.excelConverterServiceService.downloadXlsx(this.FilePath);
 }

 onFileSelected(event: any) {
  this.selectedFile = event.target.files[0];
  this.fileName = this.selectedFile.name;
}

uploadFile() {
  if (this.selectedFile) {
    this.productOpeningService.uploadFile(this.selectedFile).subscribe({
      next:(res)=>{
        if(res?.isSuccess){
          this.toaster.success(res?.message);
          this.getAllProductList();
          this.selectedFile = null;
          this.fileInput = null;
          this.fileName =null;
        }
      },error:(err)=>{
        
      }
    })
  }
}
deleteIconClicked(params){
  this.onDeleteRowClickedResponse(params?.detail?.data);
  this.productOpening      = new ProductOpening();
  this.getAllTax(); 
  this.getCharacteristicsByProductId(0); 

}
onTextCellClick(event) {
  this.openFieldValuePopUp(event);
}


openFieldValuePopUp(params){
  const valuesOrFunction = params?.values;
  const responce = typeof valuesOrFunction === 'function' ? valuesOrFunction() : valuesOrFunction??[];
  var data = {
    label : params?.colDef?.headerName,
    data : responce,
    selectedData : params?.data?.collectionsIds
      ? responce?.filter(item => params.data.collectionsIds.map(id => id.toString()).includes(item.id))
      : [],
  }
  this.notificationService.openModalPopup(FieldValueComponent,data,undefined,ModalPopupSize.MD,'',false,true).then(
  (result)=>{
    if (result?.length) {
      const value = `${result?.length}`;
      params?.node?.setDataValue('collectionsIds', value);
      const ids = Array.isArray(result) && result.length > 0 && typeof result[0] === 'object' && 'id' in result[0]
      ? result.map(item => Number(item.id))
      : [];
      params.data.collectionsIds = ids;
      params?.api?.refreshCells();
    } else {
      const value = `${0}`;
      params?.node?.setDataValue('collectionsIds', value);
      params.data.collectionsIds = [];
      params?.api?.refreshCells();
    }
  })
}

  ngOnDestroy() {
    this.gridApiProductOpening?.api?.setRowData([]);
    this.plusIconSubscription?.unsubscribe();
    this.onFilterSubscription?.unsubscribe();
    !this.data?.hide_Header? this.sendDataService.headerPageNameAndInvoiceNum.next(undefined):null;
    this.storageservice.removeItem(StorageName.PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID_INITIAL_ROWS);
    this.productOpeningService.previouscellClickedId =null;
    this.storageService.removeItem(StorageName.APIREQUEST_PRODUCT_PRODUCT_OPENING_GRID);
  }
}
