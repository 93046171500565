import { Injectable } from '@angular/core';
import packageJson from '../package.json';

@Injectable({
  providedIn: 'root',
})
export class AppInfoService {
  public readonly version: string = packageJson.version;

  getAppVersion(): string {
    return this.version;
  }
}
