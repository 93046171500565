import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ResponseModel } from './../../../shared/models/response-model.model'  ; 
import { DataService } from './../../../shared/services/data.service';
@Injectable({
  providedIn: 'root'
})
export class MetalSectionChildAppraisalService {

  constructor(public service: DataService) { }

  uploadScannoxPlannerFile(data:any):Observable<ResponseModel>{
    let url = "api/app/appraisal/importscanoxplannerfile";
    const json = JSON.stringify(data);
    const formData = new FormData();
    formData.append('file', data);
    
    return this.service.postWithFile(url,formData, true,null,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
