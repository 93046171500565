import { Injectable, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { SelectWithIconComponent } from '../common-components/select-with-icon/select-with-icon.component';
import { environment } from 'src/environments/environment';
import { convertUtcToLocalDate } from 'src/app/shared/utils/common-utils';

import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { FileCategory, FileFormats } from '../enum/common-enum';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public toaster: ToastrService;
  public baseUrl =environment.baseUrl;
  appliedData :any =0;
  groupData:any = [];
  constructor(
    public renderer: Renderer2 ,
  ) {}
  @ViewChild('selectComponent') selectComponent: SelectWithIconComponent;

   //#region error handling
   public handleError(error: any,gridApi?) {
    this.hideLoading(gridApi);
    if(error?.error?.message != "Input validation failed!")
      // this.errorpopup(error?.error?.message);
    // this.toaster.error(error?.error?.errors[0]);
    return throwError(error);
  }

  generateRandomString(characters, length = 5): string {
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
  
    return result;
  }

  isBlankUndefinedOrNull(value)
  {
    if(value == '' || value == undefined || value == null)
      return true
    else
      return false;
  }

  
   formatNgbDateToString(ngbDate: any): string {
    const year = ngbDate.year;
    const month = ngbDate.month < 10 ? '0' + ngbDate.month : ngbDate.month; // Add leading zero if needed
    const day = ngbDate.day < 10 ? '0' + ngbDate.day : ngbDate.day;         // Add leading zero if needed
  
    return `${year}-${month}-${day}`;
  }
  //#endregion
  convertStringToEnum<T>(enumType: T, enumValueName: string): T[keyof T] {
    // Assuming enumValueName is a valid enum name
    return enumType[enumValueName.replace(/\s-/g, '') as keyof T];
  }

  getEnumValue(enumType: any, enumValueName: string): number | undefined {
    const enumValues = enumType;
    const stringEnumValue = enumValueName.replace(/[\s-]/g, ''); // Removing both spaces and dashes
    const matchingValue = enumValues[stringEnumValue];

    return matchingValue as number | undefined;
}

removeHtmlTagsWithRegex(htmlString: string): string {
  return htmlString.replace(/<\/?[^>]+>/gi, '').replace(/\n/g, '').replace(/&nbsp;/g, '');
}

removeWhatsappTagsWithRegex(messageString: string): string {
  return messageString.replace(/<\/?[^>]+>/gi, '').replace(/&nbsp;/g, '');
}

dateConvertorByRegion(paramDate = undefined) {
  const datePipe = new DatePipe(navigator.language);
  var newDateReturn = datePipe.transform((paramDate == '' || paramDate == undefined) ? new Date() : paramDate, 'shortDate'); 
  const formattedDate = new Date(newDateReturn).toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' });
  return formattedDate; 
}

getUserTimeZone() : string {
  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
 return timeZone; 
}

getNumericValues(input: string): string {
  return input.match(/\d+/g)?.join('') || '';
}

  
  // onkeydown method focus next input start
  onKeydown(event: KeyboardEvent | any, nextElement:string,previousElement?:string) {

    if (event.key === 'Enter') {
      event?.preventDefault();
      // let element = document.getElementById(nextElement) as HTMLInputElement;
      // // const element = this.renderer?.selectRootElement(nextInput);
      // element?.focus();
    // element?.select();

    event?.preventDefault();
    let element = document?.getElementById(nextElement) as any;
      if(event?.srcElement?.nodeName == 'SELECT') 
      {
        if(event?.target?.value) {
          if(element?.nodeName == 'SELECT')
          {
            element?.focus();
            element?.showPicker()
          } else element?.focus();
        }
        else  {
          event?.srcElement?.focus();
          event?.srcElement?.showPicker()
        }
      } else element?.focus();
    }
    if(event?.key=='Tab' && nextElement!=undefined){
      // event.preventDefault();
      // let element = document.getElementById(nextElement) as HTMLInputElement;
      // element?.focus();
    }
    else if (event?.key === 'Backspace' && event?.shiftKey) {
      // event.preventDefault();
      // // Your logic for Shift + Backspace
      // 
    }
  }
 // onkeydown method focus next input end


 getNextElementWithTabIndex(currentElement) {
  var elementsWithTabIndex = document.querySelectorAll('[tabindex="0"]');
  var currentIndex = Array?.from(elementsWithTabIndex)?.indexOf(currentElement);
  return elementsWithTabIndex[currentIndex + 1] || null;
}

 onKeydownSelect(event: KeyboardEvent, inputField: HTMLInputElement): void {
  if (event.key === 'Enter') {
    // Select the text in the input field
    inputField.select();
  }
}

keyDown(event,nextElement){
  
  if (event.key === 'Tab') {
    event.preventDefault();
   if(nextElement){
    let element = document.getElementById(nextElement) as HTMLInputElement;
    element?.focus();
    }
  }
}

  extractAndGroupingProperties(data, ...properties) {
    return data.map(item => {
      const extractedProperties = {};
      properties.forEach(property => {
        extractedProperties[property] = item[property];
      });
      return extractedProperties;
    });
  }
 convertToNum(value: string): number {
  const numValue = parseFloat(value);
  return isNaN(numValue) ? 0 : parseFloat(numValue.toFixed(2));
}

  // errorpopup(message, title = null) {
  //   const modalOption: NgbModalOptions = {
  //     ...commonModalOptions,
  //     size            : "sm",
  //   };
  //   const modalRef = this.notificationService.openModalPopup(ErrorPopupComponent, modalOption);
  //   modalRef.componentInstance.title = title;
  //   modalRef.componentInstance.message = message;
  //   Swal.fire({
  //     title: '',
  //     html: message,
  //     // icon: 'error',
  //     iconHtml:`<svg id="Group_4453" data-name="Group 4453" xmlns="http://www.w3.org/2000/svg" width="40.139" height="40.135" viewBox="0 0 60.139 60.135">
  //     <path id="Path_2405" data-name="Path 2405" d="M321.848-1258.059a30.1,30.1,0,0,1,30.056,29.953,30,30,0,0,1-30.312,30.182,29.972,29.972,0,0,1-29.827-29.968A30.047,30.047,0,0,1,321.848-1258.059Z" transform="translate(-291.764 1258.059)" fill="#e11330"/>
  //     <path id="Path_2406" data-name="Path 2406" d="M398.632-1154.222c.223.044.324.217.453.346q4.522,4.515,9.03,9.045c.368.372.552.432.959.023,3.022-3.033,6.079-6.03,9.109-9.054.335-.334.529-.392.873-.012a23.372,23.372,0,0,0,1.95,1.946c.451.394.331.6-.032.956-3.041,2.984-6.056,5.993-9.1,8.975-.359.353-.405.53-.022.912,3.044,3.039,6.057,6.11,9.1,9.156.323.324.368.5.009.829-.679.618-1.327,1.272-1.95,1.948-.31.336-.484.281-.782-.016q-4.5-4.489-9.032-8.953c-.594-.588-.593-.588-1.161-.02q-4.474,4.474-8.94,8.957c-.322.325-.5.37-.832.02-.646-.681-1.328-1.331-2.026-1.959-.325-.293-.324-.465-.012-.778,3.038-3.046,6.051-6.117,9.1-9.155.392-.392.382-.577-.006-.96-3.015-2.98-5.994-6-9.014-8.971-.416-.41-.363-.608.021-.961.692-.634,1.337-1.321,2-1.984C398.426-1154.035,398.534-1154.13,398.632-1154.222Z" transform="translate(-378.535 1171.613)" fill="#fff"/>
  //   </svg>`,
  //     width: 350,
  //     backdrop: false,
  //   }).then((result) => { });
  // }
  
  // warningpopup(message) {
  //   Swal.fire({
  //     title: '',
  //     html: message,
  //     position: 'center',
  //     width: 350,
  //     heightAuto: false,
  //     backdrop: false,
  //     iconHtml:`<svg id="Group_4452" data-name="Group 4452" xmlns="http://www.w3.org/2000/svg" width="58.127" height="54.134" viewBox="0 0 58.127 54.134">
  //     <path id="Path_2407" data-name="Path 2407" d="M-119.2-1436.727q-12.4,0-24.8,0a4.151,4.151,0,0,1-4.2-3.243,3.793,3.793,0,0,1,.515-2.7q5.107-9.506,10.207-19.018,7.191-13.394,14.381-26.788a4.165,4.165,0,0,1,3.891-2.374,4.163,4.163,0,0,1,3.9,2.364q10.126,18.883,20.267,37.758c1.42,2.646,2.776,5.329,4.281,7.926,1.792,3.09-.642,6.1-3.694,6.087C-102.7-1436.745-110.95-1436.727-119.2-1436.727Z" transform="translate(148.278 1490.851)" fill="#fec65b"/>
  //     <path id="Path_2408" data-name="Path 2408" d="M9.169-1412.259c-.086-3.63-.191-7.261-.251-10.892a2.729,2.729,0,0,1,3.658-2.658,2.7,2.7,0,0,1,1.842,2.695c-.065,3.234-.158,6.468-.231,9.7-.093,4.09-.174,8.18-.267,12.27a2.237,2.237,0,0,1-2.272,2.257,2.236,2.236,0,0,1-2.233-2.231q-.134-5.572-.251-11.143Z" transform="translate(17.41 1436.832)" fill="#32303f"/>
  //     <path id="Path_2409" data-name="Path 2409" d="M10.4-1238.775a3,3,0,0,1,2.99,2.981,3.029,3.029,0,0,1-3.023,3.023,3.032,3.032,0,0,1-2.99-2.994A3,3,0,0,1,10.4-1238.775Z" transform="translate(18.689 1280.993)" fill="#32303f"/>
  //   </svg>`
  //     // timer: 1500,
  //     // showConfirmButton: false,
  //     // icon: 'warning'
  //   });
  // }

  deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(this.deepClone);
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, this.deepClone(value)])
    );
  }

  fixAmountDecimal(number, noOfDecimal: number = 2): any {
    if (number == undefined)
      number = 0;
    number = Number(number);
    var data = number.toFixed(noOfDecimal);
    return data === 'NaN' ? '0.00' : data;
  }

  fixWeightDecimal(number, noOfDecimal: number = 3): any {
    if (number == undefined)
      number = 0;
    number = Number(number);
    var data = number.toFixed(noOfDecimal);
    return data;
  }

  fixWeightDecimalString(number, noOfDecimal: number = 3): any {
    if (number == undefined)
      number = 0;
    number = Number(number);
    var data = number.toFixed(noOfDecimal);
    return data;
  }

  firstLetterSmallCaps(input: string): string {
    if (input.length === 0) {
      return input;
    }

    const firstLetter = input.charAt(0).toLocaleLowerCase('en-US');
    return `${firstLetter}${input.substring(1)}`;
  }

  removeBrTags(description: string) {
    return description = description?.replace(/<br\s*\/?>/gi, '');
  }


  utcToLocalDate(utcDate: string) {
    return convertUtcToLocalDate(utcDate);
  }


  isBase64Image(imageUrl: string | null | undefined): string | undefined {
    // Check if the string starts with a data URI prefix for an image
    if (!imageUrl)
      return '';
    // /^data:(image\/[a-zA-Z]+|video\/mp4);base64,/

    if (/^data:([a-zA-Z]+\/[a-zA-Z0-9-.+]+);base64,/.test(imageUrl) || imageUrl?.startsWith(environment?.baseUrl) || imageUrl == null || imageUrl?.trim() === "") {
      return imageUrl;
  } else {
      return environment.baseUrl + imageUrl;
  }
  }


  //for reading file when img upload  //ngx dropzone
  readThis(event: any): Promise<string | null> {
    return new Promise((resolve) => {
      const file: File = event;
      const myReader: FileReader = new FileReader()
      myReader.onloadend = (e) => {
        const base64String = myReader.result as string;
        resolve(base64String);
      };
      myReader.onerror = (error) => {
        console.error("File reading failed:", error);
        resolve(null);
      };
      if (file) {
        myReader.readAsDataURL(file);
      } else {
        console.error("No file selected.");
        resolve(null);
      }
    });
  }


  //#region export file xlsx

  exportExcel(header: any[], dataList: any[], fileName: string): void {
    const modifiedDataList = dataList.map(item => {
      return item.map(value => {
        return typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value;
      });
    });
    const transposedHeader = [header.map(h => [h])];
    /* generate worksheet */
    const data = [...transposedHeader, ...modifiedDataList];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }

  //#endregion

  showLoading(params) {
    setTimeout(() => {
      params?.api.showLoadingOverlay();
    }, 0);
  }
  hideLoading(params) {
    params?.api?.hideOverlay();
  }

  //#region //convert image url to base64
  convertToBase64(imageUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // To handle CORS for external images
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const base64 = canvas.toDataURL('image/jpeg'); // Convert image to base64
        resolve(base64); // Resolve the promise with the base64 data
      };
      img.onerror = (error) => {
        reject(error); // Reject the promise if there's an error loading the image
      };
      img.src = imageUrl;
    });
  }


  //#endregion

  public markFormAsTouchedAndDirty(form: NgForm) {
    Object.keys(form?.controls).forEach(field => {
      const control = form?.controls[field];
      control?.markAsTouched({ onlySelf: true });
      control?.markAsDirty({ onlySelf: true });
    });
  }


  // for generate Random GUID

  generateGUID(): string {
    function s4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  //#region for selected from date And to date in Advanced filterd popup
  selectedDateRange(date: any): string {
    if (typeof date === 'object') {
      const year = date?.year;
      const month = ('0' + date?.month).slice(-2);
      const day = ('0' + date?.day).slice(-2);
      return `${year}-${month}-${day}`;
    }
    return date;
  }
  
  updateFiltersFromLayoutDetails(
    requestModel: any, storageService: any
  ): void {
    const layoutDetails = storageService.retrieve('layoutDetails');
    if (layoutDetails?.branchId) {
      const newRule = {
        data: layoutDetails.branchId.toString(),
        field: 'branchId',
        op: 'eq'
      };
      const filters = requestModel.filters
        ? JSON.parse(requestModel.filters)
        : { rules: [], groups: [] };
      const existingRule = filters.rules.find((rule: any) => rule.field === 'branchId');

      if (existingRule) {
        existingRule.data = layoutDetails.branchId.toString();
      } else {
        filters.rules.push(newRule);
      }
      return filters;
    }
  }

  filterCount(resultData: any) {
    const filterExcludeData = ['todate', 'toduedate', 'toorderdate', 'tocreateddate', 'toinvoicedate', 'tochequedate', 'topaydate','toduedate','toorderdate','todepositedate','toreleasedate','totaskdate','tocompletiondate','estimatetodate','fromclosingdate','toclosingdate','tojoiningdate','tomaturitydate' ]; 

    // Filter out fields from `rules` and calculate the count, handling undefined cases with optional chaining
    const ruleCount = resultData?.filters?.rules?.filter(item =>
      !filterExcludeData.includes(item?.field?.toString().toLowerCase())
    ).length ?? 0;
  
    // Calculate the total count by adding the length of `groups` if present
    const groupCount = resultData?.filters?.groups?.length ?? 0;
  
    return ruleCount + groupCount || '';
  }

  //#region for selected currentBranch in advanced filter popup


  // #region for convert Date To String
  convertDateToString(dateInput: any): string {
    // If the input is already a string, return it as-is
    if (typeof dateInput === 'string') return dateInput;
  
    // If the input is an object, format it to "YYYY-MM-DD"
    if (dateInput && typeof dateInput === 'object') {
      const { year, month, day } = dateInput;
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }
  
    return ''; // Return an empty string if input is invalid
  }

  // export(res , exportAs){
  //   if(res?.isSuccess){
  //     if(exportAs == ExportAs.PDF ){
  //       this.reportService?.handleResponcePrint(res);
  //     }else{
  //       this.excelConverterServiceService?.downloadXlsx(res?.response);
  //     }
  //       this.toaster.success(res?.message);
  //   }else{
  //     this.toaster.error(res?.errors[0]);
  //   }
  // }

  getCategoryFromBase64Url(url: string): string {
    // Check if the input is a valid Base64 string
    const base64Pattern = /^data:(.+?);base64,/;
    const match = url?.match(base64Pattern);
  
    if (match) {
      // Base64 URL detected
      const mimeType = match[1];
  
      // Check and return category based on MIME type
      if (mimeType.startsWith('image/')) {
        return FileCategory.IMAGE;
      } else if (mimeType === FileFormats.PDF) {
        return FileCategory.PDF;
      } else if (mimeType.startsWith('video/')) {
        return FileCategory.VIDEO;
      } else if (mimeType === FileFormats.XLSX) {
        return FileCategory.EXCEL;
      } else if (mimeType === FileFormats.DOCX || mimeType === FileFormats.DOC) {
        return FileCategory.DOC;
      } else if (mimeType === FileFormats.TXT) {
        return FileCategory.TXT;
      }
       else {
        return FileCategory.OTHER;  // For other MIME types like text, JSON, etc.
      }
    } else {
      // Regular URL (not Base64)
      const extensionPattern = /\.(png|jpe?g|gif|bmp|webp)$/i;
      if (extensionPattern.test(url)) {
        return FileCategory.IMAGE;
      } else if (url?.endsWith('.pdf')) {
        return FileCategory.PDF;
      } else if (url?.endsWith('.mp4') || url?.endsWith('.webm') || url?.endsWith('.ogg')) {
        return FileCategory.VIDEO;
      } else if (url?.endsWith('.xlsx')) {
        return FileCategory.EXCEL;
      } else if (url?.endsWith('.doc') || url?.endsWith('.docx')) {
        return FileCategory.DOC;
      } else if (url?.endsWith('.txt')) {
        return FileCategory.TXT;
      }
       else {
        return FileCategory.OTHER;  // For other types of URLs
      }
    }
  }


  isNgbDate(obj: any): boolean {
    return obj instanceof NgbDate;
  }

 // #region start datewise filter data start
  getThisWeekDate(){
    const now = new Date();
    const dayOfWeek = now.getDay();
    const datePipe = new DatePipe('en-US'); 
    const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    const monday = new Date(now);
    monday.setDate(now.getDate() + diffToMonday);
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    return {
      fromDate: datePipe.transform(monday, 'yyyy-MM-dd'),
      toDate: datePipe.transform(sunday, 'yyyy-MM-dd')
    };
  }

  getYearToDate(): { fromDate: string, toDate: string } {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), 0, 1); // January 1st
    const datePipe = new DatePipe('en-US'); 
    return {
      fromDate: datePipe.transform(startDate, 'yyyy-MM-dd'),
      toDate: datePipe.transform(today, 'yyyy-MM-dd')
    };
  }

  getDates(monthOffset: number): { fromDate: string, toDate: string } {
    const now = new Date();
    const datePipe = new DatePipe('en-US'); 
    const targetMonth = now.getMonth() + monthOffset;
    const year = targetMonth < 0 ? now.getFullYear() - 1 : now.getFullYear();
    const month = targetMonth < 0 ? 11 : targetMonth;
    
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    return {
      fromDate: datePipe.transform(firstDay, 'yyyy-MM-dd'),
      toDate:  datePipe.transform(lastDay, 'yyyy-MM-dd')
    };
  }
  // #region start datewise filter data
  
}
