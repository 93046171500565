import { Component, EventEmitter, Input, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DiamondCategory, MetalType, ModalPopupSize } from 'src/app/shared/models/common.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { CompressImageService } from 'src/app/shared/services/compress-image.service';
import { AppraisalTabEnum } from '../metal-section-child-appraisal.model';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CalculationType, IconHover, StorageName } from 'src/app/shared/enum/common-enum';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CustomerDetailsComponent } from '../../common-voucher/popup/customer-details/customer-details.component';
import { CustomerDetailsService } from '../../common-voucher/popup/customer-details/customer-details.service';
import { AgGridService } from '../../ag-grid/ag-grid.service';
import { MetalSectionService } from '../../metal-section/metal-section.service';
import { catchError } from 'rxjs';
import { AdministrationService } from 'src/app/components/administration/administration.service';
import {MetalSectionChildAppraisalService} from '../metal-section-child-appraisal.service'
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-app-metal-appraisal',
  templateUrl: './app-metal-appraisal.component.html',
  styleUrls: ['./app-metal-appraisal.component.scss']
})
export class AppMetalAppraisalComponent extends CommonService {
  formModel: any = {};
  @Input() data: any = {};
  @Input() tabId;
  @Output() addRowToMetalGrid = new EventEmitter<any>();
  @Output() tabChange = new EventEmitter<any>();
  appraisalTabEnum: typeof AppraisalTabEnum = AppraisalTabEnum;
  udfDropdowns: any = {};
  filterdiamondCategoryDropdown: any;
  filterProductDropdown: any;
  filtercaratDropdown: any;
  accountLedgerData: any
  @ViewChild("MetalchildAppraisal") metalchildAppraisal!: NgForm;
  @Input() tabName;
  fileData : any = {};
  FileName : any;
  currentHoverEffect = IconHover.Pink;
  CategoryDropdown: any;
  constructor(public compressImageService: CompressImageService,public metalSectionChildAppraisalService:MetalSectionChildAppraisalService, public administrationService: AdministrationService, private metalSectionService: MetalSectionService, private agGridService: AgGridService, public customerDetailsService: CustomerDetailsService, public storageService: StorageService,
    public notificationService: NotificationService, public renderer: Renderer2,public toaster : ToastrService) {
    super(renderer);
  }

  onTabChange(tabId, oldFormModel = {}): void {
    this.tabId = tabId;
    this.formModel = oldFormModel;
    this.setSelectedValues()
  }

  updateDropdownOptions(): void {
    this.filterdiamondCategoryDropdown = this.CategoryDropdown?.filter(x => x.id != '0' && (this.allowedOptionsMap[this.tabId] || [])?.includes(Number(x.id)));
  }
  
  ngAfterViewInit() {
    this.CategoryDropdown = this.storageService.retrieve(StorageName.DIAMOND_CATEGORY_DROPDOWN);
    this.filterProductDropdown = this.data?.getAllDropdownModel?.productDropdown?.filter(x => x?.extra1 == MetalType.Diamond);
    this.filtercaratDropdown = this.data?.getAllDropdownModel?.caratDropdown?.filter(x => x.extra1 == MetalType.Gold);
  }

  setSelectedValues() {
    switch(this.tabId)
    {
      case AppraisalTabEnum.Jewellery: this.formModel.diamondCategoryId = DiamondCategory.Jewellery;  break;
      case AppraisalTabEnum.DiamondAndGemstone:this.formModel.diamondCategoryId =this.formModel.diamondCategoryId ? this.formModel.diamondCategoryId :DiamondCategory.Diamonds;  break;
    }
    if(this.formModel.calculationType == undefined)
      this.formModel.calculationType = CalculationType.Fix
  }

  allowedOptionsMap = {
    [1]: [DiamondCategory.Jewellery],
    [2]: [DiamondCategory.Diamonds, DiamondCategory.GemStones],
  };

  setUdf() {
    this.data.getAllDropdownModel?.udfDropdown?.filter((x) => x?.extra4 == MetalType.Diamond)?.forEach(item => {
    });
    this.data.getAllDropdownModel?.udfDropdown?.filter((x) => x?.extra4 == MetalType.Diamond)?.forEach(item => {
      this.udfDropdowns[item?.extra3] = {
        name: item?.name,
        options: item?.extra2 ? JSON?.parse(item?.extra2)?.map(udf => ({ id: udf, name: udf })) : []
      };
    });
  }

  ngOnInit(): void {
    this.customerDetailsData();
    this.setUdf()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDropdownOptions();
  }
  
  isUDFActive(value) {
    return this.data.getAllDropdownModel?.udfDropdown?.map((x) => x?.extra3)?.includes(value);
  }

  getUDFName(value: string): string | undefined {
    const udf = this.data.getAllDropdownModel?.udfDropdown?.find((x) => x.extra3 === value);
    return udf?.name;
  }


  AddRowToMetalGrid() {
    this.formModel.added= !this.formModel.id,
    this.formModel.edited= !!this.formModel.id,
    this.formModel.tabId =this.tabId
    this.formModel.id = this.formModel.id || this.generateUniqueId()
    this.setSelectedValues();
    this.addRowToMetalGrid.emit(this.formModel);
    this.formModel = {};
    this.setSelectedValues();
  }

  Continue() {
    switch(this.tabId)
    {
      case AppraisalTabEnum.Jewellery :           this.tabChange.emit(AppraisalTabEnum.DiamondAndGemstone); break;
      case AppraisalTabEnum.DiamondAndGemstone :  this.tabChange.emit(AppraisalTabEnum.Summary);            break;
    }
  }


  generateUniqueId(): number {
    return Date.now() + Math.floor(Math.random() * 1000);
  }

  openCustomerDetailsPopup(type: string, ledgerName: string = '') {
    var data = {
      accountLedgerId: type === "Edit" ? this.data.formModel?.invoiceModel?.accountLedgerId : 0,
      SundryType: this.data.formModel.sundryType,
      ledgerName: ledgerName
    }
    this.notificationService.openModalPopup(CustomerDetailsComponent, data, undefined, ModalPopupSize.LG).then(
      (result) => {
        if (result) {
          this.data.getAllDropdownModel.accountLedgerDropdown = this.storageService.retrieve(StorageName.ACCOUNTLEDGER_DROPDOWN);
          this.customerDetailsData();
        }
      }
    )
  }

  customerDetailsData() {
    this.accountLedgerData = JSON?.parse(this.data?.getAllDropdownModel?.accountLedgerDropdown.find(x => x.id === this.data?.formModel?.invoiceModel?.accountLedgerId)?.extra2)[0];
  }

  // *****repnate start *******
  get isRapnetIconEnabled(): boolean {
    const { shapeCategoryId, carat, colorId, clarityId } = this.formModel || {};
    return !!(shapeCategoryId && carat && colorId && clarityId);
  }

  onRapnetIconClick() {
    if (this.formModel?.shapeCategoryId && this.formModel?.carat && this.formModel?.colorId && this.formModel.clarityId) {
      const requestModel = {
        shape: String(this.formModel?.shapeCategoryId),
        size: String(this.formModel?.carat),
        color: String(this.formModel?.colorId),
        clarity: String(this.formModel.clarityId),
      }
      this.agGridService?.getRapnetpriceList(requestModel)?.subscribe((res => {
        if (res?.isSuccess) {
          this.formModel.rapNetValuation = res?.response;
          this.formModel.rate = res?.response;
        } else {
          this.toaster.error(res?.message)
        }
      }))
    }
  }
  // *****repnate end *******

  onCellValueChangedDetect(modal, field) {
    this.formModel = this.metalSectionService?.calculateDiamondItem(this.formModel, field);
    var fieldArrayWeight =['grossWt','carat','weight','goldWt',];
    var fieldArrayAmount =['amount','rate','goldRate','goldAmount'];
    fieldArrayWeight?.forEach((key) => {
      if(field != key)
        this.formModel[key] = this.fixWeightDecimal(this.formModel[key] );
    });
    fieldArrayAmount?.forEach((key) => {
      if(field != key)
        this.formModel[key] = this.fixAmountDecimal(this.formModel[key] );
    });
  }


  CalculateGoldRate(id) {
    this.administrationService?.getByIdCarat(id)?.pipe(catchError((err) => this.handleError(err))).subscribe((res) => {
      if (res.isSuccess) {
        this.formModel.goldRate = res?.response?.goldRate;
        this.onCellValueChangedDetect(this.formModel, 'rate')
      } else {
        this.toaster.error(res.errors[0]);
      }
    });
  }

   //#region  Scanox Planner Import

   onInputButtonClick(event: any): void {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files ? inputElement.files[0] : null;
    if (file) {
    const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/vnd.ms-excel'];
    if (!allowedTypes.includes(file.type)) {
    this.toaster?.error('Invalid file type. Please upload an Excel file.');
    inputElement.value = '';
    return;
    }
     this.FileName = file?.name;
     this.fileData = file;
    }
    inputElement.value = '';
  }

  clearInput(): void {
    this.FileName = '';
  }

  UploadScannoxPlanner() {
    if (this.fileData) {
      this.metalSectionChildAppraisalService.uploadScannoxPlannerFile(this.fileData).subscribe({
        next: (res) => {
          if (res?.isSuccess) {
            this.toaster?.success(res?.message);
            this.formModel.carat = res?.response?.carat;
            this.onCellValueChangedDetect(this.formModel ,'carat')
            Object.keys(res.response).forEach((key) => {
            const udfKey = this.getUDFColumnName(key);
            if (udfKey) {
              this.formModel[udfKey] = String(res.response[key]);
            }
          });
            this.FileName = undefined;
            this.fileData = undefined;
          }
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }

  getUDFColumnName(value: string): string | undefined {
    const udf = this.data.getAllDropdownModel?.udfDropdown?.find(
    (x) =>x.name?.replace(/[\s%]/g, (match) => (match === ' ' ? '' : 'Per')).toLowerCase() ===value.toLowerCase());
    return udf ? `udF${this.extractNumbers(udf.extra3)}` : undefined;
  }

  extractNumbers(input: string = ''): string {
    return input.replace(/\D/g, ''); // Remove all non-digit characters
  }

  //#endregion
}
