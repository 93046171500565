import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  isHidden?:boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  public isMobile: boolean = false;
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;
  
  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    { path: "/dashboard", title: "", icon: 'dashboard', type: "link", bookmark: false },
    {
      title: "HEADER.MENUITEMS.PRODUCT",
      icon: 'downArrow',
      active: true,
      type: "sub",
      children: [
        { path: "/Product/product-opening", title: "HEADER.MENUITEMS.PRODUCT.PRODUCTOPENING", type: "link" },
        { path: "/Product/ledger-opening", title: "HEADER.MENUITEMS.PRODUCT.LEDGEROPENING", type: "link", isHidden: true },
        { path: "/Product/account-ledger", title: "HEADER.MENUITEMS.PRODUCT.ACCOUNTLEDGER", type: "link" },
        { path: "/Product/parties", title: "HEADER.MENUITEMS.PRODUCT.PARTIES", type: "link" },
        { path: "/Product/appraisal", title: "HEADER.MENUITEMS.PRODUCT.APPRAISAL", type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.TRANSACTION",
      icon: 'downArrow',
      active: false,
      type: "sub",
      children: [
        { path: "/Transaction/saleinvoice", title: "HEADER.MENUITEMS.TRANSACTION.SALEINVOICE", type: "link" },
        // { path: "/Transaction/saleinvoice-unfix", title: "HEADER.MENUITEMS.TRANSACTION.SALEINVOICEUNFIX", type: "link" },
        { path: "/Transaction/sale-fixing-direct", title: "HEADER.MENUITEMS.TRANSACTION.SALEFIXINGDIRECT", type: "link" },
        { path: "/Transaction/estimate", title: "HEADER.MENUITEMS.TRANSACTION.SALESQUOTATION", type: "link" },
        { path: "/Transaction/sale-return", title: "HEADER.MENUITEMS.TRANSACTION.SALERETURN", type: "link" },
        { path: "/Transaction/purchase-invoice", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASEINVOICE", type: "link" },
        // { path: "/Transaction/purchase-invoice-unfix", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASEINVOICEUNFIX", type: "link" },
        { path: "/Transaction/purchase-fixing-direct", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASEFIXINGDIRECT", type: "link" },
        { path: "/Transaction/purchase-quotation", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASEQUOTATION", type: "link" },
        { path: "/Transaction/purchase-return", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASERETURN", type: "link" },
        { path: "/Transaction/payment-voucher", title: "HEADER.MENUITEMS.TRANSACTION.PAYMENTVOUCHER", type: "link" },
        { path: "/Transaction/receipt-voucher", title: "HEADER.MENUITEMS.TRANSACTION.RECEIPTVOUCHER", type: "link" },
        { path: "/Transaction/material-in", title: "HEADER.MENUITEMS.TRANSACTION.MATERIAL_IN", type: "link" },
        { path: "/Transaction/material-out", title: "HEADER.MENUITEMS.TRANSACTION.MATERIAL_OUT", type: "link" },
        { path: "/Transaction/deliverynote", title: "HEADER.MENUITEMS.TRANSACTION.DELIVERYNOTE", type: "link" },
        { path: "/Transaction/old-jewelry-scrap-invoice", title: "HEADER.MENUITEMS.TRANSACTION.OLDJEWElRY_SCRAP_INVOICE", type: "link" },
        { path: "/Report/old-jewellery", title: "HEADER.MENUITEMS.REPORT.OLDJEWELLERY", type: "link" },
        { path: "/Transaction/material-issue", title: "HEADER.MENUITEMS.TRANSACTION.MATERIAL_ISSUE", type: "link" },
        { path: "/Transaction/material-receive", title: "HEADER.MENUITEMS.TRANSACTION.MATERIAL_RECEIVE", type: "link" },
        { path: "/Transaction/credit-note", title: "HEADER.MENUITEMS.TRANSACTION.CREDITNOTE", type: "link" },
        { path: "/Transaction/debit-note", title: "HEADER.MENUITEMS.TRANSACTION.DEBITNOTE", type: "link" },
        { path: "/Transaction/stock-journal", title: "HEADER.MENUITEMS.TRANSACTION.STOCKJOURNAL", type: "link" },
        { path: "/Transaction/stock-journal-2", title: "HEADER.MENUITEMS.TRANSACTION.STOCK-JOURNAL-2", type: "link" },
        { path: "/Transaction/pdc-entry", title: "HEADER.MENUITEMS.TRANSACTION.PDCENTRY", type: "link" },
        { path: "/Transaction/contra-voucher", title: "HEADER.MENUITEMS.TRANSACTION.CONTRAVOUCHER", type: "link" },
        { path: "/Transaction/journal-voucher", title: "HEADER.MENUITEMS.TRANSACTION.JOURNALVOUCHER", type: "link" },
        { path: "/Transaction/repair-invoice", title: "HEADER.MENUITEMS.TRANSACTION.REPAIRINVOICE", type: "link" },
        { path: "/Transaction/pos", title: "HEADER.MENUITEMS.TRANSACTION.POS", type: "link" },
        { path: "/Transaction/investment-fund", title: "HEADER.MENUITEMS.TRANSACTION.INVESTMENTFUND", type: "link" },
        { path: "/Transaction/expenses", title: "HEADER.MENUITEMS.TRANSACTION.EXPENSES", type: "link" },
        { path: "/Transaction/loans", title: "HEADER.MENUITEMS.TRANSACTION.LOANS", type: "link" },
        { path: "/Transaction/bank-reconciliation", title: "HEADER.MENUITEMS.TRANSACTION.BANKRECONCILIATION", type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.ORDERS",
      icon: 'downArrow',
      active: false,
      type: "sub",
      children: [
        { path: "/Transaction/sale-order", title: "HEADER.MENUITEMS.TRANSACTION.SALEORDER", type: "link" },
        { path: "/Orders/repair-order", title: "HEADER.MENUITEMS.ORDERS.REPAIRORDER", type: "link" },
        { path: "/Orders/sale-repair-order-process", title: "HEADER.MENUITEMS.ORDERS.SALEREPAIRORDERPROCESS", type: "link" },
        { path: "/Transaction/purchase-order", title: "HEADER.MENUITEMS.TRANSACTION.PURCHASEORDER", type: "link" },
        { path: "/Orders/job-work-order-manufacturing", title: "HEADER.MENUITEMS.MANUFACTURER.JOBWORKORDER", type: "link" },
        { path: "/Orders/jobwork-order-outsource", title: "HEADER.MENUITEMS.ORDERS.JOBWORKORDEROUTSOURCE", type: "link" },

        // { path: "/Orders/cart", title: "HEADER.MENUITEMS.ORDERS.CART", type: "link" },
        // { path: "/Orders/orderlist", title: "HEADER.MENUITEMS.ORDERS.ORDERLIST", type: "link" },
        // { path:"/Orders/ordertracking" , title:"HEADER.MENUITEMS.ORDERS.ORDERTRACKING" , type: "link"},
        // { path:"/Orders/joborder" , title:"HEADER.MENUITEMS.ORDERS.JOBORDER" , type: "link"}
        { path: "/Orders/catalogue-quotation", title: "HEADER.MENUITEMS.ORDERS.CATALOGUEQUOTATION", type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.INVENTORY",
      icon: 'downArrow',
      active: false,
      type: "sub",
      children: [
        { path: "/Inventory/jewelry-Catalogue", title: "HEADER.MENUITEMS.INVENTORY.JEWELRYCATALOGUE", type: "link" },
        { path: "/Inventory/jewelry-Catalogue/jewelry-Catalogue-detail", title: "HEADER.MENUITEMS.INVENTORY.JEWELRY_CATALOGUE_DETAIL", type: "link", isHidden: true },
        { path: "/Inventory/physical-stock", title: "HEADER.MENUITEMS.REPORT.PHYSICAL_STOCK", type: "link" },
        { path: "/Inventory/consignment-in", title: "HEADER.MENUITEMS.INVENTORY.MEMO_CONSIGNMENT_IN", type: "link" },
        { path: "/Inventory/consignment-out", title: "HEADER.MENUITEMS.INVENTORY.MEMO_CONSIGNMENT_OUT", type: "link" },
        { path: "/Inventory/memo-report", title: "HEADER.MENUITEMS.INVENTORY.MEMO_CONSIGNMENT_ITEMS", type: "link" },
        { path: "/Inventory/stock-history", title: "HEADER.MENUITEMS.INVENTORY.STOCK-SUMMARY", type: "link" },
        { path: "/Inventory/gold-silver-analysis", title: "HEADER.MENUITEMS.INVENTORY.GOLD_SILVER_ANALYSIS", type: "link" },
        { path: "/Inventory/diamond-caratwise", title: "HEADER.MENUITEMS.INVENTORY.DIAMOND_STONE_WEIGHT_REPORT", type: "link" },
        { path: "/Inventory/gold-and-silver", title: "HEADER.MENUITEMS.INVENTORY.GOLD_AND_SILVER", type: "link" },
        { path: "/Inventory/diamond-and-stones", title: "HEADER.MENUITEMS.INVENTORY.DIAMOND_AND_STONE", type: "link" },
        { path: "/Inventory/immitation-and-watches", title: "HEADER.MENUITEMS.INVENTORY.IMITATION_AND_WATCHES", type: "link" },
        { path: "/Inventory/rfid", title: "HEADER.MENUITEMS.INVENTORY.RFID", type: "link" },
        { path: "/Inventory/barcode", title: "HEADER.MENUITEMS.INVENTORY.BARCODE", type: "link" },
        { path: "/Inventory/stock-valuation", title: "HEADER.MENUITEMS.INVENTORY.STOCK_VALUATION", type: "link" },
        { path: "/Inventory/stock-summary-report", title: "HEADER.MENUITEMS.INVENTORY.STOCK_SUMMARY_REPORT", type: "link" },
        { path: "/Inventory/stock-transfer", title: "HEADER.MENUITEMS.INVENTORY.STOCK_TRANSFER", type: "link" },
        { path: "/Inventory/stock-transfer-history", title: "HEADER.MENUITEMS.INVENTORY.STOCK_TRANSFER_HISTORY", type: "link" },
      ],

    },
    {
      title: "HEADER.MENUITEMS.MANUFACTURER",
      icon: 'downArrow',
      active: false,
      type: "sub",
      children: [
        { path: "/Manufacturer/department", title: "HEADER.MENUITEMS.MANUFACTURER.DEPARTMENT", type: "link" },
        { path: "/Manufacturer/department-report", title: "HEADER.MENUITEMS.MANUFACTURER.DEPARTMENTREPORT", type: "link" },
        { path: "/Manufacturer/jobwork-queue", title: "HEADER.MENUITEMS.MANUFACTURER.JOBWORKQUEUE", type: "link" },
        { path: "/Manufacturer/manufacturing-process", title: "HEADER.MENUITEMS.MANUFACTURER.MANUFACTURINGPROCESS", type: "link" },
        { path: "/Manufacturer/jobwork-queue-report", title: "HEADER.MENUITEMS.MANUFACTURER.JOBWORKQUEUEREPORT", type: "link" },
        { path: "/Manufacturer/loss-tracking", title: "HEADER.MENUITEMS.MANUFACTURER.LOSSTRACKING", type: "link" },
        { path: "/Manufacturer/closing-report", title: "HEADER.MENUITEMS.MANUFACTURER.CLOSINGREPORT", type: "link" },
        { path: "/Manufacturer/worklog-report", title: "HEADER.MENUITEMS.MANUFACTURER.WORKLOGREPORT", type: "link" },
        { path: "/Manufacturer/jobcard-report", title: "HEADER.MENUITEMS.MANUFACTURER.JOBCARDREPORT", type: "link" },
        { path: "/Manufacturer/jobcard-print", title: "HEADER.MENUITEMS.MANUFACTURER.JOBCARDPRINT", type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.FINANCIALSTATEMENT",
      icon: 'downArrow',
      active: false,
      type: "sub",
      children: [
        { path: "/Financial-Statement/trial-balance", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.TRIALBALANCE", type: "link" },
        { path: "/Financial-Statement/balance-sheet", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.BALANCESHEET", type: "link" },
        { path: "/Financial-Statement/profit-and-loss", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.PROFITLOSS", type: "link" },
        { path: "/Financial-Statement/cash-flow", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.CASHFLOW", type: "link" },
        { path: "/Financial-Statement/fund-flow", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.FundFUlow", type: "link" },
        { path: "/Financial-Statement/chart-of-account", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.CHARTOFACCOUNT", type: "link" },
        { path: "/Financial-Statement/tax-return", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.TAXRETURN", type: "link" },
        { path: "/Financial-Statement/sale-analysis", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.SALEANALYSIS", type: "link" },
        { path: "/Financial-Statement/purchase-analysis", title: "HEADER.MENUITEMS.FINANCIALSTATEMENT.PURCHASEANALYSIS", type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.REPORT",
      icon: 'downArrow',
      active: false,
      type: "sub",
      children: [
        { path: "/Report/invoice", title: "HEADER.MENUITEMS.REPORT.INVOICE", type: "link" },
        { path: "/Report/accountledger-report", title: "HEADER.MENUITEMS.REPORT.ACCOUNTLEDGERREPORT", type: "link" },
        { path: "/Report/day-report", title: "HEADER.MENUITEMS.REPORT.DAYREPORT", type: "link" },

        { path: "/Report/cashbank-book", title: "HEADER.MENUITEMS.REPORT.CASHBANKBOOK", type: "link" },
        { path: "/Report/ageing-report", title: "HEADER.MENUITEMS.REPORT.AGEINGREPORT", type: "link" },
        // { path: "/Report/stock-report", title: "HEADER.MENUITEMS.REPORT.STOCKREPORT", type: "link" },
        // { path: "/Report/diamond-stone-stock", title: "HEADER.MENUITEMS.REPORT.DIAMOND_STONE_STOCK", type: "link" },
        // { path: "/Report/rfidStock-Report", title: "HEADER.MENUITEMS.REPORT.RFID_STOCK", type: "link" },
        // { path: "/Report/barcodestock-report", title: "HEADER.MENUITEMS.REPORT.BARCODE_STOCK", type: "link" },
        // { path: "/Report/sale-order-list", title: "HEADER.MENUITEMS.REPORT.SALEORDERLIST", type: "link" },
        { path: "/Report/daily-sales-and-collection-summary", title: "HEADER.MENUITEMS.REPORT.DAILY_SALES_AND_COLLECTION_SUMMARY", type: "link" },
        { path: "/Report/borrowers-report", title: "HEADER.MENUITEMS.REPORT.BORROWERSREPORT", type: "link" },
        { path: "/Report/kyc-report", title: "HEADER.MENUITEMS.REPORT.KYCREPORT", type: "link" },
        { path: "/Report/Ledger-Balance-Report", title: "HEADER.MENUITEMS.REPORT.LEDGERBALANCEREPORT", type: "link" },
        { path: "/Report/fixing-position", title: "HEADER.MENUITEMS.REPORT.FIXING_POSITION", type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.SETTINGS",
      icon: 'downArrow',
      active: false,
      type: "sub",
      children: [
        { path: "/Settings/set-software", title: "HEADER.MENUITEMS.SETTINGS.SETSOFTWARE", type: "link" },
        // { path: "/Settings/financial-year", title: "HEADER.MENUITEMS.SETTINGS.FINANCIALYEAR", type: "link" },
        { path: "/Settings/voucher-type", title: "HEADER.MENUITEMS.SETTINGS.VOUCHERTYPE", type: "link" },
        { path: "/Settings/data-import-export", title: "HEADER.MENUITEMS.SETTINGS.DATAIMPORTEXPORT", type: "link" },
        { path: "/Settings/data-export-tally", title: "HEADER.MENUITEMS.SETTINGS.DATAEXPORTINTALLY", type: "link" },
        // { path: "/Settings/whats-app-marketing", title: "HEADER.MENUITEMS.SETTINGS.WHATSAPP_MARKETING", type: "link" },
        // { path: "/Settings/campaign-manager", title: "HEADER.MENUITEMS.SETTINGS.CAMPAIGN_MANAGER", type: "link" },
      ],
    },
    {
      title: "HEADER.MENUITEMS.ADMINISTRATION",
      icon: 'downArrow',
      active: false,
      type: "sub",
      children: [
        { path: "/Administration/usermanagment", title: "HEADER.MENUITEMS.SETTINGS.USER MANAGMENT", type: "link" },
        { path: "/Administration/masters", title: "HEADER.MENUITEMS.SETTINGS.MASTERS", type: "link" },
        { path: "/Administration/activityLog", title: "HEADER.MENUITEMS.SETTINGS.ACTIVITYLOG", type: "link" },
        // { path: "/Administration/taskEvents", title: "HEADER.MENUITEMS.SETTINGS.TASKEVENT", type: "link" },
        { path: "/Administration/crm", title: "HEADER.MENUITEMS.SETTINGS.CRM", type: "link" },
      ],
    },
    // { title: "HEADER.MENUITEMS.ORDERS",
    //   icon: 'downArrow', 
    //   active: false, 
    //   type: "sub",
    //   children: [
    //     { path: "/Orders/cart", title: "HEADER.MENUITEMS.ORDERS.CART", type: "link" },
    //     { path: "/Orders/orderlist", title: "HEADER.MENUITEMS.ORDERS.ORDERLIST", type: "link" },
    //     { path:"/Orders/ordertracking" , title:"HEADER.MENUITEMS.ORDERS.ORDERTRACKING" , type: "link"}
    //   ], 
    // },

    // { title: "HEADER.MENUITEMS.ADMINISTRATION",icon: 'downArrow', path: "/Administration", active: false, type: "link" },

  ];

  // Array
  items = new BehaviorSubject<Menu[]>(JSON.parse(JSON.stringify(this.MENUITEMS)));

}
