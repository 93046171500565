export class SetSoftware {
}

export class VoucherTypeModel {
    id: number;
    name: string;
    typeOfVoucher: any;
    methodOfVoucherNumbering: string;
    isTaxApplicable: any;
    comment: any;
    masterId: any;
    isDefault: any;
    active: boolean;
    taxes: any;
    metal: any;

  }
 export class CommonModel{
    deleted: boolean;
    added: boolean;
    edited: boolean;
    none: boolean;
    id: number;
    discountPer?:number;
 }

  export class UpdateVoucherType{
    id: number;
    name: string;
    typeOfVoucher: any;
    methodOfVoucherNumbering: string;
    methodOfVoucherNumberingName: string;
    isTaxApplicable: any;
    comment: any;
    masterId: any;
    isDefault: any;
    active: boolean;
    // voucherTypeModel =new VoucherTypeModel();
    taxes:CommonModel[]=[];
    metal:CommonModel[]=[];
    internalFix: boolean = false;
    metalFix: boolean = false;
    doNotApplyOnStock: boolean = false;
    salesPersonMandatory: boolean = false;
    createAutoJournalVoucherWithCreditCardEntry: boolean = false;
    doNotAllowZeroAmountProduct: boolean = false;
  }

// voucherType table header end


// bill Series model
export class CreateUpdateBillSeriesRequestModel{
    id              : number;
    prefix?         : string;
    suffix?         : string;
    startCount?     : number;
    metalId?        : number;
    branchId?       : number;
    financialYearId?: string|number;
    active?         : boolean;
    metal?          : string;
    branch?         : string;
    financialYear?  : string;
    voucherTypeId?  : number;
}

export enum SettingTab {
    PrinterSetting = 1,
    VoucherSetting = 2,
    Accounting     = 3,
    BarcodeSetting = 4,
    RDLCDesign     = 5 ,   
    Insurance      = 6          ,     
    Devices        = 7,    
    BillSeries     = 8,   
    EWayBill       = 9,    
    TallyIntegration = 10,
    RewardPoint = 11,
    DataBackup  = 12,
    Template     = 13,
    SystemFields = 14,
    GridConfiguration = 15,
    ECommerceIntegration = 16,
    NotificationConfiguration = 17,
    SchedularForm = 18,
    ScheduleTask=19,
    PaymentIntegration=20,
    InvoiceReport=21,

  }

  export class RdlcInsertUpdateRequestResponceModel {
    id: number;
    voucherTypeId: number;
    branchId: number;
    isPortrait: boolean;
    reportPageWidth: number;
    reportPageHeight: number;
    rotationId: number;
    printLeft: number;
    printTop: number;
    printRight: number;
    printBottom: number;
    whatsAppLeft: number;
    whatsAppTop: number;
    whatsAppRight: number;
    whatsAppBottom: number;
    resourcePropertyModel: ResourcePropertyModel[];
    officeCopy :boolean =false;
    metalId :number;
  }

  export class ResourcePropertyModel {;
    deleted   : boolean = false;
    added     : boolean = false;
    edited    : boolean = false;
    none      : boolean = false;
    id        : number  = 0;
    active    : boolean   =false;   
    resourceTitle   : string;
    base64Resource  : string;
    mimeSize  : string;
    preview?: string;
  }

  
// Reward Point setting model
export class CreateUpdateRewardPointRequestModel{
  id                 : number;
  metalId           ?: number;
  amount            ?: number;
  point             ?: number;
  referralPoint     ?: number;
  minInvoiceAmount  ?: number;
  validityInDays    ?: number;
  isOtp             ?: boolean;
  pointValueInAmount?: number;
  redeemOn          ?: number;
  isAutoRoundOff    ?: boolean;

}


  // Database Backup setting maodel
  export class DatabaseBackupRequestModel{
    backupType       : number | null;
    backupToLocalPath: string | null;
    email            : string | null;
    password         : string | null;
    backupFilePath   : string | null;
  }

  export class RetrySqlExecutionRequestModal{
    scriptExecutionModel: ScriptExecutionModel[]
  }

  export class ScriptExecutionModel {
    filePath: string
    isSelected: boolean
    scriptType: string
    fileName: string
  }

  export enum BackupTypes
	{
		BackupToComputer = 1,
		BackupToDrive = 2,
		RestoreBackup = 3,
	}

  export class Get_RDLC_In_PDF_Model{
    id:number;
    voucherTypeId:number;
  }
