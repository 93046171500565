import { AfterViewInit, Component, Input, Renderer2 } from '@angular/core';
import { GetAllJewelryCatalogueRequestModel } from 'src/app/components/inventory/jewelry-catalogue/jewelry-catalogue-details/jewelry-catalogue-details.model';
import { JewelryCatalogueCommonService } from './jewelry-catalogue-common.service';
import { Router } from '@angular/router';
import { ModalPopupSize } from '../../models/common.model';
import { ActivatedRoute } from '@angular/router';
import { Catelogue } from './jewelry-catalogue-common.model';
import { StorageService } from '../../services/storage.service';
import { CommonService } from '../../services/common.service';
import { PaginationPageSizes } from '../../components/common-model/common-model';
import { OpenImagePopupComponent } from 'src/app/shared/common-components/common-voucher/popup/customer-details/popup/open-image-popup/open-image-popup.component';
import { LayoutDetails, User } from 'src/app/auth/auth/auth.model';
import { NotificationService } from '../../services/notification.service';
import { AdvancedFilterPopupComponent } from './advanced-filter-popup/advanced-filter-popup.component';
import { CommonFilterService } from '../../services/common-filter.service';
import { DateRangeType, StorageName } from '../../enum/common-enum';
import { catchError } from 'rxjs';
import { SendDataService } from 'src/app/shared/services/send-data.service';
@Component({
  selector: 'app-jewelry-catalogue-common',
  templateUrl: './jewelry-catalogue-common.component.html',
  styleUrls: ['./jewelry-catalogue-common.component.scss']
})

export class JewelryCatalogueCommonComponent  extends CommonService implements AfterViewInit{
  paginationPageSizes            = new PaginationPageSizes();  
  getAllJewelryCatalogueRequestModel =new GetAllJewelryCatalogueRequestModel();
  jewelryCatalogueList: GetAllJewelryCatalogueRequestModel[];
  pagination:any ={};
  totalRecords
  showNoRecord: boolean = false;
  shimmerCard
  getAllDropdownModel            : any = {};
  // apiRequestModel : any = {};
  apiRequestStorageName = StorageName.APIREQUEST_INVENTORY_JEWELRY_CATALOGUE_CARDLIST;
  filterCountValue:any =2;
  selectedCardCount:any;
  @Input() catelogueForm=Catelogue.CustomerCatelogue;
  @Input()anonymousUrl;
  catelogue = Catelogue;
  advanceFilterAppliedData  : any = {};
  AdvanceFilterDataCount: any;
  isCardLoading: boolean = false;
  selectedIds: any[] = [];
  formName = StorageName.FORMNAME_INVENTORY_JEWELRY_CATALOGUE_INFO;
  public readonly pageLimitOptions = [
   { value: 25 },
   { value: 50 },
   { value: 75 },
 ];
 user:User;
 layoutDetails:LayoutDetails;
 selectedCards: any[] = [];
  constructor(
   private jewelryCatalogueCommonService: JewelryCatalogueCommonService,
   private router: Router,
   private activatedRoute: ActivatedRoute,
   private storageService: StorageService,
   public renderer: Renderer2,
   public commonFilterService:CommonFilterService,
   private storageservice:StorageService,
   private notificationService: NotificationService,
   private sendDataService: SendDataService,
  ){
    super(renderer);

    this.shimmerCard = new Array(25)
  };

  ngOnInit(){
    this.fillDropdownData();
    this.getLoginUserDetails()
   
  }


  ngAfterViewInit(){
    this.setDefaultFilter();
  }

  setDefaultFilter() {
    this.getAllJewelryCatalogueRequestModel.filters = '';
    this.commonFilterService.setDefaultFilter(this.apiRequestStorageName, this.getAllJewelryCatalogueRequestModel, StorageName.FILTER_INVENTORY_JEWELRY_CATALOGUE_CARDLIST, { setDefaultDateRangePickerData: { dateRangeType: DateRangeType.FinancialYear} });
    this.filterCountValue =2;
   this.getAlljewelryCatalogueList();
  }

  getLoginUserDetails(){
    this.user =this.storageservice.retrieve('user');
    this.layoutDetails =this.storageservice.retrieve('layoutDetails');
  }

  getAlljewelryCatalogueList(){
    this.isCardLoading = true;
    this.jewelryCatalogueCommonService.getAlljewelryCatalogue(this.getAllJewelryCatalogueRequestModel ,this.anonymousUrl).subscribe({
       next:(res)=>{
        if(res?.isSuccess){
           this.jewelryCatalogueList = res?.response??[];
           this.totalRecords = res?.totalRecords;
           this.showNoRecord = res?.totalRecords === 0;
         }else{
          this.showNoRecord= true;
         }
         this.isCardLoading = false;
        },error:(err)=>{
          this.showNoRecord= true;
          
        }
    })
  }


onPageChanged(value) {
  this.getAllJewelryCatalogueRequestModel.page = value;
  this.getAllJewelryCatalogueRequestModel.getAll = false; 
  this.getAlljewelryCatalogueList()
}

onPageSizeChange() {
  if (this.getAllJewelryCatalogueRequestModel.pageSize == 0) {
      this.getAllJewelryCatalogueRequestModel.getAll = true;
      this.getAllJewelryCatalogueRequestModel.page = 0;
  } else {
      this.getAllJewelryCatalogueRequestModel.getAll = false;
      this.getAllJewelryCatalogueRequestModel.page =1;
  }
  this.getAlljewelryCatalogueList()
  
}

getStartRange(): number {
if (this.getAllJewelryCatalogueRequestModel.getAll) {
    return 1;
} else {
    return (this.getAllJewelryCatalogueRequestModel.page - 1) * this.getAllJewelryCatalogueRequestModel.pageSize + 1;
}
}

getEndRange(): number {
if (this.getAllJewelryCatalogueRequestModel.getAll) {
    return this.totalRecords;
} else {
    const endRange = this.getAllJewelryCatalogueRequestModel.page * this.getAllJewelryCatalogueRequestModel.pageSize;
    return endRange > this.totalRecords ? this.totalRecords : endRange;
}
}

navigateToDetails(id){
  switch (this.catelogueForm) {
    case Catelogue.CustomerCatelogue:
      if(id){
        this.storageService.store("JewelleryCatalogueId",id);
        }
      this.router.navigate(["app-jewelry/jeweleryDetailsPage"], {
        queryParams: { id: id },
      });
      break;
    case Catelogue.LoggedCustomerCatelogue:
      this.router.navigate(["Inventory/jewelry-Catalogue/product-details"], {
        queryParams: { id: id },
      });
      break;
    case Catelogue.InventoryCatelogue:
      if(id){
          this.storageService.store("JewelleryCatalogueId",id);
          }
      this.router.navigate(['/Inventory/jewelry-Catalogue/jewelry-Catalogue-detail'],
      {relativeTo: this.activatedRoute });
      break;
    case Catelogue.BillOfMaterialCatelogue:
      if(id){
          this.storageService.store("jewelaryBillOfMaterialId",id);
          }
      this.router.navigate(['/Inventory/jewelry-Catalogue/jewelry-Catalogue-detail'],
      {
        queryParams: { id: id },
      });
      break;
  }
}
fillDropdownData() {
    this.getAllDropdownModel.branchDropdown = this.storageService.retrieve(StorageName.BRANCH_DROPDOWN);
    this.getAllDropdownModel.productDropdown = this.storageService.retrieve(StorageName.PRODUCT_DROPDOWN);
    this.getAllDropdownModel.metalDropdown = this.storageService.retrieve(StorageName.METAL_DROPDOWN);
  }

  openAdvanceFilterPopUp() {
    var data = { getAllDropdownModel: this.getAllDropdownModel, filterStorageName: StorageName.FILTER_INVENTORY_JEWELRY_CATALOGUE_CARDLIST, apiRequestModel: this.getAllJewelryCatalogueRequestModel }
    this.notificationService.openModalPopup(AdvancedFilterPopupComponent, data, 'Advance Filter',ModalPopupSize.LG , "", false, true).then(
      (resultData) => {
        if (resultData) {
          this.getAllJewelryCatalogueRequestModel.filters = JSON.stringify(resultData.filters);
          this.storageService.store(this.apiRequestStorageName, this.getAllJewelryCatalogueRequestModel);
          this.filterCountValue = this.filterCount(resultData)
          this.getAlljewelryCatalogueList();
        }
      }
    );
  }

  // openAdvanceFilterPopUp() {
  //    var data = { getAllDropdownModel: this.getAllDropdownModel,  apiRequestModel: this.apiRequestModel ,advanceFilterAppliedData:this.advanceFilterAppliedData }
  //   this.notificationService.openModalPopup(AdvancedFilterPopupComponent,data,  'Advance Filter',ModalPopupSize.LG , "", false, true).then(
  //      (resultData) => {
  //      if (resultData) {
  //         this.apiRequestModel.filters = JSON.stringify(resultData.filters);
  //         this.storageService.store(this.apiRequestStorageName, this.apiRequestModel);
  //         this.filterCountValue = this.filterCount(resultData)
  //         this.getAlljewelryCatalogueList();
  //    }
  //   },
  //    (reason) => {
  //         this.SetFinatialYearDate();
  //       }
  //   );
  // }
  // SetFinatialYearDate() {
  //   const financialYearDates = this.storageService.retrieve('financialYear');
  //     if (!this.advanceFilterAppliedData || typeof this.advanceFilterAppliedData !== 'object') {
  //     this.advanceFilterAppliedData = {};
  //   }

  //   const financialObj = {rules:[{data:financialYearDates?.financialYearStartDate,op:"eq",field:"fromdate"},{data:financialYearDates?.financialYearEndDate,op:"eq",field:"todate"}],groups:[]};
  //   this.getAllJewelryCatalogueRequestModel.filters = JSON.stringify(financialObj)
  //   this.advanceFilterAppliedData = {fromDate: financialYearDates?.financialYearStartDate, toDate: financialYearDates?.financialYearEndDate}
  // }
  // countValueAdded() {
  //   const { fromDate, toDate, ...rest } = this.advanceFilterAppliedData || {};
  //   this.advanceFilterAppliedData.fromDate = this.convertDateToString(fromDate);
  //   this.advanceFilterAppliedData.toDate = this.convertDateToString(toDate);
    
  //   const length = Object.values({ fromDate, toDate, ...rest }).filter(v => v !== "").length;
  //   this.AdvanceFilterDataCount = fromDate && toDate ? length - 1 : length || 1;
  // }
  clearAdvanceFilterFields() {
    this.getAllJewelryCatalogueRequestModel.filters = "";
    this.advanceFilterAppliedData = "";
    // this.SetFinatialYearDate();
    // this.countValueAdded();
  }

  resetFilter() {
    this.selectedIds = [];
    this.setDefaultFilter();
  }

  openImgPopUp(selectedItem: any) {
    const selectedIndex = this.jewelryCatalogueList.findIndex(item => item === selectedItem);
    var data = {
      jewelryCatalogue: [...selectedItem?.images],
      selectedIndex: selectedIndex,
    }
    this.notificationService.openModalPopup(OpenImagePopupComponent, data, '', ModalPopupSize.LG, 'image_popup_fullscreen', true, true).then(
      (result) => {
        if (result) {

        }
      }
    )
  }

isCheckDefault(img){
 const isDefault =  img?.includes('default.png')
 return isDefault
}

syncJewellryCatalogueButton(){
  this.jewelryCatalogueCommonService.getSyncJewellryCatalogue().subscribe({
    next:(res)=>{
      if(res?.isSuccess){
        this.toaster.success(res?.message);
        this.getAlljewelryCatalogueList();
      }else{  
        this.toaster.error(res?.errors[0]);            
      }  
    },
    error:(err)=>{
    }
  })
}

newSaleOrder() {
  const items = this.selectedCards?.map(card => card?.item);
  const combinedItems = items?.flatMap(item => item || []);
  this.storageService.store(StorageName.STORED_SELECTED_NEWSALEORDER, combinedItems);
  this.router.navigate(['/Transaction/sale-order']);
}

CatalogueQuotation(){
  const items = this.selectedCards?.map(card => card?.item);
  const combinedItems = items?.flatMap(item => item || []);
  this.storageService.store(StorageName.STORED_SELECTED_CATALOGUE, combinedItems);
  this.router.navigate(['/Orders/catalogue-quotation']);
}


  onCardClickForSelected(node: any) {
    const index = this.selectedCards.indexOf(node);
    if (index === -1) {
      this.selectedCards.push(node);
    } else {
      this.selectedCards.splice(index, 1);
    }
    this.selectedCardCount =this.selectedCards?.length;
  }


}