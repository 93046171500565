import { CheckboxRendererComponent } from "src/app/shared/common-components/ag-grid/checkbox-renderer/checkbox-renderer.component";
import { AgColDefsI, AgGridI, paginationModel } from "./../../../shared/common-components/ag-grid/ag-grid.model";
import { TextRendererComponent } from "src/app/shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { AgGridCustomDropdownComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-dropdown/ag-grid-custom-dropdown.component";
import { CommonOperationModel } from "src/app/shared/models/common.model";
import { StorageService } from "src/app/shared/services/storage.service";
import { NameColWidth, StorageName } from "src/app/shared/enum/common-enum";
import { CustomHeaderComponent } from "src/app/shared/common-components/ag-grid/custom-header/custom-header.component";
import { EditDeleteIconComponent } from "src/app/shared/common-components/ag-grid/edit-delete-icon/edit-delete-icon.component";
import { FinalWtColumn } from "src/app/shared/common-components/metal-section/metal-section.model";
import {API_URL_GET_ALL_PRODUCT} from "src/app/shared/url-helper/url-helper"
import { NumericCellRendererComponent } from "src/app/shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
import { AgGridCustomInputComponent } from "src/app/shared/common-components/ag-grid/ag-grid-custom-input/ag-grid-custom-input.component";

export enum TabView {
  PRODUCT_CHARACTERISTICS = 1,
  MANUFACTURING = 2,
}

export class ProductOpening {
  productModel =  new ProductModel();
  productDetailModel: ProductDetailModel[] = [];
  // billOfMaterialModel = new BillOfMaterialModel() || null;
}

export interface dynamicDropdown{
id:number;
name:string
}

export class ProductModel{
  id: number;
  name: string;
  alternateName: string;
  decimalPoint: string;
  categoryId: number;
  isOpeningStock: boolean;
  showInStock:boolean = true;
  taxApplicableOn: string;
  active: boolean;
  branchIds:   any = [];
  taxIds: any[]=[];
}

export class ProductDetailModel
  {
    id: number;
    name: string;
    productId: number;
    metalId: number;
    noOfDigits:number;
    barcodePrefix:string;
    hsnCode: string;
    unitId: number;
    caratId: number;
    collectionsIds: CommonOperationModel[];
    productCode: string;
    puritySale: number;
    purityPurchase: number;
    wastageSale: number;
    wastagePurchase: number;
    locationId: number;
    perPieceWeight: string;
    openingWeight: number;
    openingPurity: number;
    openingRate: number;
    openingValue: number;
    active: boolean;
    deleted: boolean=false;
    added:boolean;
    edited:boolean;
    none: boolean=false;
}

export class TaxModel{
  id :number = 0;
  name: string;
  taxValue: any;
  added = false;
  edited = false;
  deleted = false;
  calculationMode:number;
  active: boolean=false;
  isAlreadyAdded = false;
  taxId:number;
}

// ---manufacturing--- 

// export class BillOfMaterialModel {
//   wt: number
//   productId: number
//   productCode: string
//   description: string
//   billOfMaterialItemModel: BillOfMaterialItemModel[]
//   billOfMaterialResourceModel: BillOfMaterialResourceModel[]
//   billOfMaterialAdditionalCostModel: BillOfMaterialAdditionalCostModel[]
// }

// export class BillOfMaterialItemModel {
//   description: string = "loLorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam, quas!"
//   wt: number=100
//   detailedJson: string
//   billOfMaterialItemDetailsModel: BillOfMaterialItemDetailsModel[]
// }

// export class BillOfMaterialItemDetailsModel {
//   productDetailId: number | null;
//   metalDetailId: number | null;
//   amount: number | null
// }

// export class BillOfMaterialResourceModel {
//   base64Resource: string;
// }

// export class BillOfMaterialAdditionalCostModel {
//   chargeType: number
//   cost: number
// }


export class ProductSpecification {
  settingType:string="New Settings";
  diaShape:string="Rounded";
  grossWt:number=0;
  sieveSize:number = 0;
  diaSize:number=0;
  avgDiaWt:number=0;
  noOfDia:number=0;
  totalDiaWt:number=0;
}
// ---manufacturing--- 
export class FilterList {
  name: string
  textFilter: string
  filterData: FilterDaum[];
}
export class FilterDaum {
  id: number
  name: string
}

export class ProductListModel extends paginationModel{
  exportRequest : exportRequest[];
  filterList : FilterList[];
  getAll = true ;
  exportAs : number;
  // exportRequest : exportRequest[];
}
export class exportRequest {
  gridName : string;
  columnToInclude : any
}


const MetalColumn: AgColDefsI = {
  headerName: "Metal",
  field: "metal",
  sortable: true,
  minWidth: 170,
  maxWidth: 170,
  headerCheckboxSelection: false,
  suppressMovable: false,
  lockPinned: true,
  pinned: "left",
  editable:false,
  cellClass:'font-regular custom-column-group-cell  ',
  headerClass:"custom-column-group-cell px-0",
  cellRenderer: CheckboxRendererComponent,
  cellRendererParams: {
    showLabel: true
  },
  cellEditor:CheckboxRendererComponent,
};

const HSNColumn: AgColDefsI = {
  headerName: "HSN",
  headerComponentParams:{isRequired:true},
  field: "hsnCode",
  sortable: true,
  minWidth: 70,
  maxWidth: 100,
  editable:true,
  isRequired:true,
  cellClass:"custom-column-group-cell px-0 text-center",
  headerClass:"custom-column-group-cell px-0"
};

const CollectionColumn: AgColDefsI = {
  headerName: "Collection",
  sortable: true,
  field: "collectionsIds",
  minWidth: 100,
  maxWidth: 160,
  editable:false,
  cellClass:"custom-column-group-cell ps-0 text-start text-primary",
  headerClass:"custom-column-group-cell px-0",
  cellRenderer: TextRendererComponent,
  cellRendererParams: (params: any) => {
    return {
      value:params?.value?params.value.length:`0`,
      isCursor: true,
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        return storageService.retrieve(storage.COLLECTION_DROPDOWN);
      }

    };
  },
  // cellEditor:NgMultiSelectDropdownComponent, 
  // cellEditorParams: {
  //   plusIcon:false,
  //   values: () => {
  //     const storageService = new StorageService();
  //     const storage = StorageName;
  //     return storageService.retrieve(storage.COLLECTION_DROPDOWN);
  //   }
  // },
};

const UnitColumn: AgColDefsI = {
  headerName: "Unit",
  headerComponentParams:{isRequired:true},
  field: "unitId",
  minWidth: 80,
  maxWidth: 100,
  sortable: true,
  editable:true,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.UNIT_DROPDOWN);
    },
  },
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomDropdownComponent, 
  cellClass:"custom-column-group-cell px-0 ",
  headerClass:"custom-column-group-cell px-0",
  valueGetter: (param) => 
        {
          const storageService = new StorageService();
          const storage = StorageName;
          const storeData =  storageService.retrieve(storage.UNIT_DROPDOWN);
          const selcetedId = storeData?.find(a => a.id == param.data.unitId)??'';
          param.data.unitId=selcetedId.id !== undefined && selcetedId.id !== null? param.data.unitId : 1;
          return param.data.unitId ;
       },

};

const MakingOn: AgColDefsI = {
  headerName: "Making on",
  headerComponentParams:{isRequired:true},
  field: "makingOn",
  minWidth: 130,
  maxWidth: 130,
  sortable: true,
  editable:true,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
     const data  =  storageService.retrieve(storage.MAKING_ON_DROPDOWN);
      let filterData =data.filter(x=>x.name !='');
      return filterData;
    },
  },
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomDropdownComponent, 
  cellClass:"custom-column-group-cell px-0 ",
  headerClass:"custom-column-group-cell px-0",
  valueGetter: (param) => 
        {
          const storageService = new StorageService();
          const storage = StorageName;
          const storeData =  storageService.retrieve(storage.MAKING_ON_DROPDOWN);
          const selcetedId = storeData?.find(a => a.id == param.data.makingOn)??'';
          param.data.makingOn=selcetedId.id !== undefined && selcetedId.id !== null? param.data.makingOn : 2;
          return param.data.makingOn ;
       },
};

const DiamondCategoryColumn: AgColDefsI = {
  headerName: "Dia. Category",
  headerComponentParams:{isRequired:true},
  field: "diamondCategoryId",
  minWidth: 130,
  maxWidth: 130,
  sortable: true,
  editable:true,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
     const data  =  storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);
      let filterData =data.filter(x=>x.name !='');
      return filterData;
    },
  },
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomDropdownComponent, 
  cellClass:"custom-column-group-cell px-0 ",
  headerClass:"custom-column-group-cell px-0",
  valueGetter: (param) => 
        {
          const storageService = new StorageService();
          const storage = StorageName;
          const storeData =  storageService.retrieve(storage.DIAMOND_CATEGORY_DROPDOWN);
          const selcetedId = storeData?.find(a => a.id == param.data.diamondCategoryId)??'';
          param.data.makingOn=selcetedId.id !== undefined && selcetedId.id !== null? param.data.diamondCategoryId : 2;
          return param.data.diamondCategoryId ;
       },
};

const FinalWtProductGrid  = {...FinalWtColumn};
      FinalWtProductGrid.editable = true;
      FinalWtProductGrid.field = 'openingFinalWeight';

const SKUProductCodeColumn: AgColDefsI = {
  headerName: "SKU/Product Code",
  sortable: true,
  editable:true,
  field: "productCode",
  minWidth: 140,
  maxWidth: 160,
   cellClass:"custom-column-group-cell ps-0 text-end ",
  headerClass:"custom-column-group-cell px-0"
}

const LocationColumn : AgColDefsI = {
  headerName: "Location",
  field: "locationId",
  minWidth: 110,
  maxWidth: 150,
  sortable: true,
  editable:true,
  headerComponentParams:{enablePlusIconLight: true },
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.LOCATION_DROPDOWN);
    },
  },
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomDropdownComponent, 
  cellClass:"custom-column-group-cell px-0",
  headerClass:"custom-column-group-cell px-0"
  
};
const CaratColumn: AgColDefsI = {
  headerName: "Carat",
  field: "caratId",
  minWidth: 100,
  maxWidth: 100,
  sortable: true,
  editable:true,
  headerComponentParams:{enablePlusIconLight: true },
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.CARAT_DROPDOWN);
    },
  },
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomDropdownComponent, 
  cellClass:"custom-column-group-cell px-0 ",
  headerClass:"custom-column-group-cell px-0"
  
};


const PurityColumn: AgColDefsI = {
  headerName: "Purity",
  field: "purity",
  marryChildren: true,
  sortable: true,
  headerComponentParams:{enablePlusIconLight: true,isRequired:true},
  headerClass: "custom-column-group-cell justify-content-center px-0",
  children: [
    {
      field: "puritySale",
      headerName: "Sale",
      sortable: true,
      minWidth: 60,
      maxWidth:60,
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell ps-0 text-end ",
      editable:true,
      cellRenderer: TextRendererComponent,
    },
  {
    field: "purityPurchase",
    headerName: "Purchase",
    sortable: true,
    minWidth: 60,
    maxWidth:60,
    headerClass: "custom-column-group-cell child_header px-0",
    cellClass: "custom-column-group-cell ps-0 text-end ",
    editable:true,
    cellRenderer: TextRendererComponent,
  },
  ]
};

const WastageColumn: AgColDefsI = {
  headerName: "Wastage",
  field: "wastage",
  marryChildren: true,
  headerClass: "custom-column-group-cell justify-content-center",
  children: [
    {
      field: "wastageSale",
      headerName: "Sale",
      sortable: true,
      minWidth: 60,
      maxWidth:60,
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell ps-0 text-end ",
      editable:true,
      cellRenderer: TextRendererComponent,
    },
    {
      field: "wastagePurchase",
      headerName: "Purchase",
      maxWidth: 80,
      minWidth: 80,
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell ps-0 text-end ",
      editable:true,
      sortable: true,
      cellRenderer: TextRendererComponent,
    },
  ],
};

// const LocationColumn: AgColDefsI = {
//   headerName: "Location",
//   sortable: true,
//   field: "locationId",
//   minWidth: 150  ,
//   cellRenderer: TextRendererComponent,
//   headerClass: "custom-column-group-cell px-0",
//   cellClass: "custom-column-group-cell f-12 text-gray-color font-regular",
//   editable:true,
// };

const WtPerPieceColumn: AgColDefsI = {
  headerName: "Wt. Per Piece",
  sortable: true,
  editable:true,
  field: "weightPerPiece",
  minWidth: 140,
  maxWidth: 160,
  cellRenderer: NumericCellRendererComponent,
  cellRendererParams: {isWeight:true},
   cellClass:"custom-column-group-cell ps-0 text-end ",
  headerClass:"custom-column-group-cell px-0"
};

const OpeningColumn: AgColDefsI = {
  headerName: "Opening",
  field: "opening",
  marryChildren: true,
  headerClass: "custom-column-group-cell justify-content-center px-0",
  children: [
    {
      field: "openingWeight",
      sortable: true,
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell text-end ",
      headerName: "Weight",
      minWidth: 80,
      maxWidth: 80,
      editable:true,
      cellRenderer: TextRendererComponent,
      
    },
    {
      field: "openingPurity",
      sortable: true,
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell text-end f-12 text-gray-color font-regular",
      headerName: "Purity",
      minWidth: 80,
      maxWidth: 80,
      editable:true,
      cellRenderer: TextRendererComponent,
    },
    {
      headerName: "Quantity",
      field: "openingQuantity",
      sortable: true,
      minWidth: 90,
      maxWidth:100,
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell text-end f-12 text-gray-color font-regular",
      editable:true,
      cellRenderer: TextRendererComponent,
    },
    FinalWtProductGrid,
    {
      headerName: "Rate",
      field: "openingRate",
      sortable: true,
      minWidth: 80,
      maxWidth:100,
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell text-end ",
      editable:true,
      cellRenderer: TextRendererComponent,
    },
    {
      headerName: "Value",
      field: "openingValue",
      minWidth: 70,
      maxWidth:100,
      sortable: true,
      headerClass: "custom-column-group-cell child_header px-0",
      cellClass: "custom-column-group-cell text-end ",
      editable:true,
      cellRenderer: TextRendererComponent,
    },
  ],
  
};
const NoOfDigitsColumn: AgColDefsI = {
  headerName: "No of Digits",
  field: "noOfDigits",
  minWidth: 120,
  maxWidth: 120,
  cellRenderer: TextRendererComponent,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell f-12 text-gray-color font-regular",
  editable:true,
  sortable: true,
};

const BarcodePrefixColumn: AgColDefsI = {
  headerName: "Barcode Prefix",
  field: "barcodePrefix",
  minWidth: 130,
  maxWidth: 150,
  cellRenderer: TextRendererComponent,
  headerClass: "custom-column-group-cell px-0",
  cellClass: "custom-column-group-cell ",
  editable:true,
  sortable: true,

};





const  taxListTableColumnDef: AgColDefsI[] = [
  { headerName: "Tax", field: 'name', editable:false,  headerCheckboxSelection: false, cellRenderer: CheckboxRendererComponent, headerClass: 'justify-content-end ps-1 font-semibold custom-column-group-cell',resizable: true, cellClass: "px-2 font-semibold custom-column-group-cell", minWidth: 40, flex:1},
  { headerName: "Value", field: 'taxValue',editable:params => params?.data?.active ? true : false,  cellEditor: AgGridCustomInputComponent, cellRenderer:NumericCellRendererComponent,minWidth: 20, flex:1,resizable: false, headerClass: 'px-1 border-0 custom-column-group-cell ag_header_end', cellClass: "px-2 custom-column-group-cell text-end" },
  {
    headerName: "Calculation Mode",
    field: "calculationMode",
    headerComponentParams: { isRequired: false, enableSorting: false},
    minWidth: 100,
    sortable: true,
    editable: true,
    cellRenderer: TextRendererComponent,
    cellEditor: AgGridCustomDropdownComponent,
    cellEditorParams: {
      values: () => {
        const storageService = new StorageService();
        const storage = StorageName;
        const data  =  storageService.retrieve(storage.CALCULATION_DROPDOWN);
         let filterData =data.filter(x=>x.name !='');
         return filterData;
      },
    },
    cellClass: "custom-column-group-cell px-0",
    headerClass: "custom-column-group-cell px-0",
    valueGetter: (param) => 
      {
        const storageService = new StorageService();
        const storage = StorageName;
        const storeData =  storageService.retrieve(storage.CALCULATION_DROPDOWN);
        const selcetedId = storeData?.find(a => a.id == param.data.calculationMode)??'';
        param.data.calculationMode = selcetedId.id !== undefined && selcetedId.id !== null && selcetedId.id !== "0"? param.data.calculationMode: 1;        
        return param.data.calculationMode ;
     },
  }
];

export const ColDefsVendorContacts: AgColDefsI[] = [

  MetalColumn,
  HSNColumn,
  CollectionColumn,
  UnitColumn,
  SKUProductCodeColumn,
  MakingOn,
  DiamondCategoryColumn,
  LocationColumn,
  CaratColumn,
  PurityColumn,
  WastageColumn,
  WtPerPieceColumn,
  OpeningColumn,
  NoOfDigitsColumn,
  BarcodePrefixColumn
];


const SettingTypeColumn: AgColDefsI = {
  headerName: "Setting Type",
  sortable: true,
  headerClass:
    "px-0 custom-column-group-cell border-0 ",
  field: "settingType",
  cellClass: "px-0 custom-column-group-cell",
  minWidth: 120,
  flex: 2,
  editable: false,
  cellRenderer:TextRendererComponent
};

const DiaShapeColumn: AgColDefsI = {
  headerName: "Dia. Shape",
  headerClass:
    "px-0 custom-column-group-cell border-0",
  field: "diaShape",
  sortable: true,
  cellClass: "px-0 custom-column-group-cell",
  minWidth: 120,
  flex: 2,
  editable: false,
  cellRenderer:TextRendererComponent
};

const GrossWtColumn: AgColDefsI = {
  headerName: "Gross Wt",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 text-gray-color",
  field: "grossWt",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 120,
  cellRenderer:NumericCellRendererComponent,
  flex: 2,
  editable: false,
};


const SieveSizeColumn: AgColDefsI = {
  headerName: "Sieve Size",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 ",
  field: "sieveSize",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 90,
  cellRenderer:NumericCellRendererComponent,
  flex: 1,
  editable: false,
};

const DiaSizeColumn: AgColDefsI = {
  headerName: "Dia. Size.",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 ",
  field: "diaSize",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 120,
  cellRenderer:NumericCellRendererComponent,
  flex: 1,
  editable: false,
};

const AveDiaWtColumn: AgColDefsI = {
  headerName: "Avg. Dia. Wt.",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 ",
  field: "avgDiaWt",
  sortable: true,
  cellRenderer:NumericCellRendererComponent,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 90,
  flex: 1,
  editable: false,
};

const NoOfDiaColumn: AgColDefsI = {
  headerName: "No. Of Dia.",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 ",
  field: "noOfDia",
  sortable: true,
  cellRenderer:NumericCellRendererComponent,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 90,
  flex: 1,
  editable: false,
};

const TotalDiaColumn: AgColDefsI = {
  headerName: "Total Dia. Wt.",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 ",
  field: "totalDiaWt",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 90,
  flex: 1,
  editable: false,
  cellRenderer:NumericCellRendererComponent,
};

const ManufacturingActionColumn: AgColDefsI = {
  headerName: "",
  suppressNavigable: true,
  // pinned:'right',
  editable: false,
  lockPinned: true,
  headerComponent: CustomHeaderComponent,
  headerClass: "justify-content-end  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellClass: " custom-column-group-cell d-flex align-items-center justify-content-center",
  cellRendererParams: { focusOnColumn: "paymentType", isEditRowOnEdit: true },
  headerComponentParams: { enableMenu: false, moreButton: true },
  minWidth: 80,
  maxWidth: 80,
};

export const ColDefManufacturing : AgColDefsI[] = [
  SettingTypeColumn,
  DiaShapeColumn,
  GrossWtColumn,
  SieveSizeColumn,
  DiaSizeColumn,
  AveDiaWtColumn,
  NoOfDiaColumn,
  TotalDiaColumn,
  ManufacturingActionColumn,
]

const DescriptionColumn: AgColDefsI = {
  headerName: "Description",
  headerComponent: CustomHeaderComponent,
  sortable: true,
  editable:false,
  headerClass:"px-0 custom-column-group-cell border-0  justify_content_start",
  field: "description",
  minWidth: 130,
  cellRenderer:TextRendererComponent,
  flex: 3,
  cellClass: "custom-column-group-cell",
};

const Weight: AgColDefsI = {
  headerName: "Wt.",
  editable:false,
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0  justify_content_end",
  field: "wt",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  maxWidth: 120,
  cellRenderer:NumericCellRendererComponent,
  cellRendererParams: {isWeight:true},
  flex: 1,
};

const ItemListTaxColumn: AgColDefsI = {
  headerName: "Tax",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0  justify_content_end",
  field: "tax",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  maxWidth: 80,
  flex: 1,
};

const ItemListAmtColumn: AgColDefsI = {
  headerName: "Amt",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0 justify_content_end",
  field: "amt",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  maxWidth: 80,
  flex: 1,
};

const ItemListNetAmtColumn: AgColDefsI = {
  headerName: "Total Dia. Wt.",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0  justify_content_end",
  field: "netAmt",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  maxWidth: 80,
  flex: 1,
};

const ActionColumn: AgColDefsI = {
  headerName: "",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  headerComponent: CustomHeaderComponent,
  headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellRendererFramework: EditDeleteIconComponent,
  // Pass gridOptions to the cell renderer component
  headerComponentParams: { enableMenu: false, moreButton: true },
  cellClass: "mx-0 text-end px-1 d-flex align-items-center justify-content-center",
  minWidth: 80,
  maxWidth: 80,
};

const ManufacturerDescActionColumn: AgColDefsI = {
  headerName: "",
  pinned: "right",
  suppressNavigable: true,
  editable: false,
  lockPinned: true,
  headerComponent: CustomHeaderComponent,
  headerClass: "justify_content_start  custom-column-group-cell border-0 px-0",
  cellRenderer: EditDeleteIconComponent,
  cellRendererFramework: EditDeleteIconComponent,
  // Pass gridOptions to the cell renderer component
  cellClass: "mx-0 text-end px-1 d-flex align-items-center justify-content-center",
  minWidth: 40,
  maxWidth: 50,
};

export const ColDefManufacturDesc  : AgColDefsI[] = [
  DescriptionColumn,
  Weight,
  ManufacturerDescActionColumn
]

const ChargesColumn: AgColDefsI = {
  headerName: "Charges",
  field: "chargeType",
  minWidth: 70,
  sortable: true,
  editable:true,
  flex: 1,
  cellEditorParams: {
    values: () => {
      const storageService = new StorageService();
      const storage = StorageName;
      return storageService.retrieve(storage.MAKINGCHARGESTYPE_DROPDOWN);
    },
  },
  cellRenderer: TextRendererComponent,
  cellEditor:AgGridCustomDropdownComponent, 
  cellClass:"custom-column-group-cell px-0",
  headerClass:"custom-column-group-cell px-0"
};

const EstimatedCostColumn: AgColDefsI = {
  headerName: "Estimated Cost",
  headerComponent: CustomHeaderComponent,
  headerClass:
    "px-0 custom-column-group-cell border-0",
  field: "cost",
  sortable: true,
  cellClass: " custom-column-group-cell text-end",
  minWidth: 110,
  maxWidth: 120,
  flex: 1,
  cellRenderer: TextRendererComponent,
  editable: true,
};



export const ColDefChargesTable  : AgColDefsI[] = [
  ChargesColumn,
  EstimatedCostColumn,
  ActionColumn
]

export const AgGridDataModelChargesTable:AgGridI={
  colDefs: ColDefChargesTable,
  rowSelection: "multiple",
  storageName:StorageName.PRODUCT_PRODUCTOPENING_PRODUCT_CHARGES_GRID,
  rowHeight:28,
}

export const AgGridDataModelManufacturDesc:AgGridI={
  colDefs: ColDefManufacturDesc,
  storageName:StorageName.PRODUCT_PRODUCTOPENING_MANUFACTURING_GOLD_DESCRIPTION_GRID,
  rowHeight:28,
  rowSelection: "multiple",
}

export const AgGridDataModelManufacturing:AgGridI={
  colDefs: ColDefManufacturing,
  storageName:StorageName.PRODUCT_PRODUCTOPENING_MANUFACTURING_GOLD_ITEM_GRID,
  rowSelection: "multiple",
  rowHeight:28,
}

export const AgGridDataModelProductCharacteristics: AgGridI = {
  colDefs: ColDefsVendorContacts,
  storageName:StorageName.PRODUCT_PRODUCTOPENING_PRODUCT_CHARACTERISTIC_GRID,
  rowSelection: "multiple",
  rowHeight:28,
};


const Product: AgColDefsI = { 
  headerName: "Product", 
  field: 'name',  
  sortable: false,
  resizable: true,
  minWidth:NameColWidth.MinWidth,
  maxWidth:NameColWidth.MaxWidth,
  editable: false,
  cellClass: "text-primary custom-column-group-cell px-0", 
  headerClass: "px-1 d-flex custom-column-group-cell" ,
  cellRenderer: TextRendererComponent,
  cellRendererParams :{isCursor:true},
  headerComponentParams: {
    filterValueColumnName: "name",
    filterIdColumnName: "name",
    isInputFilter: true,
  },

  };

  const Action: AgColDefsI = {
    headerName: "",
    field: "",
    minWidth:40,
    maxWidth:40,
    editable: false,
    cellRenderer: EditDeleteIconComponent,
    // cellRendererParams :{focusOnColumn:'name' ,isEditRowOnEdit:false},
    headerClass: "",
    cellClass: "custom-column-group-cell d-flex align-items-center justify-content-center",
    cellRendererParams: (params: any) => {
      const storageService = new StorageService();
      const userPermissions = storageService.retrieve(StorageName.USER_PERMISSIONS);
      return {
        hasDeletePermission: userPermissions?.productOpeningCanDelete,focusOnColumn:'name' ,isEditRowOnEdit:false
      };
    },
}

const ProductListColumnDefs: AgColDefsI[] = [
  Product, 
  Action,
]

export const  AgGridDataModelProductList:AgGridI = {
  colDefs: ProductListColumnDefs,
  rowSelection: "multiple",
  storageName:StorageName.PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID,
  filterStorageName: StorageName.FILTER_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID,
  sortStorageName: StorageName.SORT_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID,
  paginationStorageName : StorageName.PAGINATION_PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID,
  initialRows: StorageName.PRODUCT_PRODUCTOPENING_PRODUCTLIST_GRID_INITIAL_ROWS,
  rows:[],
  headerHeight : 70,
  isFilter: true,  
  apiUrl : API_URL_GET_ALL_PRODUCT,
  isServerSidepagination : true,
  showPagination:false,
  leftSideShowPagination:true,
  
}

export const  AgGridDataModelTaxListTable:AgGridI = {
  colDefs: taxListTableColumnDef,
  rowSelection: "multiple",
  storageName: StorageName.PRODUCT_PRODUCTOPENING_TAX_GRID,
  singleClickEdit : true,
  rowHeight:28,
}
