import { NgClass } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { JobWorkStatus } from 'src/app/components/orders/order-tracking/order-tracking.model';
import { ReportService } from 'src/app/components/Report/report.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { SendDataService } from 'src/app/shared/services/send-data.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-receive-button-cell',
  templateUrl: './receive-button-cell.component.html',
  styleUrls: ['./receive-button-cell.component.scss']
})
export class ReceiveButtonCellComponent  implements ICellRendererAngularComp {
  @ViewChild('myButton') myButton: ElementRef;
  @Input() buttonType:string;
  @Input() buttonClass:string;
  @Output("buttonOutputEvent") buttonOutputEvent: EventEmitter<any> = new EventEmitter();
  @Input('ngClass') ngClass: NgClass["ngClass"];
  @Input() inputReadonly : boolean  = false;
  @Input() buttonLabel:string;
  @Input() showIcon: boolean = false;
  @Input()showFilterIcon:boolean =false;
  @Input() showPlusIcon: boolean = false;
  @Input() showUploadIcon: boolean = false;
  @Input() showUploadIconWhite: boolean = false;
  @Input() showPlusIconwhite : boolean = false;
  @Input() showRefreshIcon : boolean = false;
  @Input() showUpload1Icon : boolean = false;
  @Input() showBackIcon : boolean = false;
  @Input() importIcon : boolean = false;
  @Input() buttonId:string;
  @Input()ResetBlueIcon :boolean =false;
  @Input()ResetdarkBlueIcon :boolean=false;
  @Input()copywhiteIcon :boolean=false;
  @Input()buttonDisabled :boolean=false;
  jobWorkStatus : typeof JobWorkStatus = JobWorkStatus;
  params:any;
  Jobworkorder:boolean=false;
  UnreleaseReleaseButton:boolean=false;
  StockInOutButton:boolean=false;
  constructor( 
    private sendDataService   : SendDataService,  
    private router            : Router,
    private reportService     : ReportService,
    private storageService    : StorageService
    ){}



  agInit(params: any): void {
    this.params = params;
    this.buttonDisabled = this.params.buttonDisabled !== undefined ? this.params.buttonDisabled : false; 
  }
 
  refresh(params: any): boolean {
    return false;
  }

  navitageToReceive(params)
  {
    if((params?.hasAddPermission === false || params?.data?.jobWorkInvoiceVoucherNo)) return;
    this.storageService.store(StorageName.STORED_JOBWORK_ORDERID_FORRECEIVE,params.node.data.id);
    this.storageService?.onReceiveButtonclick.next(params);
  }

  navitageToJobWorkOrder(params)
  {
    this.storageService.store(StorageName.STORED_JOBWORK_ORDER,params.node.data.id);
    this.storageService?.onJobWorkOrderButtonclick.next(params);
  }
  openPopup(params){
    this.storageService?.releaseUnreleaseButtonclick.next(params);
  }

  onStockInButtonClick(params){
    const customEvent = new CustomEvent('stockInButtonClick', {
      detail: { data: params },
    });
    this.params?.api?.dispatchEvent(customEvent);
  }
  onStockOutButtonClick(params){
    const customEvent = new CustomEvent('stockOutButtonClick', {
      detail: { data: params },
    });
    this.params?.api?.dispatchEvent(customEvent);
  }
  onstockJournal2ButtonClick(params){
    const customEvent = new CustomEvent('stockJournal2ButtonClick', {
      detail: { data: params },
    });
    this.params?.api?.dispatchEvent(customEvent);
  }
}
