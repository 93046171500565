import { Injectable } from '@angular/core';
import { DataService } from './../../shared/services/data.service';
import { Observable, Subject, tap } from 'rxjs';
import { ResponseModel } from './../../shared/models/response-model.model';
import { CreateUpdateBillSeriesRequestModel, CreateUpdateRewardPointRequestModel, DatabaseBackupRequestModel, Get_RDLC_In_PDF_Model, RdlcInsertUpdateRequestResponceModel, RetrySqlExecutionRequestModal, UpdateVoucherType } from './set-software/set-software.model';
import { FinancialListUpdateRequestModel, FinancialYearGetRequestModel } from './set-software/tabs/accounting/accounting.model';
import { CreateUpdateDeviceRequestModel } from './set-software/tabs/devices/devices.model';
import { CreateUpdateTemplateSettingRequestModel } from './set-software/tabs/template/template.model'
import { TransactionmessageModel } from './set-software/tabs/transaction-message/transaction-message.model';
import { GetallGridConfigurationModel } from './set-software/tabs/grid-configuration/grid-configuration.model';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StorageName } from 'src/app/shared/enum/common-enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NotificationmessageModel } from './set-software/tabs/notification-message/notification-message.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public previouscellClickedId: number = null;
  whatsAppBaseUrl:string;
  constructor(public service: DataService, private storageService: StorageService, private http: HttpClient) { }
  
  public initialize(): void {
    this.whatsAppBaseUrl =  this.storageService.retrieve(StorageName.WHATSAPP_INTEGRATION_SETTING).apiUrl;
  }

  public onResetClickedSource = new Subject<any>();
  onResetClicked$ = this.onResetClickedSource.asObservable();

  getAllFinancialYear(financialYearGetRequestModel :FinancialYearGetRequestModel):Observable<ResponseModel>{
    let url = "api/app/financialyear/getallfinancialyears";
    const json =JSON.stringify(financialYearGetRequestModel)
    return this.service.post(url, financialYearGetRequestModel,true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

  updateAddFinancialYear(financialListUpdateRequestModel:FinancialListUpdateRequestModel):Observable<ResponseModel>{
    let url = "api/app/financialyear/insertupdatefinancialyear";
    const json = JSON.stringify(financialListUpdateRequestModel);
    
    return this.service.put(url,financialListUpdateRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


  // setting service  
  getAllSetting():Observable<ResponseModel>{
    let url = "api/app/setting/getallsetting";
    return this.service.get(url, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************get by id Setting **************
    getByIdSetting(id:number):Observable<ResponseModel>{
      let url = `api/app/setting/getbyidsetting?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************setting update  **************
    updateSetting(data):Observable<ResponseModel>{
      let url = "api/app/setting/updatesetting";
      const json = JSON.stringify(data);
      
      return this.service.putWithId(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

   // *************get by id setting **************
      getByIdMetal(id:number):Observable<ResponseModel>{
        let url = `api/app/setting/getbyidsetting?Id=${id}`;
        return this.service.get(url, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }
    //  *************  end  setting api ************

      getAllMetal(){
        let url = 'api/app/metal/getallmetal';
        return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
        return response;
          })
        );
      }
    
      getAllVoucherType(data:any){
        let url = 'api/app/vouchertype/getallvouchertype';
        const json = JSON.stringify(data);
        return this.service.post(url,data,true).pipe<ResponseModel>(tap((response: any) => {
        return response;
          })
        );
      }
    
      updateAllVoucherType(updateVoucherType:UpdateVoucherType){
        let url = 'api/app/vouchertype/updatevouchertype';
        const data={voucherTypeModel:updateVoucherType}
        
        return this.service.putWithId(url,data,true).pipe<ResponseModel>(tap((response: any) => {
        return response;
          })
        );
      }
    


    // *********** Bill Series service start ************
   // **********get all Bill Series *********
   getAllBillSeries(data : any):Observable<ResponseModel>{
    let url = "api/app/suffixprefix/getallsuffixprefix";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insert Bill Series **************
  insertUpdateBillSeries(createUpdateBillSeriesRequestModel:CreateUpdateBillSeriesRequestModel):Observable<ResponseModel>{
    let url = "api/app/suffixprefix/insertupdatesuffixprefix";
    const json = JSON.stringify(createUpdateBillSeriesRequestModel);
    return this.service.putWithId(url,createUpdateBillSeriesRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


    // *************delete Bill Series **************
    deleteBillSeries(id:number):Observable<ResponseModel>{
      let url = `api/app/suffixprefix/deletesuffixprefix?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id Bill Series **************
    getByIdBillSeries(id:number):Observable<ResponseModel>{
      let url = `api/app/suffixprefix/getsuffixprefixbyvouchertypeid?VoucherTypeId=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    // *********** Bill Series service end ************


    getAllVoucherTypeById(id){
      let url = 'api/app/vouchertype/getvouchertypebyid?Id='+id;
      return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
        })
      );
    }

    getRDLCVoucherTypeById(branchId,voucherTypeId,metalId){
      let url = `api/app/rdlcreport/getbyvouchertypeidrdlcreport?VoucherTypeId=${voucherTypeId}&branchId=${branchId}&MetalId=${metalId}`;
      return this.service.get(url,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
        })
      );
    }

    getPDF_FromRDLC(get_RDLC_In_PDF_Model:Get_RDLC_In_PDF_Model){
      let url = 'api/app/rdlcpreview/getrdlcpreview';
      return this.service.post(url,get_RDLC_In_PDF_Model,true).pipe<ResponseModel>(tap((response: any) => {
        return response;
          })
        );
    }

    insertRDLCVoucherTypeById(rdlcInsertUpdateModel:RdlcInsertUpdateRequestResponceModel){
      let url = 'api/app/rdlcreport/insertupdaterdlcreport';
      const json = JSON.stringify(rdlcInsertUpdateModel);
      return this.service.post(url,rdlcInsertUpdateModel,true).pipe<ResponseModel>(tap((response: any) => {
      return response;
        })
      );
    }

      // *************delete RDLCUploadFiles  **************
    deleteRDLCUploadFiles(id:number):Observable<ResponseModel>{
      let url = `api/app/rdlcreport/deleteuploadedrdlcdocument?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // Reward point setting service start 
    // **********get all Reward point setting *********
      getAllRewardPoint():Observable<ResponseModel>{
        let url = "api/app/reward/getallrewardsetting";
        return this.service.get(url, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }
      
    // *************insert Reward point setting **************
      insertRewardPoint(createUpdateRewardPointRequestModel:CreateUpdateRewardPointRequestModel):Observable<ResponseModel>{
        let url = "api/app/reward/insertrewardsetting";
        const json = JSON.stringify(createUpdateRewardPointRequestModel);
        return this.service.post(url,createUpdateRewardPointRequestModel, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }

      // *************update Reward point setting **************
      updateRewardPoint(createUpdateRewardPointRequestModel:CreateUpdateRewardPointRequestModel):Observable<ResponseModel>{
        let url = "api/app/reward/updaterewardsetting";
        const json = JSON.stringify(createUpdateRewardPointRequestModel);
        return this.service.putWithId(url,createUpdateRewardPointRequestModel,true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }

      // *************delete Reward point setting **************
      deleteRewardPoint(id:number):Observable<ResponseModel>{
        let url = `api/app/reward/deleterewardsetting?Id=${id}`;
        return this.service.delete(url, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }

      // *************get by id Reward Point setting**************
      getByIdRewardPoint(id:number):Observable<ResponseModel>{
        let url = `api/app/reward/getbyidrewardsetting?Id=${id}`;
        return this.service.get(url, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }

      // *************get Redeem point by id AccountLedgerId**************
      getRedeemPointByIdAccountLedgerId(id:number):Observable<ResponseModel>{
        let url = `api/app/reward/getredeempointbyidaccountledgerid?Id=${id}`;
        return this.service.get(url, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }


    
    // **********get all Metal Id *********
    getAllMetalId():Observable<ResponseModel>{
      let url = "api/app/metal/getallmetal";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
      

    // *************get RewardSettingByMetalId **************
    getRewardSettingByMetalId(id:number):Observable<ResponseModel>{
      let url = `api/app/reward/getrewardsettingbymetalid?MetalId=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    // reward point service end 

 
      // *********** barcode setting start **********
      // get all barcode setting service 
      getAllBarcodeSetting():Observable<ResponseModel>{
        let url = "api/app/barcodesetting/getallbarcodesettings";
        return this.service.get(url, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }
        
      // add update barcode setting service
      insertUpdateBarcodeSetting(data:any):Observable<ResponseModel>{
        let url = "api/app/barcodesetting/insertupdatebarcodesetting";
        const json = JSON.stringify(data);
        
        return this.service.putWithId(url,data, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }
      
      // **********Barcode setting get by Id *********
      barcodeSettingGetById(id:number):Observable<ResponseModel>{
        let url = `api/app/barcodesetting/getbyid?id=${id}`;
        return this.service.get(url, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }
        

      // *************get ByMetalId & get byVoucherTypeId **************
      getByMetalIdAndVoucherTypeId(metalId:number , voucherTypeId:number ,labelSizeId:number):Observable<ResponseModel>{
        let url = `api/app/barcodesetting/getbymetalvouchertypeidandlabelsizeid?metalId=${metalId}&VoucherTypeId=${voucherTypeId}&labelSizeId=${labelSizeId}`;
        return this.service.get(url, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }


       // *************delete Barcode setting **************
       deleteBarcodeSetting(id:number):Observable<ResponseModel>{
        let url = `api/app/barcodesetting/deletebarcodesetting?id=${id}`;
        return this.service.delete(url, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
      }


      // *********** BarcodeToolbox service start ************
  // **********get all BarcodeToolbox *********
   getAllBarcodeToolbox(getallbarcodetoolboxkeyRequestModel : any):Observable<ResponseModel>{
    let url = "api/app/barcodetoolboxkey/getallbarcodetoolboxkey";
    const json =JSON.stringify(getallbarcodetoolboxkeyRequestModel)
    return this.service.post(url,getallbarcodetoolboxkeyRequestModel, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  
 // *************insertupdate BarcodeToolbox **************
  updateBarcodeToolbox(data:any[]):Observable<ResponseModel>{
    let url = "api/app/barcodetoolboxkey/updatebarcodetoolboxkey";
    const json = JSON.stringify(data);
    return this.service.putWithId(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

    // *************get by id BarcodeToolbox **************
    getByIdBarcodeToolbox(id:number):Observable<ResponseModel>{
      let url = `api/app/metal/getbyidmetal?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    // *********** BarcodeToolbox service end ************



     // device setting service start 
    // **********get all Device setting *********
    getAllDevice(data : any):Observable<ResponseModel>{
      let url = "api/app/devicemachine/getalldevicemachine";
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    
  // *************insert Device setting **************
  insertUpdateDeviceRecord(createUpdateDeviceRequestModel:CreateUpdateDeviceRequestModel):Observable<ResponseModel>{
      let url = "api/app/devicemachine/insertupdatedevicemachine";
      const json = JSON.stringify(createUpdateDeviceRequestModel);
      return this.service.putWithId(url,createUpdateDeviceRequestModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }


    // *************delete Device setting **************
    deleteDeviceRecord(id:number):Observable<ResponseModel>{
      let url = `api/app/devicemachine/deletedevicemachine?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // *************get by id Device setting**************
    getDeviceByImplementedOnid(id:number):Observable<ResponseModel>{
      let url = `api/app/devicemachine/getdevicemachinebyimplementedonid?ImplementedOnId=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }



    // template setting service start 
    // **********get all template setting *********
    getAllTemplateSetting():Observable<ResponseModel>{
      let url = "api/app/invoiceitemtemplate/getallinvoiceitemtemplate";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    
    // *************insertupdate template setting **************
    insertUpdateTemplateSetting(createUpdateTemplateSettingRequestModel:CreateUpdateTemplateSettingRequestModel):Observable<ResponseModel>{
        let url = "api/app/invoiceitemtemplate/insertupdateinvoiceitemtemplate";
        const json = JSON.stringify(createUpdateTemplateSettingRequestModel);
        return this.service.putWithId(url,createUpdateTemplateSettingRequestModel, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
    } 

    // *************delete template setting **************
    deleteTemplateSetting(id:number):Observable<ResponseModel>{
      let url = `api/app/invoiceitemtemplate/deleteinvoiceitemtemplate?Id=${id}`;
      return this.service.delete(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

   // **********template setting get by Id *********
   TemplateSettingGetById(id:number):Observable<ResponseModel>{
      let url = `api/app/invoiceitemtemplate/getbyidinvoiceitemtemplate?Id=${id}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
      

    // *************get ByMetalId & get byVoucherType Id **************
    getByMetalIdAndVoucherTypeTemplateSettingId(metalId:number , voucherTypeId:number,diamondCategoryTypeId:number):Observable<ResponseModel>{
      let url = `api/app/invoiceitemtemplate/getbymetalandvoucheridinvoiceitemtemplate?MetalId=${metalId}&VoucherTypeId=${voucherTypeId}&DiamondCategoryId=${diamondCategoryTypeId}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }



    // **********get Refresh sql Script ***********
    getRefreshsqlScript():Observable<ResponseModel>{
      let url = "api/app/setting/refreshdbhelperfiles";
      return this.service.get(url, true,undefined,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    getRefreshSystemFields():Observable<ResponseModel>{
      let url = "api/app/barcodetoolboxkey/refreshsystemfields";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    refreshcountries():Observable<ResponseModel>{
      let url = "api/app/setting/refreshcountries";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    resetVouchers():Observable<ResponseModel>{
      let url = "api/app/setting/resetVouchers";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    refreshSystemLedger():Observable<ResponseModel>{
      let url = "api/app/setting/refreshaccountledgers";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    resetMetals():Observable<ResponseModel>{
      let url = "api/app/setting/resetmetals";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    refreshSchedular():Observable<ResponseModel>{
      let url = "api/app/scheduletask/refreshscheduletask";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    
    refreshTaskTypes():Observable<ResponseModel>{
      let url = "api/app/tasktype/refreshtasktype";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    runMigrations():Observable<ResponseModel>{
      let url = "api/app/setting/runMigrations";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    
    getResetGrid():Observable<ResponseModel>{
      let url = "api/app/gridcolumndefinition/resetgridcolumndefinition";
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }


    retrySqlExecutionService(data:RetrySqlExecutionRequestModal):Observable<ResponseModel>{
      let url = "api/app/setting/retrydbhelperfiles";
      return this.service.post(url,data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // Database Backup setting service start 
    backupDatabase(data:DatabaseBackupRequestModel):Observable<ResponseModel>{
        let url = "api/app/databasebackup/backupdatabase";
        const json = JSON.stringify(data);
        return this.service.post(url,data, true).pipe<ResponseModel>(
          tap((response: any) => {
            return response;
          })
        );
    } 
    // Data Backup setting service end

    //Transaction message service start 

    insertTransactionMessage(transactionmessageModel:TransactionmessageModel):Observable<ResponseModel>{
      let url = "api/app/transactionmessage/insertupdatetransactionmessage";
      const json = JSON.stringify(transactionmessageModel);
      return this.service.post(url,transactionmessageModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    getTransactionMessage(voucherTypeId ,notificationServiceTypeDropdown):Observable<ResponseModel>{
    let url =`api/app/transactionmessage/gettransactionmessage?voucherTypeId=${voucherTypeId}&NotificationServiceTypeId=${notificationServiceTypeDropdown}`
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

      //Transaction message service end 
//Notification message service start 


    insertNotificationMessage(notificationmessageModel:NotificationmessageModel):Observable<ResponseModel>{
      let url = "api/app/notificationmessage/insertupdatenotificationmessage";
      const json = JSON.stringify(notificationmessageModel);
      return this.service.post(url,notificationmessageModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    getNotificationMessage(taskTypeId ,notificationServiceTypeDropdown):Observable<ResponseModel>{
    let url = `api/app/notificationmessage/getnotificationmessage?taskTypeId=${taskTypeId}&NotificationServiceTypeId=${notificationServiceTypeDropdown}`;
      return this.service.get(url, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

      //Notification message service end 


    // grid configuration service start 
    // **********get all grid configuration *********
    getAllGridConfiguration(getallGridConfigurationModel: GetallGridConfigurationModel):Observable<ResponseModel>{
      let url = "api/app/gridproperty/getallgridproperties";
      return this.service.post(url,getallGridConfigurationModel, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }
    // schedule task service start 
    // **********get all schedule task service *********
    getAllScheduleTask(data : any):Observable<ResponseModel>{
      let url = "api/app/scheduletask/getallscheduletask";
      return this.service.post(url, data,true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    executeScheduleTask(scheduleTaskId:any):Observable<ResponseModel>{
      let url = `api/app/scheduletask/${scheduleTaskId}/run-now`;
      return this.service.post(url,null, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

    // updateScheduleTask(data:any):Observable<ResponseModel>{
    //   let url = "api/app/scheduletask/updatescheduletask";
    //   return this.service.post(url,data, true).pipe<ResponseModel>(
    //     tap((response: any) => {
    //       return response;
    //     })
    //   );
    // }

    updateScheduleTask(data:any):Observable<ResponseModel>{
      let url = "api/app/scheduletask/updateJobSchedule";
      return this.service.post(url,data, true).pipe<ResponseModel>(
        tap((response: any) => {
          return response;
        })
      );
    }

   // *************update grid configuration **************
   updateGridConfiguration(data:any[]):Observable<ResponseModel>{
    let url = "api/app/gridproperty/insertupdategridproperties";
    const requestData = {
      data
    };
    return this.service.post(url,requestData, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
    


  // *************Enable / Disable Notifications Configure*************//
  getnotificationservicemapping(data : any):Observable<ResponseModel>{
    let url = "api/app/transactionmessage/getnotificationservicemapping";
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }


  insertnotificationservicemapping(data):Observable<ResponseModel>{
    let url = "api/app/transactionmessage/insertnotificationservicemapping";
    const json =JSON.stringify(data);
    
    return this.service.post(url,data, true).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }

 //*************Credentials Notifications Send Test Sms*************//

notificationSendTestSmsAndEmail(data,smsOrEmail):Observable<ResponseModel>{
  let url =`api/app/notification/sendtest${smsOrEmail}`;
  const json =JSON.stringify(data);
  
  return this.service.post(url,data, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}


whatsAppIntegrationGenerateToken(session,secretKey):Observable<ResponseModel>{
  this.whatsAppBaseUrl =  this.storageService.retrieve(StorageName.WHATSAPP_INTEGRATION_SETTING).apiUrl;
  let url:any =`${this.whatsAppBaseUrl}/api/${session}/${secretKey}/generate-token`;
  return this.service.postForWhatsApp(url,null).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

whatsAppIntegrationStartSession(session,data):Observable<ResponseModel>{
  this.whatsAppBaseUrl =  this.storageService.retrieve(StorageName.WHATSAPP_INTEGRATION_SETTING).apiUrl;
  let url:any =`${this.whatsAppBaseUrl}/api/${session}/start-session`;
  return this.service.postForWhatsApp(url,data).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

whatsAppIntegrationCloseSession(session):Observable<ResponseModel>{
  this.whatsAppBaseUrl =  this.storageService.retrieve(StorageName.WHATSAPP_INTEGRATION_SETTING).apiUrl;
  let url:any =`${this.whatsAppBaseUrl}/api/${session}/close-session`;
  return this.service.postForWhatsApp(url,'').pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

whatsAppIntegrationStatusSession(session):Observable<ResponseModel>{
  this.whatsAppBaseUrl = this.storageService.retrieve(StorageName.WHATSAPP_INTEGRATION_SETTING).apiUrl;
  let url:any =`${this.whatsAppBaseUrl}/api/${session}/status-session`;
  return this.service.getForWhatsApp(url,true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

  setWhatsappConnectionStatus(isWhatsappConnected): any {
    this.storageService.store("isWhatsappConnected", isWhatsappConnected);
    return isWhatsappConnected;
  }

whatsAppIntegrationSendMessage(session,data):Observable<ResponseModel>{
  this.whatsAppBaseUrl =  this.storageService.retrieve(StorageName.WHATSAPP_INTEGRATION_SETTING).apiUrl;
  let url:any =`${this.whatsAppBaseUrl}/api/${session}/send-message`;
  return this.service.postForWhatsApp(url,data).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

whatsAppIntegrationSendImage(session,data):Observable<ResponseModel>{
  this.whatsAppBaseUrl =  this.storageService.retrieve(StorageName.WHATSAPP_INTEGRATION_SETTING).apiUrl;
  let url:any =`${this.whatsAppBaseUrl}/api/${session}/send-image`;
  return this.service.postForWhatsApp(url,data).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}



// *************get by filename **************
getByBackUpbtn(filename : string):Observable<ResponseModel>{
  let url = `api/ftp/downloadfilefromftp?fileName=${filename}`;
  return this.service.get(url, true).pipe<ResponseModel>(
    tap((response: any) => {
      return response;
    })
  );
}

  downloadFile(filename: string) {
    let headers: any = '';
    const token = this.storageService.retrieve('authorizationDataIdToken');
    if (token !== undefined && token !== '')
      headers = { Authorization: `Bearer ${token}}` };


    let url = environment.baseUrl + `api/ftp/downloadfilefromftp?fileName=${filename}`;
    return this.http.get(url, {
      headers,
      responseType: 'blob',
      observe: 'response',
    });
  }

}