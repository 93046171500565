import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective implements AfterViewInit {
  @Input('appFocus') shouldFocus: any = false;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    if (this.shouldFocus == 'true') {
      this.el.nativeElement.focus();
    }
  }

  focusElement() {
    this.el.nativeElement.focus();
  }
} 
