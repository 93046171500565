<div class="d-flex gap-3 align-items-center justify-content-center h-100">
    <div class="d-flex gap-3 align-items-center">
      <input
        type="radio"
        id="blue-color"
        name="radio"
        value="HTML" 
        [ngClass]="{'pe-none':params?.isReadonly}"
        [checked]="params?.value" 
        (change)="onRadioChange()" 
        class="cursorPointer"
      />
      <label
        *ngIf="showLabel"
        for="portrait"
        class="f-14 mb-0 text-gray-color font-semibold mb-0"
        >
        {{ labelName }}
      </label
      >
    </div>
</div>


