<!-- ag-grid-button-cellrenderer.component.html -->
<button *ngIf="viewHistory && !params?.data?.isFooterRow" class="ag_button d-flex align-items-center justify-content-center white_button_BlueText border-primary" (click)="viewDetails(params)">View History</button>

<button *ngIf="view && !params?.data?.isFooterRow && !params?.data.isSumRow" class="ag_button d-flex align-items-center justify-content-center blue_button border-primary" (click)="viewDetails(params)"  [disabled]="params?.data?.voucherTypeId == voucherType.OpeningStock">View</button>

<button *ngIf="viewAllBtn && !params?.data?.isFooterRow && !params?.data.isSumRow" class="ag_button d-flex align-items-center justify-content-center blue_button  border-primary" (click)="viewDetails(params)">View All</button>

<button *ngIf="payable" class="ag_button px-4  d-flex align-items-center justify-content-center white_button_sky-blueText border-sky-blue" (click)="onButtonClicked()">PAYABLE</button>

<button *ngIf="bounce" class="ag_button px-4 d-flex align-items-center justify-content-center white_button_orangeText border-orange" (click)="onButtonClicked()">BOUNCE</button>

<button *ngIf="cancle" class="ag_button px-4 d-flex align-items-center justify-content-center white_button_redText border-red" (click)="onButtonClicked()">CANCEL</button>

<button *ngIf="receive" class="ag_button px-4 d-flex align-items-center justify-content-center white_button_greenText border-green" (click)="onButtonClicked()">RECEIVE</button>

<button *ngIf="runNow && !params?.data?.isFooterRow"  class="ag_button d-flex align-items-center justify-content-center white_button_BlueText border-primary" (click)="viewDetails(params)">Run Now</button>




<ng-container *ngIf="viewAndDeleteIcon">
    <div class="d-flex gap-2 align-items-center">
        <div>
            <button class="ag_button d-flex align-items-center justify-content-center white_button_BlueText border-primary" (click)="viewDetails(params)">View</button>
        </div>
        <div class="mx-1 d-flex align-items-center" (click)="isConfirmationPopOpen?openDeleteConfirmationPopup(contents,params):deleterowdata(params)">
            <svg class="cursorPointer px-0" xmlns="http://www.w3.org/2000/svg" width="11" height="14.5" viewBox="0 0 13.902 17.372">
                <g id="Group_1871" data-name="Group 1871" transform="translate(1558.804 -5979.472)">
                <path id="Path_1245" data-name="Path 1245" d="M-1557.89,5989.77c0-1.554,0-3.108,0-4.663,0-.216-.025-.317-.285-.327a.621.621,0,0,1-.629-.7c0-.354-.006-.707,0-1.061a1.709,1.709,0,0,1,1.687-1.679q1.029-.014,2.058,0c.191,0,.271-.041.259-.248-.016-.3-.01-.6,0-.9a.656.656,0,0,1,.722-.718q2.219-.006,4.438,0a.657.657,0,0,1,.729.711c.01.3.013.6,0,.9-.009.195.052.259.252.255.664-.011,1.329-.008,1.994,0a1.715,1.715,0,0,1,1.76,1.77c0,.333.005.665,0,1a.609.609,0,0,1-.622.673c-.295,0-.29.144-.29.354q.005,4.566,0,9.132a2.469,2.469,0,0,1-1.767,2.477,2.649,2.649,0,0,1-.761.1c-2.337,0-4.674,0-7.01,0a2.453,2.453,0,0,1-2.533-2.506C-1557.9,5992.814-1557.89,5991.292-1557.89,5989.77Zm10.786-.068c0-1.522-.006-3.044.006-4.566,0-.258-.054-.349-.332-.348q-4.422.016-8.844,0c-.274,0-.335.083-.334.344.009,3.033,0,6.067.007,9.1a1.194,1.194,0,0,0,1.338,1.323h6.785a1.211,1.211,0,0,0,1.374-1.385Q-1547.1,5991.936-1547.1,5989.7Zm-4.748-6.2c1.79,0,3.579,0,5.369,0,.211,0,.3-.037.3-.277-.015-.461-.145-.6-.622-.6q-5.047,0-10.095,0a1.318,1.318,0,0,0-.256.009c-.273.056-.393.307-.357.7.028.306.26.165.392.166C-1555.366,5983.506-1553.609,5983.5-1551.851,5983.5Zm0-2.166c.471,0,.943-.015,1.413.005.248.01.245-.119.246-.295s0-.3-.247-.293q-1.413.021-2.826,0c-.246,0-.247.117-.246.294s0,.3.247.294C-1552.8,5981.322-1552.323,5981.337-1551.852,5981.337Z" fill="#ec444d"/>
                <path id="Path_1246" data-name="Path 1246" d="M-1515.529,6059.129q0,1.719,0,3.438c0,.491-.252.787-.653.778-.385-.008-.632-.306-.633-.773q0-3.454,0-6.909c0-.474.243-.754.643-.754s.641.271.642.75Q-1515.526,6057.394-1515.529,6059.129Z" transform="translate(-38.378 -68.952)" fill="#ec444d"/>
                <path id="Path_1247" data-name="Path 1247" d="M-1485.436,6059.136q0-1.736,0-3.472c0-.479.235-.754.637-.757s.648.271.649.745q0,3.472,0,6.942c0,.462-.261.756-.652.749s-.632-.3-.633-.769Q-1485.438,6060.855-1485.436,6059.136Z" transform="translate(-67.06 -68.95)" fill="#ec444d"/>
                <path id="Path_1248" data-name="Path 1248" d="M-1454.07,6059.137q0-1.737,0-3.471c0-.482.232-.755.633-.757s.651.274.652.745q0,3.472,0,6.942a.665.665,0,0,1-.656.75.651.651,0,0,1-.629-.738C-1454.072,6061.451-1454.07,6060.294-1454.07,6059.137Z" transform="translate(-95.729 -68.952)" fill="#ec444d"/>
                </g>
            </svg>          
        </div>
    </div>
</ng-container>


<!-- Delete Cofirmation  modal -->
<ng-template #contents let-modal>
    <div class="modal-body pt-4 pb-4">
        <div class="row">
            <div class="col-12 d-flex justify-content-center mb-4">
                <img src="./assets/SVGicon/delete-confirm-icon.svg" alt="">
            </div>
            <div class="col-12 ">
                <div class="d-flex justify-content-center">
                    <h5 class="text-gray-color f-16 mb-0  font-semibold ">Deleting {{params?.getParamasData}}</h5>
                </div>
                <div class="d-flex justify-content-center">
                    <p class="theme-color-secondary f-13 w-p-200 mt-1 text-center font-regular mb-0">Are you sure you want to delete {{params?.getParamasData}} from this list</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block">
        <div class="row m-0">
            <div class="col-6 border-right d-flex justify-content-center align-items-center">
                <label class="f-14 font-semibold cursorPointer py-2 mb-0 text-gray-color" (click)="modal.dismiss('Cross click')">CANCEL</label>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center">
                <label class="f-14 font-semibold cursorPointer py-2 mb-0 text-danger"  (click)="deleterowdata(params,modal)">DELETE</label>
            </div>
        </div>
    </div>
</ng-template>
<!-- end -->
