import { AgGridService } from "./ag-grid.service";
import { Component, EventEmitter,Renderer2, Input, Output, ViewChild } from "@angular/core";
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  SelectionChangedEvent,
  ColumnApi,
  Column
} from "ag-grid-community";

import { AgGridI, Pagination, Updategridcolumndefinition, defaultPageLimit } from "./ag-grid.model";
import { CustomHeaderComponent } from "./custom-header/custom-header.component";
import { StorageService } from "../../services/storage.service";
import { Subscription } from "rxjs";
import { CommonService } from "../../services/common.service";
import { NotificationService } from "../../services/notification.service";
import { StorageName } from "../../enum/common-enum";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ToastrService } from "ngx-toastr";
declare var window: any;
@Component({
  selector: "app-ag-grid",
  templateUrl: "./ag-grid.component.html",
  styleUrls: ["./ag-grid.component.scss"],
})
export class AgGridComponent extends  CommonService {

  updategridcolumndefinition = new Updategridcolumndefinition();
  pagination = new Pagination();
  goToPageNumber: number;
  isBtnDisabled: boolean;
  totalrecord :number;
  record:number;

  public readonly pageLimitOptions = [
    { value: 25 },
    { value: 50 },
    { value: 75 },
    { value: 100 },
    { value:  1 },
  ];
  
  // code by pranay
  @Input()gridClass:any;
  gridApi:any;
  public editType: "fullRow" = "fullRow"; // for full row edit
  @Input() dataModel: AgGridI;
  @ViewChild(AgGridAngular) mainGrid: AgGridAngular;
  @Input() tableId: string;
  @Input() inputFocus: string='';
  @Input () tableRowCellEditable:boolean=true;
  @Input () addBlankRowOnEnter:boolean=false;
  @Input () autoGroupColumnDef:any;
  @Output() lastCellTriggered = new EventEmitter<any>();
  @Output() onRowDragged = new EventEmitter<any>();
  @Output() onGridDestroy = new EventEmitter<any>();

  isRowDrag = false;

  public components: {
    [p: string]: any;
  } = {
    agColumnHeader: CustomHeaderComponent,
  };
  defaultColDef: ColDef = {
    flex: 1,
    minWidth: 0,
    editable: true,
    resizable: true,
    singleClickEdit  : true,
    // suppressKeyboardEvent: this.suppressNavigation,
  };
  defaultColDefination: ColDef = {
    flex: 1,
    minWidth: 0,
    editable: false,
    resizable: true,
  };
  // code by pranay

  @Input() columnDefs: (ColDef | ColGroupDef)[];
  @Input() rowData: any[];
  @Input() gridOptions!: GridOptions;
  @Input() navigateGridOption!: GridOptions;
  @Input() rowHeight = 28;
  @Input() headerHeight = 28;
  @Input() columnSettings:boolean=false
  @Output() columnApi = new EventEmitter();
  @Output() gridApiEmit = new EventEmitter();
  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowDoubleClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() cellValueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() columnMoveChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() addNewBlankRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCellKeyDownValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() previewClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPagination: EventEmitter<Pagination> = new EventEmitter();
  @Output() imgCellClicked: EventEmitter<Pagination> = new EventEmitter();
  @Output() barcodeCellClicked: EventEmitter<Pagination> = new EventEmitter();
  @Output() onPageSizeUpdated: EventEmitter<number> = new EventEmitter();
  @Output() onSortIconClick: EventEmitter<any> = new EventEmitter();
  @Output() gridReady: EventEmitter<any> = new EventEmitter();
  @Output() onHeaderAddClick: EventEmitter<any> = new EventEmitter();
  @Output() onPrintButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onEditIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onCheckBoxClick: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onBillIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onDownloadIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onShowIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onCardPaymentIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onTextCellClick: EventEmitter<any> = new EventEmitter();
  @Output() onInnerHtmlContentClick: EventEmitter<any> = new EventEmitter();
  @Output() onArrowUpDownIconClick: EventEmitter<any> = new EventEmitter();
  @Output() barcodeIconClick: EventEmitter<any> = new EventEmitter();
  @Output() labelPrintClick: EventEmitter<any> = new EventEmitter();
  @Output() cardPrintClick: EventEmitter<any> = new EventEmitter();
  @Output() expandableIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onInfoIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onClockClick: EventEmitter<any> = new EventEmitter();
  @Output() onStockInButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onStockOutButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onStockJournal2ButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onBlockUnblockIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onCellContextMenu: EventEmitter<any> = new EventEmitter();
  @Output() onkycIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onPlusIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onRapnetIconClick: EventEmitter<any> = new EventEmitter();
  @Output() onHeaderCheckboxClick: EventEmitter<any> = new EventEmitter();
  @Output() onViewIconClick: EventEmitter<any> = new EventEmitter();

  showContextMenu = false;
  contextMenuPosition = { x: 0, y: 0 };
  selectedCellValue = '';
  gettotalrecordSubscription: Subscription;
  paramsData: any[];
  // @Input ()getRowStyle : any;
  private gridColumnApi!: ColumnApi;
  draggedColumnPosition: any;
  // noRowsTemplate
  constructor(public agGridService: AgGridService, 
    public notificationService : NotificationService, 
    public toaster: ToastrService,
    public storageService : StorageService,  
    public renderer: Renderer2,public router : Router) {
    super(renderer);
  }
  noRowsTemplate =
  `<span style="font-size:16px" No Record Found</span>`;
  ngOnInit(): void {
    this.prepareStorages();
    this.gettotalrecordSubscription = this.storageService.totalRecordpagonation.subscribe((result) => {
      if (result != undefined) {
       this.totalrecord = result; 
      }
    });
  }


  handleCustomHeaderEvent(data) {
    this.onHeaderAddClick.emit(data);
    
  }

  handlePrintButtonClick(event: any): void {
    if(event != undefined)
      this.onPrintButtonClick.emit(event);
  } 

  handleStockInButtonClick(event: any): void {
    if (event != undefined)
      this.onStockInButtonClick.emit(event);
  }
  handleStockOutButtonClick(event: any): void {
    if (event != undefined)
      this.onStockOutButtonClick.emit(event);
  } 
  handleStockJournal2ButtonClick(event: any): void {
    if (event != undefined)
      this.onStockJournal2ButtonClick.emit(event);
  } 
  handleBlockUnblockIconClick(event: any): void {
    if(event != undefined)
      this.onBlockUnblockIconClick.emit(event);
  } 
  handleEditIconClick(event: any): void {
    if(event != undefined)
    this.onEditIconClick.emit(event);
  }

  handleCheckBoxClick(event: any): void {
    if(event != undefined)
    this.onCheckBoxClick.emit(event);
  }

  handleTextCellClick(event: any): void {
    if(event != undefined)
    this.onTextCellClick.emit(event.detail.data);
  }

  handleDeleteButtonClick(event: any): void {
    if(event != undefined)
    this.onDeleteIconClick.emit(event);
  }

  handleInnerHtmlContentClick(event: any): void {
    if(event != undefined)
    this.onInnerHtmlContentClick.emit(event);
  }

  handleCardPaymentIconClick(event: any): void {
    if(event != undefined)
    this.onCardPaymentIconClick.emit(event);
  }

  billIconEvent(event: any): void {
    if(event != undefined)
    this.onBillIconClick.emit(event);
  }

  handleSortIconClick(event: any): void {
    if(event != undefined)
    this.onSortIconClick.emit(event);
  }

  barcodeIconEvent(event: any): void {
    if(event != undefined)
    this.barcodeIconClick.emit(event);
  }

  handleInfoIconCellClick(event: any): void {
    if(event != undefined)
      this.onInfoIconClick.emit(event?.detail?.data?.data);
  }
  handleClockIconCellClick(event: any): void {
    if(event != undefined)
      this.onClockClick.emit(event?.detail?.data?.data);
  }

  labelPrintClickEvent(event: any): void {
    if(event != undefined)
    this.labelPrintClick.emit(event);
  }
  cardPrintClickEvent(event: any): void {
    if(event != undefined)
    this.cardPrintClick.emit(event);
  }
  expandableIconClickEvent(event: any): void {
    if(event != undefined)
    this.expandableIconClick.emit(event);
  }
  downloadIconEvent(event: any): void {
    if(event != undefined)
    this.onDownloadIconClick.emit(event);
  }

  plusIconClickEvent(event: any): void {
    if(event != undefined)
    this.onPlusIconClick.emit(event);
  }


  ShowIconEvent(event: any): void {
    if(event != undefined)
    this.onShowIconClick.emit(event);
  }

  kycIconClick(event: any): void {
    if(event != undefined)
    this.onkycIconClick.emit(event);
  }

  handleArrowUpDownIconClick(event: any): void {
    if(event != undefined)
    this.onArrowUpDownIconClick.emit(event);
  }
  handlerapnetIconClick(event: any): void {
    if(event != undefined)
    this.onRapnetIconClick.emit(event);
  }

  handlerHeaderCheckboxClick(event: any): void {
    if(event != undefined)
    this.onHeaderCheckboxClick.emit(event);
  }

  handlerViewIconClick(event: any): void {
    if(event != undefined)
    this.onViewIconClick.emit(event);
  }

  
  handleCellContextMenu(event: any): void {
    event.preventDefault(); // Prevent the default browser context menu

    this.selectedCellValue = event.detail.data.value; // Store the clicked cell value
    const textarea = document.createElement('textarea');
    textarea.value =  this.selectedCellValue || ''; // Fallback in case the value is null/undefined
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.toaster.success("text copied");
  }

  copyText(): void {
    // Copy the selected cell value to the clipboard
    navigator.clipboard.writeText(this.selectedCellValue).then(() => {
      
    });
    this.showContextMenu = false; // Hide the custom menu after copying
  }

  // Hide the menu when clicking elsewhere
  hideMenu(): void {
    this.showContextMenu = false;
  }

  //#region onGridReady
  async onGridReady(params) {
    params.api.addEventListener('editIconClick', this.handleEditIconClick.bind(this));
    params.api.addEventListener('deleteButtonClick', this.handleDeleteButtonClick.bind(this));
    params.api.addEventListener('printButtonClick', this.handlePrintButtonClick.bind(this));
    params.api.addEventListener('billIconClick', this.billIconEvent.bind(this));
    params.api.addEventListener('downloadIconClick', this.downloadIconEvent.bind(this));
    params.api.addEventListener('plusIconClickEventTriggered', this.plusIconClickEvent.bind(this));
    params.api.addEventListener('ShowDetailsIconClick', this.ShowIconEvent.bind(this));
    params.api.addEventListener('kycIconClick', this.kycIconClick.bind(this));
    params.api.addEventListener('cardPaymentIconClick', this.handleCardPaymentIconClick.bind(this));
    params.api.addEventListener('textCellClick', this.handleTextCellClick.bind(this));
    params.api.addEventListener('innerHtmlContentClick', this.handleInnerHtmlContentClick.bind(this));
    params.api.addEventListener('arrowUpDownIconClick', this.handleArrowUpDownIconClick.bind(this));
    params.api.addEventListener('barcodeIconClick', this.barcodeIconEvent.bind(this));
    params.api.addEventListener('InfoIconClick', this.handleInfoIconCellClick.bind(this));
    params.api.addEventListener('ClockIconClick', this.handleClockIconCellClick.bind(this));
    params.api.addEventListener('labelPrintClick', this.labelPrintClickEvent.bind(this));
    params.api.addEventListener('cardPrintClick', this.cardPrintClickEvent.bind(this));
    params.api.addEventListener('expandableIconClick', this.expandableIconClickEvent.bind(this))
    params.api.addEventListener('stockInButtonClick', this.handleStockInButtonClick.bind(this));
    params.api.addEventListener('stockOutButtonClick', this.handleStockOutButtonClick.bind(this));
    params.api.addEventListener('stockJournal2ButtonClick', this.handleStockJournal2ButtonClick.bind(this));
    params.api.addEventListener('blockUnblockIconClick', this.handleBlockUnblockIconClick.bind(this));
    params.api.addEventListener('cellContextMenu', this.handleCellContextMenu.bind(this));
    params.api.addEventListener('checkBoxClick', this.handleCheckBoxClick.bind(this));
    params.api.addEventListener('rapnetIconClick', this.handlerapnetIconClick.bind(this));
    params.api.addEventListener('sortIconClick', this.handleSortIconClick.bind(this));
    params.api.addEventListener('headerCheckboxClick', this.handlerHeaderCheckboxClick?.bind(this));
    params.api.addEventListener('viewIconClick', this.handlerViewIconClick.bind(this));


    this.rowData= this.rowData??[]
    this.agGridService.setGridApi(params);
    this.agGridService.setGridColumnApi(params.columnApi);
    this.paramsData = params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if(this.dataModel.headerHeight == undefined)
      this.dataModel.headerHeight = 30;
    
    this.storageService.removeItem(this.dataModel?.filterStorageName);
    this.storageService.removeItem(this.dataModel?.sortStorageName);
    
    this.gridReady.emit(params);
    this.gridApiEmit.emit(params.api);
    this.noRowsTemplate =
    `<span style="font-size:16px" No Record Found</span>`;
  //  this.prepareStorages();

        // For Column Save State
        var colState = this.storageService.retrieve(this.dataModel.storageName != undefined ? this.dataModel?.storageName.toString() : "");
        if (colState == undefined) {
          colState= await this.agGridService.getAllColumnDefination(this.dataModel);
         // // call api to get col def using this.dataModel.storageName.toString()
        }
        if (!colState) 
          return;
      
        this.gridColumnApi.applyColumnState({
          state: colState,
          applyOrder: true,
        });


  }
  //#endregion

  prepareStorages()
  {
    if (this.dataModel.colDefs) {
      this.dataModel.colDefs =    this.dataModel?.colDefs.map((item, index) => ({
        ...item,
        headerComponentParams: {
          ...item.headerComponentParams,
          storageName:  item?.headerComponentParams?.storageName == undefined ? this.dataModel?.storageName : item?.headerComponentParams?.storageName,
          filterStorageName : item.headerComponentParams?.filterStorageName == undefined ? this.dataModel?.filterStorageName : item.headerComponentParams?.filterStorageName,
          initialRows : item.headerComponentParams?.initialRows == undefined ? this.dataModel?.initialRows : item.headerComponentParams?.initialRows,
          sortStorageName : item.headerComponentParams?.sortStorageName  == undefined ? this.dataModel?.sortStorageName : item.headerComponentParams?.sortStorageName,
          paginationStorageName : item.headerComponentParams?.paginationStorageName == undefined ? this.dataModel?.paginationStorageName : item.headerComponentParams?.paginationStorageName,
          moreButton : (item?.extraField == "Action" || item.field=='action') ? this.dataModel?.moreButton : false,
          selectAll : (item?.field=='active' || item?.field=='isSelected') ?  this.dataModel?.selectAll  : false,
          isFilter : this.dataModel.isFilter,
          isServerSidepagination : item?.headerComponentParams?.isServerSidepagination == undefined ? this.dataModel?.isServerSidepagination : item.headerComponentParams?.isServerSidepagination,
          // apiUrl :  item?.headerComponentParams?.apiUrl == undefined ? this.dataModel?.apiUrl : item?.headerComponentParams?.apiUrl ,
          apiUrl :  this.dataModel?.apiUrl,
          onCustomHeaderEvent     : this.handleCustomHeaderEvent.bind(this),
          oncustome     : this.handleCustomHeaderEvent.bind(this),
         
        },
        storageName: item?.headerComponentParams?.storageName == undefined ? this.dataModel?.storageName : item.headerComponentParams?.storageName,
      }))
    }
  }

  // assignValues(element){
  //   element.headerComponentParams                         = element?.headerComponentParams??{};
  //   // element.headerComponentParams.apiUrl                  = this.dataModel?.apiUrl;   
  //   // element.headerComponentParams.storageName =  element.headerComponentParams.storageName == undefined ? this.dataModel?.storageName : element.headerComponentParams.storageName;
  //   // element.headerComponentParams.filterStorageName                  = element.headerComponentParams.filterStorageName == undefined ? this.dataModel?.filterStorageName : element.headerComponentParams.filterStorageName;   
  //   // element.headerComponentParams.sortStorageName                  = element.headerComponentParams.sortStorageName  == undefined ? this.dataModel?.sortStorageName : element.headerComponentParams.sortStorageName ;   
  //   // element.headerComponentParams.paginationStorageName                  = element.headerComponentParams.paginationStorageName == undefined ? this.dataModel?.paginationStorageName : element.headerComponentParams.paginationStorageName;   
  //   // if(element.extraField=='Action'){
  //   //   element.headerComponentParams.moreButton            = element.headerComponentParams.moreButton == undefined ? this.dataModel?.moreButton : element.headerComponentParams.moreButton;  
  //   // }
  //   // if(element.field=='action'){
  //   //   element.headerComponentParams.moreButton            = element.headerComponentParams.moreButton == undefined ? this.dataModel?.moreButton : element.headerComponentParams.moreButton;  
  //   // }
  //   // if(element.field==='active'){
  //   //   element.headerComponentParams.selectAll            =  element.headerComponentParams.selectAll == undefined ? this.dataModel?.selectAll :  element.headerComponentParams.selectAll;  
  //   // }
  
    
  //   // element.headerComponentParams.isFilter                =  element.headerComponentParams.isFilter  == undefined ? this.dataModel.isFilter :  element.headerComponentParams.isFilter ;
  //   // element.headerComponentParams.isServerSidepagination  = element.headerComponentParams.isServerSidepagination == undefined ? this.dataModel?.isServerSidepagination : element.headerComponentParams.isServerSidepagination;  
  //   // element.headerComponentParams.tableIndex              = element.headerComponentParams.tableIndex == undefined ? this.dataModel?.tableIndex : element.headerComponentParams.tableIndex;

  //   // element.headerComponentParams.onCustomHeaderEvent     = this.handleCustomHeaderEvent.bind(this);
  //   // element.headerComponentParams.oncustome     = this.handleCustomHeaderEvent.bind(this);
    
  // }



  //#region onSelectionChanged
  onSelectionChanged(event: SelectionChangedEvent) {

    var selectedNodes =   event.api.getSelectedNodes();
     selectedNodes.forEach(selectedNode => {
      selectedNode.data.active = true;

      event.api.refreshCells({ rowNodes: [selectedNode], force: true, columns: ['active'] });
    });
  }
  //#endregion

  // getRowStyle(params)
  // {
  //   return {
  //     background: params.node.rowIndex % 2 === 0 ? '#EEF1F7' : '#FFFFFF',
  //   };
  // }

  // suppressNavigation(params: SuppressKeyboardEventParams) {
  //   if (params.event.code != "Tab") return true;
  //   if (params.event.code == "Tab") {
  //     this.storageService.onTabKeyPressed.next({dataModel : this.dataModel, gridOptions : params});
  //     return true;
  //   }
  // }

  onMovingChanged(event) {
    
  }

  onRowClicked(event: any) {
    this.rowClicked.emit(event); 
  }
  onRowDoubleClicked(event: any) {
    this.rowDoubleClicked.emit(event); 
  }
  addNewRow(data:{}) 
  {
    this.rowData.push(data);
    this.gridApi.setRowData(this.rowData);
    // add the new row to the grid
   
  }
  onCellKeyDown(gridOptions) {
    if (gridOptions.data.added != true) {
      gridOptions.data.edited = true;
    } 
    // if(gridOptions.colDef.field == "productDetailId" 
    // || gridOptions.colDef.field == "subCategoryId"
    // || gridOptions.colDef.field == "caratId" 
    // || gridOptions.colDef.field == "unitId" 
    // || gridOptions.colDef.field == "makingOn" 
    // || gridOptions.colDef.field == "purityPer"  
    // || gridOptions.colDef.field == "paymentMethodId"  
    // || gridOptions.colDef.field == "transferFromId"  
    // || gridOptions.colDef.field == "puritySale"  
    // || gridOptions.colDef.field == "purityPurchase"  
    // || gridOptions.colDef.field == "purityPurchase"  
    // || gridOptions.colDef.field == "metalId"  
    // || gridOptions.colDef.field == "depositeInto"  
    // || gridOptions.colDef.field == "makingTypeId"  
    // || gridOptions.colDef.field == "accountLedgerId"
    // || gridOptions.colDef.field == "crOrDr"
    // || gridOptions.colDef.field == "againstVoucherTypeId"
    // || gridOptions.colDef.field == "calculationType"
    // || gridOptions.colDef.field == "diamondCategoryId"
    // || gridOptions.colDef.field == "goldCaratId"
    // || gridOptions.colDef.field == "cutId"
    // || gridOptions.colDef.field == "colorId"
    // || gridOptions.colDef.field == "seiveSizeId"
    // || gridOptions.colDef.field == "sizeId"
    // || gridOptions.colDef.field == "shapeId"
    // || gridOptions.colDef.field == "qualityId"
    //  )
    //   gridOptions.node.data[gridOptions.colDef.field] =  Number(gridOptions?.event?.target?.value);
    // else
    gridOptions.node.data[gridOptions.colDef.field] = gridOptions?.event?.target?.value??gridOptions.value;
    // const fieldName=gridOptions.colDef.field;
    // Object.entries(gridOptions.data).forEach((obj:any)=>{
    //   // if(obj[0]=='transactionNo'){
    //   //   gridOptions.data[fieldName]=(obj[1]=='' || obj[1] ==undefined )?'0':obj[1];
    //   // }
    //   if(obj[0]==fieldName){
    //     // if(fieldName !='transactionNo' && gridOptions.event.target.value){
    //       gridOptions.data[fieldName]=(gridOptions.event.target.value=='' || gridOptions.event.target.value)
    //     // }
    //     // if(fieldName =='transactionNo'){
    //     //   gridOptions.data[fieldName]=(gridOptions.event.target.value=='' || gridOptions.event.target.value ==undefined )?'0':gridOptions.event.target.value
    //     // }
       
    //   }
    // })
    Object.entries(gridOptions.node.data).forEach((obj:any)=>{
      if((obj[1]) === ''){
        gridOptions.node.data[obj[0]]=null;
      }
    })

    if(gridOptions.colDef.field !== 'reverseCalculationAmount' && gridOptions.colDef.field !== 'itemCode')
      this.cellValueChanged.emit(gridOptions);

   
    
      if(gridOptions.event.key == "Enter" && (gridOptions.colDef.field === 'reverseCalculationAmount' || gridOptions.colDef.field === 'itemCode') )
      this.cellValueChanged.emit(gridOptions);
    const navigateToNextGrid=  this.navigateToNextGridCell(gridOptions)
    if ((gridOptions.event.key == "Enter"|| gridOptions.event.key =='ArrowRight' || gridOptions.event.key =='ArrowLeft') && navigateToNextGrid == false) {


      if((gridOptions.event.key == "Enter"|| gridOptions.event.key =='ArrowRight')){
        gridOptions.api.tabToNextCell();
      }else if(gridOptions.event.key =='ArrowLeft'){
        gridOptions.api.tabToPreviousCell()
      }

      
      var currentCell = gridOptions.api.getFocusedCell();
      const isCurrentCellEditable=currentCell.
      column.getColDef().editable == false
      
      if(isCurrentCellEditable){
        gridOptions.api.tabToNextCell();
      }
      var currentCell = gridOptions.api.getFocusedCell();
      gridOptions.api.stopEditing();
      gridOptions.api.clearFocusedCell();
      gridOptions.api.startEditingCell({
        rowIndex: currentCell.rowIndex,
        colKey: currentCell.column.colId,
      });
    }

    if(navigateToNextGrid == true)
      this.lastCellTriggered.emit({dataModel : this.dataModel, gridOptions : gridOptions});
   
    if((gridOptions.event.key == "Tab"  )){
      this. storageService.onTabKeyPressed.next({dataModel : this.dataModel, gridOptions : gridOptions});
      // if(this.navigateGridOption==undefined){
      //   document.getElementById(this.inputFocus).focus();
      // }
      // const columnApi: ColumnApi = this.navigateGridOption.columnApi;
      // const allColumns: Column[] = columnApi.getAllColumns();
      // const firstColumn = allColumns.length > 0 ? allColumns[0] : null;
      // const firstColumnDef: ColDef = firstColumn.getColDef();
      //   this.navigateGridOption.api.setFocusedCell(0, firstColumnDef.field);
      //   gridOptions.api.stopEditing()
      //   this.navigateGridOption.api.startEditingCell({
      //     rowIndex: 0,
      //     colKey: firstColumnDef.field,
      //   });
    }
    // const data= getRowDataAndRequiredFields(gridOptions)
    // const isValidRow=areFieldsRequired(data?.rowdata,data?.requiredColumn)
    // this.onCellKeyDownValue.emit(gridOptions)
    // if(navigateToNextGrid && this.addBlankRowOnEnter && isValidRow && gridOptions.event.key == "Enter"){
    //   this.addNewBlankRow.emit(this.tableId)
    // }

    
  }


  navigateToNextGridCell(gridOptions):boolean {

    const rowCount = gridOptions.api.getModel().getRowCount();
    const lastIndex = rowCount > 0 ? rowCount - 1 : null;
   
    const columnApi: ColumnApi = gridOptions.columnApi;
    const allColumn: Column[] = columnApi.getAllColumns();
    const lastColumnDef= allColumn.length > 0 ? allColumn[allColumn.length - 1] : null;
    
    const cellRenderer = lastColumnDef.getColDef().cellRenderer?.name;
    let lastSecondColumn  = null;
      if('EditDeleteIconComponent' == cellRenderer){
        lastSecondColumn  = allColumn.length > 0 ? allColumn[allColumn.length - 2] : null;
      }

      const allVisibleColumn: Column[] = columnApi.getAllDisplayedColumns();
     
  
      if(allVisibleColumn[allVisibleColumn.length - 2]?.getColId() == gridOptions.column?.colId && gridOptions.rowIndex == lastIndex){
        return true
      }
      return false;
  }

  onCellValueChanged(event) {
    if (event.newValue != null) {
      if (event.data.added !== true) {
        event.data.edited = true;
      } 
    }
    this.cellValueChanged.emit(event);
  }
  


  async onDrag(params){
    
   if(this.isRowDrag){
    this.isRowDrag = false;
    this.onRowDragged?.emit(params);
    return
   }
    window.colState = params.columnApi.getColumnState(); // For getting Column State;
    const columnDefs = params?.columnApi?.getAllGridColumns();

   window?.colState?.forEach(columnState => {
    const matchingColumn = columnDefs.find(column => column?.colId === columnState.colId);
    if (matchingColumn) {
      columnState.headerName = matchingColumn?.colDef?.headerName;
    }
  });

    this.storageService.store(this.dataModel.storageName?.toString(), window.colState);
    const gridName = this.dataModel.storageName;
    const colDefs =window.colState
    // colDefs.map(col => {
    //   return { ...col, pinned: null };
    // })
    await this.agGridService.insertUpdateAllColumnDefinations(gridName ,colDefs)
  }

  getRowStyle = params => {
    let style = {};
    if (params.node.rowPinned) {
      style = { background: '#EEF1F7', 'pointer-events': 'none'  };
    
      // const columnToHide = 'active'; // Replace with the actual column you want to hide
      // if (params?.column?.getColId() === columnToHide) {
      //   style['visibility'] = 'hidden';
      // }
    } else if (params.node.rowIndex % 2 === 0 && params.node.data.colorCode == undefined) {
      style = { background: '#EEF1F7' };
    }
    else if(params.node.data.colorCode != undefined)
    {
      style = { background: params.node.data.colorCode};
    }
    else
    {

    }
    return style;
  };

  getRowHeight(params: any) {
    // Minimum height for the row (adjust as needed)
    const minHeight = 30;

    // Calculate height based on content
    const contentHeight = params.data.description != undefined ? params.data.description.split('\n').length * 20 : 0; // Adjust the line height as needed

    // Return the maximum of minimum height and content height
    return Math.max(minHeight, contentHeight);
  }

  onPreviewClicked(data: any): void {
    this.previewClicked.emit(data);
  }

  @Input() getRowClass = (row) => {
    if (row.data.isScanned) {
     return "scanned_row_color";
   }
   
   };

   //#region Pagination
   clearPaginationElements() {
    this.pagination.count = 0;
    this.pagination.totalPages = 1;
    this.pagination.page = 1;
    this.pagination.pageSize = defaultPageLimit;
    // this.pagnationUpdated = true;
  }

  //#region PaginationisBtnDisabled
  updateServersidePaginationData(totalRows, totalPages, page,pageSize) {
    this.pagination.page = page;
    this.pagination.pageSize = pageSize;
    this.pagination.totalPages = totalPages;
    this.pagination.count = totalRows;
    this.pagination.fromRecordNumber = ((this.pagination.page - 1) * this.pagination.pageSize) + 1;
    var lastRow = (this.pagination.fromRecordNumber - 1) +Number(this.pagination.pageSize);
    this.pagination.toRecordNumber = (lastRow < this.pagination.count) ? (lastRow) : this.pagination.count;
    this.pagination.fromRecordNumber = this.pagination.toRecordNumber == 0 ? 0 : this.pagination.fromRecordNumber;
    this.pagination.totalPages = this.pagination.totalPages == 0 ? this.pagination.page : this.pagination.totalPages;
    this.record = 0;
  }
  updateServersidePaginationgetallData(page ,totalrecord){
    this.pagination.page = page;
    this.pagination.pageSize = 1;
    this.pagination.toRecordNumber = totalrecord;
    this.pagination.count =totalrecord == 0? 0:1;
    this.record = totalrecord
    this.pagination.fromRecordNumber = totalrecord == 0? 0:1;

  }
  setInputValue() {
    if (this.goToPageNumber == 0 || this.goToPageNumber > this.pagination.totalPages)
      this.isBtnDisabled = true;
    else
      this.isBtnDisabled = false;
  }

  onPageNumberChanged(index) {
    this.pagination.page = index;
    if (this.dataModel.isServerSidepagination)
      this.onPagination.emit(this.pagination)
    else
    {
      this.gridApi.paginationSetPageSize(index);
      this.onPageSizeUpdated.emit(this.pagination.pageSize)
      //this.updatePageSize(this.pagination.pageSize)
    }
  }

  onPageSizeChanged(index) {

    if (this.dataModel.isServerSidepagination){
      var paginationStorageName  =  this.dataModel.paginationStorageName
      this.storageService.store(paginationStorageName, this.pagination)
      if(this.pagination.pageSize == 1){
        this.pagination.page = 1;
      }
      this.onPagination.emit(this.pagination)  
    }    
    else
    {
      this.gridApi.paginationSetPageSize(index);
      this.onPageSizeUpdated.emit(this.pagination.pageSize)
      //this.updatePageSize(this.pagination.pageSize)
    }
  }
  onBtFirst() {
    if (this.dataModel.isServerSidepagination) {
      this.pagination.page = 1;
      this.onPagination.emit(this.pagination)
    }
    else
      this.gridApi.paginationGoToFirstPage();
  }
  onBtLast() {
    if (this.dataModel.isServerSidepagination) {
      this.pagination.page = this.pagination.totalPages;
      this.onPagination.emit(this.pagination)
    }
    else
      this.gridApi.paginationGoToLastPage();
  }
  onBtNext() {
    if (this.dataModel.isServerSidepagination) {
      if (this.pagination.page < this.pagination.totalPages) {
        this.pagination.page++;
        this.onPagination.emit(this.pagination)
      }
      else
        this.notificationService.warningPopup("You reached last page");
    }
    else
      this.gridApi.paginationGoToNextPage();
  }
  onBtPrevious() {
    if (this.dataModel.isServerSidepagination) {
      if (this.pagination.page > 1) {
        this.pagination.page--;
        this.onPagination.emit(this.pagination)
      }
      else
        this.notificationService.warningPopup("You reached first page");
    }
    else
      this.gridApi.paginationGoToPreviousPage();
  }
  onPaginationChanged(event) {
    if (!this.dataModel.isServerSidepagination) {
      this.pagination.page = event.api.paginationGetCurrentPage() + 1;
      this.pagination.pageSize = event.api.paginationGetPageSize();
      this.pagination.totalPages = event.api.paginationGetTotalPages();
      this.pagination.count = event.api.paginationGetRowCount();
      this.pagination.fromRecordNumber = (event.api.paginationGetCurrentPage() * this.pagination.pageSize) + 1;
      var lastRow = (this.pagination.fromRecordNumber - 1) + this.pagination.pageSize;
      this.pagination.toRecordNumber = (lastRow < this.pagination.count) ? (lastRow) : this.pagination.count;
      this.pagination.fromRecordNumber = this.pagination.toRecordNumber == 0 ? 0 : this.pagination.fromRecordNumber;
      this.pagination.totalPages = this.pagination.totalPages == 0 ? this.pagination.page : this.pagination.totalPages;
    }

    

  }
  goToPage() {
    if (this.dataModel.isServerSidepagination) {
      if (this.goToPageNumber <= this.pagination.totalPages) {
        this.pagination.page = this.goToPageNumber;
        this.onPagination.emit(this.pagination)
      }
      else
        this.notificationService.warningPopup("Page number does not exist");
    }
    else
      this.gridApi.paginationGoToPage(this.goToPageNumber - 1);
  }
   //#endregion

    onCellClicked(event) {
      const colDef = event.colDef;
      if (colDef.extraField=='JewelleryImg') {
          this.imgCellClicked.emit(event);
      }
    //   if (colDef.extraField=='Barcodepopup') {
    //     this.barcodeCellClicked.emit(event);
    // }
      if(colDef.storageName == StorageName.REPORT_STOCKREPORT_CURRENTSTOCK_GRID && (colDef.field == "quantity" || colDef.field == "weight" || colDef.field == "netWt" ))
      {
        this.storageService.onCellClicked.next(event);
      }
    }
    onCellDoubleClicked(event){
      const colDef = event.colDef;
      if (colDef.extraField=='Barcodepopup') {
        this.barcodeCellClicked.emit(event);
    }
    }

    ngOnDestroy(): void {
      this.gettotalrecordSubscription?.unsubscribe();
      this.totalrecord =undefined;
      this.storageService.removeItem(this.dataModel?.storageName);
      this.onGridDestroy.emit();
    }
}