// import { StorageName } from "/src/app/shared/enum/common-enum";
import { AgColDefsI, AgGridI, Filters } from "../../../../../shared/common-components/ag-grid/ag-grid.model";
import { AmountColWidth, ColumnCellType, NameColWidth, ProductColWidth, StorageName, WeightColWidth } from "../../../../../shared/enum/common-enum";
import { TextRendererComponent, getCellStyleForColor } from "../../../../../shared/common-components/ag-grid/text-renderer/text-renderer.component";
import { API_URL_GET_ACCOUNTlEDGER_REPORT_AMOUNT } from "src/app/shared/url-helper/url-helper";
import { AgGridButtonCellrendererComponent } from "src/app/shared/common-components/ag-grid/ag-grid-button-cellrenderer/ag-grid-button-cellrenderer.component";
import { NumericCellRendererComponent } from "src/app/shared/common-components/ag-grid/numeric-cell-renderer/numeric-cell-renderer.component";
const SRColumn: AgColDefsI = {
    headerName: "Sr No",
    field: "srNo",
    valueGetter: 'node.rowIndex + 1',
    minWidth: 60,
    maxWidth: 60,
    sortable: false,
    editable: false,
    cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
    headerClass: "custom-column-group-cell px-0",
  };
const LedgerNameColumn: AgColDefsI = {
    headerName: "Ledger",
    field: "ledgerName",
    minWidth: ProductColWidth.MinWidth,
    maxWidth: ProductColWidth.MaxWidth,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell ",
    editable: false,
    sortable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
  };

  const BranchName: AgColDefsI = { 
    headerName: "Branch Name", 
    field: 'branchName',
    headerComponentParams: {enableMenu:false},
    sortable:false,
    headerClass: "custom-column-group-cell px-0",
    cellClass:'custom-column-group-cell ',
    suppressMovable: false,
    resizable: true, 
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:false},
    editable:false, 
    minWidth: 180, flex:2,
  }

  // const GroupNameColumn: AgColDefsI = {
  //   headerName: "Group",
  //   field: "groupName",
  //   minWidth: NameColWidth.MinWidth,
  //   maxWidth: NameColWidth.MaxWidth,
  //   headerClass: "custom-column-group-cell px-0",
  //   cellClass: "custom-column-group-cell ",
  //   editable: false,
  //   sortable: false,
  //   resizable:true,
  //   cellRenderer: TextRendererComponent,
  //   cellRendererParams :{isCursor:true},
  // };

  const OpeningCrOrDrColumn: AgColDefsI = {
    headerName: "Opening CrOrDr",
    field: "openingCrOrDr",
    minWidth: 70,
    cellStyle: getCellStyleForColor(ColumnCellType.AmountColumn),
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    resizable:true,
    cellRenderer: TextRendererComponent,
  };

  const OpeningValueColumn: AgColDefsI = {
    headerName: "Opening Value",
    field: "openingValue",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.AmountColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  };

  const DebitColumn: AgColDefsI = {
    headerName: "Debit",
    field: "debit",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.AmountColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  };
  
  const CreditColumn: AgColDefsI = {
    headerName: "Credit",
    field: "credit",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.AmountColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isAmt:true},
  
  };

  const ClosingCrOrDrColumn: AgColDefsI = {
    headerName: "Closing CrOrDr",
    field: "closingCrOrDr",
    minWidth: 70,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.AmountColumn),
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  
  };

  const ClosingValueColumn: AgColDefsI = {
    headerName: "Closing Value",
    field: "closingValue",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.AmountColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
  
  };

  const OpeningPureWtColumn: AgColDefsI = {
    headerName: "Opening Wt",
    field: "openingPureWtCrOrDr",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.WtColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
   
  };

  const OpeningPureWtValueColumn: AgColDefsI = {
    headerName: "Opening Wt Value",
    field: "openingPureWtValue",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.WtColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isWeight:true},
 
  };

  const DebitPureWtColumn: AgColDefsI = {
    headerName: "Debit Wt",
    field: "debitPureWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.WtColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isWeight:true},
  
  };
  
  const CreditPureWtColumn: AgColDefsI = {
    headerName: "Credit Wt",
    field: "creditPureWt",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.WtColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isWeight:true},
   
  };
  const ClosingPureWtCrOrDrColumn: AgColDefsI = {
    headerName: "Closing Wt",
    field: "closingPureWtCrOrDr",
    minWidth: WeightColWidth.MinWidth,
    maxWidth: WeightColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.WtColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: TextRendererComponent,
  
  };

  const ClosingPureWtValueColumn: AgColDefsI = {
    headerName: "Closing Wt Value",
    field: "closingPureWtValue",
    minWidth: AmountColWidth.MinWidth,
    maxWidth: AmountColWidth.MaxWidth,
    resizable:true,
    cellStyle: getCellStyleForColor(ColumnCellType.WtColumn),
    headerClass: "custom-column-group-cell ag_header_end px-0",
    cellClass: "custom-column-group-cell",
    editable: false,
    sortable: false,
    cellRenderer: NumericCellRendererComponent,
    cellRendererParams:{isWeight:true},
  
  };
  //n
  const ledgerType : AgColDefsI = {
    headerName: "Ledger Type",
    field: "ledgerTypeName",
    minWidth: NameColWidth.MinWidth,
    maxWidth: NameColWidth.MaxWidth,
    resizable:true,
    headerClass: "custom-column-group-cell px-0",
    cellClass: "custom-column-group-cell ",
    editable: false,
    sortable: true,
    cellRenderer: TextRendererComponent,
    cellRendererParams :{isCursor:true},
  };

  
  const ActionColumn: AgColDefsI = {
    headerName: "",
    field: "action",
    minWidth: 90,
    maxWidth:90,
    sortable: true,
    editable: false,
    headerComponentParams: { enableSorting: false},
    cellRenderer: AgGridButtonCellrendererComponent, 
    cellRendererParams: { viewAllBtn: true },
    cellClass: "custom-column-group-cell px-0 d-flex align-items-center justify-content-center",
    headerClass: "custom-column-group-cell px-0",
    footerClass: "custom-footer-class",
    suppressPaste: true,
  };
 


  
  const AccountLedgerReportColumnDef :AgColDefsI[] = [
    
    ActionColumn,
    LedgerNameColumn,
    BranchName,
    // GroupNameColumn,    
    OpeningCrOrDrColumn,
    OpeningValueColumn,
    DebitColumn,
    CreditColumn,
    ClosingCrOrDrColumn,
    ClosingValueColumn,
    OpeningPureWtColumn,
    OpeningPureWtValueColumn,
    DebitPureWtColumn,
    CreditPureWtColumn,
    ClosingPureWtCrOrDrColumn,
    ClosingPureWtValueColumn,
    ledgerType

  ]
  
  export const AgGridAccountLedgerReportAmountTable: AgGridI = {
    storageName: StorageName.REPORT_ACCOUNTLEDGERREPORT_AMOUNT_GRID,
    colDefs: AccountLedgerReportColumnDef.map((item, index) => ({
      ...item,
      headerComponentParams: {
        ...item.headerComponentParams,
        moreButton : false,
        selectAll :  false,
        isFilter : true,
      },
    })),
    rowSelection: "multiple",
    columnSettings:true,
    singleClickEdit:true,
    rows:[],
    rowHeight:28,
    isServerSidepagination: true,  
    apiUrl :  API_URL_GET_ACCOUNTlEDGER_REPORT_AMOUNT,
    filterStorageName : StorageName.FILTER_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID,
    sortStorageName : StorageName.SORT_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID,
    paginationStorageName : StorageName.PAGINATION_ACCOUNT_LEDGER_REPORT_AMOUNT_GRID,
    isFilter:true
  };
  
