<div class="mb-0 h-100 d-flex align-items-center w-100 me-2 px-2 checkboxRenderer" 
*ngIf="!this.customCheckbox"
[ngClass]="{'justify-content-center':!labelName}">
  <div [ngClass]="{'cursor-not-allowed ': !params?.data?.isCursor}" class="d-flex align-items-center" *ngIf="!params?.data?.isFooterRow &&  !params?.data.isSumRow">
    <input 
      class="form-check-input mt-0" 
      type="checkbox"
      role="checkbox"
      [checked]="checked"
      (change)="onCheckboxChange($event)"
      [disabled]="isReadOnly || params?.data?.isReadOnly || params?.data?.isAlreadyUsed"
      [ngClass]="{'cursorPointer': isCursor ,'pointer-none': !params?.data?.isCursor,'cursor-not-allowed opacity-50': params?.data?.isAlreadyUsed}"
    >
    <label *ngIf="showLabel" class="mb-0 ps-2 lh-1" [ngClass]="{'cursorPointer':!(isReadOnly || params?.data?.isReadOnly),'cursor-not-allowed':params?.data?.isAlreadyUsed}">{{ labelName }}</label>
  </div>
</div>

  <span class="d-flex justify-content-center aling-item-center mt-rem" *ngIf="this.customCheckbox && !params?.data?.isFooterRow &&  !params?.data.isSumRow">
    <input 
      class="cursorPointer form-check-input my-auto "
      style="border:1px solid #5843CF; width: 17px; height: 16px;"
      type="checkbox"
      role="checkbox"
      [checked]="checked"
      (change)="onCheckboxChange($event)"
      [disabled]="isReadOnly || params?.data?.isReadOnly"
      [ngClass]="{'cursorPointer': isCursor ,'pointer-none': !params?.data?.isCursor}"
    >
  </span>