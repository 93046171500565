<div class="col editor-container">
    <textarea data-character-count
        [id]="id"
        debounce="500"
        [(ngModel)]="inputValue"
        (paste)="onPaste($event)"
        (drop)="onDrop($event)"
        (ngModelChange)="onNgModelChange($event)"
        placeholder="Type your Markdown here..."
        style="font-family: monospace; resize: none;">
    </textarea>
</div>